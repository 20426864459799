import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { RUBRO_AEREA_CARGO_PAIS_MEDIODECONTACTO } from "../../utils/graphql_query";
import LoadingPage from "../../components/base/LoadingPage";
import Step1 from "./Step1";
import Step2 from "./Step2";
import HeaderStep from "./HeaderStep";
import Step4 from "./Step4";
import Step5 from "./Step5";
// import StepContactoGeneral from './StepContactoGeneral';
import StepContactoGeneralV2 from "./StepContactoGeneralV2";
import useCountries from "../../hooks/useCountries";
import useCities from "../../hooks/useCities";

const baseAlojamiento = {
  tipoAlojamiento: 0,
  categoria: 0,
  anioConstruccion: 0,
  nroPiso: 0,
  tipoPagos: [],

  nroSingle: 0,
  nroDoble: 0,
  nroTriple: 0,
  nroCuadruple: 0,
  nroMatrimonial: 0,
  nroSuite: 0,

  servicios: [],
  lugares: [],
};
const baseEmpresa = {
  nombre: "",
  razon_social: "",
  nit: "",
  web: "",
  rubro: 0,
  pais: [],
  ciudad: [],
};

const RegisterEmpresa = ({ data }) => {
  // STATE
  const [empresa, setEmpresa] = useState(baseEmpresa);
  const [sucursales, setSucursales] = useState([]);
  const [stepShow, setStepShow] = useState(1);
  const [resultado, setResultado] = useState({ success: false, mensaje: "" });
  const [alojamiento, setAlojamiento] = useState(baseAlojamiento);
  const [contactoGeneral, setContactoGeneral] = useState([]);
  // CUSTOM HOOKS
  const [getCities, { loading: loadCities, data: dataCities }] = useCities();
  // QUERY
  const {
    loading,
    error,
    data: dataQuery,
  } = useQuery(RUBRO_AEREA_CARGO_PAIS_MEDIODECONTACTO);
  // CUSTOM
  const { loading: loadingCountry, response: countries } = useCountries();
  // RENDER
  if (loading || loadingCountry) return <LoadingPage />;
  if (error) return `Error! ${error}`;

  return (
    <>
      <HeaderStep show={stepShow} resultado={resultado} />

      {stepShow === 1 && (
        <Step1
          empresa={empresa}
          setEmpresa={setEmpresa}
          rubros={dataQuery.rubros}
          setStep={setStepShow}
          alojamiento={alojamiento}
          setAlojamiento={setAlojamiento}
          paises={countries}
          getCities={getCities}
          loadCities={loadCities}
          dataCities={dataCities}
        />
      )}

      {stepShow === 2 && (
        <StepContactoGeneralV2
          rubroEmpresa={empresa.rubro}
          medioDeContactos={dataQuery.medio_de_contacto_all}
          contactoGeneral={contactoGeneral}
          setContactoGeneral={setContactoGeneral}
          setStep={setStepShow}
        />
      )}

      {stepShow === 3 && (
        <Step2
          sucursales={sucursales}
          setSucursales={setSucursales}
          setStep={setStepShow}
          paises={countries}
          areas={dataQuery.areas}
          cargos={dataQuery.cargos}
        />
      )}

      {stepShow === 4 && (
        <Step4
          empresa={empresa}
          sucursales={sucursales}
          rubros={dataQuery.rubros}
          areas={dataQuery.areas}
          cargos={dataQuery.cargos}
          setStep={setStepShow}
          invitacion={data.invitacion}
          setResultado={setResultado}
          alojamiento={alojamiento}
          contactoGeneral={contactoGeneral}
          medioDeContactos={dataQuery.medio_de_contacto_all}
        />
      )}

      {stepShow === 5 && (
        <Step5 empresa={empresa} resultado={resultado} setStep={setStepShow} />
      )}
    </>
  );
};

export default RegisterEmpresa;
