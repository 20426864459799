import React from "react";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import {
  DELETE_CONTACTO_GENERAL,
  UPDATE_CONTACTO_GENERAL,
} from "../../utils/graphql_mutation";
import FrmGrupo from "./FrmGrupo";
import { ValidarFormCrudGrupo } from "../../utils/helpers";
import LoadingButton from "../base/LoadingButton";

const CrudContactoGeneral = ({ crud, refectEmpresa, onClose }) => {
  // VARIABLE
  const initialState = {
    nombre: "",
    telefono: "",
    email: "",
    chat: "",
    visible: true,
    dpto_area: "",
    comentario: "",
  };
  // STATE
  const [contacto, setContacto] = useState(initialState);
  const [sending, setSending] = useState(false);
  const [validate, setValidate] = useState(false);
  // MUTATION
  const [deleteContactoGeneral] = useMutation(DELETE_CONTACTO_GENERAL);
  const [updateContactoGeneral] = useMutation(UPDATE_CONTACTO_GENERAL);
  // FUNCTION
  const handleModificar = async () => {
    setSending(true);
    if (ValidarFormCrudGrupo(contacto)) {
      try {
        const params = {
          id: crud.grupo.id,
          nombre: _.trim(contacto.nombre),
          telefono: _.trim(contacto.telefono),
          email: _.trim(contacto.email),
          chat: _.trim(contacto.chat),
          visible: contacto.visible ? 1 : 0,
          dpto_area: _.trim(contacto.dpto_area),
          comentario: _.trim(contacto.comentario),
        };
        await updateContactoGeneral({ variables: params });
        await refectEmpresa();
        onClose();
      } catch (error) {
        console.log(error);
        onClose();
      }
    } else {
      setValidate(true);
      setSending(false);
    }
  };
  const handleEliminar = async () => {
    setSending(true);
    try {
      await deleteContactoGeneral({ variables: { id: crud.grupo.id } });
      await refectEmpresa();
      onClose();
    } catch (error) {
      console.log(error);
      onClose();
    }
  };
  const handleChange = (e) => {
    let { type, name, value, checked } = e.target;
    if (type === "checkbox") {
      value = checked;
    }
    setContacto({ ...contacto, [name]: value });
  };
  // EFFECT
  useEffect(() => {
    if (crud.type !== "create") {
      setContacto({
        nombre: crud.grupo.nombre,
        telefono: crud.grupo.telefono,
        email: crud.grupo.email ? crud.grupo.email : "",
        chat: crud.grupo.chat ? crud.grupo.chat : "",
        visible: parseInt(crud.grupo.visible) > 0 ? true : false,
        dpto_area: crud.grupo.dpto_area ? crud.grupo.dpto_area : "",
        comentario: crud.grupo.comentario ? crud.grupo.comentario : "",
      });
    }
  }, [crud]);
  // RENER
  const disabledDelete = crud.type === "delete" ? true : false;
  return (
    <Modal show={true} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>
          {`${
            crud.type === "create"
              ? "Agregar - Contacto Generico o Globales"
              : ""
          }`}
          {`${
            crud.type === "update"
              ? "Editar - Contacto Generico o Globales"
              : ""
          }`}
          {`${
            crud.type === "delete"
              ? "Eliminar - Contacto Generico o Globales"
              : ""
          }`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-4">
        <FrmGrupo
          typeCrud={crud.type}
          validate={validate}
          grupo={contacto}
          handleChange={handleChange}
          disabledDelete={disabledDelete}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} disabled={sending}>
          Cancelar
        </Button>
        {crud.type === "update" && (
          <Button
            variant="primary"
            onClick={handleModificar}
            disabled={sending}
          >
            {sending && <LoadingButton />}
            Modificar
          </Button>
        )}
        {crud.type === "delete" && (
          <Button variant="danger" onClick={handleEliminar} disabled={sending}>
            {sending && <LoadingButton />}
            Eliminar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CrudContactoGeneral;
