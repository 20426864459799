import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useFormik } from "formik";

import { useQuery, useMutation } from "@apollo/client";
import {
  ADD_EMPRESA,
  ADD_ALOJAMIENTO,
  ADD_TIPO_PAGO_ALOJAMIENTO,
  ADD_SERVICIOS_ALOJAMIENTO,
  ADD_PUNTO_INTERES,
  // OBTENER_CIUDADES,
} from "../utils/graphql_mutation";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { RUBROS_Y_PAISES } from "../utils/graphql_query";

import SelectPaises from "./../components/pais/SelectPaises";
import SelectCiudades from "./../components/ciudad/SelectCiudades";
import RegistrarAlojamiento from "../components/alojamiento/RegistrarAlojamiento";

import useCountries from "../hooks/useCountries";
import useCities from "../hooks/useCities";

const baseAlojamiento = {
  tipoAlojamiento: 0,
  categoria: 0,
  anioConstruccion: 0,
  nroPiso: 0,
  tipoPagos: [],

  nroSingle: 0,
  nroDoble: 0,
  nroTriple: 0,
  nroCuadruple: 0,
  nroMatrimonial: 0,
  nroSuite: 0,

  servicios: [],
  lugares: [],
};

// const blankSucursal = {
//   nombre: "",
//   telefono: "",
//   direccion: "",
//   ciudad_id: null,
//   idpais: 0,
// };

const NuevaEmpresa = () => {
  const idHotelero = process.env.REACT_APP_RUBRO_HOTELERO_ID;
  let history = useHistory();

  const [validate, setValidate] = useState(false);
  const [alojamiento, setAlojamiento] = useState(baseAlojamiento);
  const [empresa, setEmpresa] = useState({ pais: [], ciudad: [] });
  const [ciudades, setCiudades] = useState([]);
  const [SucursalState, setSucursalState] = useState([]);

  const { loading, error, data: datos } = useQuery(RUBROS_Y_PAISES);

  const { response: countries } = useCountries();

  const formik = useFormik({
    initialValues: {
      nombre: "",
      razon_social: "",
      nit: "",
      rubro: 0,
      web_oficial: "",
    },
    onSubmit: (values) => {
      handleRegister(values);
    },
  });

  const [addEmpresa] = useMutation(ADD_EMPRESA);
  const [addAlojamiento] = useMutation(ADD_ALOJAMIENTO);
  const [addTipoPagoAlojamiento] = useMutation(ADD_TIPO_PAGO_ALOJAMIENTO);
  const [addServiciosAlojamiento] = useMutation(ADD_SERVICIOS_ALOJAMIENTO);
  const [addPuntoInteresAlojamiento] = useMutation(ADD_PUNTO_INTERES);

  const [getCities, { loading: loadCities, data: dataCities }] = useCities();

  const removerSucursal = (idx) => {
    const temp = [...SucursalState];
    temp.splice(idx, 1);
    setSucursalState(temp);
  };
  const handleSucursalChange = (e) => {
    const updatedSucursal = [...SucursalState];
    updatedSucursal[e.target.dataset.idx][e.target.dataset.name] =
      e.target.value;
    setSucursalState(updatedSucursal);
  };
  const handlePaisChange = (e, id) => {
    let temp = [...SucursalState];
    temp[id].idpais = e[0].id;
    setSucursalState(temp);
  };
  const handleCiudadChange = (e, id) => {
    let temp = [...SucursalState];
    temp[id].ciudad_id = e[0].id;
    console.log(e);
    setSucursalState(temp);
  };
  const createSucursalEmpresa = (values) => {
    if (SucursalState.length > 0) {
      values.sucursales = JSON.stringify(SucursalState);
    }
    return values;
  };
  const handleRegister = async (values) => {
    if (validarForm(values)) {
      let valores = createSucursalEmpresa(values);
      try {
        const {
          data: { addEmpresa: resultEmpresa },
        } = await addEmpresa({
          variables: {
            nombre: valores.nombre,
            razon_social: valores.razon_social,
            nit: valores.nit,
            rubro: parseInt(valores.rubro),
            sucursales: valores.sucursales ? valores.sucursales : "",
            web_oficial: valores.web_oficial,
            pais_id: parseInt(empresa.pais[0].id),
            ciudad_id: _.size(empresa.ciudad) > 0 ? empresa.ciudad[0].id : 0,
            departamento_id:
              _.size(empresa.ciudad) > 0 ? empresa.ciudad[0].state_id : 0,
            provincia_id:
              _.size(empresa.ciudad) > 0 ? empresa.ciudad[0].province_id : 0,
          },
        });

        // REgistrar DATOS DE HOTELES
        if (resultEmpresa && parseInt(valores.rubro) === parseInt(idHotelero)) {
          // ALOJAMIENTO
          const {
            data: { addAlojamiento: resultAlojamiento },
          } = await addAlojamiento({
            variables: {
              tipo_alojamiento_id: parseInt(alojamiento.tipoAlojamiento),
              empresa_id: parseInt(resultEmpresa.id),
              categoria: parseInt(alojamiento.categoria),
              fecha_construccion: alojamiento.anioConstruccion.toString(),
              numero_pisos: parseInt(alojamiento.nroPiso),
              nro_single: parseInt(alojamiento.nroSingle),
              nro_doble: parseInt(alojamiento.nroDoble),
              nro_triple: parseInt(alojamiento.nroTriple),
              nro_cuadruple: parseInt(alojamiento.nroCuadruple),
              nro_matrimonial: parseInt(alojamiento.nroMatrimonial),
              nro_suite: parseInt(alojamiento.nroSuite),
            },
          });

          // Verificar si se registro ALOJAMIENTO
          if (resultAlojamiento) {
            // TIPO_PAGO_ALOJAMIENTO
            await Promise.all(
              _.map(alojamiento.tipoPagos, async (tipo) => {
                await addTipoPagoAlojamiento({
                  variables: {
                    alojamiento_id: parseInt(resultAlojamiento.id),
                    tipo_pago_id: parseInt(tipo.value),
                  },
                });
              })
            );

            // SERVICIOS_ALOJAMIENTO
            await Promise.all(
              _.map(alojamiento.servicios, async (servicio) => {
                await addServiciosAlojamiento({
                  variables: {
                    alojamiento_id: parseInt(resultAlojamiento.id),
                    servicio_alojamiento_id: parseInt(servicio.id),
                  },
                });
              })
            );

            // PUNTO_INTERES
            await Promise.all(
              _.map(alojamiento.lugares, async (lugar) => {
                await addPuntoInteresAlojamiento({
                  variables: {
                    alojamiento_id: parseInt(resultAlojamiento.id),
                    lugar: lugar.nombre,
                    distancia: lugar.distancia,
                  },
                });
              })
            );
          }
        }

        history.push(`/proveedor/${resultEmpresa.id}`);
      } catch (error) {
        console.log(error);
      }
    } else {
    }
  };
  const validarForm = (values) => {
    // EMPRESA
    let result = [];
    if (_.trim(values.nombre) === "") {
      result = _.concat(result, "NOMBRE");
    }
    if (parseInt(values.rubro) === 0) {
      result = _.concat(result, "RUBRO");
    }
    if (_.size(empresa.pais) === 0) {
      result = _.concat(result, "PAIS");
    }

    // SUCURSAL
    _.map(SucursalState, (val, i) => {
      if (_.trim(val.nombre) === "") {
        result = _.concat(result, "NOMBRES SUCURSAL #" + (i + 1));
      }
      if (_.trim(val.telefono) === "") {
        result = _.concat(result, "TELEFONO SUCURSAL #" + (i + 1));
      }
      if (_.trim(val.direccion) === "") {
        result = _.concat(result, "DIRECCION SUCURSAL #" + (i + 1));
      }
    });

    // ALOJAMIENTO
    if (parseInt(values.rubro) === parseInt(idHotelero)) {
      if (parseInt(alojamiento.tipoAlojamiento) === 0)
        result = _.concat(result, "TIPO DE ALOJAMIENTO");
      if (_.size(alojamiento.tipoPagos) === 0)
        result = _.concat(result, "TIPO DE PAGOS");
    }

    // VERIFICAR SI EXITE ERROR
    if (_.size(result) > 0) {
      setValidate(true);
      return false;
    }
    return true;
  };
  const handleChangePais = async (e) => {
    setEmpresa({ ...empresa, pais: e });
    if (_.size(e) > 0) {
      getCities({ countryId: e[0].id });
    } else {
      setCiudades([]);
    }

    setEmpresa({ ...empresa, pais: e, ciudad: [] });
  };
  // EFFECT
  useEffect(() => {
    setCiudades(dataCities);
  }, [dataCities]);

  if (loading) return <p>Cargando Datos...</p>;
  if (error) return `Error! ${error}`;

  let classNombre = "";
  let classRubro = "";
  let validatePais = "";
  if (validate) {
    classNombre = formik.values.nombre === "" ? "is-invalid" : "is-valid";
    classRubro = formik.values.rubro === 0 ? "is-invalid" : "is-valid";
    validatePais = _.size(empresa.pais) > 0 ? "isValid" : "isInvalid";
  }

  return (
    <Container className="  mt-4 ">
      <h3 className="pl-0 mb-4">
        <i className="far fa-building"></i> Nueva Empresa
      </h3>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Row className="p-5 shadow bg-white rounded">
          <Form.Group as={Col} md="12">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              name="nombre"
              value={formik.values.nombre}
              onChange={formik.handleChange}
              autoComplete="off"
              className={classNombre}
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Razón Social</Form.Label>
            <Form.Control
              type="text"
              name="razon_social"
              value={formik.values.razon_social}
              onChange={formik.handleChange}
              autoComplete="off"
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Nit</Form.Label>
            <Form.Control
              type="text"
              name="nit"
              value={formik.values.nit}
              onChange={formik.handleChange}
              autoComplete="off"
            />
          </Form.Group>

          <Form.Group as={Col} md="6">
            <Form.Label>Rubro</Form.Label>
            <Form.Control
              name="rubro"
              as="select"
              onChange={formik.handleChange}
              value={formik.values.rubro}
              className={classRubro}
            >
              <option value="0"></option>
              {_.map(datos.rubros, (val) => {
                return (
                  <option key={val.id} value={val.id}>
                    {val.nombre}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Web Oficial</Form.Label>
            <Form.Control
              type="text"
              name="web_oficial"
              value={formik.values.web_oficial}
              onChange={formik.handleChange}
              autoComplete="off"
            />
          </Form.Group>

          <Form.Group as={Col} md="6">
            <Form.Label>Pais</Form.Label>
            <Typeahead
              id="pais"
              name="pais"
              labelKey="name"
              onChange={(e) => handleChangePais(e)}
              options={countries}
              selected={empresa.pais}
              isInvalid={
                validate && validatePais === "isInvalid" ? true : false
              }
              isValid={validate && validatePais === "isValid" ? true : false}
            />
          </Form.Group>

          <Form.Group as={Col} md="6">
            <Form.Label>Ciudad</Form.Label>
            <Typeahead
              id="ciudad"
              name="ciudad"
              labelKey="name"
              onChange={(e) => setEmpresa({ ...empresa, ciudad: e })}
              options={ciudades}
              selected={empresa.ciudad}
              placeholder={loadCities ? "Cargando..." : ""}
              disabled={loadCities}
            />
          </Form.Group>
        </Row>

        {/* <Row className="mt-5">
          <Form.Group as={Col} md="4" className="d-flex my-3">
            <h5>Agregar Oficina </h5>{" "}
            <Button
              type="button"
              className="btn btn-sm ms-2 "
              onClick={addSucursal}
            >
              <i className="fa fa-plus-square" />
            </Button>
          </Form.Group>
        </Row> */}
        {SucursalState.map((val, idx) => {
          const sucursalId = `nombre-${idx}`;
          const telefonoId = `telefono-${idx}`;
          const direccionId = `direccion-${idx}`;

          let classNombreSuc = "";
          let classTelefonoSuc = "";
          let classDireccionSuc = "";
          if (validate) {
            classNombreSuc = val.nombre === "" ? "is-invalid" : "is-valid";
            classTelefonoSuc = val.telefono === "" ? "is-invalid" : "is-valid";
            classDireccionSuc =
              val.direccion === "" ? "is-invalid" : "is-valid";
          }

          return (
            <Row
              key={`sucursal-${idx}`}
              className="p-2 mb-2 shadow bg-white rounded"
            >
              <Form.Group as={Col} md="4">
                <Form.Label htmlFor={sucursalId}>Nombre Oficina</Form.Label>
                <Form.Control
                  // size="sm"
                  type="text"
                  name={sucursalId}
                  data-idx={idx}
                  id={sucursalId}
                  value={SucursalState[idx].nombre}
                  data-name="nombre"
                  onChange={handleSucursalChange}
                  autoComplete="off"
                  className={classNombreSuc}
                />
              </Form.Group>
              <Form.Group as={Col} md="2">
                <Form.Label htmlFor={telefonoId}>Teléfono</Form.Label>
                <Form.Control
                  // size="sm"
                  type="text"
                  name={telefonoId}
                  data-idx={idx}
                  id={telefonoId}
                  value={SucursalState[idx].telefono}
                  data-name="telefono"
                  onChange={handleSucursalChange}
                  autoComplete="off"
                  className={classTelefonoSuc}
                />
              </Form.Group>
              <Form.Group as={Col} md="5">
                <Form.Label htmlFor={direccionId}>Dirección</Form.Label>
                <Form.Control
                  // size="sm"
                  type="text"
                  name={direccionId}
                  data-idx={idx}
                  id={direccionId}
                  value={SucursalState[idx].direccion}
                  data-name="direccion"
                  onChange={handleSucursalChange}
                  autoComplete="off"
                  className={classDireccionSuc}
                />
              </Form.Group>

              <Form.Group as={Col} md="1">
                <Form.Label>Quitar</Form.Label>
                <Button
                  onClick={() => removerSucursal(idx)}
                  type="button"
                  size="sm"
                  className="form-control form-control-sm shadow"
                  variant="danger"
                >
                  <i className="fas fa-trash" />
                </Button>
              </Form.Group>

              <Form.Group as={Col} md="4">
                <Form.Label>Pais</Form.Label>
                <SelectPaises
                  options={countries}
                  id={idx}
                  selected={[]}
                  handlePaisChange={handlePaisChange}
                />
              </Form.Group>
              <Form.Group as={Col} md="4">
                <Form.Label>Ciudad</Form.Label>
                <SelectCiudades
                  idPais={SucursalState[idx].idpais}
                  id={idx}
                  handleCiudadChange={handleCiudadChange}
                  selected={[]}
                />
              </Form.Group>
            </Row>
          );
        })}

        {parseInt(formik.values.rubro) === parseInt(idHotelero) && (
          <>
            <Row className="mt-5">
              <Form.Group as={Col} md="4" className="d-flex my-3">
                <h5>Informacion Hotelero</h5>
              </Form.Group>
            </Row>
            <Container className="p-5 mb-2 shadow bg-white rounded">
              <RegistrarAlojamiento
                alojamiento={alojamiento}
                setAlojamiento={setAlojamiento}
                validate={validate}
              />
            </Container>
          </>
        )}

        <Button className="mt-4 mb-5" type="submit">
          Registrar Empresa
        </Button>
      </Form>
    </Container>
  );
};

export default NuevaEmpresa;
