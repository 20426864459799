import React from "react";
import ContainerNotResult from "../base/ContainerNotResult";
import { Col, Row, Button } from "react-bootstrap";
import _ from "lodash";
import LoadingButton from "../base/LoadingButton";

const CiudadFormAdd = ({ selected, nameCity, loadAddCity, onRegister }) => {
  return (
    <ContainerNotResult icon="fas fa-globe-americas" classContainer="mt-3">
      <Row>
        <Col md={{ span: 3, offset: 3 }} className="text-end">
          <b>Pais :</b>
        </Col>
        <Col md="6" className="text-start">
          {selected.country[0].name}
        </Col>
      </Row>

      {_.size(selected.state) > 0 && (
        <Row>
          <Col md={{ span: 3, offset: 3 }} className="text-end">
            <b>Departamento :</b>
          </Col>
          <Col md="6" className="text-start">
            {selected.state[0].name}
          </Col>
        </Row>
      )}

      {_.size(selected.province) > 0 && (
        <Row>
          <Col md={{ span: 3, offset: 3 }} className="text-end">
            <b>Provincia :</b>
          </Col>
          <Col md="6" className="text-start">
            {selected.province[0].nombre}
          </Col>
        </Row>
      )}

      <Row>
        <Col md={{ span: 3, offset: 3 }} className="text-end">
          <b>Ciudad :</b>
        </Col>
        <Col md="6" className="text-start">
          {nameCity}
        </Col>
      </Row>

      <Button
        variant="primary"
        onClick={onRegister}
        className="mt-3"
        disabled={loadAddCity}
      >
        {loadAddCity && <LoadingButton />}
        Registrar
      </Button>
    </ContainerNotResult>
  );
};

export default CiudadFormAdd;
