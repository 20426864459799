import { useState, useEffect } from "react";
import countries from "../data/countries.min.json";

const useCountries = () => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState([]);

  const fetchData = () => {
    setResponse(countries);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { loading, response };
};

export default useCountries;
