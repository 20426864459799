import React, { useState, useEffect } from 'react'
import { Container, Form, Col, Button, ListGroup, Row } from 'react-bootstrap';
import _ from 'lodash'
import { ValidarFormContactoGeneralV2 } from '../../utils/helpers';
import ButtonIcon from '../base/ButtonIcon';

const baseDetalleContacto = { telefono: "", email: "" };
const baseContactoGeneral = { titulo: '', detalleContacto: [baseDetalleContacto] }

const StepContactoGeneralV2 = ({ medioDeContactos, contactoGeneral, setContactoGeneral, setStep, rubroEmpresa}) => {
  const idHotelero = process.env.REACT_APP_RUBRO_HOTELERO_ID

  const [validate, setValidate] = useState(false);

  const handleAddContacto = () => {
    setContactoGeneral(_.concat(contactoGeneral, baseContactoGeneral))
  }

  const handleDeleteContacto = (i) => {
    let result = []
    _.map(contactoGeneral, (contacto, kc) => {
      if (kc !== i) {
        result = _.concat(result, contacto)
      }
    })
    setContactoGeneral(result)
  }

  const handleChange = (e, i) => {
    let result = []
    _.map(contactoGeneral, (contacto, kc) => {
      let aux = contacto
      if (kc === i) {
        aux = {
          ...contacto,
          [e.target.name]: e.target.value
        }
      }
      result = _.concat(result, aux)
    })
    setContactoGeneral(result)
  }

  const handleChangeDetalle = (e, ic, id) => {
    let result = []
    _.map(contactoGeneral, (contacto, kc) => {
      let auxContacto = contacto
      if (kc === ic) {
        let auxDetale = []
        _.map(contacto.detalleContacto, (detalle, kd) => {
          let aux = detalle
          if (kd === id) {
            let place = '';
            if (e.target.name === 'medio' && parseInt(e.target.value) > 0) {
              const objMedio = _.find(medioDeContactos, (medio) => parseInt(medio.id) === parseInt(e.target.value))
              place = `Ingrese el ${objMedio.nombre}`;
            }
            aux = { ...detalle, placeholder: place, [e.target.name]: e.target.value }
          }
          auxDetale = _.concat(auxDetale, aux)
        })
        auxContacto = { ...auxContacto, detalleContacto: auxDetale }
      }
      result = _.concat(result, auxContacto)
    })
    setContactoGeneral(result)
  }

  const handleNext = () => {
    if (ValidarFormContactoGeneralV2(contactoGeneral)) {
      setStep(3)
    } else {
      setValidate(true)
    }
  }

  useEffect(() => {
    // CARGAR 3 CONTACTO SI ES RUBRO HOTEL
    const cargarContactoGeneralHotel = () => {
      if (_.size(contactoGeneral) === 0 && parseInt(rubroEmpresa) === parseInt(idHotelero)) {
        const obj = [
          { titulo: 'Reserva', detalleContacto: [baseDetalleContacto] },
          { titulo: 'Ventas', detalleContacto: [baseDetalleContacto] },
          { titulo: 'Contabilidad', detalleContacto: [baseDetalleContacto] },
        ]
        setContactoGeneral(_.concat(contactoGeneral, obj))
      }
    }


    cargarContactoGeneralHotel();
  }, [rubroEmpresa, idHotelero, contactoGeneral, setContactoGeneral])
  
  return (
    <>
      <h3 className="mb-0">Contactos Generales</h3>
      <p>Informacion de contactos generales o grupales. Ejm (Reservas, Ventas, Contabilidad, etc)</p>

      {
        _.size(contactoGeneral) === 0 ?
          <Container className="shadow bg-white pb-3 pt-5 text-center">
            <h4>Desea agregar Contactos Generales?</h4>
            <Button variant="outline-primary" className="mt-1 mb-3" onClick={handleAddContacto}>Agregar</Button>
            <h6 className="text-secondary">Informacion no requerido para el registro.</h6>
          </Container>
        :
          <ListGroup className="shadow p-3 bg-white" variant="flush">
            <ListGroup.Item>
              <Row>
                <Col md={4} className="text-center">Titulo</Col>
                <Col md={3} className="text-center">Telefono</Col>
                <Col md={4} className="text-center">Email</Col>
              </Row>
            </ListGroup.Item>
            {
              _.map(contactoGeneral, (contacto, kc) => {
                let classTitulo = '';
                if (validate) { classTitulo = _.trim(contacto.titulo) === '' ? 'is-invalid' : 'is-valid' }

                return (
                  <ListGroup.Item key={kc}>
                    <Row>
                      <Col md={4} className="my-auto"><Form.Control
                        name="titulo"
                        type="text"
                        autoComplete="off"
                        value={contacto.titulo}
                        onChange={(e) => handleChange(e, kc)}
                        className={classTitulo}
                        placeholder="Ej: Reservas, Ventas, etc."
                        autoFocus
                      />
                      </Col>
                      <Col md={8}>
                        {
                          _.map(contacto.detalleContacto, (detalle, kd) => {
                            let classTelefonoEmail = '';
                            if (validate) {
                              classTelefonoEmail = _.trim(detalle.telefono) === "" && _.trim(detalle.email) === "" ? 'is-invalid' : 'is-valid'
                            }

                            return (
                              <Row key={kd}>
                                <Col md={5} className="my-auto">
                                  <Form.Control
                                    name="telefono"
                                    type="text"
                                    autoComplete="off"
                                    value={detalle.telefono}
                                    onChange={(e) => handleChangeDetalle(e, kc, kd)}
                                    placeholder="Telefono"
                                    className={classTelefonoEmail}
                                  />
                                </Col>
                                <Col md={5} className="my-auto">
                                  <Form.Control
                                    name="email"
                                    type="text"
                                    autoComplete="off"
                                    value={detalle.email}
                                    onChange={(e) => handleChangeDetalle(e, kc, kd)}
                                    className={classTelefonoEmail}
                                    placeholder="Email"
                                  />
                                </Col>
                                <Col md={2} className="my-auto">
                                  <Form.Group as={Row} className="my-auto">
                                    <ButtonIcon titulo="Quitar" icono="fa fa-times" variant="danger" size="sm" className="me-2" onClick={() => handleDeleteContacto(kc)}/>
                                    {
                                      kc + 1 === _.size(contactoGeneral) &&
                                      <ButtonIcon titulo="Nuevo" icono="fa fa-plus" variant="primary" size="sm" onClick={handleAddContacto}/>
                                    }
                                  </Form.Group>
                                </Col>
                              </Row>
                            )
                          })
                        }

                      </Col>
                    </Row>
                  </ListGroup.Item>
                )
              })
            }
          </ListGroup>
      }

      <div className="mt-3">
        <Button variant="secondary" onClick={() => setStep(1)}>Atras</Button>
        <Button variant="primary" className="float-right" onClick={handleNext}>Siguiente</Button>
      </div>
    </>
  )
}

export default StepContactoGeneralV2
