import React, { useState, useEffect } from "react";
import { Modal, Form, Row, Col, Button } from "react-bootstrap";
import _ from "lodash";
import useCountries from "../../hooks/useCountries";
import { Typeahead } from "react-bootstrap-typeahead";
import { getStatesByCountry, valiteEditService } from "../../utils/helpers";
import { GET_ATRACCIONES } from "../../utils/graphql_query";
import { useQuery, useMutation } from "@apollo/client";
import { UPDATE_SERVICE_EMPRESA } from "../../utils/graphql_mutation";
import ContainerNotResult from "../base/ContainerNotResult";

const CrudServicio = ({
  servicio,
  onClose,
  onRefresh,
  selectCountryStateCity,
  dataCities,
  loadCities,
  getCities,
}) => {
  // VARIABLE
  const initialState = {
    id: servicio.id,
    titulo: servicio.titulo,
    descripcion: servicio.descripcion,
    precio: servicio.precio,
    moneda: _.toUpper(servicio.moneda),
    nro_dias: servicio.nro_dias ? servicio.nro_dias : "",
  };
  // STATE
  const [state, setState] = useState(initialState);
  const [countryStateCity, setCountryStateCity] = useState({
    country: selectCountryStateCity.country,
    state: selectCountryStateCity.state,
    city: selectCountryStateCity.city,
  });
  const [atracciones, setAtracciones] = useState(servicio.atracciones);
  const [allState, setAllState] = useState([]);
  const [allCity, setAllCity] = useState([]);
  const [allAtraccion, setAllAtraccion] = useState([]);
  const [validate, setValidate] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [errorResponse, setErrorResponse] = useState({
    show: false,
    message: "",
  });

  // CUSTOM HOOKS
  const { loading, response: allCountry } = useCountries();
  // QUERY
  const { loading: loadingAtracion, data: dataAtraccion } =
    useQuery(GET_ATRACCIONES);
  // MUTATION
  const [updateServicio] = useMutation(UPDATE_SERVICE_EMPRESA);
  // FUNCTION
  const handleChangePais = (e) => {
    setCountryStateCity({
      ...countryStateCity,
      country: e,
      state: [],
      city: [],
    });
    if (_.size(e) > 0) {
      setAllState(getStatesByCountry(e[0].id));
      getCities({ countryId: e[0].id });
    } else {
      setAllState([]);
      setAllCity([]);
    }
  };
  const handleChangeState = (e) => {
    setCountryStateCity({ ...countryStateCity, state: e, city: [] });
    if (_.size(e) > 0) {
      getCities({ stateId: e[0].id });
    } else {
      setAllCity([]);
      getCities({ countryId: countryStateCity.country[0].id });
    }
  };
  const handleChangeCity = (e) => {
    setCountryStateCity({ ...countryStateCity, city: e });
  };
  const handleUpdateService = async () => {
    if (valiteEditService(state, countryStateCity.country)) {
      try {
        setLoadingRequest(true);
        // GET ATRACCIONES JSON
        let arrAtracciones = [];
        _.map(atracciones, (val) => {
          arrAtracciones = _.concat(arrAtracciones, val.id);
        });
        const auxCSC = countryStateCity;
        // GET PARAMS REQUEST
        const varRequest = {
          id: state.id,
          titulo: _.trim(state.titulo),
          descripcion: _.trim(state.descripcion),
          moneda: state.moneda,
          precio: parseFloat(state.precio),
          pais_id: _.size(auxCSC.country) > 0 ? auxCSC.country[0].id : 0,
          departamento_id: _.size(auxCSC.state) > 0 ? auxCSC.state[0].id : 0,
          ciudad_id: _.size(auxCSC.city) > 0 ? auxCSC.city[0].id : 0,
          json_atracciones_id: JSON.stringify(arrAtracciones),
          nro_dias:
            _.trim(state.nro_dias) !== "" && parseInt(state.nro_dias) > 0
              ? parseInt(state.nro_dias)
              : 0,
          localidad_id: 0,
        };
        // SEND
        await updateServicio({ variables: varRequest });
        await onRefresh();
        onClose();
      } catch (error) {
        console.log(error);
        setErrorResponse({
          show: true,
          message: "No se pudo modificar el servicios.",
        });
        setLoadingRequest(false);
      }
    } else {
      setValidate(true);
    }
  };
  // EFFECT
  useEffect(() => {
    if (servicio.pais_id) {
      const staties = getStatesByCountry(servicio.pais_id);
      setAllState(staties);
    }
  }, [servicio]);

  useEffect(() => {
    if (dataAtraccion) {
      setAllAtraccion(dataAtraccion.atracciones);
    }
  }, [dataAtraccion]);

  useEffect(() => {
    setAllCity(dataCities);
  }, [dataCities]);
  // RENDER
  if (loading) {
    return "Cargando...";
  }
  if (loadingAtracion) {
    return "Cargando...";
  }

  let classTitulo = "";
  let classPrecio = "";
  if (validate) {
    classTitulo = _.trim(state.titulo) === "" ? "is-invalid" : "is-valid";
    classPrecio =
      _.trim(state.precio) === "" || parseFloat(state.precio) <= 0
        ? "is-invalid"
        : "is-valid";
  }
  return (
    <Modal show={true} onHide={onClose} backdrop="static" size="lg">
      <Modal.Header>
        <Modal.Title className="h4">Editar Servicio</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        {errorResponse.show ? (
          <ContainerNotResult
            icon="fas fa-exclamation-triangle"
            mensaje={errorResponse.message}
            iconColor="danger"
          />
        ) : (
          <>
            <Form.Group as={Row}>
              <Form.Label column sm={12}>
                Titulo *
              </Form.Label>
              <Col sm={12}>
                <Form.Control
                  input="text"
                  name="titulo"
                  autoComplete="off"
                  onChange={(e) =>
                    setState({ ...state, titulo: e.target.value })
                  }
                  value={state.titulo}
                  className={classTitulo}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={12}>
                Descripci&oacute;n
              </Form.Label>
              <Col sm={12}>
                <Form.Control
                  as="textarea"
                  name="descripcion"
                  onChange={(e) =>
                    setState({ ...state, descripcion: e.target.value })
                  }
                  value={state.descripcion}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Col sm={6}>
                <Form.Label>Precio *</Form.Label>
                <Form.Control
                  type="number"
                  name="precio"
                  autoComplete="off"
                  onChange={(e) =>
                    setState({ ...state, precio: e.target.value })
                  }
                  value={state.precio}
                  className={classPrecio}
                />
              </Col>

              <Col sm={6}>
                <Form.Label>Moneda</Form.Label>
                <Form.Control
                  as="select"
                  name="moneda"
                  autoComplete="off"
                  defaultValue={state.moneda}
                  onChange={(e) =>
                    setState({ ...state, moneda: e.target.value })
                  }
                >
                  <option value="BOB">BOB</option>
                  <option value="USD">USD</option>
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Col sm={6}>
                <Form.Label>Pais *</Form.Label>
                <Typeahead
                  clearButton
                  id="pais"
                  name="pais"
                  labelKey="name"
                  options={allCountry}
                  selected={countryStateCity.country}
                  onChange={handleChangePais}
                  isInvalid={
                    validate && _.size(countryStateCity.country) === 0
                      ? true
                      : false
                  }
                  isValid={
                    validate && _.size(countryStateCity.country) !== 0
                      ? true
                      : false
                  }
                />
              </Col>

              <Col sm={6}>
                <Form.Label>Departamento</Form.Label>
                <Typeahead
                  clearButton
                  id="departamento"
                  name="departamento"
                  labelKey="name"
                  options={allState}
                  selected={countryStateCity.state}
                  onChange={handleChangeState}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Col sm={6}>
                <Form.Label>Ciudad</Form.Label>
                <Typeahead
                  clearButton
                  id="ciudad"
                  name="ciudad"
                  labelKey="name"
                  options={allCity}
                  selected={countryStateCity.city}
                  onChange={handleChangeCity}
                  placeholder={loadCities ? "Cargando..." : ""}
                  disabled={loadCities}
                />
              </Col>
              <Col sm={6}></Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={12}>
                Atracciones Turistica
              </Form.Label>
              <Col sm={12}>
                <Typeahead
                  id="atracciones"
                  labelKey="nombre"
                  multiple
                  options={allAtraccion}
                  selected={atracciones}
                  onChange={(e) => setAtracciones(e)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={12}>
                Nro de Dias
              </Form.Label>
              <Col sm={12}>
                <Form.Control
                  type="number"
                  name="nro_dias"
                  autoComplete="off"
                  onChange={(e) =>
                    setState({ ...state, nro_dias: e.target.value })
                  }
                  value={state.nro_dias}
                />
              </Col>
            </Form.Group>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" disabled={loadingRequest} onClick={onClose}>
          Cancelar
        </Button>
        {!errorResponse.show && (
          <Button
            variant="primary"
            disabled={loadingRequest}
            onClick={handleUpdateService}
          >
            Modificar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CrudServicio;
