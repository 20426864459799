import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { genPass, validateFormCrudUsuario } from "../../utils/helpers";
import ButtonIcon from "../base/ButtonIcon";
import { useMutation } from "@apollo/client";
import {
  ADD_USUARIO_EMPRESA,
  RESET_PASSWORD,
  DELETE_USUARIO,
} from "../../utils/graphql_mutation";
import _ from "lodash";
import ContainerNotResult from "../base/ContainerNotResult";
import isEmail from "validator/lib/isEmail";

const sizePass = 6;

const CrudUser = ({ idEmpresa, crud, onClose, onRefetch }) => {
  // console.log(crud);
  // VARIABLE
  const initialState = {
    state: {
      nombre: "",
      apellido: "",
      username: "",
      is_active: true,
      email: "",
      password: genPass(sizePass),
      sendMail: false,
    },
    response: { show: false, message: "" },
  };
  // STATE
  const [state, setState] = useState(initialState.state);
  const [validate, setValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(initialState.response);
  // MUTATION
  const [addUsuarioEmpresa] = useMutation(ADD_USUARIO_EMPRESA);
  const [resetPassword] = useMutation(RESET_PASSWORD);
  const [deleteUsuario] = useMutation(DELETE_USUARIO);
  // FUNCTION
  const newPass = () => {
    setState({ ...state, password: genPass(sizePass) });
  };
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    // console.log(state);
  };
  const handleRegistrar = async () => {
    if (validateFormCrudUsuario(state)) {
      try {
        setLoading(true);
        let params = {
          nombre: _.trim(state.nombre),
          apellido: _.trim(state.apellido),
          username: _.trim(state.username),
          email: _.trim(state.email),
          password: _.trim(state.password),
          empresa_id: parseInt(idEmpresa),
        };
        // Enviar mail
        if (state.sendMail) {
          params = { ...params, sendMail: 1 };
        }
        if (state.is_active) {
          params = { ...params, is_active: 1 };
        }
        // Registrar
        const { data } = await addUsuarioEmpresa({ variables: params });
        const { success, message } = data.addUsuarioEmpresa;
        if (success) {
          onRefetch();
          onClose();
        } else {
          setResponse({ ...response, show: true, message: message });
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      setValidate(true);
    }
  };
  const handleModificar = async () => {
    if (validateFormCrudUsuario(state)) {
      try {
        setLoading(true);
        let params = {
          id: parseInt(crud.usuario.id),
          password: _.trim(state.password),
        };
        // Enviar mail
        if (state.sendMail) {
          params = { ...params, sendMail: 1 };
        }
        if (state.is_active) {
          params = { ...params, is_active: 1 };
        }
        const { data } = await resetPassword({ variables: params });
        const { success, message } = data.resetPassword;
        if (success) {
          onRefetch();
          onClose();
        } else {
          setResponse({ ...response, show: true, message: message });
        }
        // resetPassword;
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      setValidate(true);
    }
  };
  const handleEliminar = async () => {
    try {
      setLoading(true);
      const {
        data: {
          deleteUsuario: { success, message },
        },
      } = await deleteUsuario({ variables: { id: parseInt(crud.usuario.id) } });
      if (success) {
        onRefetch();
        onClose();
      } else {
        setResponse({ ...response, show: true, message: message });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  // EFFECT
  useEffect(() => {
    if (crud && crud.type !== "create") {
      setState({
        nombre: crud.usuario.persona.nombres,
        apellido: crud.usuario.persona.apellidos,
        username: crud.usuario.username,
        email: crud.usuario.email,
        password: "",
        sendMail: false,
        is_active: crud.usuario.is_active,
      });
    }
  }, [crud]);
  // RENDER

  let classNombre = "";
  let classApellido = "";
  let classEmail = "";
  let classPassword = "";
  let classUsername = "";
  if (validate) {
    classNombre = _.trim(state.nombre) === "" ? "is-invalid" : "is-valid";
    classApellido = _.trim(state.apellido) === "" ? "is-invalid" : "is-valid";
    classUsername = _.trim(state.username) === "" ? "is-invalid" : "is-valid";
    classEmail =
      _.trim(state.email) === "" || !isEmail(_.trim(state.email))
        ? "is-invalid"
        : "is-valid";
    classPassword = 
    _.size(_.trim(state.password)) > 0 ?
          _.size(_.trim(state.password)) < sizePass
          ? "is-invalid"
          : "is-valid" 
        : "is-valid"
  }
  return (
    <Modal show={true} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>
          {`${crud.type === "create" ? "Agregar Usuario" : ""}`}
          {`${crud.type === "update" ? "Actualizar Password-Estado" : ""}`}
          {`${crud.type === "delete" ? "Eliminar Usuario" : ""}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        {response.show ? (
          <ContainerNotResult
            icon="fas fa-exclamation-triangle"
            mensaje={response.message}
            iconColor="danger"
          />
        ) : (
          <Form>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Nombre *
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  name="nombre"
                  type="text"
                  autoComplete="off"
                  value={state.nombre}
                  onChange={handleChange}
                  className={classNombre}
                  disabled={crud.type !== "create"}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Apellido *
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  name="apellido"
                  type="text"
                  autoComplete="off"
                  value={state.apellido}
                  onChange={handleChange}
                  className={classApellido}
                  disabled={crud.type !== "create"}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Username *
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  name="username"
                  type="text"
                  autoComplete="off"
                  value={state.username}
                  onChange={handleChange}
                  className={classNombre}
                  disabled={crud.type !== "create"}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Email *
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  name="email"
                  type="text"
                  autoComplete="off"
                  value={state.email}
                  onChange={handleChange}
                  className={classEmail}
                  disabled={crud.type !== "create"}
                />
              </Col>
            </Form.Group>
            {crud.type !== "delete" && (
              <>
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Password *
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      name="password"
                      type="text"
                      autoComplete="off"
                      value={state.password}
                      onChange={(e) =>
                        setState({
                          ...state,
                          password: _.toLower(_.trim(e.target.value)),
                        })
                      }
                      className={classPassword}
                    />
                  </Col>
                  <Col sm="2">
                    <ButtonIcon
                      titulo="Generar Password"
                      variant="primary"
                      icono="fas fa-sync-alt"
                      onClick={() => newPass()}
                      disabled={loading}
                    >
                      <i className="fas fa-sync-alt"></i>
                    </ButtonIcon>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="is_active">
                  <Col sm={{ span: 9, offset: 3 }}>
                    <Form.Check
                      id="is_active"
                      label="Activar Usuario Trippin-hotelero"
                      onChange={(e) =>
                        setState({ ...state, is_active: e.target.checked })
                      }
                      checked={state.is_active}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="sendMail">
                  <Col sm={{ span: 9, offset: 3 }}>
                    <Form.Check
                      id="sendMail"
                      label="Enviar correo electronico"
                      onChange={(e) =>
                        setState({ ...state, sendMail: e.target.checked })
                      }
                      checked={state.sendMail}
                    />
                  </Col>
                </Form.Group>
              </>
            )}
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} disabled={loading}>
          Cancelar
        </Button>

        {crud.type === "create" && !response.show && (
          <Button
            variant="primary"
            onClick={handleRegistrar}
            disabled={loading}
          >
            Registrar
          </Button>
        )}

        {crud.type === "update" && (
          <Button
            variant="primary"
            onClick={handleModificar}
            disabled={loading}
          >
            Actualizar
          </Button>
        )}

        {crud.type === "delete" && (
          <Button variant="danger" onClick={handleEliminar} disabled={loading}>
            Eliminar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CrudUser;
