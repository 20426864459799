import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

// RUTAS
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

// PUBLIC
import Login from "./page/public/Login";
import Invitacion from "./page/public/Invitacion";

// PRIVATE
import Inicio from "./page/Inicio";
import Configuracion from "./page/Configuracion";
import NuevaEmpresa from "./page/NuevaEmpresa";
import PerfilProveedor from "./page/PerfilProveedor";
import Invitaciones from "./page/Invitaciones";
import PerfilUsuario from "./page/PerfilUsuario";
import Empresa from "./page/Empresa";
import { getLogin } from "./utils/authentication";
import PageNotFound from "./components/base/PageNotFound";
//
const storgeUser = getLogin();

function App() {
  const [user, setUser] = useState([]);
  useEffect(() => {
    setUser(storgeUser);
  }, []);
  return (
    <BrowserRouter>
      <Switch>
        {/* PUBLIC */}
        <PublicRoute
          restricted={true}
          component={Login}
          path="/login"
          exact
          setUser={(u) => setUser(u)}
        />
        <PublicRoute
          restricted={true}
          component={Invitacion}
          path="/invitacion/:codigo"
          exact
        />

        {/* PRIVATE */}
        <PrivateRoute 
          user={user}
          component={Inicio}
          path="/"
          exact
          fluid
        />
        <PrivateRoute
          user={user}
          component={PerfilProveedor}
          path="/proveedor/:id"
          exact
        />
        <PrivateRoute
          user={user}
          component={PerfilUsuario}
          path="/usuario"
          exact
        />

        {user && !user.empresa && (
          <>
            <PrivateRoute
              user={user}
              component={Empresa}
              path="/empresas"
              exact
            />
            <PrivateRoute
              user={user}
              component={Configuracion}
              path="/configuracion"
              exact
            />
            <PrivateRoute
              user={user}
              component={NuevaEmpresa}
              path="/NuevaEmpresa"
              exact
            />
            <PrivateRoute
              user={user}
              component={Invitaciones}
              path="/invitaciones"
              exact
            />
          </>
        )}

        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
