import React from 'react'
import PuntoInteres from './PuntoInteres';
import ServiciosAlojamiento from './ServiciosAlojamiento';
import NroHabitaciones from './NroHabitaciones';
import InfoAlojamiento from './InfoAlojamiento';

import { useQuery } from '@apollo/client';
import { DATA_SERVICIO_METODO_PAGO_TIPO_ALOJA } from '../../utils/graphql_query';


const RegistrarAlojamiento = ({ alojamiento, setAlojamiento, validate}) => {
  
  const { loading, data } = useQuery(DATA_SERVICIO_METODO_PAGO_TIPO_ALOJA)

  if (loading) return "Cargando..."


  return (
    <>
      <InfoAlojamiento 
        baseTipoPago={data.tipo_pagos}
        baseTipoAlojamiento={data.tipo_alojamientos}
        alojamiento={alojamiento}
        setAlojamiento={setAlojamiento}
        validate={validate}
        />

      <NroHabitaciones 
        alojamiento={alojamiento}
        setAlojamiento={setAlojamiento}
        />

      <ServiciosAlojamiento 
        baseServicios={data.servicios_alojamiento}
        alojamiento={alojamiento}
        setAlojamiento={setAlojamiento}
        />

      <PuntoInteres
        alojamiento={alojamiento}
        setAlojamiento={setAlojamiento}
        validate={validate}
        />
    </>
  )
}

export default RegistrarAlojamiento
