import React from 'react'
import { Row, Col, Form } from 'react-bootstrap';
import * as yup from 'yup';
import _ from 'lodash'
import Select from 'react-select';

const InfoAlojamiento = ({ baseTipoPago, baseTipoAlojamiento, alojamiento, setAlojamiento, validate}) => {
  
  const handleChange = (e) => {
    setAlojamiento({ ...alojamiento, [e.target.name]: e.target.value})
  }

  const handleChangeSelect = (e) => {
    setAlojamiento({ ...alojamiento, tipoPagos: e})
  }

  const handleBlur = async (e) => {
    const nombre = e.target.name;
    let valor = e.target.value;
    
    let schema = yup.object().shape({
      cantidad: yup.number().required().positive().integer(),
    });

    const resp = await schema.isValid({
      cantidad: valor
    })
    .then(function (valid) {
      return valid;
    });

    valor = resp ? parseInt(valor) : 0;

    setAlojamiento({ ...alojamiento, [nombre]: valor})
  }

  const options = () => {
    let result = [];
    _.map(baseTipoPago, (val) => {
      result = _.concat(result, { value: val.id, label: val.nombre })
    })
    return result;
  }
  
  let classTipo = '';
  let classTipoPagos = '';
  if (validate) {
    classTipo = parseInt(alojamiento.tipoAlojamiento) === 0 ? 'is-invalid' : 'is-valid'
    classTipoPagos = _.size(alojamiento.tipoPagos) > 0 ? '#28a745' : '#dc3545'
  }
  return (
    <>
      <h5>General</h5>
      <hr />
      <Row className="mb-5">
        <Col sm="4" className="mb-3">
          <Form.Label>Tipo Alojamiento</Form.Label>
          <Form.Control className={classTipo} name="tipoAlojamiento" as="select" value={alojamiento.tipoAlojamiento} onChange={handleChange}>
            <option value='0'>Seleccionar</option>
            {
              _.map(baseTipoAlojamiento, (tipo, kt) => {
                return (
                  <option key={kt} value={tipo.id}>{tipo.nombre}</option>
                )
              })
            }
          </Form.Control>
        </Col>

        <Col sm="4" className="mb-3">
          <Form.Label>Categoria</Form.Label>
          <Form.Control name="categoria" as="select" value={alojamiento.categoria} onChange={handleChange}>
            <option value='0'>--- Sin categoria ---</option>
            <option value='1'>1</option>
            <option value='2'>2</option>
            <option value='3'>3</option>
            <option value='4'>4</option>
            <option value='5'>5</option>
            <option value='6'>6</option>
          </Form.Control>
        </Col>

        <Col sm="4" className="mb-3">
          <Form.Label>Año Construccion</Form.Label>
          <Form.Control 
            name="anioConstruccion" 
            type="text"
            className="text-center" 
            value={alojamiento.anioConstruccion} 
            onChange={handleChange}
            onBlur={handleBlur} 
            />
        </Col>

        <Col sm="4" className="mb-3">
          <Form.Label>Nro Pisos</Form.Label>
          <Form.Control 
            name="nroPiso" 
            type="number"
            className="text-center" 
            value={alojamiento.nroPiso} 
            onChange={handleChange}
            onBlur={handleBlur} 
            />
        </Col>

        <Col sm="8" className="mb-3">
          <Form.Label>Metodos de Pago</Form.Label>
          <Select
            defaultValue={alojamiento.tipoPagos}
            isMulti
            name="colors"
            options={options()}
            isSearchable={false}
            className={`${classTipoPagos} basic-multi-select`}
            onChange={handleChangeSelect}
            styles={{ container: base => ({ ...base, backgroundColor: classTipoPagos, padding: 1.2})}}
          />
        </Col>
      </Row>
    </>
  )
}

export default InfoAlojamiento
