//import { useEffect } from "react";
import { GET_ALL_ENTIDAD_FINANCIERA } from "../utils/graphql_query";
import { useLazyQuery } from "@apollo/client";

const useEntidadesFinancieras = () => {
  // Query
  const [getAllEntidadFinanciera, { loading, error, data }] = useLazyQuery(GET_ALL_ENTIDAD_FINANCIERA);
  // Function
  // Effect
  // useEffect(() => {
  //   console.log("EFFECT");
  // }, []);
  return [getAllEntidadFinanciera, { loading, error, data }];
};

export default useEntidadesFinancieras;