import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { ListGroup, Form, Container } from 'react-bootstrap';

const InfoHotelService = ({serviceAll, serviceSave, setServiceSave}) => {
    const [service, setService] = useState([])
    
    useEffect(() => {
        agruparTipo(serviceAll)            
    },[serviceAll])

    const agruparTipo = (data) => {
        const agrupado = _.chain(data)
          .groupBy('tipo_servicio')
          .map((value, key) => ({ tipo_servicio: key, servicio: value }))
          .value();
        setService(agrupado)
    } 


    const onChangeService = (e, val) => {
        
        let result = []
        if(e.target.checked) {
            result = _.concat(serviceSave, val)
        }
        else {
            _.map(serviceSave, (s) => {
                if(s.id !== val.id) {
                    result = _.concat(result, s)
                }
            })
        }

        setServiceSave(result)
    }

    return (
        <Container className="bg-white rounded mb-5">
            <div className="row">
                 {
                    _.map(service, (value, i)=>{
                        return (
                                <div className="col-4" key={i}>
                                    <strong>{_.startCase(_.camelCase(value.tipo_servicio))}</strong>
                                    <ListGroup variant="flush">
                                        {
                                            _.map(value.servicio, (val, k)=>{
                                                const aux =  _.findIndex(serviceSave,(ob) => ob.id === val.id)
                                                return (
                                                    <ListGroup.Item  className="d-flex justify-content-between py-1" key={k}> 
                                                        <div className="col-2">
                                                            <Form.Check 
                                                            type="checkbox" 
                                                            onChange={(e) => onChangeService(e, val)} 
                                                            checked={aux >= 0 ? true: false} 
                                                            style={{width:'15px', height:'15px'}} />
                                                        </div>
                                                        <div className="col-10">
                                                            {val.nombre}
                                                        </div>
                                                    </ListGroup.Item>
                                                )
                                            })
                                        }
                                    </ListGroup>
                                </div>
                        )
                    })
                }
            </div>
        </Container>
    )
}

export default InfoHotelService