import React, { useState } from "react";
import _ from "lodash";
import Errors from "../base/Errors";
import { useQuery } from "@apollo/client";
import Select from 'react-select';
import { SERVICE_EMPRESA } from "./../../utils/graphql_query";
import { Table, Button, Badge, Col, Form, Row } from "react-bootstrap";
import { getCountryById } from "../../utils/helpers";
import { Typeahead } from "react-bootstrap-typeahead";

const ListarServicios = ({
  empresa_id,
  setShowAccion,
  setSelectService,
  countries,
}) => {
  // STATE
  const [input, setInput] = useState("");
  const [pais, setPais] = useState(0);
  const [selectValue, setSelectValue] = useState(1);
  const selectOptions = [
    { value: 1, label: 'Activos' },
    { value: 0, label: 'Inactivos' }
  ]
  // QUERY
  const { loading, data, error } = useQuery(SERVICE_EMPRESA, {
    variables: {
      empresa_id: empresa_id,
      filter: input,
      pais_id: pais,
      estado: selectValue
    },
  });
  // FUNCTION
  const selectService = (id) => {
    setSelectService(id);
    setShowAccion("showServicio");
  };
  const handlePais = (e) => {
    let result = 0;
    if (_.size(e) > 0) {
      result = e[0].id;
    }
    console.log("CHANGE PAIS");
    setPais(result);
  };
  const renderNamePais = (pais_id) => {
    let result = "";
    if (pais_id) {
      result = getCountryById(pais_id).name;
    }
    return result;
  };
  // RENDER
  return (
    <>
      <h5>Listar Servicios</h5>
      <Form>
        <Row className="mb-2">
          <Form.Group as={Col} sm={8} md={6}>
            <Form.Control
              name="nombre"
              type="text"
              autoComplete="off"
              placeholder="Buscar por Titulo, Atraccion"
              onChange={(e) => setInput(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} sm={4} md={3}>
            <Typeahead
              id="pais"
              name="pais"
              labelKey="name"
              placeholder="Pais"
              options={countries}
              onChange={(e) => handlePais(e)}
            />
          </Form.Group>
          <Form.Group as={Col} sm={4} md={3}>
            <Select
              onChange={(newValue) => setSelectValue(newValue.value)}
              value={_.find(selectOptions, {'value':selectValue})}
              options={selectOptions}
            />
          </Form.Group>
        </Row>
      </Form>
      <div className="text-end mb-2">
        <Button onClick={() => setShowAccion("addServicio")}>
          Agregar Servicio
        </Button>
      </div>
      <Table striped bordered hover className="bg-white mb-0">
        <thead>
          <tr>
            <th className="text-center">Titulo</th>
            <th className="text-center">Pais</th>
            <th className="text-center">Atracciones</th>
            <th className="text-center"></th>
          </tr>
        </thead>
        <tbody>
          {!loading ? (
            _.size(data.search_servicio_empresa) > 0 ? (
              _.map(data.search_servicio_empresa, (service, ei) => {
                return (
                  <tr key={ei}>
                    <td>{service.titulo}</td>
                    <td>{renderNamePais(service.pais_id)}</td>
                    <td style={{ width: "300px" }}>
                      <div>
                        {_.map(service.atracciones, (val, i) => {
                          return (
                            <Badge key={i} variant="secondary" className="me-2">
                              {val.nombre}
                            </Badge>
                          );
                        })}
                      </div>
                    </td>
                    <td className="text-center">
                      <Button
                        variant="light"
                        onClick={() => selectService(service.id)}
                      >
                        Ver
                      </Button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  No se encontraron servicios.
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td colSpan="5" className="text-center">
                Buscando...
              </td>
            </tr>
          )}

          {error && <Errors error={error} />}
        </tbody>
      </Table>
    </>
  );
};

export default ListarServicios;
