import React, { useState } from "react";
import { useFormik } from "formik";
import { Button, Container, Row, Col, Form, Alert } from "react-bootstrap";
import { useMutation } from "@apollo/client";

import { LOGIN } from "../../utils/graphql_mutation";
import { LOGIN_SCHEMA } from "../../utils/yup_schema";

import { login } from "../../utils/authentication";
import { useHistory } from "react-router-dom";

const Login = ({ setUser }) => {
  const [showError, setShowError] = useState(false);
  let history = useHistory();
  const [LoginUsuario] = useMutation(LOGIN);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LOGIN_SCHEMA,
    onSubmit(values) {
      Iniciar(values);
    },
  });

  const Iniciar = async (values) => {
    const {
      data: { login: usuario },
    } = await LoginUsuario({ variables: values });
    if (usuario) {
      login(usuario);
      setUser(usuario);
      history.push("/");
    } else {
      setShowError(true);
      formik.setSubmitting(false);
    }
  };

  return (
    <Container>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <Container
            className="shadow-lg p-5 mt-5 rounded"
            style={{ backgroundColor: "#62D3C0" }}
          >
            <Col
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "20px",
              }}
            >
              <img
                src={process.env.PUBLIC_URL + "/yoturismo.svg"}
                className=""
                style={{ width: "180px" }}
              />
            </Col>
            <Form onSubmit={formik.handleSubmit} noValidate>
              <Form.Group className="mb-3">
                <Form.Control
                  style={{ border: "none" }}
                  type="email"
                  name="email"
                  placeholder="Correo"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                {formik.errors.email &&
                  formik.touched.email &&
                  formik.errors.email}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Contraseña"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                {formik.errors.password &&
                  formik.touched.password &&
                  formik.errors.password}
              </Form.Group>
              <Button
                className="shadow-none"
                variant="primary"
                style={{ width: "100%", backgroundColor: "#FF881A" }}
                type="submit"
                disabled={formik.isSubmitting}
              >
                INICIAR SESION
              </Button>
            </Form>

            {showError && (
              <Alert variant="danger" className="m-0 mt-3">
                Email o Password es incorrecto.
              </Alert>
            )}
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
