import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom'

const Footer = () => {
//año actual
const today = new Date();
const year = today.getFullYear();

  return (
    <Container fluid className="footer mt-5 pt-5 pb-3 text-white text-center" style={{backgroundColor: '#222222'}}>
      <Row>
        <Col md>
          <div className='ms-sm-3 ms-md-5 text-start d-flex flex-column justify-content-end h-100 pb-3'>
            <p>Contactanos:</p>
            <p className='p-0 m-0'> <i className={`fa fa-phone-alt mr-1`}></i> Telefono: 3363610</p>
            <p className='p-0 m-0'> <i className={`fa fa-envelope mr-1`}></i> Email: info@yoturismo.com</p>
            <p className='p-0 m-0'> <i className={`fa fa-map-marker-alt mr-1`}></i> Direccion: Av. San Martin, Calle 9 Este #5</p>
          </div>
        </Col>
        <Col md>
          <div className='d-flex justify-content-end align-items-center h-100 flex-wrap'>
            <img  src={process.env.PUBLIC_URL + "/bb.svg"}  className="" style={{width: "150px"}}/>
            <img src={process.env.PUBLIC_URL + "/yt.svg"}  className="ms-4 me-4" style={{width: "150px"}}/>
          </div>
        </Col>
      </Row>
      <hr class="solid .bg-light me-5 ms-5 "></hr>
      <Row>
      <Col >
          <div className=' ms-md-5 text-start d-flex flex-column justify-content-end h-100 mt-2 pt-1'>
            <p className='p-0 m-0 mb-2'><i className="far fa-copyright"></i> {year} YO TURISMO - TODOS LOS DERECHOS RESERVADOS</p>
            </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Footer
