import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import _ from "lodash";
import SubTitleForm from "../base/SubTitleForm";
import InputRequired from "../base/InputRequired";

const FrmEmpleado = ({
  typeCrud,
  validate,
  empleado,
  handleChange,
  disabledDelete,
  areas,
  cargos,
  paises,
  paisCiudad,
  handleChangePais,
  ciudades,
  handleChangeCiudad,
  sucursales,
}) => {
  let classNombres = "";
  let classApellidos = "";
  let classArea = "";
  let classCargo = "";
  let classOficina = "";
  if (validate) {
    classNombres = _.trim(empleado.nombres) === "" ? "is-invalid" : "is-valid";
    classApellidos =
      _.trim(empleado.apellidos) === "" ? "is-invalid" : "is-valid";
    classArea = parseInt(empleado.area) > 0 ? "is-valid" : "is-invalid";
    classCargo = parseInt(empleado.cargo) > 0 ? "is-valid" : "is-invalid";
    classOficina = parseInt(empleado.oficina) > 0 ? "is-valid" : "is-invalid";
  }
  const tipo = typeCrud ? typeCrud : "create";
  return (
    <Form>
      <SubTitleForm title="Informacion Personal" />
      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Nombres <InputRequired />
        </Form.Label>
        <Col sm="9">
          <Form.Control
            name="nombres"
            type="text"
            autoComplete="off"
            value={empleado.nombres}
            onChange={handleChange}
            className={classNombres}
            disabled={disabledDelete}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Apellidos <InputRequired />
        </Form.Label>
        <Col sm="9">
          <Form.Control
            name="apellidos"
            type="text"
            autoComplete="off"
            value={empleado.apellidos}
            onChange={handleChange}
            className={classApellidos}
            disabled={disabledDelete}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Area <InputRequired />
        </Form.Label>
        <Col sm="9">
          <Form.Control
            name="area"
            as="select"
            value={empleado.area}
            onChange={handleChange}
            className={classArea}
            disabled={disabledDelete}
          >
            <option value="0"></option>
            {_.map(areas, (val) => {
              return (
                <option key={val.id} value={val.id}>
                  {val.nombre}
                </option>
              );
            })}
          </Form.Control>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Cargo <InputRequired />
        </Form.Label>
        <Col sm="9">
          <Form.Control
            name="cargo"
            as="select"
            value={empleado.cargo}
            onChange={handleChange}
            className={classCargo}
            disabled={disabledDelete}
          >
            <option value="0"></option>
            {_.map(cargos, (val) => {
              return (
                <option key={val.id} value={val.id}>
                  {val.nombre}
                </option>
              );
            })}
          </Form.Control>
        </Col>
      </Form.Group>
      <SubTitleForm title="Informacion de Contacto" classTop="mt-5" />
      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Email
        </Form.Label>
        <Col sm="9">
          <Form.Control
            name="email"
            type="text"
            autoComplete="off"
            value={empleado.email}
            onChange={handleChange}
            disabled={disabledDelete}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Telefono
        </Form.Label>
        <Col sm="9">
          <Form.Control
            name="telefono"
            type="text"
            autoComplete="off"
            value={empleado.telefono}
            onChange={handleChange}
            disabled={disabledDelete}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Oficina <InputRequired />
        </Form.Label>
        <Col sm="9">
          <Form.Control
            name="oficina"
            as="select"
            value={empleado.oficina}
            onChange={handleChange}
            className={classOficina}
            disabled={disabledDelete}
          >
            <option value="0"></option>
            {_.map(sucursales, (val) => {
              return (
                <option key={val.id} value={val.id}>
                  {val.nombre}
                </option>
              );
            })}
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formCheckEmpleado">
        <Col sm={{ span: 9, offset: 3 }}>
          <Form.Check
            name="visible"
            type="checkbox"
            label="Visible para todos"
            checked={empleado.visible}
            onChange={handleChange}
            disabled={disabledDelete}
          />
          {!empleado.visible && tipo !== "delete" && (
            <div
              className="text-secondary"
              style={{ fontSize: 12, marginLeft: "20px" }}
            >
              {`Luego de ${
                tipo === "create" ? "registrar" : "modificar"
              } podras gestionar los que pueden visualizar.`}
            </div>
          )}
        </Col>
      </Form.Group>
    </Form>
  );
};

export default FrmEmpleado;
