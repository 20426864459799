import { useState, useEffect } from "react";
import { getCitiesByCountry } from "../utils/helpers";

const useCitiesByCountry = (id) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState([]);

  const fetchData = async (countryId) => {
    const result = await getCitiesByCountry(countryId);
    setResponse(result);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  return { loading, response };
};

export default useCitiesByCountry;
