import React, { useState } from "react";
import { InputGroup, FormControl, Button, Form } from "react-bootstrap";
import _ from "lodash";
import LoadingButton from "../base/LoadingButton";
import { ADD_GRUPO_EMPRESA_VER_CONTACTO } from "../../utils/graphql_mutation";
import { useMutation } from "@apollo/client";

const NewGroupEmpresas = ({
  empresaId,
  placeholder = "",
  refetchGroup,
  onCloseAdd,
}) => {
  // STATE
  const [name, setName] = useState("");
  const [validate, setValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  // MUTATION
  const [addGrupoEmpresaVerContacto] = useMutation(
    ADD_GRUPO_EMPRESA_VER_CONTACTO
  );
  // FUNCTION
  const onNewGroup = async () => {
    if (_.trim(name) === "") {
      setValidate(true);
      return null;
    }
    setLoading(true);
    await addGrupoEmpresaVerContacto({
      variables: {
        empresa_id: empresaId,
        nombre: _.trim(name),
      },
    });
    refetchGroup();
    onCloseAdd();
  };
  // RENDER
  let classNombre = "";
  if (validate) {
    classNombre = _.trim(name) === "" ? "is-invalid" : "is-valid";
  }
  return (
    <InputGroup className="mt-2 mb-4" hasValidation>
      <FormControl
        placeholder={placeholder}
        value={name}
        className={classNombre}
        onChange={(e) => setName(e.target.value)}
      />
      <Form.Control.Feedback tooltip type="invalid">
        Introducir datos
      </Form.Control.Feedback>

        <Button variant="primary" onClick={onNewGroup} disabled={loading}>
          {loading && <LoadingButton />}
          Agregar
        </Button>
    </InputGroup>
  );
};

export default NewGroupEmpresas;
