import React from "react";
import { Container } from "react-bootstrap";

function ContainerNotResult({
  icon,
  mensaje,
  children,
  iconColor,
  classContainer = "",
}) {
  // text - danger;
  let color = "";
  if (iconColor) {
    color = iconColor === "danger" ? "text-danger" : color;
    color = iconColor === "success" ? "text-success" : color;
  }
  return (
    <Container className={`text-center bg-light p-3 ${classContainer}`}>
      {icon && <i className={`${icon} fa-3x mb-2 ${color}`}></i>}
      <h6>{mensaje}</h6>
      {children}
    </Container>
  );
}

export default ContainerNotResult;
