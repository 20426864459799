import React from "react";
import Carousel from "react-bootstrap/Carousel";
import styled from "styled-components";


const images = ['1.png','2.png','3.png','4.png',]

const Slider = () => {

  const items = images.map((image, i) => (
        <Carousel.Item>
            <img
              className="d-block w-100"
              style={{minHeight: '200px', objectFit: 'cover'}}
              src={process.env.PUBLIC_URL + "/home/"+ image}
              alt={'Slider-'+i}
            />
            <Carousel.Caption style={{left:0, right:0, bottom: '50%', transform: 'translate(0, 50%)'}}>
              <Caption>
                <h2 className="fw-bold display-2 m-0" >BIENVENIDOS</h2>
                <Subtitle className="fw-light">A la plataforma de turismo nacional</Subtitle>
                <img src={process.env.PUBLIC_URL + "/yoturismo.svg"}  className="" style={{width: "200px"}}/>
              </Caption>
          </Carousel.Caption>
        </Carousel.Item>
      )
    )
  return (
    <Carousel controls={false} fade={true} interval={2500} >
      {items}
    </Carousel>
  );
};
export default Slider;

const Caption = styled.div`
    font-family: "Intelo-Bold";
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Subtitle = styled.h4`
    font-family: "Intelo-Light";
    font-size: 26px;
    color: white;
    margin: 0 auto 20px auto;
    padding: 0 32px 5px 32px;
    text-shadow: 0 1px 2px rgba(0,0,0,.6);
    background-color: #62D3C0;
    border-radius: 20px;
`