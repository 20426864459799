import React, { forwardRef, useImperativeHandle } from "react";
import { useQuery, useMutation } from "@apollo/client";
import _ from "lodash";
import {
  Button,
  Spinner,
  Tooltip,
  OverlayTrigger,
  Badge,
} from "react-bootstrap";
import { GET_INVITACIONES } from "../../utils/graphql_query";
import Errors from "../base/Errors";
import { DELETE_INVITACION } from "../../utils/graphql_mutation";

const ResultadoInvitaciones = forwardRef((props, ref) => {
  const { loading, error, data, refetch } = useQuery(GET_INVITACIONES, {
    variables: { nombre: props.nombre },
  });

  const [deleteInvitacion] = useMutation(DELETE_INVITACION);

  useImperativeHandle(ref, () => ({
    recargarLista() {
      refetch();
    },
  }));

  if (loading) {
    return (
      <tbody>
        <tr>
          <td colSpan="6" className="text-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Cargando...</span>
            </Spinner>
          </td>
        </tr>
      </tbody>
    );
  }

  if (error) {
    return (
      <tbody>
        <tr>
          <td colSpan="6" className="text-center">
            <Errors error={error} />
          </td>
        </tr>
      </tbody>
    );
  }

  const copyToClipboard = (codigo) => {
    const text = window.location.origin + `/invitacion/${codigo}`;
    var textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };

  const hanbleEliminar = async (id) => {
    try {
      await deleteInvitacion({ variables: { id } });
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const list = data.invitaciones;

  return (
    <tbody>
      {_.size(list) > 0 ? (
        _.map(list, (val, i) => {
          return (
            <tr key={i}>
              <td className="text-center">{val.codigo}</td>
              <td>{val.nombre}</td>
              <td className="text-center">{val.fecha}</td>
              <td className="text-center">
                {val.vigente ? (
                  <Badge variant="primary">Pendiente</Badge>
                ) : (
                  <Badge variant="success">Utilizado</Badge>
                )}
              </td>
              <td>{val.descripcion}</td>
              <td className="text-center">
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>Copiar Link</Tooltip>}
                >
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => copyToClipboard(val.codigo)}
                    overlay={<Tooltip id="tooltip-disabled">Tooltip!</Tooltip>}
                    className="me-2"
                  >
                    <i className="fas fa-link"></i>
                  </Button>
                </OverlayTrigger>
                {parseInt(val.vigente) === 1 && (
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip>Eliminar</Tooltip>}
                  >
                    <Button
                      variant="outline-danger"
                      size="sm"
                      onClick={() => hanbleEliminar(val.id)}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </OverlayTrigger>
                )}
              </td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td colSpan="6" className="text-center">
            No existe datos.
          </td>
        </tr>
      )}
    </tbody>
  );
});

export default ResultadoInvitaciones;
