import React, { useState } from "react";
import { Container, Button, Col, Row, ListGroup } from "react-bootstrap";
import ContainerNotResult from "../base/ContainerNotResult";
import _ from "lodash";
import CrudSucursal from "./CrudSucursal";
import ButtonIcon from "../base/ButtonIcon";
import { getCountryById, getCityById } from "../../utils/helpers";
import CrudContacto from "../contacto/CrudContacto";
import useCities from "../../hooks/useCities";

const Sucursal = ({
  empresaId,
  sucursales,
  paises,
  refetchEmpresa,
  areas,
  cargos,
}) => {
  const [crud, setCrud] = useState({ show: false, type: "", sucursal: {} });
  const [addContacto, setAddContacto] = useState({
    show: false,
    idSucursal: 0,
  });
  // CUSTOM
  const [getCities, { loading: loadCities, data: dataCities }] = useCities();
  // FUNCTION
  const viewRow = (icon, title) => {
    return (
      <Row>
        <Col sm="2">
          <i className={icon}></i>
        </Col>
        <Col sm="10">{title}</Col>
      </Row>
    );
  };
  const handleEditSucursal = (sucursal) => {
    setCrud({ ...crud, show: true, type: "update", sucursal: sucursal });
    // Cargar ARRAY CIUDAD
    if (sucursal.ciudad_id) {
      const newVal = sucursal.city
        ? sucursal.city.country_id
        : getCityById(sucursal.ciudad_id).country_id;
      getCities({ countryId: newVal });
    }
  };
  const handleEliminarSucursal = (sucursal) => {
    console.log(sucursal)
    setCrud({ ...crud, show: true, type: "delete", sucursal: sucursal });
    
  };


  const renderPaisCiudad = (sucursal) => {
    const auxCity = sucursal.city
      ? sucursal.city
      : getCityById(sucursal.ciudad_id);
    const { name: nameCity, country_id } = auxCity;
    const { name } = getCountryById(country_id);
    return viewRow("fas fa-globe-americas", `${name} - ${nameCity}`);
  };

  return (
    <>
      <Container className="shadow p-4 bg-white rounded mb-3 mt-3">
        {_.size(sucursales) === 0 && (
          <ContainerNotResult
            icon="fa fa-building"
            mensaje="No existen oficinas registrados"
          >
            <Button
              variant="outline-primary"
              className="mt-3"
              onClick={() => setCrud({ ...crud, show: true, type: "create" })}
            >
              Agregar
            </Button>
          </ContainerNotResult>
        )}

        <ListGroup variant="flush">
          {_.map(sucursales, (sucursal, ks) => {
            return (
              <ListGroup.Item key={ks}>
                <Row>
                  <Col sm="3" className="my-auto">
                    <strong>{sucursal.nombre} </strong>
                  </Col>
                  <Col sm="6" className="my-auto">
                    {sucursal.telefono &&
                      viewRow("fas fa-phone-alt", sucursal.telefono)}
                    {sucursal.direccion &&
                      viewRow("fas fa-map-marked-alt", sucursal.direccion)}
                    {sucursal.ciudad_id && renderPaisCiudad(sucursal)}
                  </Col>
                  <Col sm="3" className="my-auto text-center">
                    <ButtonIcon
                      titulo="Editar Oficina"
                      icono="fa fa-pen"
                      variant="primary"
                      size="sm"
                      className="me-2"
                      onClick={() => handleEditSucursal(sucursal)}
                    />
                    <ButtonIcon
                      titulo="Eliminar Oficina"
                      icono="fa fa-trash"
                      variant="primary"
                      size="sm"
                      className="me-2"
                      onClick={() => handleEliminarSucursal(sucursal)}
                    />
                    <ButtonIcon
                      titulo="Agregar Funcionario"
                      icono="fa fa-user-plus"
                      variant="primary"
                      size="sm"
                      onClick={() =>
                        setAddContacto({
                          ...addContacto,
                          show: true,
                          idSucursal: sucursal.id,
                        })
                      }
                    />
                  </Col>
                </Row>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </Container>

      {_.size(sucursales) > 0 && (
        <Container className="text-end p-0">
          <Button
            variant="primary"
            onClick={() => setCrud({ ...crud, show: true, type: "create" })}
          >
            Agregar Oficina
          </Button>
        </Container>
      )}

      {crud.show && (
        <CrudSucursal
          crud={crud}
          setCrud={setCrud}
          paises={paises}
          empresaId={empresaId}
          refetchEmpresa={refetchEmpresa}
          getCities={getCities}
          loadCities={loadCities}
          dataCities={dataCities}
        />
      )}
      {addContacto.show && (
        <CrudContacto
          empresaId={empresaId}
          sucursales={sucursales}
          idSucursales={addContacto.idSucursal}
          paises={paises}
          areas={areas}
          cargos={cargos}
          onClose={() => setAddContacto({ ...addContacto, show: false })}
          refetchEmpresa={refetchEmpresa}
        />
      )}
    </>
  );
};

export default Sucursal;
