import React from 'react'
import { Row, Form, Col, Button } from 'react-bootstrap';
import _ from 'lodash'
import ButtonIcon from '../base/ButtonIcon';
import ContainerNotResult from '../base/ContainerNotResult';

const baseLugares = { nombre: "", distancia: "" }

const PuntoInteres = ({ alojamiento, setAlojamiento, validate}) => {
  
  const handleAdd = () => {
    const newLugares = _.concat(alojamiento.lugares, baseLugares)
    setAlojamiento({ ...alojamiento, lugares: newLugares})
  }

  const handleChange = (e, i) => {
    let result = []
    _.map(alojamiento.lugares, (lugar, kl) => {
      let aux = lugar;
      if(kl === i) {
        aux = { ...lugar, [e.target.name]: e.target.value}
      }
      result = _.concat(result, aux);
    })
    setAlojamiento({ ...alojamiento, lugares: result})
  }

  const handleDelete = (e, i) => {
    let result = []
    _.map(alojamiento.lugares, (lugar, kl) => {
      if (kl !== i) {
        result = _.concat(result, lugar);
      }
    })
    setAlojamiento({ ...alojamiento, lugares: result })
  }

  return (
    <>
      <h5>Punto de Interes o lugares cercano</h5>
      <hr />
      {
        _.size(alojamiento.lugares) === 0 &&
        <ContainerNotResult icon="fas fa-map-marked" mensaje="Desea agregar lugares?">
          <Row>
            <Col>
              <Button variant="outline-primary" onClick={handleAdd}>Agregar</Button>
            </Col>
          </Row>
        </ContainerNotResult>
        
      }
      
         
      {
        _.map(alojamiento.lugares, (lugar, kl) => {
          let classLugar = '';
          let classDistancia = '';
          if (validate) {
            classLugar = _.trim(lugar.nombre) === '' ? 'is-invalid' : 'is-valid'
            classDistancia = _.trim(lugar.distancia) === '' ? 'is-invalid' : 'is-valid'
          }
          return (
            <Row key={kl} className="mb-2">
              <Col sm="6">
                <Form.Control name="nombre" autoComplete="off"
                  placeholder={`Lugar ${kl + 1}`}
                  value={lugar.nombre}
                  className={classLugar}
                  onChange={(e) => handleChange(e, kl)}
                  />
              </Col>
              <Col sm="4">
                <Form.Control name="distancia" autoComplete="off" 
                  placeholder={`Distancia ${kl + 1}`} 
                  value={lugar.distancia} 
                  className={classDistancia}
                  onChange={(e) => handleChange(e, kl)}
                  />
              </Col>
              <Col sm="2">
                <ButtonIcon
                  titulo="Quitar"
                  icono="fa fa-trash"
                  variant="danger"
                  className="me-2"
                  onClick={(e) => handleDelete(e, kl)}
                />

                {
                  kl === _.size(alojamiento.lugares) - 1 &&
                  <ButtonIcon
                    titulo="Nuevo"
                    icono="fa fa-plus"
                    variant="primary"
                    onClick={handleAdd}
                  />
                }
              </Col>
            </Row>
          )
        })
      }
      
      
      

    </>
  )
}

export default PuntoInteres
