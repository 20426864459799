import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLogin } from "./utils/authentication.js";

const PublicRoute = ({
  component: Component,
  restricted,
  setUser,
  ...rest
}) => {
  console.log(setUser);
  console.log(rest);
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() && restricted ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} setUser={setUser} />
        )
      }
    />
  );
};

export default PublicRoute;
