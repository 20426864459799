import { useEffect, useCallback } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  GET_CONTACTO_GENERAL_VER_EMPRESA,
  GET_EMPLEADO_VER_EMPRESA,
} from "../utils/graphql_query";

const useViewContactos = (type, visible, id) => {
  // QUERY
  const [getContactoGeneral, { loading: loadingCG, data: dataCG }] =
    useLazyQuery(GET_CONTACTO_GENERAL_VER_EMPRESA);
  const [getEmpleado, { loading: loadingE, data: dataE }] = useLazyQuery(
    GET_EMPLEADO_VER_EMPRESA
  );
  // FUNCTION
  const getContacto = useCallback(() => {
    if (type === "general") {
      getContactoGeneral({ variables: { id } });
    }
    if (type === "empleado") {
      getEmpleado({ variables: { id } });
    }
  }, [type, id, getContactoGeneral, getEmpleado]);
  // EFFECT
  useEffect(() => {
    if (!visible) {
      getContacto();
    }
  }, [visible, getContacto]);
  // RENDER
  let response = type === "general" ? dataCG : dataE;
  let loading = type === "general" ? loadingCG : loadingE;
  return { loading, data: response };
};

export default useViewContactos;
