import React from "react";

const SubTitleForm = ({ title, classTop, classBottom }) => {
  return (
    <>
      <h6 className={`text-secondary ${classTop}`} style={{ fontSize: 14 }}>
        {title}
      </h6>
      <hr className={`mt-1 ${classBottom}`} />
    </>
  );
};

export default SubTitleForm;
