import React, { useState } from "react";
import { Table, Container, Button, Modal, Alert, Row, Form, Col } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import DatePicker from 'react-datepicker';
import _ from 'lodash'
import {
    ADD_HABITACION,
    UPDATE_HABITACION,
    DELETE_HABITACION
  } from "./../../utils/graphql_mutation";
import ButtonIcon from '../base/ButtonIcon';

const Habitaciones = ({ data, refetch }) => {
    
    const verifyInput = [
        {'name':'tipo_habitacion', 'accion':'validar'},
        {'name':'camas', 'accion':'validar'},
        {'name':'precio', 'accion':'validar'}
    ];

    const [habitacion, setHabitacion] = useState({id:0, tipo_habitacion:'', camas:'', moneda:'BOB', alojamiento_id:data.id})
    const [showModalHabitacion, setShowModalHabitacion] = useState(false)
    const [disableTxt, setDisableTxt] = useState(false)
    const [alertErr, setAlertErr] = useState(false);
    const [msgAlert, setMsgAlert] = useState('');
    const [variant, setVariant] = useState('success')
    const [verificador, setVerificador]     = useState(false);

    const [dateInicio, setDateInicio] = useState(new Date());
    const [dateFin, setDateFin] = useState(new Date())
    
    const [addHabitacion] = useMutation(ADD_HABITACION);
    const [deleteHabitacion] = useMutation(DELETE_HABITACION);
    const [updateHabitacion] = useMutation(UPDATE_HABITACION);

    const handleCloseHabitacion = () => setShowModalHabitacion(false)

    const onChangeHab = (e) => {
        var target = e.target
        if (verificador && target.value !== '') {
            target.className = 'form-control is-valid'
        }
        else if (verificador) {
            target.className = 'form-control is-invalid'
        }
        setHabitacion({ ...habitacion, [e.target.name]: target.value})
    }

    const confirmDeletHab = async() => {
        try {
            await deleteHabitacion({
                variables: {
                    habitacion_id: habitacion.id
                }
            })
            refetch()
            setMsgAlert('Eliminado Correctamente.')
            setVariant('success')
            setAlertErr(true);
            setTimeout(() => {
                setAlertErr(false);
                handleCloseHabitacion(false)
              }, 2000);
        } catch (error) {
            setMsgAlert('Error al eliminar.')
            setVariant('error')
            setAlertErr(true);
            
            console.log(error)
        }   
    }

    const saveUpdateHab = async() => {
        try {
            var canSave = true
            setVerificador(true)
            _.map(verifyInput, (val) => {
                var input = document.getElementById('txt_' + val.name)
                if (val.accion === 'validar' && input.value === '') {
                    input.className = 'form-control is-invalid'
                    canSave =  false
                }
            })

            if (!canSave) {
                return false;
            }else if (canSave) {
                setVerificador(false)
            }
            
            if (habitacion.id === 0) {
                console.log(habitacion)
                await addHabitacion({
                    variables: {
                        json_habitacion: JSON.stringify(habitacion)
                    }
                })
                setMsgAlert('Registrado Correctamente.')
                
            } else {
                
                await updateHabitacion({
                    variables: {
                        json_habitacion: JSON.stringify(habitacion)
                    }
                })
                setMsgAlert('Actualizado Correctamente.')
            }
            refetch()
            setVariant('success')
            setAlertErr(true);
            setTimeout(() => {
                setAlertErr(false);
                handleCloseHabitacion(false)
            }, 2000);
        } catch (error) {
            const mensaje = habitacion.id === 0 ? 'Error al guardar' : 'Error al actualizar';
            setMsgAlert(mensaje)
            setVariant('danger')
            setAlertErr(true);
            console.log(error)
        }   
    }

    const nuevoHab = () => {
        setVerificador(false)
        let tempHabitacion = {}
        tempHabitacion.id    = 0
        tempHabitacion.camas = ''
        tempHabitacion.precio = ''
        tempHabitacion.moneda = 'BOB'
        tempHabitacion.descripcion = ''
        tempHabitacion.tipo_habitacion = ''
        tempHabitacion.fecha_inicio_temp = ''
        tempHabitacion.fecha_fin_temp = ''
        tempHabitacion.alojamiento_id = data.id
        
        setDateInicio(new Date())
        setDateFin(null)

        setHabitacion(tempHabitacion)
        setDisableTxt(false)
        setShowModalHabitacion(true)
    }

    const updateHab = (hab) => {
        setVerificador(false)
        let tempHabitacion = {}
        tempHabitacion.id    = hab.id
        tempHabitacion.camas = hab.camas
        tempHabitacion.precio = hab.precio
        tempHabitacion.moneda = hab.moneda
        tempHabitacion.descripcion = hab.descripcion
        tempHabitacion.tipo_habitacion = hab.tipo_habitacion
        
        const newDateEntrada = formatoDateFromDB(hab.fecha_inicio_temp)
        tempHabitacion.fecha_inicio_temp = newDateEntrada
        setDateInicio(new Date(formatoDateFromDBStarDate(hab.fecha_inicio_temp)))
        
        const newDateFin = formatoDateFromDB(hab.fecha_fin_temp)
        tempHabitacion.fecha_fin_temp = newDateFin
        setDateFin(new Date(formatoDateFromDBStarDate(hab.fecha_fin_temp)))
        
        setDisableTxt(false)
        setHabitacion(tempHabitacion)
        setShowModalHabitacion(true)
    }

    const deleteHab = (hab) => {
        setVerificador(false)
        let tempHabitacion = {}
        tempHabitacion.id    = hab.id
        tempHabitacion.camas = hab.camas
        tempHabitacion.precio = hab.precio
        tempHabitacion.descripcion = hab.descripcion
        tempHabitacion.tipo_habitacion = hab.tipo_habitacion
        
        const newDateEntrada = formatoDateFromDB(hab.fecha_inicio_temp)
        tempHabitacion.fecha_inicio_temp = newDateEntrada
        //dateEntrada = new Date(formatoDateFromDBStarDate(hab.fecha_inicio_temp))
        
        const newDateFin = formatoDateFromDB(hab.fecha_fin_temp)
        tempHabitacion.fecha_fin_temp = newDateFin

        setDisableTxt(true)
        setHabitacion(tempHabitacion)
        setShowModalHabitacion(true)
    }

    const handleDatepickerInicio = (value) => {
        const fecha  =  formatDate(value);
        setDateInicio(new Date(value))
        //setDateFinMin(new Date(value))
        setHabitacion({...habitacion,'fecha_inicio_temp':fecha});
    }

    const handleDatepickerFin = (value) => {
        const fecha  =  formatDate(value);
        setHabitacion({...habitacion,'fecha_fin_temp':fecha});
    }

    const formatDate = (value) => {
        const d  = new Date(value);
        var formatDate = ("0" + d.getDate()).slice(-2) + "/" + ("0"+(d.getMonth()+1)).slice(-2) + "/" +
        d.getFullYear();
        return formatDate
    }
    // 0000-00-00 from 00/00/0000
    const formatoDateFromDB = (fecha) => {
        const anos = fecha.slice(0,4)
        const mes = fecha.slice(5,7)
        const dia = fecha.slice(8,10)
        const formaDate = dia + '/' + mes + '/' +anos
        return formaDate
    }

    const formatoDateFromDBStarDate = (fecha) => {
        const anos = fecha.slice(0,4)
        const mes = fecha.slice(5,7)
        const dia = fecha.slice(8,10)
        const formaDate = anos + '/' + mes + '/' + dia
        return formaDate
    }

    const btnDisable = false ? true : false;
    return (
        <>
            <h5>Habitaciones</h5>
            <Table striped bordered hover className="shadow bg-white mb-2">
                <thead>
                    <tr className="text-center">
                        <th>Tip Hab.</th>
                        <th>Camas</th>
                        <th>Precio</th>
                        <th>Descripci&oacute;n</th>
                        <th>Temporada</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.habitaciones.length === 0
                        ?
                            <tr>
                                <td colSpan="6">No existen datos insertados.</td>
                            </tr>
                        :
                        _.map(data.habitaciones,(hab, i) => {
                            return(
                                <tr key={i}>
                                    <td>{hab.tipo_habitacion}</td>
                                    <td>{hab.camas}</td>
                                    <td>{hab.precio + ' ' + hab.moneda } </td>
                                    <td>{hab.descripcion}</td>
                                    <td>{formatoDateFromDB(hab.fecha_inicio_temp)} al {formatoDateFromDB(hab.fecha_fin_temp)}</td>
                                    <td>
                                        <ButtonIcon
                                            titulo="Editar"
                                            icono="fas fa-pen"
                                            variant="primary"
                                            className="me-2"
                                            onClick={() => updateHab(hab)}
                                            />
                                        {' '}
                                        <ButtonIcon
                                            titulo="Quitar"
                                            icono="fa fa-trash"
                                            variant="danger"
                                            className="me-2"
                                            onClick={() => deleteHab(hab)}
                                            />
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
            <Container className="text-end mb-3 p-0">
                <Button onClick={()=>nuevoHab()}>Agregar</Button>
            </Container>

            <Modal show={showModalHabitacion} onHide={handleCloseHabitacion}  backdrop="static"  size="md" >
                
                <Modal.Header>
                    <Modal.Title className="h4">
                        {habitacion.id === 0 ? 'Agregar' : disableTxt ? 'Eliminar' : 'Editar'} Habitaci&oacute;n
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="p-4">
                    
                    <Form.Group as={Row} >
                        <Form.Label column sm={12}>
                          Tipo Habitacion
                        </Form.Label>
                        <Col sm={12}>
                            <Form.Control 
                                input="text" 
                                id="txt_tipo_habitacion"
                                name="tipo_habitacion"
                                value={habitacion.tipo_habitacion} 
                                onChange={(e) => onChangeHab(e) } 
                                disabled={disableTxt}
                                rows="2"  />
                        </Col>
                    </Form.Group>
                    
                    <Form.Group as={Row} >
                        <Form.Label column sm={12}>
                          Camas
                        </Form.Label>
                        <Col sm={12}>
                            <Form.Control 
                                input="text" 
                                id="txt_camas"
                                name="camas"
                                value={habitacion.camas}
                                onChange={(e) => onChangeHab(e) } 
                                disabled={disableTxt}
                                rows="2"  />
                        </Col>
                    </Form.Group>
                    
                    <Form.Group as={Row} >
                        <Form.Label column sm={6}>
                          Precio
                        </Form.Label>
                        
                        <Form.Label column sm={6}>
                          Moneda
                        </Form.Label>
                        
                    </Form.Group>
                    
                    <Form.Group as={Row} >
                        <Col sm={6}>
                            <Form.Control 
                                input="text" 
                                id="txt_precio"
                                name="precio"
                                value={habitacion.precio}
                                onChange={(e) => onChangeHab(e) } 
                                disabled={disableTxt}
                                rows="2"  />
                        </Col>

                        <Col sm={6}>
                            <Form.Control 
                                as="select" 
                                id="txt_moneda"
                                name="moneda"
                                defaultValue={habitacion.moneda}
                                onChange={(e) => onChangeHab(e) } 
                                disabled={disableTxt}
                                rows="2">
                                    <option value="BOB">BOB</option>c
                                    <option value="USD">USD</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    

                    <Form.Group as={Row} >
                        <Form.Label column sm={12}>
                          Descripci&oacute;n
                        </Form.Label>
                        <Col sm={12}>
                            <Form.Control 
                                as="textarea"
                                id="txt_descripcion"
                                name="descripcion"
                                rows="2"
                                disabled={disableTxt}   
                                value={habitacion.descripcion}
                                onChange={(e) => onChangeHab(e) }  />
                        </Col>
                    </Form.Group>
                    
                    <Form.Group as={Row} >
                        <Form.Label column sm={12}>
                          Temporada Inicio
                        </Form.Label>
                        <Col sm={12}>
                            <DatePicker 
                                 className="form-control"
                                 id="txt_fecha_inicio_temp"
                                 name="fecha_inicio_temp"
                                 disabled={disableTxt}
                                 value={habitacion.fecha_inicio_temp}
                                 minDate={new Date()}
                                 selected={dateInicio}
                                 autoComplete="off"
                                 onChange={(date) => handleDatepickerInicio(date)} />
                        </Col>
                    </Form.Group>
                    
                    <Form.Group as={Row} >
                        <Form.Label column sm={12}>
                          Temporada Final
                        </Form.Label>
                        <Col sm={12}>
                            <DatePicker 
                                className="form-control"
                                id="fecha_fin_temp"
                                name="fecha_fin_temp"
                                disabled={disableTxt}
                                value={habitacion.fecha_fin_temp}
                                selected={dateFin}
                                autoComplete="off"
                                minDate={dateInicio}
                                onChange={(date) => handleDatepickerFin(date)} />
                        </Col>
                    </Form.Group>
                    
                    <Alert show={alertErr} variant={variant}>
                        {msgAlert}
                    </Alert>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseHabitacion}  >Salir</Button>
                    {
                        disableTxt
                        ?
                            <Button variant="danger" disabled={btnDisable} onClick={() => confirmDeletHab() } >Eliminar</Button>
                        :
                            <Button 
                                variant="primary" 
                                disabled={btnDisable} 
                                onClick={() => saveUpdateHab() } >
                                {habitacion.id === 0 ? 'Registrar' : 'Confirmar'}
                            </Button>
                    }
                    
                </Modal.Footer>

            </Modal>
        </>
    );
};

export default Habitaciones;
