import React, { useState, useEffect, useRef } from "react";
import { ListGroup, Row, Col, Button } from "react-bootstrap";
import ButtonIcon from "../base/ButtonIcon";
import { AsyncTypeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import useSearchEmpresa from "../hooks/useSearchEmpresa";
import _ from "lodash";
import ContainerNotResult from "../base/ContainerNotResult";
import { useMutation } from "@apollo/client";
import { EDIT_GRUPO_EMPRESA_DETALLE } from "../../utils/graphql_mutation";
import LoadingButton from "../base/LoadingButton";

const ListGroupEmpresas = ({ empresaId, selected, refetch }) => {
  const inputEl = useRef();
  // STATE
  const [listEmpresa, setListEmpresa] = useState([]);
  const [loading, setLoading] = useState(false);
  // CUSTOM
  const [onSearch, { loading: loadingSearch, data: dataSearch }] =
    useSearchEmpresa();
  // MUTATION
  const [updateGrupoEmpresaDetalle] = useMutation(EDIT_GRUPO_EMPRESA_DETALLE);
  // FUNCTION
  const handleAdd = (params) => {
    if (_.size(params) > 0) {
      const auxList = _.findIndex(
        listEmpresa,
        (item) => item.id === params[0].id
      );

      if (auxList < 0) {
        setListEmpresa(_.concat(listEmpresa, params));
      }

      inputEl.current.clear();
      inputEl.current.focus();
    }
  };
  const handleEditList = (params) => {
    let newList = [];
    _.map(listEmpresa, (item, k) => {
      if (parseInt(params) !== k) {
        newList = _.concat(newList, item);
      }
    });
    setListEmpresa(newList);
  };
  const onSaveList = async () => {
    setLoading(true);
    let auxArr = [];
    _.map(listEmpresa, (item) => {
      auxArr = _.concat(auxArr, item.id);
    });
    const params = {
      variables: {
        id: selected.id,
        jsonEmpresa: JSON.stringify(auxArr),
      },
    };
    await updateGrupoEmpresaDetalle(params);
    refetch();
    setLoading(false);
  };
  // EFFECT
  useEffect(() => {
    if (selected) {
      setListEmpresa(selected.grupo_empresa_detalle);
    }
  }, [selected]);
  // RENDER
  const auxData = dataSearch ? dataSearch.buscar_empresa_ver_contacto : [];
  if (_.size(selected) === 0) {
    return (
      <ContainerNotResult
        icon="fas fa-hand-point-left"
        mensaje={`Seleccionar un grupo`}
        classContainer="pt-5 bg-transparent text-secondary"
      />
    );
  }
  return (
    <>
      <AsyncTypeahead
        useCache={false}
        ref={inputEl}
        id="async-example"
        isLoading={loadingSearch}
        labelKey="nombre"
        onSearch={(text) => onSearch({ nombre: text, empresaId })}
        options={auxData}
        placeholder="Buscar empresa para agregar al grupo"
        onChange={(e) => handleAdd(e)}
        renderMenu={(results, menuProps) => {
          let posItem = 0;

          const items = _.map(results, (result, index) => {
            const disab = _.findIndex(
              listEmpresa,
              (item) => item.id === result.id
            );
            const itemJSX =
              disab >= 0 ? (
                <MenuItem disabled={true} key={index} option={result}>
                  {result.nombre}
                </MenuItem>
              ) : (
                <MenuItem key={index} option={result} position={posItem}>
                  {result.nombre}
                </MenuItem>
              );
            if (disab < 0) {
              posItem += 1;
            }
            return itemJSX;
          });
          return <Menu {...menuProps}>{items}</Menu>;
        }}
      />

      <ListGroup variant="flush" className="mt-3">
        {_.size(listEmpresa) > 0 ? (
          <>
            {_.map(listEmpresa, (item, kc) => {
              return (
                <ListGroup.Item key={`cg${kc}`}>
                  <Row>
                    <Col sm="9" className="my-auto">
                      {item.nombre}
                    </Col>
                    <Col sm="3" className="text-end my-auto">
                      <ButtonIcon
                        titulo="Quitar"
                        icono="fa fa-times"
                        variant="danger"
                        className="me-2"
                        size="sm"
                        onClick={() => handleEditList(kc)}
                      />
                    </Col>
                  </Row>
                </ListGroup.Item>
              );
            })}
            <Row>
              <Col>
                <Button
                  className="mt-3"
                  variant="primary"
                  onClick={onSaveList}
                  disabled={loading}
                >
                  {loading && <LoadingButton />}
                  Guardar Lista
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <ListGroup.Item className="bg-transparent">
            <ContainerNotResult
              icon="fas fa-building"
              mensaje={`No existe empresa agregada.`}
              classContainer="pt-4 bg-transparent text-secondary"
            />
          </ListGroup.Item>
        )}
      </ListGroup>
    </>
  );
};

export default ListGroupEmpresas;
