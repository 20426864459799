import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, ListGroup, Row, Col, Form } from "react-bootstrap";
import ContainerNotResult from "../base/ContainerNotResult";
import _ from "lodash";
import ButtonIcon from "../base/ButtonIcon";
import { AsyncTypeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import {
  EDIT_VISIBILIDAD_CONTACTO_GENERAL,
  EDIT_VISIBILIDAD_EMPLEADO,
} from "../../utils/graphql_mutation";
import useSearchEmpresa from "../hooks/useSearchEmpresa";
import LoadingPage from "../base/LoadingPage";
import useViewContactos from "../../hooks/useViewContactos";
import { useMutation, useQuery } from "@apollo/client";
import LoadingButton from "../base/LoadingButton";
import { GET_GRUPO_EMPRESA_VER_CONTACTO } from "../../utils/graphql_query";

const CrudAccessToContact = ({ empresaId, crud, onClose, refectEmpresa }) => {
  const { contacto, type } = crud;
  const inputEl = useRef();
  // VARIABLE
  const initialState = {
    visible: parseInt(contacto.visible) > 0 ? true : false,
  };
  // STATE
  const [visible, setVisible] = useState(initialState.visible);
  const [listEmpresa, setListEmpresa] = useState([]);
  const [loading, setLoading] = useState(false);
  // CUSTOM HOOKS
  const { loading: loadingContacto, data: dataContacto } = useViewContactos(
    type,
    contacto.visible,
    contacto.id
  );
  const [onSearch, { loading: loadingSearch, data: dataSearch }] =
    useSearchEmpresa();
  // QUERY
  const { loading: loadingGrupo, data: dataGrupo } = useQuery(
    GET_GRUPO_EMPRESA_VER_CONTACTO,
    {
      variables: { empresa_id: empresaId },
    }
  );
  // MUTATION
  const [updateVisibilidadContactoGeneral] = useMutation(
    EDIT_VISIBILIDAD_CONTACTO_GENERAL
  );
  const [editVisibilidadEmpleado] = useMutation(EDIT_VISIBILIDAD_EMPLEADO);
  // FUNCTION
  const handleEditList = (params) => {
    let newList = [];
    _.map(listEmpresa, (item, k) => {
      if (parseInt(params) !== k) {
        newList = _.concat(newList, item);
      }
    });
    setListEmpresa(newList);
  };
  const handleAdd = (params) => {
    if (_.size(params) > 0) {
      const auxList = _.findIndex(
        listEmpresa,
        (item) => item.id === params[0].id
      );

      if (auxList < 0) {
        setListEmpresa(_.concat(listEmpresa, params));
      }

      inputEl.current.clear();
      inputEl.current.focus();
    }
  };
  const onUpdate = async () => {
    setLoading(true);
    let auxArr = [];
    _.map(listEmpresa, (item) => {
      auxArr = _.concat(auxArr, item.id);
    });
    const params = {
      variables: {
        id: contacto.id,
        visible: visible ? 1 : 0,
        jsonEmpresa: JSON.stringify(auxArr),
      },
    };
    if (type === "general") {
      await updateVisibilidadContactoGeneral(params);
    }
    if (type === "empleado") {
      await editVisibilidadEmpleado(params);
    }
    refectEmpresa();
    onClose();
  };
  const handleSelectGroup = (params) => {
    const id = parseInt(params.target.value);
    let newList = [];
    if (id > 0) {
      newList = _.filter(
        dataGrupo.getGrupoEmpresaVerContactoByEmpresaId,
        (val) => val.id === id
      )[0].grupo_empresa_detalle;
    }

    setListEmpresa(newList);
  };

  // EFFECT
  useEffect(() => {
    if (dataContacto) {
      const auxContacto =
        type === "general"
          ? dataContacto.contacto_general
          : dataContacto.empleado;
      setListEmpresa(auxContacto.ver_empresas);
    }
  }, [dataContacto, type]);
  // RENDER
  const nombre_contacto =
    type === "general"
      ? _.toUpper(contacto.nombre)
      : _.toUpper(contacto.nombre_completo);
  const auxData = dataSearch ? dataSearch.buscar_empresa_ver_contacto : [];
  // if (dataGrupo) {
  //   console.log(dataGrupo);
  // }
  return (
    <Modal show={true} backdrop="static" keyboard={false} onHide={onClose}>
      {!visible && (
        <Modal.Header>
          <Modal.Title>Contacto: {nombre_contacto}</Modal.Title>
        </Modal.Header>
      )}

      <Modal.Body className="p-4" style={{ backgroundColor: "whitesmoke" }}>
        {visible ? (
          <ContainerNotResult
            icon="fas fa-globe-americas"
            mensaje={`El contacto ${nombre_contacto} es visible para todos.`}
            classContainer="pt-4 bg-transparent text-secondary"
          >
            <ButtonIcon
              text="Restringir"
              titulo="Habilitar la visibilidad para ciertas empresas"
              icono="fa fa-eye-slash"
              variant="primary"
              className="me-2"
              size="sm"
              onClick={() => setVisible(false)}
            />
          </ContainerNotResult>
        ) : (
          <>
            {loadingContacto || loadingGrupo ? (
              <LoadingPage size={20} />
            ) : (
              <>
                <Row className="mb-3">
                  <Col>
                    {dataGrupo &&
                      _.size(dataGrupo.getGrupoEmpresaVerContactoByEmpresaId) >
                        0 && (
                        <Form.Group controlId="exampleForm.ControlSelect12">
                          <Form.Control
                            as="select"
                            placeholder="Large text"
                            // size="sm"
                            onChange={handleSelectGroup}
                          >
                            <option value="0">
                              Seleccionar un grupo de empresa para agregar...
                            </option>
                            {_.map(
                              dataGrupo.getGrupoEmpresaVerContactoByEmpresaId,
                              (grupo, kg) => {
                                return (
                                  <option key={kg} value={grupo.id}>
                                    {grupo.nombre}
                                  </option>
                                );
                              }
                            )}
                          </Form.Control>
                        </Form.Group>
                      )}

                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <AsyncTypeahead
                        // size="sm"
                        useCache={false}
                        ref={inputEl}
                        id="async-example"
                        isLoading={loadingSearch}
                        labelKey="nombre"
                        minLength={3}
                        onSearch={(text) =>
                          onSearch({ nombre: text, empresaId })
                        }
                        options={auxData}
                        placeholder="Buscar empresas para agregar"
                        onChange={(e) => handleAdd(e)}
                        renderMenu={(results, menuProps) => {
                          let posItem = 0;

                          const items = _.map(results, (result, index) => {
                            const disab = _.findIndex(
                              listEmpresa,
                              (item) => item.id === result.id
                            );
                            const itemJSX =
                              disab >= 0 ? (
                                <MenuItem
                                  disabled={true}
                                  key={index}
                                  option={result}
                                >
                                  {result.nombre}
                                </MenuItem>
                              ) : (
                                <MenuItem
                                  key={index}
                                  option={result}
                                  position={posItem}
                                >
                                  {result.nombre}
                                </MenuItem>
                              );
                            if (disab < 0) {
                              posItem += 1;
                            }
                            return itemJSX;
                          });
                          return <Menu {...menuProps}>{items}</Menu>;
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <ListGroup variant="flush">
                  {_.size(listEmpresa) > 0 ? (
                    <>
                      {_.map(listEmpresa, (item, kc) => {
                        return (
                          <ListGroup.Item
                            key={`cg${kc}`}
                            // className="bg-transparent"
                          >
                            <Row>
                              <Col sm="9" className="my-auto">
                                {item.nombre}
                              </Col>
                              <Col sm="3" className="text-end my-auto">
                                <ButtonIcon
                                  titulo="Quitar"
                                  icono="fa fa-times"
                                  variant="danger"
                                  className="me-2"
                                  size="sm"
                                  onClick={() => handleEditList(kc)}
                                />
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        );
                      })}
                    </>
                  ) : (
                    <ListGroup.Item className="bg-transparent">
                      <ContainerNotResult
                        icon="fas fa-building"
                        mensaje={`No existe empresa agregada.`}
                        classContainer="pt-4 bg-transparent text-secondary"
                      />
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </>
            )}
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} disabled={loading}>
          Salir
        </Button>

        {!visible && (
          <Button variant="primary" onClick={onUpdate} disabled={loading}>
            {loading && <LoadingButton />}
            Guardar Cambios
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CrudAccessToContact;
