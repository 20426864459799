// import React, { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { BUSCAR_EMPRESA_VER_CONTACTO } from "../../utils/graphql_query";

const useSearchEmpresa = () => {
  // STATE
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState("");
  // const [response, setResponse] = useState([]);
  // QUERY
  const [getEmpresa, { loading, error, data }] = useLazyQuery(
    BUSCAR_EMPRESA_VER_CONTACTO
  );
  // FUNCTION
  const onSearch = (params) => {
    // setResponse([]);
    getEmpresa({
      variables: { nombre: params.nombre, empresaId: params.empresaId },
    });
  };

  // RENDER
  // return { loading, error, data: response };
  // console.log("->", data);
  // const newData = data ? data.buscar_empresa_ver_contacto : [];
  // return [onSearch, { loading, error, data: newData }];
  return [onSearch, { loading, error, data }];
};

export default useSearchEmpresa;
