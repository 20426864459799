import React, { useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import _ from "lodash";
import {
  ADD_EMPRESA_INVITACION,
  ADD_SUCURSAL_INVITACION,
  ADD_EMPLEADO_INVITACION,
  PROCESAR_INVITACION,
  ADD_ALOJAMIENTO_INVITACION,
  ADD_TIPO_PAGO_ALOJAMIENTO_INVITACION,
  ADD_SERVICIOS_ALOJAMIENTO_INVITACION,
  ADD_PUNTO_INTERES_INVITACION,
  ADD_CONTACTO_GENERAL_INVITACION,
} from "../../utils/graphql_mutation";
import { useMutation } from "@apollo/client";

const Step4 = ({
  empresa,
  sucursales,
  empleados,
  setStep,
  rubros,
  areas,
  cargos,
  invitacion,
  setResultado,
  alojamiento,
  contactoGeneral,
  medioDeContactos,
}) => {
  // VARIABLES
  const idHotelero = process.env.REACT_APP_RUBRO_HOTELERO_ID;
  // STATE
  const [disabled, setDisabled] = useState(false);
  // MUTATION
  const [addEmpresaInvitacion] = useMutation(ADD_EMPRESA_INVITACION);
  const [addSucursalInvitacion] = useMutation(ADD_SUCURSAL_INVITACION);
  const [addEmpleadoInvitacion] = useMutation(ADD_EMPLEADO_INVITACION);
  const [procesarInvitacion] = useMutation(PROCESAR_INVITACION);
  const [addAlojamientoInvitacion] = useMutation(ADD_ALOJAMIENTO_INVITACION);
  const [addTipoPagoAlojamientoInvitacion] = useMutation(
    ADD_TIPO_PAGO_ALOJAMIENTO_INVITACION
  );
  const [addServiciosAlojamientoInvitacion] = useMutation(
    ADD_SERVICIOS_ALOJAMIENTO_INVITACION
  );
  const [addPuntoInteresAlojamientoInvitacion] = useMutation(
    ADD_PUNTO_INTERES_INVITACION
  );
  const [addContactoGeneralInvitacion] = useMutation(
    ADD_CONTACTO_GENERAL_INVITACION
  );

  const rowInfo = (titulo, valor) => {
    return (
      <Row>
        <Col sm="6" className="text-end">
          <b>{`${titulo} :`}</b>
        </Col>
        <Col sm="6">{valor}</Col>
      </Row>
    );
  };

  const handleFinish = async () => {
    try {
      setDisabled(true);
      // EMPRESA
      const {
        data: { addEmpresaInvitacion: resultEmpresa },
      } = await addEmpresaInvitacion({
        variables: {
          codigo_invitacion: invitacion.codigo,
          nombre: empresa.nombre,
          razon_social: empresa.razon_social,
          nit: empresa.nit,
          web: empresa.web,
          rubro: parseInt(empresa.rubro),
          pais_id: parseInt(empresa.pais[0].id),
          ciudad_id: _.size(empresa.ciudad) > 0 ? empresa.ciudad[0].id : 0,
          departamento_id:
            _.size(empresa.ciudad) > 0 ? empresa.ciudad[0].state_id : 0,
          provincia_id:
            _.size(empresa.ciudad) > 0 ? empresa.ciudad[0].province_id : 0,
        },
      });

      let success = false;
      let mensaje = "";
      if (resultEmpresa) {
        // CONTACTO GENERAL Y DETALLE_CONTACTO
        await Promise.all(
          _.map(contactoGeneral, async (contacto, kc) => {
            // CONTACTO GENERAL
            await addContactoGeneralInvitacion({
              variables: {
                codigo_invitacion: invitacion.codigo,
                empresa_id: parseInt(resultEmpresa.id),
                nombre: _.trim(contacto.titulo),
                telefono: _.trim(contacto.detalleContacto[0].telefono),
                email: _.trim(contacto.detalleContacto[0].email),
              },
            });
          })
        );

        // ALOJAMIENTO, SUCURSAL, EMPLEADOS
        if (parseInt(empresa.rubro) === parseInt(idHotelero)) {
          // ALOJAMIENTO
          const {
            data: { addAlojamientoInvitacion: resultAlojamientoInvitacion },
          } = await addAlojamientoInvitacion({
            variables: {
              codigo_invitacion: invitacion.codigo,
              tipo_alojamiento_id: parseInt(alojamiento.tipoAlojamiento),
              empresa_id: parseInt(resultEmpresa.id),
              categoria: parseInt(alojamiento.categoria),
              fecha_construccion: alojamiento.anioConstruccion.toString(),
              numero_pisos: parseInt(alojamiento.nroPiso),
              nro_single: parseInt(alojamiento.nroSingle),
              nro_doble: parseInt(alojamiento.nroDoble),
              nro_triple: parseInt(alojamiento.nroTriple),
              nro_cuadruple: parseInt(alojamiento.nroCuadruple),
              nro_matrimonial: parseInt(alojamiento.nroMatrimonial),
              nro_suite: parseInt(alojamiento.nroSuite),
            },
          });
          // Verificar si se registro ALOJAMIENTO
          if (resultAlojamientoInvitacion) {
            // TIPO_PAGO_ALOJAMIENTO
            await Promise.all(
              _.map(alojamiento.tipoPagos, async (tipo) => {
                await addTipoPagoAlojamientoInvitacion({
                  variables: {
                    codigo_invitacion: invitacion.codigo,
                    alojamiento_id: parseInt(resultAlojamientoInvitacion.id),
                    tipo_pago_id: parseInt(tipo.value),
                  },
                });
              })
            );

            // SERVICIOS_ALOJAMIENTO
            await Promise.all(
              _.map(alojamiento.servicios, async (servicio) => {
                await addServiciosAlojamientoInvitacion({
                  variables: {
                    codigo_invitacion: invitacion.codigo,
                    alojamiento_id: parseInt(resultAlojamientoInvitacion.id),
                    servicio_alojamiento_id: parseInt(servicio.id),
                  },
                });
              })
            );

            // PUNTO_INTERES
            await Promise.all(
              _.map(alojamiento.lugares, async (lugar) => {
                await addPuntoInteresAlojamientoInvitacion({
                  variables: {
                    codigo_invitacion: invitacion.codigo,
                    alojamiento_id: parseInt(resultAlojamientoInvitacion.id),
                    lugar: lugar.nombre,
                    distancia: lugar.distancia,
                  },
                });
              })
            );
          }
        }

        // SUCURSAL, EMPLEADO
        if (_.size(sucursales) > 0) {
          await Promise.all(
            _.map(sucursales, async (sucursal, iSuc) => {
              // SUCURSAL
              const {
                data: { addSucursalInvitacion: resultSucursal },
              } = await addSucursalInvitacion({
                variables: {
                  codigo_invitacion: invitacion.codigo,
                  empresa_id: parseInt(resultEmpresa.id),
                  nombre: sucursal.nombre,
                  telefono: sucursal.telefono,
                  direccion: sucursal.direccion,
                  ciudad:
                    _.size(sucursal.ciudad) > 0
                      ? parseInt(sucursal.ciudad[0].id)
                      : 0,
                },
              });
              // EMPLEADO
              if (resultSucursal) {
                await Promise.all(
                  _.map(sucursal.empleados, async (empleado) => {
                    await addEmpleadoInvitacion({
                      variables: {
                        codigo_invitacion: invitacion.codigo,
                        sucursal_id: parseInt(resultSucursal.id),
                        nombres: empleado.nombres,
                        apellidos: empleado.apellidos,
                        email: empleado.email,
                        interno: empleado.interno,
                        area_id: parseInt(empleado.area),
                        cargo_id: parseInt(empleado.cargo),
                      },
                    });
                  })
                );
              }
            })
          );
        } else {
          await addSucursalInvitacion({
            variables: {
              codigo_invitacion: invitacion.codigo,
              empresa_id: parseInt(resultEmpresa.id),
              nombre: "Oficina 1",
            },
          });
        }

        // SI SE REGISTRO EMPRESA CAMBIAR ESTADO DE INVITACION
        await procesarInvitacion({
          variables: {
            codigo_invitacion: invitacion.codigo,
            id: parseInt(invitacion.id),
            descripcion: `Se registro la empresa ${empresa.nombre}`,
          },
        });
        success = true;
        mensaje = "Se registro correctamente la empresa";
      } else {
        success = false;
        mensaje = "Error: La empresa ya se encuentra registrada";
      }

      await setResultado({
        success: success,
        mensaje: mensaje,
      });

      setStep(5);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <h3 className="mb-0">Confirmacion</h3>
      <p>
        Informacion de empresa, oficinas y contactos cargados para el registro.
      </p>
      <div className="p-4 shadow bg-white rounded">
        <h4 className="mt-3">Empresa</h4>
        <hr />
        {rowInfo("Nombre", empresa.nombre)}
        {_.trim(empresa.razon_social) &&
          rowInfo("Razon Social", empresa.razon_social)}
        {_.trim(empresa.nit) && rowInfo("Nit", empresa.nit)}
        {_.trim(empresa.web) && rowInfo("Pagina Web", empresa.web)}
        {rowInfo(
          "Rubro",
          _.find(rubros, (val) => val.id === parseInt(empresa.rubro)).nombre
        )}
        {rowInfo("Pais", empresa.pais[0].name)}
        {_.size(empresa.ciudad) > 0 &&
          rowInfo("Ciudad", empresa.ciudad[0].name)}

        {_.size(contactoGeneral) > 0 && (
          <>
            <h4 className="mt-3">Contactos Generales</h4>
            <hr />
            <Row>
              {_.map(contactoGeneral, (contacto, kc) => {
                return (
                  <Col sm="12" key={kc}>
                    <Row>
                      <Col sm="6" className="text-end">
                        <b>{contacto.titulo} :</b>
                      </Col>
                      <Col sm="6">
                        <ul style={{ paddingLeft: 18 }}>
                          {_.map(contacto.detalleContacto, (detalle, kd) => {
                            return (
                              <div key={kd}>
                                {_.trim(detalle.telefono) !== "" && (
                                  <li>
                                    <i className={`fas fa-phone-alt me-3`}></i>
                                    {_.trim(detalle.telefono)}
                                  </li>
                                )}

                                {_.trim(detalle.email) !== "" && (
                                  <li>
                                    <i className={`far fa-envelope me-3`}></i>
                                    {_.trim(detalle.email)}
                                  </li>
                                )}
                              </div>
                            );
                          })}
                        </ul>
                      </Col>
                    </Row>
                  </Col>
                );
              })}
            </Row>
          </>
        )}

        {_.size(sucursales) > 0 && (
          <>
            <h4 className="mt-3">Oficinas</h4>
            <hr />
            <Row>
              {_.map(sucursales, (sucursal, i) => {
                return (
                  <Col key={i} sm="12">
                    {rowInfo("Nombre Oficina", sucursal.nombre)}
                    {rowInfo("Telefono", sucursal.telefono)}
                    {_.size(sucursal.pais) > 0 &&
                      rowInfo("Pais", sucursal.pais[0].nombre_pais)}
                    {_.size(sucursal.ciudad) > 0 &&
                      rowInfo("Ciudad", sucursal.ciudad[0].nombre_ciudad)}
                    {rowInfo("Direccion", sucursal.direccion)}
                    {_.size(sucursales) > 2 && <hr />}

                    {_.size(sucursal.empleados) > 0 && (
                      <Row>
                        <Col sm="6" className="text-end">
                          <b>Funcionarios :</b>
                        </Col>
                        <Col sm="6">
                          <ul style={{ paddingLeft: 18 }}>
                            {_.map(sucursal.empleados, (empleado, ke) => {
                              return (
                                <li key={ke}>
                                  <label className="mb-0">{`${empleado.nombres} ${empleado.apellidos}`}</label>
                                  <br />
                                  <label style={{ fontSize: 12 }}>
                                    {`${
                                      _.find(
                                        cargos,
                                        (cargo) =>
                                          cargo.id === parseInt(empleado.cargo)
                                      ).nombre
                                    } - ${
                                      _.find(
                                        cargos,
                                        (cargo) =>
                                          cargo.id === parseInt(empleado.cargo)
                                      ).nombre
                                    }`}
                                    <br />
                                    {empleado.email}
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </Col>
                      </Row>
                    )}
                    <br />
                  </Col>
                );
              })}
            </Row>
          </>
        )}

        {parseInt(empresa.rubro) === parseInt(idHotelero) && (
          <>
            <h4 className="mt-3">Hoteleria</h4>
            <hr />
            <Row>
              <Col sm="12">
                {rowInfo("Tipo Alojamiento", alojamiento.tipoAlojamiento)}
                {parseInt(alojamiento.categoria) > 0 &&
                  rowInfo("Categoria", alojamiento.categoria)}
                {parseInt(alojamiento.anioConstruccion) > 0 &&
                  rowInfo("Año de Construccion", alojamiento.anioConstruccion)}
                {parseInt(alojamiento.nroPiso) > 0 &&
                  rowInfo("Nro de Pisos", alojamiento.nroPiso)}

                <Row>
                  <Col sm="6" className="text-end">
                    <b>Metodos de Pago :</b>
                  </Col>
                  <Col sm="6">
                    <ul style={{ paddingLeft: 18 }}>
                      {_.map(alojamiento.tipoPagos, (aloja, ka) => {
                        return (
                          <li key={ka}>
                            {_.upperFirst(_.lowerCase(aloja.label))}
                          </li>
                        );
                      })}
                    </ul>
                  </Col>
                </Row>
                {parseInt(alojamiento.nroSingle) > 0 &&
                  rowInfo("Habit. Single", alojamiento.nroSingle)}
                {parseInt(alojamiento.nroDoble) > 0 &&
                  rowInfo("Habit. Doble", alojamiento.nroDoble)}
                {parseInt(alojamiento.nroTriple) > 0 &&
                  rowInfo("Habit. Triple", alojamiento.nroTriple)}
                {parseInt(alojamiento.nroCuadruple) > 0 &&
                  rowInfo("Habit. Cuadruple", alojamiento.nroCuadruple)}

                {parseInt(alojamiento.nroMatrimonial) > 0 &&
                  rowInfo("Habit. Matrimonial", alojamiento.nroMatrimonial)}
                {parseInt(alojamiento.nroSuite) > 0 &&
                  rowInfo("Habit. Suite", alojamiento.nroSuite)}

                {_.size(alojamiento.servicios) > 0 && (
                  <Row>
                    <Col sm="6" className="text-end">
                      <b>Servicios :</b>
                    </Col>
                    <Col sm="6">
                      <ul style={{ paddingLeft: 18 }}>
                        {_.map(alojamiento.servicios, (servicio, ks) => {
                          return (
                            <li key={ks}>
                              {_.upperFirst(_.lowerCase(servicio.nombre))}
                            </li>
                          );
                        })}
                      </ul>
                    </Col>
                  </Row>
                )}

                {_.size(alojamiento.lugares) > 0 && (
                  <Row>
                    <Col sm="6" className="text-end">
                      <b>Punto de Interes :</b>
                    </Col>
                    <Col sm="6">
                      <ul style={{ paddingLeft: 18 }}>
                        {_.map(alojamiento.lugares, (lugar, kl) => {
                          return (
                            <li key={kl}>
                              {`${_.upperFirst(
                                _.lowerCase(lugar.nombre)
                              )} (${_.upperFirst(
                                _.lowerCase(lugar.distancia)
                              )})`}{" "}
                            </li>
                          );
                        })}
                      </ul>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </>
        )}
      </div>

      <div className="mt-3">
        <Button
          variant="secondary"
          onClick={() => setStep(3)}
          disabled={disabled}
        >
          Atras
        </Button>
        <Button
          variant="success"
          className="float-right"
          onClick={handleFinish}
          disabled={disabled}
        >
          Registrar
        </Button>
      </div>
    </>
  );
};

export default Step4;
