import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
// import { useQuery } from "@apollo/client";
// import { OBTENER_CIUDADES } from "./../../utils/graphql_query";
// import { getCitiesByCountry } from "../../utils/helpers";
// import _ from "lodash";
import useCitiesByCountry from "../../hooks/useCitiesByCountry";

const SelectCiudades = ({ idPais, id, selected, handleCiudadChange }) => {
  const { loading, response: cities } = useCitiesByCountry(idPais);

  // RENDER
  if (loading) {
    return (
      <input
        type="text"
        disabled={true}
        placeholder="Cargando Ciudades"
        className="form-control"
      />
    );
  }

  return (
    <Typeahead
      id={id}
      name={id}
      labelKey="name"
      options={cities}
      onChange={(e) => handleCiudadChange(e, id)}
      defaultSelected={selected.length === 0 ? [] : [selected]}
    />
  );
};

export default SelectCiudades;
