import React from "react";
import { useState, useRef } from "react";
import { Modal, Button, ButtonGroup } from "react-bootstrap";
import AvatarEditor from "react-avatar-editor";
import ButtonIcon from "../base/ButtonIcon";
import ContainerNotResult from "../base/ContainerNotResult";
import { uploadImgS3 } from "../../utils/helpers";
import { ADD_LOGO_EMPRESA } from "../../utils/graphql_mutation";
import { useMutation } from "@apollo/client";
import LoadingButton from "../base/LoadingButton";

export default function UploadLogo({ empresaId, onClose, onSetLogo }) {
  const fileInput = useRef(null);
  const [image, setImage] = useState(null);
  const [scale, setScale] = useState(1);
  const [resul, setResul] = useState(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({
    success: false,
    message: "",
  });
  // MUTATION
  const [addLogoEmpresa] = useMutation(ADD_LOGO_EMPRESA);
  // FUNCTION
  const handleRegistrar = async () => {
    setLoading(true);
    try {
      const canvas = resul.getImageScaledToCanvas().toDataURL(`image/png`, 1);
      const res = await uploadImgS3({
        // base64: canvas,C
        //data.replace(/^data:image\/\w+;base64,/, "")

        data: Buffer.from(
          canvas.replace(/^data:image\/\w+;base64,/, ""),
          "base64"
        ),
        filename: "logo.png",
        prefix: "logo",
      });
      addLogoEmpresa({
        variables: {
          id: parseInt(empresaId),
          nombre_logo: res?.key,
        },
      });
      onSetLogo(res?.key);
      onClose();
    } catch (error) {
      console.log(error);
      setResponse({
        success: true,
        message: "Error al cargar logo de la empresa.",
      });
    }
    setLoading(false);
  };
  // RENDER
  return (
    <Modal show={true} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Cargar logo</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <input
          ref={fileInput}
          type="file"
          accept="image/*"
          onChange={(e) => setImage(e.target.files[0])}
          style={{ display: "none" }}
        />
        {image ? (
          <>
            {response.success ? (
              <ContainerNotResult
                icon="fas fa-exclamation-triangle"
                mensaje={response.message}
                iconColor="danger"
              />
            ) : (
              <>
                <AvatarEditor
                  ref={(e) => setResul(e)}
                  width={400}
                  height={250}
                  image={image}
                  scale={scale}
                />
                <div className="text-center">
                  <ButtonIcon
                    titulo="Cargar"
                    variant="primary"
                    size="sm"
                    icono="fas fa-upload"
                    onClick={() => fileInput.current.click()}
                    className="float-left"
                    disabled={loading}
                  />
                  <ButtonGroup aria-label="Basic example" size="sm">
                    <ButtonIcon
                      titulo="Zoom"
                      variant="primary"
                      icono="fas fa-search-minus"
                      onClick={() => setScale(scale - 0.1)}
                      disabled={loading}
                    />
                    <ButtonIcon
                      titulo="Zoom"
                      variant="primary"
                      icono="fas fa-search-plus"
                      onClick={() => setScale(scale + 0.1)}
                      disabled={loading}
                    />
                  </ButtonGroup>
                </div>
              </>
            )}
          </>
        ) : (
          <ContainerNotResult>
            <ButtonIcon
              text="Cargar imagen"
              variant="primary"
              icono="fas fa-upload"
              onClick={() => fileInput.current.click()}
            />
          </ContainerNotResult>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} disabled={loading}>
          Cancelar
        </Button>
        {image && !response.success && (
          <Button
            variant="primary"
            onClick={handleRegistrar}
            disabled={loading}
          >
            {loading && <LoadingButton />}
            Guardar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
