import React from "react";
import styled, { css } from "styled-components";
import { Container, Row, Col, Image } from "react-bootstrap";
// import './Inicio.css'

import Slider from "../components/base/Slider";

const Inicio = () => {
  return (
    <>
      <Container fluid className="p-0">
        <Slider />
        <Section className='text-center'>
          <Col xs={12}>
            <p className="my-md-5 mx-auto" style={{maxWidth: '1000px', fontSize: '20px'}}>  
            Yo Turismo es la Plataforma Administrativa para proveedores de servicios turisticos  que te permitira 
            conectar con cientos de agencias a traves de Bolivia Booking, desde aqui obtendras: 
            </p>
          </Col>
          </Section>
          </Container>
          <Container fluid style={{paddingLeft:"110px", paddingRight:"110px"}}>
          <Section className='text-center'>
          <SectionItem iconName={'user'} iconColor={'#FF881A'} text={'Perfil personalizado'} />
          <SectionItem iconName={'address-card'} iconColor={'#62D3C0'} text={'Autogestión de tus servicios'} />
          <SectionItem iconName={'chart-line'} iconColor={'#FF881A'} text={'Asesoria de la plataforma'} />
          <SectionItem iconName={'shopping-bag'} iconColor={'#FF881A'} text={'Visualizacion en + de 700 agencias '} />
          <SectionItem iconName={'newspaper'} iconColor={'#62D3C0'} text={'Integración de forma gratuita'} />
          <SectionItem iconName={'retweet'} iconColor={'#FF881A'} text={'Actualización en tiempo real '} />
          </Section>
          </Container>
          <Container fluid className="p-0">


        <Section>
          <SectionBot />
        </Section>
        {/* <Section2 className='text-center p-sm-3 p-lg-5'>
          <Col md={6} className='p-lg-5 lh-base lh-lg'>
            <p className='text-start' style={{fontSize: '20px',}} >
              Creemos firmemente que el desarrollo de herramientas tecnológicas que vinculen los servicios de los prestadores de servicios turísticos con las agencias de viaje, facilitará promocionar y comercializar la oferta turística de nuestro país.
            </p>
          </Col>
          <Col md={6} className='d-flex align-items-center justify-content-center' >
            <Image src={process.env.PUBLIC_URL + "/home/video.png"} alt="yoturismo-video-home" fluid />
          </Col>
        </Section2> */}
      </Container>
    </>
  );
};

export default Inicio;

const Section = styled(Row)`
  margin: 0;
  padding: 2rem;
`
const Section2 = styled(Row)`
  margin: 0;
  background-color: #F1F2F7;
`

const BorderDiv = styled.div`
    width: 300px;
    height: 110px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 auto;
    border: 1px solid #BDBDBD;
    border-radius: 15px;
`

const RoundIcon = styled.div`
    position: absolute;
    left: 108px;
    top: 0;
    transform: translate(0,-50%);
    width: 5rem;
    height: 5rem;
    background-color: orange;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    ${({ color }) => color && `
    background: ${color};
  `}
`

const CheckIcon = styled.div`
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translate(0,-50%);
    display: flex;
    justify-content: center;
    align-items: center;
`

const BorderP = styled.p`
    margin: 0 auto;
    padding: 44px 50px;
    font-size: 1.1rem;
`

const SectionItem = ({text, iconColor, iconName}) => (
  <Col xs={4} className='my-5'>
    <BorderDiv>
      <RoundIcon color={iconColor}>
        <i className={`fa fa-${iconName} fa-lg pr-1 text-white`}></i>
      </RoundIcon>
      <BorderP>
        {text}
      </BorderP>
    </BorderDiv>
  </Col>
);




const ItemH1 = styled.div`
      color:red;
      font-family:roboto;
      font-size:20px;
    `
const SectionBot = ({}) => (
  <>
  {/* <Col xs={12} className="text-center">
    <ItemH1 className="my-md-5 mx-auto">
      Un sistema diseñado para ti
    </ItemH1>
  </Col> */}
  <Col xs={12} className="text-center " style={{backgroundImage: "url("+process.env.PUBLIC_URL + "/world.png)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: "400px",
                display: "flex",
                alignItems: "center",
                paddingLeft:"100px",
                paddingRight:"100px"
                }}>
    <p className="my-md-5 mx-auto " style={{fontSize:"25px", lineHeight:"3"}}>
    <img src={process.env.PUBLIC_URL + '/1.svg'}  className="mb-4" />
     Creemos firmemente que el desarrollo de herramientas tecnologicas 
que vinculen los servicios de los prestadores de servicios turisticos 
con las agencias de viaje, facilitara promocionar y comercializar
 la oferta turistica de nuestro pais.
      <img src={process.env.PUBLIC_URL + '/2.svg' }  className="mb-4" />
    </p>
  </Col>
  </>
);
