import React, { useState } from "react";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import {
  Row,
  Col,
  Button,
  Modal,
  Form,
  Alert,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import ContainerNotResult from "../base/ContainerNotResult";
import { GALERIA_EMPRESA } from "../../utils/graphql_query";
import {
  ADD_IMAGEN_GALERIA_EMPRESA,
  DELETE_IMAGEN_GALERIA_EMPRESA,
} from "../../utils/graphql_mutation";
import { useQuery } from "@apollo/client";
import Errors from "../base/Errors";
import LoadingPage from "../base/LoadingPage";
import { uploadImgS3 } from "../../utils/helpers";
import moment from "moment";

export const trimLastKey = (key) => {
  if (!key) return key;
  return key.split("/").pop();
};

const GaleriaDeImagenes = ({ empresa_id }) => {
  const { data, loading, refetch, error } = useQuery(GALERIA_EMPRESA, {
    variables: { empresa_id: empresa_id },
  });
  const [showModal, setShowModal] = useState(false);
  const [modDelete, setModDelete] = useState(false);
  const [modShowImg, setModShowImg] = useState({
    img: "",
    open: false,
    isOld: false,
  });
  const [ldAccion, setLdAccion] = useState(false);
  const [complet, setComplet] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [selectedElement, setselectedElement] = useState(undefined);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showImg, setShowImg] = useState(null);
  const [isHovered, setHover] = useState({
    visible: false,
    id: null,
  });

  const [addImagenGaleriaEmpresa] = useMutation(ADD_IMAGEN_GALERIA_EMPRESA);
  const [deleteImagenGaleriaEmpresa] = useMutation(
    DELETE_IMAGEN_GALERIA_EMPRESA
  );

  const handleClose = () => {
    setShowModal(false);
    setShowImg(null);
  };
  const handleShow = () => setShowModal(true);
  const handleCloseModShowImg = () =>
    setModShowImg({ img: "", open: false, isOld: false });
  const handleCloseModDelete = () => setModDelete(false);
  const handleShowModDelete = () => setModDelete(true);

  const selectFile = (event) => {
    let file = event.target.files[0];
    setSelectedFiles(file);
    setShowImg(URL.createObjectURL(file));
  };

  const saveImage = async () => {
    if (selectedFiles !== undefined) {
      setBtnDisabled(true);
      setLdAccion(true);
      const res = await uploadImgS3({
        data: selectedFiles,
        filename: "added_image.png",
        prefix: "galeria",
      });
      await addImagenGaleriaEmpresa({
        variables: {
          nombre_imagen: res?.key,
          empresa_id,
        },
      });
      refetch();
      // Eliminar imagen si no guardo el archivo en el S3
      setBtnDisabled(false);
      setLdAccion(false);
      setTimeout(() => {
        setComplet(false);
        setShowImg(null);
        setSelectedFiles("");
        setShowModal(false);
      }, 1000);
    } else {
      let txtArea = document.getElementById("formImageInput");
      txtArea.className = "form-control is-invalid";
    }
  };

  const toImageURL = ({ nameFile, isOld }) => {
    const folderTest = process.env.REACT_APP_AWS_S3_FOLDER_TEST
      ? process.env.REACT_APP_AWS_S3_FOLDER_TEST
      : "";
    if (isOld) {
      return `${process.env.REACT_APP_AWS_S3_URL}/galeria${folderTest}/${nameFile}`;
    }
    return `${
      process.env.REACT_APP_AWS_S3_URL
    }/galeria${folderTest}/${trimLastKey(nameFile)}`;
  };

  const handleClicShowModImg = ({ nombre_descargar, isOld }) => {
    setModShowImg({ img: nombre_descargar, open: true, isOld });
  };

  const handleClickSetElement = (value) => {
    handleShowModDelete();
    setselectedElement(value);
  };

  const handleDeletElement = async () => {
    setLdAccion(true);
    const {
      data: { deleteImagenGaleriaEmpresa: result },
    } = await deleteImagenGaleriaEmpresa({
      variables: { id: selectedElement.id },
    });
    if (parseInt(result.id) > 0) {
      refetch();
    }
    setLdAccion(false);
    setComplet(true);
    setTimeout(() => {
      setComplet(false);
      setModDelete(false);
    }, 1000);
  };

  if (loading) return <LoadingPage />;
  if (error) return <Errors error={error} />;
  if (!data.galeria_empresa) return "No existe informacion";

  return (
    <>
      <h5 className="">Galería de Imagenes</h5>

      <div className="mb-2">
        <Button className="btn-primary me-2" size="sm" onClick={handleShow}>
          <i className="fa fa-plus"></i> Agregar Imagen
        </Button>
      </div>

      {loading ? (
        <div>
          <td>Cargando...</td>
        </div>
      ) : (
        <Row className="justify-content-md-center" style={{ gap: "10px 0" }}>
          {data.galeria_empresa.length === 0 ? (
            <ContainerNotResult
              icon="fa fa-search"
              mensaje="No hay im&aacute;genes a&uacute;n."
            >
              <Button
                variant="outline-primary"
                className="mt-3"
                onClick={handleShow}
              >
                Agregar Imagen
              </Button>
            </ContainerNotResult>
          ) : (
            _.map(data.galeria_empresa, (value, key) => {
              return (
                <Col
                  key={key}
                  xs={6}
                  md={4}
                  lg={3}
                  style={{ position: "relative" }}
                  onMouseOver={() => setHover({ visible: true, id: value.id })}
                  onMouseLeave={() => setHover({ visible: false, id: null })}
                >
                  <Image
                    key={key}
                    src={toImageURL({
                      nameFile: value.nombre_descargar,
                      isOld: moment(value.updated_at).isBefore(
                        moment("2024-08-15")
                      ),
                    })}
                    width={200}
                    height={200}
                    style={{ objectFit: "cover", cursor: "pointer" }}
                    onClick={() => {
                      handleClicShowModImg({
                        nombre_descargar: value.nombre_descargar,
                        isOld: moment(value.fecha).isBefore(
                          moment("2024-08-15")
                        ),
                      });
                    }}
                  />
                  {isHovered.visible && isHovered.id === value.id && (
                    <Button
                      size="sm"
                      style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                      }}
                      variant="danger"
                      onClick={() => handleClickSetElement(value)}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  )}
                </Col>
              );
            })
          )}
        </Row>
      )}

      <Modal show={showModal} onHide={handleClose} backdrop="static" size="md">
        <Modal.Body className="px-4 pt-4 pb-0">
          <Form.Group>
            <div>
              {showImg ? (
                <>
                  <div style={{ textAlign: "center" }}>
                    <div
                      className="p-3"
                      style={{ position: "relative", background: "#f2f2f2" }}
                    >
                      <Image src={showImg} fluid />
                    </div>
                  </div>
                  <div className="float-right">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip>{"Editar Imagen"}</Tooltip>}
                    >
                      <Form.Label
                        className="btn btn-outline-primary"
                        style={{
                          position: "absolute",
                          right: "1.5rem",
                          top: "1.5rem",
                        }}
                      >
                        <Form.Control
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={selectFile}
                          id="imagenesFiles"
                        />
                        <i className="fa fa-pen"></i>
                      </Form.Label>
                    </OverlayTrigger>
                  </div>
                </>
              ) : (
                <>
                  <ContainerNotResult icon="fas fa-image">
                    <Form.Label
                      className="btn btn-outline-primary"
                      style={{ marginBottom: "0px" }}
                    >
                      <Form.Control
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={selectFile}
                        id="imagenesFiles"
                      />
                      Subir Imagen
                    </Form.Label>
                  </ContainerNotResult>
                </>
              )}
            </div>
          </Form.Group>
          {ldAccion ? <Alert variant="success">Cargando...</Alert> : ""}
          {complet ? (
            <Alert variant="success">Cargada Correctamente.</Alert>
          ) : (
            ""
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Salir
          </Button>
          <Button variant="primary" disabled={btnDisabled} onClick={saveImage}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modDelete}
        onHide={handleCloseModDelete}
        backdrop="static"
        size="md"
      >
        <Modal.Body className="p-4">
          <div>Se Necesita Confirmaci&oacute;n Para Eliminar.</div>
          {ldAccion ? <Alert variant="success">Cargando...</Alert> : ""}
          {complet ? (
            <Alert variant="success">Eliminado Correctamente.</Alert>
          ) : (
            ""
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModDelete}>
            Salir
          </Button>
          <Button variant="primary" onClick={handleDeletElement}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modShowImg.open}
        onHide={handleCloseModShowImg}
        size="lg"
        centered
        contentClassName="bg-transparent border-0"
      >
        <Modal.Body className="p-0 m-0 text-center">
          <Image
            src={toImageURL({ nameFile: modShowImg.img, isOld: false })}
            style={{ maxWidth: "600px" }}
            onClick={handleCloseModShowImg}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GaleriaDeImagenes;
