const TOKEN_KEY = process.env.REACT_APP_NAME_TOKEN;

export const login = (user) => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(user));
  // localStorage.setItem("token", "rudy.marca.87");
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const isLogin = () => {
  if (localStorage.getItem(TOKEN_KEY)) {
    return true;
  }
  return false;
};

export const getLogin = () => {
  return JSON.parse(localStorage.getItem(TOKEN_KEY));
};
