import React, { useEffect } from "react";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import { ListGroup } from "react-bootstrap";
import IuDeletElement from "./IuDeletElement";
import LoadingPage from "../base/LoadingPage";
import Errors from "../base/Errors";

const ResultadoBusqueda = ({
  nombre,
  setNombre,
  query,
  title,
  validateRegister,
  mut_delet,
  mut_edit,
}) => {
  const { loading, error, data, refetch } = useQuery(query, {
    variables: { nombre },
  });

  useEffect(() => {
    if (!loading && data) {
      validateRegister(data[`${title}`]);
    }
  });

  if (loading) return <LoadingPage />;
  if (error) return <Errors error={error} />;

  const result = data[`${title}`];
  if (!result) return "No existe informacion";

  return _.size(result) > 0 ? (
    <ListGroup variant="">
      {_.map(result, (val, i) => {
        return (
          <ListGroup.Item key={i} className='d-flex justify-content-between'>
            {val.nombre}
            <IuDeletElement
              idElement={val.id}
              mut_delet={mut_delet}
              query={query}
              refetch={refetch}
              mut_edit={mut_edit}
            />
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  ) : (
    <div className="jumbotron text-center secondary light mb-3">
      <i className="fas fa-search fa-4x "></i>
      <h4 className="mt-4 light">{nombre}</h4>
      <h6>No existe en la busqueda.</h6>
    </div>
  );
};

export default ResultadoBusqueda;
