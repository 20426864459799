import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <Container className="mt-5">
      <Row>
        <Col md={{ span: 6, offset: 3 }} className="pt-5 pb-3 shadow bg-white rounded text-center ">
          <i className="far fa-frown fa-4x"></i>
          <h3 className="mt-4">Error 404</h3>
          <h6 className="text-secondary">Pagina no encontrado. <Link to="/">Inicio</Link></h6>
        </Col>
      </Row>
    </Container>
  )
}

export default PageNotFound
