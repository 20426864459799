import React, { useState } from "react";
import { Modal, Tabs, Tab, Button } from "react-bootstrap";
import FrmEmpleado from "../empleado/FrmEmpleado";
import _ from "lodash";
import {
  getCitiesByCountry,
  AgregarProvinciaDepartamento,
  ValidarFormCrudEmpleado,
  ValidarFormCrudGrupo,
} from "../../utils/helpers";
import FrmGrupo from "./FrmGrupo";
import {
  ADD_EMPLEADO,
  ADD_CONTACTO_GENERAL,
} from "../../utils/graphql_mutation";
import { useMutation } from "@apollo/client";
import LoadingButton from "../base/LoadingButton";

const CrudContacto = ({
  empresaId,
  sucursales,
  paises,
  areas,
  cargos,
  onClose,
  refetchEmpresa,
  idSucursales,
}) => {
  // VARIABLE
  const initialState = {
    personal: {
      nombres: "",
      apellidos: "",
      email: "",
      telefono: "",
      area: 0,
      cargo: 0,
      oficina: idSucursales ? idSucursales : 0,
      visible: true,
    },
    grupo: {
      nombre: "",
      telefono: "",
      email: "",
      chat: "",
      visible: true,
    },
  };
  // STATE
  const [tab, setTab] = useState("personal");
  const [validate, setValidate] = useState(false);
  const [personal, setPersonal] = useState(initialState.personal);
  const [grupo, setGrupo] = useState(initialState.grupo);
  const [sending, setSending] = useState(false);
  const [paisCiudad, setPaisCiudad] = useState({ pais: [], ciudad: [] });
  const [ciudades, setCiudades] = useState([]);
  // MUTATIONS
  const [addEmpleado] = useMutation(ADD_EMPLEADO);
  const [addContactoGeneral] = useMutation(ADD_CONTACTO_GENERAL);
  // FUNCTION
  const handleChange = (e) => {
    let { type, name, value, checked } = e.target;
    if (type === "checkbox") {
      value = checked;
    }
    if (tab === "personal") {
      setPersonal({ ...personal, [name]: value });
    }
    if (tab === "grupo") {
      setGrupo({ ...grupo, [name]: value });
    }
  };
  const handleChangePais = (e) => {
    setPaisCiudad({ ...paisCiudad, pais: e });
    if (_.size(e) > 0) {
      const ciudadesPorPais = getCitiesByCountry(e[0].id);
      setCiudades(AgregarProvinciaDepartamento(ciudadesPorPais));
    }
    setPaisCiudad({ ...paisCiudad, pais: e, ciudad: [] });
  };
  const handleChangeCiudad = (e) => {
    setPaisCiudad({ ...paisCiudad, ciudad: e });
  };
  const onRegister = async () => {
    setSending(true);
    // PERSONAL
    if (tab === "personal") {
      if (ValidarFormCrudEmpleado(personal)) {
        try {
          await addEmpleado({
            variables: {
              sucursal_id: parseInt(personal.oficina),
              nombres: _.trim(personal.nombres),
              apellidos: _.trim(personal.apellidos),
              email: _.trim(personal.email),
              interno: _.trim(personal.telefono),
              area_id: parseInt(personal.area),
              cargo_id: parseInt(personal.cargo),
              ciudad_id:
                _.size(paisCiudad.ciudad) > 0
                  ? parseInt(paisCiudad.ciudad[0].id)
                  : 0,
              visible: personal.visible ? 1 : 0,
            },
          });
          await refetchEmpresa();
          onClose();
        } catch (error) {
          console.log(error);
          onClose();
        }
      } else {
        setValidate(true);
        setSending(false);
      }
    }
    // GRUPO
    if (tab === "grupo") {
      if (ValidarFormCrudGrupo(grupo)) {
        try {
          let params = {
            empresa_id: empresaId,
            nombre: _.trim(grupo.nombre),
            telefono: _.trim(grupo.telefono),
            visible: grupo.visible ? 1 : 0,
            dpto_area:grupo.dpto_area,
            comentario:grupo.comentario,
          };
          // EMAIL
          if (_.trim(grupo.email) !== "") {
            params = { ...params, email: _.trim(grupo.email) };
          }
          // CHAT
          if (_.trim(grupo.chat) !== "") {
            params = { ...params, chat: _.trim(grupo.chat) };
          }
          await addContactoGeneral({ variables: params });
          await refetchEmpresa();
          onClose();
        } catch (error) {
          console.log(error);
          onClose();
        }
      } else {
        setValidate(true);
        setSending(false);
      }
    }
  };
  // RENDER
  return (
    <Modal
      show={true}
      backdrop="static"
      keyboard={false}
      // size="lg"
    >
      <Modal.Header>
        <Modal.Title>Agregar Contacto</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3" style={{ backgroundColor: "whitesmoke" }}>
        <Tabs
          id="uncontrolled-tab-example"
          activeKey={tab}
          onSelect={(e) => setTab(e)}
          className="nav-fill"
        >
          <Tab
            eventKey="personal"
            title="Funcionario"
            className="bg-white p-4 border-r border-b border-l"
          >
            {_.size(sucursales) === 0 ? (
              <div className="text-center">
                <i className={`fas fa-user fa-3x mb-2 `}></i>
                <h6 className="text-secondary mt-2" style={{ fontSize: 14 }}>
                  Para registrar tiene que ver al menos una oficina
                </h6>
              </div>
            ) : (
              <FrmEmpleado
                validate={validate}
                empleado={personal}
                disabledDelete={false}
                paisCiudad={paisCiudad}
                ciudades={ciudades}
                sucursales={sucursales}
                areas={areas}
                cargos={cargos}
                paises={paises}
                handleChange={handleChange}
                handleChangePais={handleChangePais}
                handleChangeCiudad={handleChangeCiudad}
              />
            )}
          </Tab>
          <Tab
            eventKey="grupo"
            title="Genéricos o globales"
            className="bg-white p-4 border-r border-b border-l"
          >
            <div
              className="text-secondary mb-4"
              style={{ fontSize: 12, marginTop: "-10px" }}
            >
              Ejm: Ventas, Contabilidad, etc.
            </div>
            <FrmGrupo
              validate={validate}
              grupo={grupo}
              handleChange={handleChange}
              disabledDelete={false}
            />
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} disabled={sending}>
          Cancelar
        </Button>
        {tab === "personal" && _.size(sucursales) === 0 ? null : (
          <Button variant="primary" onClick={onRegister} disabled={sending}>
            {sending && <LoadingButton />}
            Registrar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CrudContacto;
