import React, { useState } from "react";
import { Form, Row, Col, FormControl } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import _ from "lodash";
import { getStatesByCountry, getProvinceByState } from "../../utils/helpers";

const CiudadForm = (props) => {
  const { allCountry, initialState, selected, nameCity } = props;
  const { setSelected, setNameCity, getCities } = props;
  // STATE
  const [allState, setAllState] = useState([]);
  const [allProvince, setAllProvince] = useState([]);
  // FUNCTION
  const handleChangePais = (e) => {
    const newSelected = { ...initialState.selected, country: e };
    setSelected(newSelected);

    let newState = [];
    let paramsCity = {};
    if (_.size(e) > 0) {
      newState = getStatesByCountry(e[0].id);
      paramsCity = { countryId: e[0].id };
    }
    getCities(paramsCity);
    setAllState(newState);
    setAllProvince([]);
  };
  const handleChangeState = (e) => {
    const newSelected = {
      ...initialState.selected,
      country: selected.country,
      state: e,
    };
    setSelected(newSelected);
    let newProvince = [];
    let paramsCity = { countryId: selected.country[0].id };
    if (_.size(e) > 0) {
      newProvince = getProvinceByState(e[0].id);
      paramsCity = { stateId: e[0].id };
    }
    getCities(paramsCity);
    setAllProvince(newProvince);
  };
  const handleChangeProvince = (e) => {
    const newSelected = {
      ...initialState.selected,
      country: selected.country,
      state: selected.state,
      province: e,
    };
    let paramsCity = { stateId: selected.state[0].id };
    if (_.size(e) > 0) {
      paramsCity = { provinceId: e[0].id };
    }
    getCities(paramsCity);
    setSelected(newSelected);
  };
  // RENDER
  return (
    <Form>
      <Form.Group as={Row} controlId="FormPais">
        <Form.Label column sm="4" className="text-end">
          Pais :
        </Form.Label>
        <Col sm="5">
          <Typeahead
            id="pais"
            name="pais"
            labelKey="name"
            placeholder="Seleccionar"
            options={allCountry}
            selected={selected.country}
            onChange={handleChangePais}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="FormDpto">
        <Form.Label column sm="4" className="text-end">
          Departamento :
        </Form.Label>
        <Col sm="5">
          <Typeahead
            id="departamento"
            name="departamento"
            labelKey="name"
            placeholder={_.size(allState) > 0 ? "Seleccionar" : ""}
            options={allState}
            selected={selected.state}
            onChange={handleChangeState}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="FormProv">
        <Form.Label column sm="4" className="text-end">
          Provincia :
        </Form.Label>
        <Col sm="5">
          <Typeahead
            id="provincia"
            name="provincia"
            labelKey="nombre"
            placeholder={_.size(allProvince) > 0 ? "Seleccionar" : ""}
            options={allProvince}
            selected={selected.province}
            onChange={handleChangeProvince}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="FormCiudad">
        <Form.Label column sm="4" className="text-end">
          Ciudad :
        </Form.Label>
        <Col sm="5">
          <FormControl
            name="nombre"
            autoComplete="off"
            value={nameCity}
            onChange={(e) => setNameCity(e.target.value)}
            disabled={_.size(selected.country) === 0}
            placeholder={
              _.size(selected.country) > 0 ? "Ingresar la Ciudad" : ""
            }
          />
        </Col>
      </Form.Group>
    </Form>
  );
};

export default CiudadForm;
