import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import _ from "lodash";
import RegistrarAlojamiento from "../alojamiento/RegistrarAlojamiento";
import { Typeahead } from "react-bootstrap-typeahead";

const Step1 = ({
  empresa,
  setEmpresa,
  rubros,
  setStep,
  alojamiento,
  setAlojamiento,
  paises,
  getCities,
  loadCities,
  dataCities,
}) => {
  const idHotelero = process.env.REACT_APP_RUBRO_HOTELERO_ID;
  const [validate, setValidate] = useState(false);
  const [loadingPais, setLoadingPais] = useState({
    placeholder: "",
    disabled: false,
  });
  const [ciudades, setCiudades] = useState([]);
  // EFFECT
  useEffect(() => {
    setCiudades(dataCities);
  }, [dataCities]);
  // FUNCTION
  const handleNext = () => {
    if (validar()) {
      setStep(2);
    }
  };
  const handleChange = (e) => {
    setEmpresa({ ...empresa, [e.target.name]: e.target.value });
  };
  const validar = () => {
    let result = [];
    if (_.trim(empresa.nombre) === "")
      result = _.concat(result, "Nombre Empresa");
    if (parseInt(empresa.rubro) === 0)
      result = _.concat(result, "Rubro Empresa");
    if (_.size(empresa.pais) === 0) result = _.concat(result, "Pais Empresa");

    if (parseInt(empresa.rubro) === parseInt(idHotelero)) {
      if (parseInt(alojamiento.tipoAlojamiento) === 0)
        result = _.concat(result, "Tipo de Alojamiento");
      if (_.size(alojamiento.tipoPagos) === 0)
        result = _.concat(result, "Tipo de Pagos");

      _.map(alojamiento.lugares, (lugar, kl) => {
        if (_.trim(lugar.nombre) === "")
          result = _.concat(result, `Lugar Punto Interes#${kl}`);
        if (_.trim(lugar.distancia) === "")
          result = _.concat(result, `Distancia Punto Interes#${kl}`);
      });
    }

    if (_.size(result)) {
      console.log(result);
      setValidate(true);
      return false;
    }
    return true;
  };
  const handleChangePais = async (e) => {
    setEmpresa({ ...empresa, pais: e });
    setLoadingPais({ placeholder: "Cargando...", disabled: true });
    if (_.size(e) > 0) {
      getCities({ countryId: e[0].id });
    } else {
      setCiudades([]);
    }
    setEmpresa({ ...empresa, pais: e, ciudad: [] });
    setLoadingPais({ placeholder: "", disabled: false });
  };

  let classNombre = "";
  let classRubro = "";
  let validatePais = "";
  if (validate) {
    classNombre = _.trim(empresa.nombre) === "" ? "is-invalid" : "is-valid";
    classRubro = parseInt(empresa.rubro) === 0 ? "is-invalid" : "is-valid";
    validatePais = _.size(empresa.pais) > 0 ? "isValid" : "isInvalid";
  }

  return (
    <>
      <h3 className="mb-0">Empresa</h3>
      <p>Informacion de la empresa</p>
      <div className="p-4 shadow bg-white rounded mb-4">
        <Row noValidate>
          <Col sm="6" className="mb-3">
            <Form.Label>Nombre Empresa</Form.Label>
            <Form.Control
              name="nombre"
              type="text"
              autoComplete="off"
              value={empresa.nombre}
              onChange={(e) => handleChange(e)}
              className={classNombre}
            />
          </Col>

          <Col sm="6" className="mb-3">
            <Form.Label>Rubro</Form.Label>
            <Form.Control
              name="rubro"
              as="select"
              onChange={(e) => handleChange(e)}
              value={empresa.rubro}
              className={classRubro}
            >
              <option value="0"></option>
              {_.map(rubros, (val) => {
                return (
                  <option key={val.id} value={val.id}>
                    {val.nombre}
                  </option>
                );
              })}
            </Form.Control>
          </Col>

          <Col sm="6" className="mb-3">
            <Form.Label>Pais</Form.Label>
            <Typeahead
              id="pais"
              name="pais"
              labelKey="name"
              onChange={(e) => handleChangePais(e)}
              options={paises}
              selected={empresa.pais}
              isInvalid={
                validate && validatePais === "isInvalid" ? true : false
              }
              isValid={validate && validatePais === "isValid" ? true : false}
            />
          </Col>

          <Col sm="6" className="mb-3">
            <Form.Label>Ciudad</Form.Label>
            <Typeahead
              id="ciudad"
              name="ciudad"
              labelKey="name"
              onChange={(e) => setEmpresa({ ...empresa, ciudad: e })}
              options={ciudades}
              selected={empresa.ciudad}
              placeholder={loadCities ? "Cargando..." : ""}
              disabled={loadCities}
            />
          </Col>

          <Col sm="6" className="mb-3">
            <Form.Label>Pagina Web</Form.Label>
            <Form.Control
              name="web"
              type="text"
              autoComplete="off"
              value={empresa.web}
              onChange={(e) => handleChange(e)}
            />
          </Col>
        </Row>
      </div>

      {parseInt(empresa.rubro) === parseInt(idHotelero) && (
        <>
          <h6>Informacion Hotelero</h6>
          <Container className="p-4 shadow bg-white rounded mb-4">
            <RegistrarAlojamiento
              alojamiento={alojamiento}
              setAlojamiento={setAlojamiento}
              validate={validate}
            />
          </Container>
        </>
      )}

      <div className="mt-3">
        <Button
          variant="primary"
          className="float-right"
          disabled={loadingPais.disabled}
          onClick={handleNext}
        >
          Siguiente
        </Button>
      </div>
    </>
  );
};

export default Step1;
