import React from "react";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import LoadingButton from "./LoadingButton";

const ButtonIcon = ({
  titulo,
  icono,
  variant,
  className,
  onClick,
  size,
  disabled,
  text,
  style,
  loading = false,
}) => {
  if (!titulo) {
    return (
      <Button
        variant={`outline-${variant}`}
        className={className}
        onClick={onClick}
        size={size}
        disabled={disabled}
      >
        {loading ? (
          <LoadingButton />
        ) : (
          <>{icono && <i className={`${icono} ${text && "pr-1"}`}></i>}</>
        )}

        {text}
      </Button>
    );
  }
  return (
    <OverlayTrigger placement="bottom" overlay={<Tooltip>{titulo}</Tooltip>}>
      <Button
        variant={`outline-${variant}`}
        className={className}
        onClick={onClick}
        size={size}
        disabled={disabled}
        style={style}
      >
        {loading ? (
          <LoadingButton />
        ) : (
          <>{icono && <i className={`${icono} ${text && "pr-1"}`}></i>}</>
        )}
        {text}
      </Button>
    </OverlayTrigger>
  );
};

export default ButtonIcon;
