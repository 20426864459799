import React, { useState, useEffect } from "react";
import { EMPRESA } from "../utils/graphql_query";
import { useQuery } from "@apollo/client";
import { Tab, Row, Col, ListGroup, Tabs, Image, Button } from "react-bootstrap";

import InfoHotel from "../components/empresa/InfoHotel";
import InfoGeneral from "../components/empresa/InfoGeneral";
import { BuscarContactos } from "../components/empresa/BuscarContactos";
import DOCUMENTACION from "../components/empresa/documentacion";
import EntidadFinanciera from "../components/entidadFinanciera/EntidadFinanciera";
import GaleriaDeImagenes from "../components/empresa/galeriaImagenes";
import Sucursal from "../components/sucursal/Sucursal";
import InfoServicio from "../components/empresa/InfoServicio";
import { getLogin } from "../utils/authentication";
import NotAccess from "../components/base/NotAccess";
import LoadingPage from "../components/base/LoadingPage";
import Errors from "../components/base/Errors";
import useCountries from "../hooks/useCountries";
import Usuario from "../components/usuario/Usuario";
import UploadLogo from "../components/empresa/UploadLogo.jsx";
import Contactos from "../components/contacto/Contactos";
import Sincronizar from "../components/empresa/Sincronizar";
import moment from "moment";

const PerfilProveedor = (props) => {
  const user = getLogin();
  const [showAccion, setShowAccion] = useState("listar_servicio");
  const [uploadLogo, setUploadLogo] = useState(false);
  const [id] = useState(parseInt(props.match.params.id));
  const [nameLogo, setNameLogo] = useState("");

  const { loading, error, data, refetch } = useQuery(EMPRESA, {
    variables: { id },
  });
  // CUSTOM
  const { response: countries } = useCountries();

  useEffect(() => {
    if (data) {
      if (data.empresa.nombre_logo) {
        setNameLogo(data.empresa.nombre_logo);
      }
    }
  }, [data]);

  if (loading) return <LoadingPage />;
  if (error) return <Errors error={error} />;
  if (!data || !data.empresa)
    return <h2>No existe datos con el codigo = {id}</h2>;

  if (user.empresa && user.empresa.id !== id) return <NotAccess />;
  const result = data.empresa;
  const idHotelero = process.env.REACT_APP_RUBRO_HOTELERO_ID;
  const renderLogo = ({ nameFile, isOld }) => {
    if (!nameFile) {
      return `${process.env.PUBLIC_URL}/not_logo.png`;
    }
    if (isOld) {
      return `${process.env.REACT_APP_AWS_S3_URL}/logo/${nameFile}`;
    }
    return `${process.env.REACT_APP_AWS_S3_URL}/${nameFile}`;
  };

  //New loads are full path so includes a /
  const calcIsOld = !nameLogo.includes("/");
  return (
    <>
      <h3 className="mb-0">{result.nombre}</h3>
      <hr />
      <Tab.Container
        id="list-group-tabs-example"
        defaultActiveKey="informacion"
      >
        <Row>
          <Col sm={3}>
            <ListGroup>
              <ListGroup.Item className="text-center">
                <Image
                  src={renderLogo({ nameFile: nameLogo, isOld: calcIsOld })}
                  rounded
                  style={{ width: 215 }}
                />
                <Button
                  variant="outline-light"
                  style={{ position: "absolute", right: 0, top: 1 }}
                  onClick={() => setUploadLogo(true)}
                >
                  <i className="fas fa-pen text-primary"></i>
                </Button>
              </ListGroup.Item>
              <ListGroup.Item action eventKey="informacion">
                <Row>
                  <Col sm="10">
                    <i className="fa fa-info-circle"></i>{" "}
                    &nbsp;Informaci&oacute;n
                  </Col>
                  <Col sm="2">
                    <i className="fas fa-chevron-right"></i>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item action eventKey="adjuntos">
                <Row>
                  <Col sm="10">
                    <i className="fa fa-paperclip"></i> &nbsp;Adjuntos
                  </Col>
                  <Col sm="2">
                    <i className="fas fa-chevron-right"></i>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item action eventKey="contactos">
                <Row>
                  <Col sm="10">
                    <i className="fa fa-user-friends"></i> &nbsp;Buscar Contacto
                  </Col>
                  <Col sm="2">
                    <i className="fas fa-chevron-right"></i>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item
                action
                eventKey="servicios"
                onClick={() =>
                  showAccion !== "listar_servicio"
                    ? setShowAccion("listar_servicio")
                    : ""
                }
              >
                <Row>
                  <Col sm="10">
                    <i className="fa fa-store"></i> &nbsp;Servicios
                  </Col>
                  <Col sm="2">
                    <i className="fas fa-chevron-right"></i>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item action eventKey="galeria">
                <Row>
                  <Col sm="10">
                    <i className="fa fa-images"></i> &nbsp;Im&aacute;genes
                  </Col>
                  <Col sm="2">
                    <i className="fas fa-chevron-right"></i>
                  </Col>
                </Row>
              </ListGroup.Item>
              {parseInt(data.empresa.rubro.id) === parseInt(idHotelero) && (
                <ListGroup.Item action eventKey="extra">
                  <Row>
                    <Col sm="10">
                      <i className="fas fa-hotel"></i> &nbsp;Informaci&oacute;n
                      Extra
                    </Col>
                    <Col sm="2">
                      <i className="fas fa-chevron-right"></i>
                    </Col>
                  </Row>
                </ListGroup.Item>
              )}
            </ListGroup>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="informacion">
                <Tabs defaultActiveKey="general" id="uncontrolled-tab-example">
                  <Tab eventKey="general" title="General">
                    <InfoGeneral
                      empresaDatos={result}
                      refectEmpresa={refetch}
                      paisesAll={countries}
                    />
                  </Tab>
                  <Tab eventKey="oficinas" title="Oficinas">
                    <Sucursal
                      empresaId={result.id}
                      sucursales={result.sucursales}
                      paises={countries}
                      refetchEmpresa={refetch}
                      areas={data.areas}
                      cargos={data.cargos}
                    />
                  </Tab>
                  <Tab eventKey="contactos" title="Contactos">
                    <Contactos
                      empresaId={result.id}
                      contactoGeneral={result.contacto_general}
                      sucursales={result.sucursales}
                      paises={countries}
                      areas={data.areas}
                      cargos={data.cargos}
                      refetchEmpresa={refetch}
                    />
                  </Tab>
                  <Tab eventKey="usuario" title="Usuarios">
                    <Usuario idEmpresa={id} />
                  </Tab>
                  {user && user.empresa === null && (
                    <Tab eventKey="sincronizacion" title="Sincronizar">
                      <Sincronizar empresa={result} refectEmpresa={refetch} />
                    </Tab>
                  )}
                  <Tab eventKey="bancarizacion" title="Bancarización">
                    <EntidadFinanciera
                      idEmpresa={id}
                      refetchEmpresa={refetch}
                    />
                  </Tab>
                </Tabs>
              </Tab.Pane>
              <Tab.Pane eventKey="adjuntos">
                <DOCUMENTACION empresa_id={result.id} />
              </Tab.Pane>
              <Tab.Pane eventKey="contactos">
                <BuscarContactos
                  sucursales={result.sucursales}
                  contactoGeneral={result.contacto_general}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="servicios">
                <InfoServicio
                  empresa_id={result.id}
                  showAccion={showAccion}
                  setShowAccion={setShowAccion}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="galeria">
                <GaleriaDeImagenes empresa_id={result.id} />
              </Tab.Pane>
              {parseInt(data.empresa.rubro.id) === parseInt(idHotelero) && (
                <Tab.Pane eventKey="extra">
                  <InfoHotel empresa_id={result.id} />
                </Tab.Pane>
              )}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      {uploadLogo && (
        <UploadLogo
          empresaId={id}
          onClose={() => setUploadLogo(false)}
          onSetLogo={(img) => setNameLogo(img)}
        />
      )}
    </>
  );
};

export default PerfilProveedor;
