import _ from "lodash";
import S3 from "react-aws-s3";
import cities from "../data/cities.min.json";
import countries from "../data/countries.min.json";
import province from "../data/province.min.json";
import states from "../data/states.min.json";
import isEmail from "validator/lib/isEmail";
import AWS from "aws-sdk";

export const AgregarProvinciaDepartamento = (ciudades) => {
  let auxCiudades = [];
  _.map(ciudades, (ciudad) => {
    let auxName = `${ciudad.nombre_ciudad}`;
    if (ciudad.provincia) {
      auxName = `${auxName} - ${ciudad.provincia.nombre}`;
      if (ciudad.provincia.departamento) {
        auxName = `${auxName} - ${ciudad.provincia.departamento.nombre}`;
      }
    }
    auxCiudades = _.concat(auxCiudades, {
      ...ciudad,
      nombre_ciudad_buscar: auxName,
    });
  });
  return auxCiudades;
};

export const ObjCiudadParaCombo = (ciudad) => {
  let result = {};
  if (ciudad) {
    let aux = `${ciudad.nombre_ciudad}`;
    if (ciudad.provincia) {
      aux = `${aux} - ${ciudad.provincia.nombre}`;
      if (ciudad.provincia.departamento) {
        aux = `${aux} - ${ciudad.provincia.departamento.nombre}`;
      }
    }
    result = { ...ciudad, nombre_ciudad_buscar: aux };
  }
  return result;
};

export const ValidarFormContactoGeneral = (contactoGeneral) => {
  let result = [];
  _.map(contactoGeneral, (contacto, kc) => {
    if (_.trim(contacto.titulo) === "") {
      result = _.concat(result, `TITULO #C${kc}`);
    }
    _.map(contacto.detalleContacto, (detalle, kd) => {
      if (parseInt(detalle.medio) === 0) {
        result = _.concat(result, `MEDIO DE CONTACTO #C${kc} #D${kd}`);
      }
      if (_.trim(detalle.informacion) === "") {
        result = _.concat(result, `INFORMACION #C${kc} #D${kd}`);
      }
    });
  });

  if (_.size(result) > 0) {
    console.log(result);
    return false;
  }
  return true;
};

export const ValidarFormContactoGeneralV2 = (contactoGeneral) => {
  let result = [];
  _.map(contactoGeneral, (contacto, kc) => {
    if (_.trim(contacto.titulo) === "") {
      result = _.concat(result, `TITULO #C${kc}`);
    }
    _.map(contacto.detalleContacto, (detalle, kd) => {
      if (_.trim(detalle.telefono) === "" && _.trim(detalle.email) === "") {
        result = _.concat(result, `TELEFONO y EMAIL #C${kc} #D${kd}`);
      }
    });
  });

  if (_.size(result) > 0) {
    console.log(result);
    return false;
  }
  return true;
};

export const ValidarFormCrudSucursal = (sucursal) => {
  let result = [];

  if (_.trim(sucursal.nombre) === "") {
    result = _.concat(result, "Nombre");
  }
  if (_.trim(sucursal.telefono) === "") {
    result = _.concat(result, "Telefono");
  }
  if (_.trim(sucursal.direccion) === "") {
    result = _.concat(result, "Direccion");
  }

  if (_.size(result) > 0) {
    console.log(result);
    return false;
  }
  return true;
};

export const ValidarFormCrudEmpleado = (empleado) => {
  let result = [];
  if (_.trim(empleado.nombres) === "") {
    result = _.concat(result, "Nombres");
  }
  if (_.trim(empleado.apellidos) === "") {
    result = _.concat(result, "Apellidos");
  }

  if (parseInt(empleado.area) === 0) {
    result = _.concat(result, "Area");
  }
  if (parseInt(empleado.cargo) === 0) {
    result = _.concat(result, "Cargo");
  }
  if (parseInt(empleado.oficina) === 0) {
    result = _.concat(result, "Oficina");
  }

  if (_.size(result) > 0) {
    console.log(result);
    return false;
  }
  return true;
};

export const getCitiesByCountry = (id) => {
  return _.filter(
    cities,
    ({ country_id }) => parseInt(country_id) === parseInt(id)
  );
};

export const getCitiesByState = (id) => {
  return _.filter(
    cities,
    ({ state_id }) => parseInt(state_id) === parseInt(id)
  );
};

export const getCitiesByProvince = (id) => {
  return _.filter(
    cities,
    ({ province_id }) => parseInt(province_id) === parseInt(id)
  );
};

export const getStatesByCountry = (id) => {
  return _.filter(
    states,
    ({ country_id }) => parseInt(country_id) === parseInt(id)
  );
};

export const getProvinceByState = (id) => {
  return _.filter(
    province,
    ({ departamento_id }) => parseInt(departamento_id) === parseInt(id)
  );
};

export const getStatesById = (stateId) => {
  return _.find(states, ({ id }) => parseInt(id) === parseInt(stateId));
};
export const getProvinceById = (provinceId) => {
  return _.find(province, ({ id }) => parseInt(id) === parseInt(provinceId));
};

export const getCountryById = (countryId) => {
  return _.find(countries, ({ id }) => parseInt(id) === parseInt(countryId));
};

export const getCityById = (cityId) => {
  return _.find(cities, ({ id }) => parseInt(id) === parseInt(cityId));
};

export const uploadingAwsS3 = async (file, folder, nameFile) => {
  const {
    REACT_APP_AWS_S3_BUCKET,
    REACT_APP_AWS_S3_FOLDER_TEST,
    REACT_APP_AWS_S3_REGION,
    REACT_APP_AWS_S3_ID,
    REACT_APP_AWS_S3_SECRET,
    REACT_APP_AWS_S3_URL,
  } = process.env;
  const config = {
    bucketName: REACT_APP_AWS_S3_BUCKET,
    dirName: REACT_APP_AWS_S3_FOLDER_TEST
      ? folder + REACT_APP_AWS_S3_FOLDER_TEST
      : folder,
    region: REACT_APP_AWS_S3_REGION,
    accessKeyId: REACT_APP_AWS_S3_ID,
    secretAccessKey: REACT_APP_AWS_S3_SECRET,
    s3Url: REACT_APP_AWS_S3_URL,
  };
  const ReactS3Client = new S3(config);
  /* This is optional */
  return await ReactS3Client.uploadFile(file, nameFile)
    .then((data) => {
      return { success: true, data: data };
    })
    .catch((err) => {
      return { success: false, error: err };
    });
};

export const genPass = (size) => {
  let a = "";
  const b = "abcdefghijklmnopqrstuvwxyz1234567890";
  const c = size;
  for (let ma = 0; ma < c; ma++) {
    a += b[Math.floor(Math.random() * b.length)];
  }
  return a;
};

export const validateFormCrudUsuario = (usuario) => {
  const sizePass = 6;
  let result = [];
  if (_.trim(usuario.nombre) === "") {
    result = _.concat(result, "Nombre");
  }
  if (_.trim(usuario.apellido) === "") {
    result = _.concat(result, "Apellido");
  }
  if (_.trim(usuario.email) === "" || !isEmail(_.trim(usuario.email))) {
    result = _.concat(result, "Email");
  }
  if (_.size(_.trim(usuario.password)) > 0) {
    if (_.size(_.trim(usuario.password)) < sizePass) {
      result = _.concat(result, "Password");
    }
  }

  if (_.size(result) > 0) {
    console.log(result);
    return false;
  }
  return true;
};

/** Get presigned URL from backend
 * @param {{filename: string, contentType?: string, metadata?: object, bucket?: string, prefix: string, contentDisposition?: string}} param0
 * @returns {Promise<{success: boolean, data: {urlUpload: string, key: string}} | null>}
 */
export const getPresignedURL = async ({
  filename,
  prefix,
  metadata,
  contentType,
  contentDisposition,
  bucket = "trippin-admin",
}) => {
  const { REACT_APP_END_POINT_TRIPPIN, REACT_APP_NAME_TOKEN } = process.env;
  // api/archivos/presignedUrlUploadTemp
  const url = `${REACT_APP_END_POINT_TRIPPIN}/api/archivos/presignedUrlUploadTemp`;
  const data = localStorage.getItem(REACT_APP_NAME_TOKEN ?? "");
  if (!data) return null;
  const { token } = JSON.parse(data);
  //Headers
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const presigned = await fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify({
      filename,
      metadata,
      bucket,
      prefix,
      contentType,
      contentDisposition,
    }),
  });
  return await presigned.json();
};

/**
 *
 * @param {{key: string, bucket?: string}} param0
 */
export const makeObjectPermanent = async ({
  key,
  bucket = "trippin-admin",
}) => {
  const { REACT_APP_END_POINT_TRIPPIN, REACT_APP_NAME_TOKEN } = process.env;
  const url = `${REACT_APP_END_POINT_TRIPPIN}/api/archivos/makeObjectPermanent`;
  const data = localStorage.getItem(REACT_APP_NAME_TOKEN ?? "");
  if (!data) return null;
  const { token } = JSON.parse(data);
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify({ key, bucket }),
  });
  return await response.json();
};


/**
 *
 * @param {{isBase64?: boolean, data: Buffer, filename: string, metadata?: object, prefix: string}} param0
 * @returns  {Promise<{success: boolean, key: string} | null>}
 */
export const uploadFileS3 = async ({
  data,
  filename,
  prefix,
}) => {
  const url = await getPresignedURL({
    // contentDisposition: "attachment",
    filename,
    prefix,
  });
  if (!url) {
    throw new Error("Error al obtener presigned URL");
  }
  const { urlUpload, key } = url.data;
  const response = await fetch(urlUpload, {
    method: "PUT",
    body: data,
  });
  //Make object permanent
  const permanent = await makeObjectPermanent({ key });
  if (!permanent) {
    throw new Error("Error al hacer objeto permanente");
  }
  return { success: response.ok, key: permanent.data.url };
};

/**
 *
 * @param {{isBase64?: boolean, data: Buffer, filename: string, metadata?: object, prefix: string}} param0
 * @returns  {Promise<{success: boolean, key: string} | null>}
 */
export const uploadImgS3 = async ({
  data,
  filename,
  prefix,
}) => {
  const url = await getPresignedURL({
    contentType: "image/png",
    // contentDisposition: "attachment",
    filename,
    prefix,
  });
  if (!url) {
    throw new Error("Error al obtener presigned URL");
  }
  const { urlUpload, key } = url.data;
  const response = await fetch(urlUpload, {
    method: "PUT",
    headers: { "Content-Encoding": "base64", "Content-Type": "image/png" },
    body: data,
  });
  //Make object permanent
  const permanent = await makeObjectPermanent({ key });
  if (!permanent) {
    throw new Error("Error al hacer objeto permanente");
  }
  return { success: response.ok, key: permanent.data.url };
};

export const valiteEditService = (state, pais) => {
  let result = [];
  // TITULO
  if (_.trim(state.titulo) === "") {
    result = _.concat(result, "Titulo");
  }
  // PRECIO
  if (_.trim(state.precio) === "" || parseFloat(state.precio) <= 0) {
    result = _.concat(result, "Precio");
  }
  // PAIS
  if (_.size(pais) === 0) {
    result = _.concat(result, "Pais");
  }

  if (_.size(result) > 0) {
    console.log(result);
    return false;
  }
  return true;
};

export const ValidarFormCrudGrupo = (grupo) => {
  let result = [];
  if (_.trim(grupo.nombre) === "") {
    result = _.concat(result, "Nombre");
  }
  if (_.trim(grupo.telefono) === "") {
    result = _.concat(result, "Telefono");
  }

  if (_.size(result) > 0) {
    console.log(result);
    return false;
  }
  return true;
};

export const removeAccents = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};
