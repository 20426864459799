import React from "react";

const InputRequired = ({ className }) => {
  return (
    <span style={{ color: "red" }} className={className}>
      *
    </span>
  );
};

export default InputRequired;
