import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Modal, Form, Button, Row, Col, Alert } from "react-bootstrap";

const IuDeletElement = ({ idElement, mut_delet, refetch, mut_edit }) => {
  const [mutDeletElement, { loading: ldDelet }] = useMutation(mut_delet, {
    onCompleted(data) {
      const keysData = Object.keys(data);
      const valueData = data[keysData[0]];
      if (valueData.id === 0) {
        setcanDelet(false);
      }
    },
  });
  const [mutEditElement, { loading: ldEdit }] = useMutation(mut_edit, {
    onCompleted(data) {
      const keysData = Object.keys(data);
      const valueData = data[keysData[0]];
      if (valueData.id === 0) {
        setAlertErr(true);
        setTimeout(() => {
          setAlertErr(false);
        }, 2000);
      } else if (valueData.id !== 0) {
        setAlertSucc(true);
        setTimeout(() => {
          setAlertSucc(false);
          setShowModalEdit(false);
        }, 2000);
      }
      refetch();
    },
  });
  const [canDelet, setcanDelet] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [alertErr, setAlertErr] = useState(false);
  const [alertSucc, setAlertSucc] = useState(false);
  const [nameTxt, setnameTxt] = useState("");

  const handleClose = () => setShowModal(false);
  const handleShow = async () => {
    await mutDeletElement({
      variables: {
        id: idElement,
        verify: "verificar",
      },
    });
    setShowModal(true);
  };
  const handleCloseEdit = () => setShowModalEdit(false);
  const handleShowEdit = () => setShowModalEdit(true);
  const handleNameTxt = (e) => setnameTxt(e.target.value);
  const handleDeletElement = async () => {
    try {
      await mutDeletElement({
        variables: {
          id: idElement,
          verify: "",
        },
      });
      refetch();
      setShowModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditElement = async () => {
    await mutEditElement({
      variables: {
        id: idElement,
        nombre: nameTxt,
      },
    });
  };

  return (
    <>
      <div md="1" className="float-right">
        <Button
          type="button"
          size="sm"
          variant="primary"
          onClick={handleShowEdit}
        >
          <i className="fas fa-pen" />
        </Button>{" "}
        <Button type="button" size="sm" variant="danger" onClick={handleShow}>
          <i className="fas fa-trash" />
        </Button>
      </div>

      <Modal show={showModal} onHide={handleClose} backdrop="static" size="md">
        <Modal.Body className="p-4">
          {canDelet ? (
            <div>Confirma eliminar el elemento?</div>
          ) : (
            <div>No es posible eliminar por que tiene una relacion.</div>
          )}
          {ldDelet ? <p>Loading...</p> : ""}
          <Alert show={alertSucc} variant="success">
            Editado Correctamente.
          </Alert>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Salir
          </Button>
          {canDelet ? (
            <Button variant="primary" onClick={handleDeletElement}>
              Eliminar
            </Button>
          ) : (
            ""
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModalEdit}
        onHide={handleCloseEdit}
        backdrop="static"
        size="md"
      >
        <Modal.Body className="p-4">
          <Form>
            <Form.Group as={Row} controlId="formNameTxt">
              <Form.Label column sm={4}>
                Nombre
              </Form.Label>
              <Col sm={6}>
                <Form.Control
                  type="text"
                  value={nameTxt}
                  onChange={handleNameTxt}
                />
              </Col>
            </Form.Group>
          </Form>
          {ldEdit ? <p>Loading...</p> : ""}
          <Alert show={alertSucc} variant="success">
            Editado Correctamente.
          </Alert>
          <Alert show={alertErr} variant="warning">
            El Dato Introducido Ya Existe.
          </Alert>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEdit}>
            Salir
          </Button>
          <Button variant="primary" onClick={handleEditElement}>
            Actualizar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default IuDeletElement;
