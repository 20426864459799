import React from 'react'
import { Button } from 'react-bootstrap';

const Step5 = ({ empresa, resultado, setStep}) => {
  const iconResult = resultado.success? 'check': 'times'
  const colorText = resultado.success ? 'text-success' : 'text-danger'
  return (
    <>
      <div className="p-5 shadow bg-white rounded text-center">
        {/* <i className="fas fa-check fa-4x"></i> */}
        <i className={`fas fa-${iconResult} fa-4x ${colorText}`}></i>
        <h6 className="mt-4">{resultado.mensaje}</h6>
        <h4>{empresa.nombre}</h4>
      </div>
      <div className="mt-3">
        {
          !resultado.success 
            && <Button variant="secondary" onClick={() => setStep(4)}>Atras</Button>
        }
      </div>
    </>
  )
}

export default Step5
