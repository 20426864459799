import React, { useState, useEffect } from 'react'
import { Container, Table, ListGroup, Button, Modal, Form, Row, Col, Alert } from 'react-bootstrap';
import { INFO_HOTEL } from './../../utils/graphql_query';
import { SAVE_SERVICE, DELETE_PUNTO, ADD_PUNTO_INTERES, UPDATE_PUNTO, UPDATE_ALOJAMIENTO, SAVE_TIPO_PAGO } from './../../utils/graphql_mutation';
import { useQuery, useMutation } from '@apollo/client';
import _ from 'lodash'
import InfoHotelService from './InfoHotelService'
import Habitaciones from '../alojamiento/habitaciones'
import Select from 'react-select';
import ContainerNotResult from '../base/ContainerNotResult';
import Errors from '../base/Errors';
import LoadingPage from '../base/LoadingPage';

const baseAlojamiento = {"categoria":'', "tipo_alojamiento":{"id":0,"nombre":''},  "numero_pisos":0,     "nro_single":0, "nro_doble":0,
                        "nro_triple":0, "nro_cuadruple":0,      "nro_matrimonial":0,  "nro_suite":0, "fecha_construccion":'','tipos_pago':[]}

const InfoHotel = ({empresa_id}) => {
    const { loading, data, refetch, error } = useQuery(INFO_HOTEL, { variables: { empresa_id: empresa_id } })
    
    const [addService, {loading:ldAddServ}] = useMutation(SAVE_SERVICE);
    const [mutDeletePunto, {loading:ldDePunto}] = useMutation(DELETE_PUNTO);
    const [mutUpAlojamiento, {loading:ldUpAlojamiento}] = useMutation(UPDATE_ALOJAMIENTO);
    const [mutAddPunto, {loading:ldAddPunto}] = useMutation(ADD_PUNTO_INTERES);
    const [mutUpTipPago, {loading:ldUpTipPago}] = useMutation(SAVE_TIPO_PAGO);
    const [mutUpdatePunto, {loading:ldUpPunto}] = useMutation(UPDATE_PUNTO, {
        onCompleted(datos) {
            const keysData  = Object.keys(datos)
            const valueData = datos[keysData[0]]
            if (valueData.id === 0) {
                setAlertErr(true)
                setTimeout(() => { 
                    setAlertErr(false)
                }, 2000);
            }
            else {
                setShowModalPunto(false)
                refetch()
            }
        }
    });

    const [service, setService] = useState([])
    const [alertErr, setAlertErr] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [disableTxt, setDisableTxt] = useState(false)
    const [serviceSave, setServiceSave] = useState([])
    const [puntoInteres, setPuntoInteres] = useState({id:0,lugar:'',distancia:''})
    const [showModalInfo, setShowModalInfo] = useState(false);
    const [dataHotelInfo, setDataHotelInfo] = useState(baseAlojamiento)
    const [showModalPunto, setShowModalPunto] = useState(false)
    const [validarPuntoInteres, setValidarPuntoInteres] = useState(false)

    useEffect(() => {
        if (data && data.alojamiento) {
            setServiceSave(data.alojamiento.servicios_alojamiento)
            agruparTipo(data.alojamiento.servicios_alojamiento) 
        }
    },[data])

    if (loading) return <LoadingPage />;
    if (error) return <Errors error={error}/>
    if (!data.alojamiento) return "No existe informacion";
    
    if (_.isNull(data.alojamiento)) {
        return (
            <div className="mb-5 mt-3 shadow">
                <ContainerNotResult icon="fas fa-exclamation" mensaje="Empresa de tipo HOTELERO, pero no contiene informacion." />
            </div>
        )
    }


    const handleClose = () => setShowModal(false);

    const agruparTipo = (data) => {
        const agrupado = _.chain(data)
          .groupBy('tipo_servicio')
          .map((value, key) => ({ tipo_servicio: key, servicio: value }))
          .value();
        setService(agrupado)
    }

    const saveService = async () => {
        let arr = []
        _.map(serviceSave,(val) => {
            arr = _.concat(arr, val.id)
        })
        await addService({
            variables:{
                alojamiento_id:data.alojamiento.id,
                services:JSON.stringify(arr)
            }
        })
        setShowModal(false)
        refetch()
    }

    const updatePunto = (val) => {
        let punto       = puntoInteres;
        punto.id        = val.id
        punto.lugar     = val.lugar
        punto.distancia = val.distancia
        setValidarPuntoInteres(false)
        setPuntoInteres(punto)
        setDisableTxt(false)
        setShowModalPunto(true)
    }

    const confirmDeletPunto = async (val) => {
        try {
            await mutDeletePunto({
                variables:{
                    id:puntoInteres.id
                }
            })
            setShowModalPunto(false)
            refetch()
        } catch (error) {
            alert('Error al eliminar')
            console.log('Error al editar')
            console.log(error)
        }
    }

    const deletePunto = (val) => {
        let punto       = puntoInteres;
        punto.id        = val.id
        punto.lugar     = val.lugar
        punto.distancia = val.distancia
        setValidarPuntoInteres(false)
        setPuntoInteres(punto)
        setDisableTxt(true)
        setShowModalPunto(true)
    }

    const newPunto = () => {
        let punto       = puntoInteres;
        punto.id        = 0
        punto.lugar     = ''
        punto.distancia = ''
        setValidarPuntoInteres(false)
        setPuntoInteres(punto)
        setDisableTxt(false)
        setShowModalPunto(true)
    }

    const onChangeLugar = (e) => {
        let lugar   = e.target.value;
        setPuntoInteres({
            ...puntoInteres,
            lugar:lugar
        })
    }

    const onChangeDistancia = (e) => {
        let distancia   = e.target.value;
        setPuntoInteres({
            ...puntoInteres,
            distancia:distancia
        })
    }

    const saveUpdatePunto = async () => {
        const p = puntoInteres
        try {
            if (p.id === 0) {
                // INSERTA NUEVO PUNTO DE INTERES
                if (validarInputPuntoInteres())
                    setValidarPuntoInteres(true)
                
                if (!validarInputPuntoInteres()) {
                    await mutAddPunto({
                        variables:{
                            alojamiento_id:data.alojamiento.id,
                            lugar:p.lugar,
                            distancia:p.distancia
                        }
                    })
                    refetch()
                    setShowModalPunto(false)
                }
                
            }
            else {
                // ACTUALIZA UN PUNTO DE INTERES
                if (validarInputPuntoInteres())
                    setValidarPuntoInteres(true)
                
                if (!validarInputPuntoInteres()) {
                    await mutUpdatePunto({
                        variables:{
                            id:p.id,
                            alojamiento_id:data.alojamiento.id,
                            lugar:p.lugar,
                            distancia:p.distancia
                        }
                    })
                }
            }
        } catch (error) {
            alert('Error al realizar esta accion')
            console.log('Error al editar')
            console.log(error)
        }       
    }
    
    const validarInputPuntoInteres = () => {
        var lugar = document.getElementById('txtLugar')
        var distancia = document.getElementById('txtDistancia')
        let can_save = false
        if (lugar.value === '')
            can_save = true
        if (distancia.value === '')
            can_save = true
        
        return can_save
    }

    const textHabitacion = (nro_habitacion) => {
        if (nro_habitacion !== null && nro_habitacion !== 0) {
            return parseInt(nro_habitacion) === 1 ? nro_habitacion + ' Habitación' : nro_habitacion + ' Habitaciones'
        }
        else {
            return '-'
        }
    }

    const textCategoria = (cat) => {
        if (cat !== null && cat !== '')
            return cat + ' Est.'
        return '-'
    }
    
    const openModalHotelInfo = () => {
        let hotelInfo = data.alojamiento
        dataHotelInfo.fecha_construccion = hotelInfo.fecha_construccion !== null ? hotelInfo.fecha_construccion : ''
        dataHotelInfo.categoria         = hotelInfo.categoria !== null ? hotelInfo.categoria : '' 
        dataHotelInfo.tipo_alojamiento  = hotelInfo.tipo_alojamiento
        dataHotelInfo.numero_pisos      = hotelInfo.numero_pisos !== null ? hotelInfo.numero_pisos : 0
        dataHotelInfo.nro_single        = hotelInfo.nro_single !== null ? hotelInfo.nro_single : 0
        dataHotelInfo.nro_doble         = hotelInfo.nro_doble !== null ? hotelInfo.nro_doble : 0
        dataHotelInfo.nro_triple        = hotelInfo.nro_triple !== null ? hotelInfo.nro_triple : 0
        dataHotelInfo.nro_cuadruple     = hotelInfo.nro_cuadruple !== null ? hotelInfo.nro_cuadruple : 0
        dataHotelInfo.nro_matrimonial   = hotelInfo.nro_matrimonial !== null ? hotelInfo.nro_matrimonial : 0
        dataHotelInfo.nro_suite         = hotelInfo.nro_suite !== null ? hotelInfo.nro_suite : 0
        let result = [];
        _.map(data.alojamiento.tipos_pago, (val) => {
        result = _.concat(result, { value: val.id, label: val.nombre })
        })
        dataHotelInfo.tipos_pago = result
        setShowModalInfo(true)
    }

    const handleChange = (e) => {
        let hotelInfo   = dataHotelInfo
        const name      = e.target.name
        
        if (name === 'tipo_alojamiento') {
            hotelInfo.tipo_alojamiento.id = parseInt(e.target.value)
        }
        else {
            hotelInfo[name] = e.target.value
        }
        setDataHotelInfo({...dataHotelInfo, hotelInfo})
    }

    const handleChangeSelect = (e) => {
        let hotelInfo   = dataHotelInfo
        hotelInfo.tipos_pago = e
        setDataHotelInfo({...dataHotelInfo, hotelInfo})
    }

    const saveAlojamiento = async () => {
        await mutUpAlojamiento({
            variables : {
                id:data.alojamiento.id,
                tipo_alojamiento_id: dataHotelInfo.tipo_alojamiento.id,
                empresa_id: empresa_id,
                categoria: dataHotelInfo.categoria === '' ? null : parseInt(dataHotelInfo.categoria),
                fecha_construccion: dataHotelInfo.fecha_construccion,
                numero_pisos: dataHotelInfo.numero_pisos === '' ? null : parseInt(dataHotelInfo.numero_pisos),
                nro_single: dataHotelInfo.nro_single === '' ? null : parseInt(dataHotelInfo.nro_single),
                nro_doble: dataHotelInfo.nro_doble === '' ? null : parseInt(dataHotelInfo.nro_doble),
                nro_triple: dataHotelInfo.nro_triple === '' ? null : parseInt(dataHotelInfo.nro_triple),
                nro_cuadruple: dataHotelInfo.nro_cuadruple === '' ? null : parseInt(dataHotelInfo.nro_cuadruple),
                nro_matrimonial: dataHotelInfo.nro_matrimonial === '' ? null : parseInt(dataHotelInfo.nro_matrimonial),
                nro_suite: dataHotelInfo.nro_suite === '' ? null : parseInt(dataHotelInfo.nro_suite),
            }
        })

        let tipos_pago_id = []
        
        _.map(dataHotelInfo.tipos_pago, (val) => {
            tipos_pago_id = _.concat(tipos_pago_id, val.value)
        })
        
        await mutUpTipPago({
            variables : {
                alojamiento_id: data.alojamiento.id,
                json_tipos_pago: JSON.stringify(tipos_pago_id)
            }
        })
        refetch()
        setShowModalInfo(false)
    }

    const tipo_pagos_options = () => {
        let result = [];
        _.map(data.tipo_pagos, (val) => {
            result = _.concat(result, { value: val.id, label: val.nombre })
        })
        return result;
    }
    
    const btnDisable = ldAddPunto || ldDePunto || ldUpPunto || ldUpAlojamiento || ldUpTipPago ? true : false;
    const btnServiceDisabled = ldAddServ ? true : false;
    
    let txLugar = ''
    let txDistancia = ''
    if (validarPuntoInteres) {
        txLugar = puntoInteres.lugar !== '' ? 'is-valid' : 'is-invalid'
        txDistancia = puntoInteres.distancia !== '' ? 'is-valid' : 'is-invalid'
    }
    return(

        <>
            <h5>Informaci&oacute;n basica del Hotel</h5>
            <Container className="shadow p-4 bg-white rounded mb-2">
                <ListGroup variant="flush">
                    <ListGroup.Item  className="d-flex justify-content-between py-1 p-2">
                        <div className="col-3"><strong>A&ntilde;o Construcci&oacute;n :</strong></div>
                        <div className="col-9">{ data.alojamiento.fecha_construccion }</div>
                    </ListGroup.Item>
                    <ListGroup.Item  className="d-flex justify-content-between py-1 p-2">
                        <div className="col-3"><strong>Categoria :</strong></div>
                        <div className="col-9">{textCategoria(data.alojamiento.categoria)}</div>
                    </ListGroup.Item>
                    <ListGroup.Item  className="d-flex justify-content-between py-1 p-2">
                        <div className="col-3"><strong>Tipo Alojamiento :</strong></div>
                        <div className="col-9">{data.alojamiento.tipo_alojamiento.nombre}</div>
                    </ListGroup.Item>
                    <ListGroup.Item  className="d-flex justify-content-between py-1 p-2">
                        <div className="col-3"><strong>Numero Pisos :</strong></div>
                        <div className="col-9">{data.alojamiento.numero_pisos}</div>
                    </ListGroup.Item>
                    <ListGroup.Item  className="d-flex justify-content-between py-1 p-2">
                        <div className="col-3"><strong>Single :</strong></div>
                        <div className="col-9">{textHabitacion(data.alojamiento.nro_single)}</div>

                    </ListGroup.Item>
                    <ListGroup.Item  className="d-flex justify-content-between py-1 p-2">
                        <div className="col-3"><strong>Doble :</strong></div>
                        <div className="col-9">{textHabitacion(data.alojamiento.nro_doble)}</div>
                    </ListGroup.Item>
                    <ListGroup.Item  className="d-flex justify-content-between py-1 p-2">
                        <div className="col-3"><strong>Triple :</strong></div>
                        <div className="col-9">{textHabitacion(data.alojamiento.nro_triple)}</div>
                    </ListGroup.Item>
                    <ListGroup.Item  className="d-flex justify-content-between py-1 p-2">
                        <div className="col-3"><strong>Cuadruple :</strong></div>
                        <div className="col-9">{textHabitacion(data.alojamiento.nro_cuadruple)}</div>
                    </ListGroup.Item>
                    <ListGroup.Item  className="d-flex justify-content-between py-1 p-2">
                        <div className="col-3"><strong>Matrimonial :</strong></div>
                        <div className="col-9">{textHabitacion(data.alojamiento.nro_matrimonial)}</div>
                    </ListGroup.Item>
                    <ListGroup.Item  className="d-flex justify-content-between py-1 p-2">
                        <div className="col-3"><strong>Suite :</strong></div>
                        <div className="col-9">{textHabitacion(data.alojamiento.nro_suite)}</div>

                    </ListGroup.Item>
                    <ListGroup.Item  className="d-flex justify-content-between py-1 p-2">
                        <div className="col-3"><strong>Tipos de Pago :</strong></div>
                        <div className="col-9">
                            {
                                _.map(data.alojamiento.tipos_pago, (val ,i) => {
                                    return(
                                        <div key={i}>
                                            {val.nombre}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </Container>
            <Container className="text-end mb-3 p-0">
                <Button onClick={() => openModalHotelInfo() }>Editar</Button>
            </Container>
            
            <Habitaciones data={data.alojamiento} refetch={refetch} />

            <h5>Servicios en el Hotel</h5>
            <Container className="shadow p-4 bg-white rounded mb-2">
                <div className="row">
                    {
                        (service.length > 0)
                        ?
                            _.map(service, (value, i)=>{
                                return (
                                        <div className="col-4" key={i}>
                                            <strong>{_.startCase(_.camelCase(value.tipo_servicio))}</strong>
                                            <ListGroup variant="flush">
                                                {
                                                    _.map(value.servicio, (val, k)=>{
                                                        return (
                                                            <ListGroup.Item  className="d-flex justify-content-between py-1" key={k}> 
                                                                <div className="col-2">
                                                                    <i className="fa fa-check-circle" style={{color: '#6ec563',fontSize: '25px'}}></i>
                                                                </div>
                                                                <div className="col-10">
                                                                    {val.nombre}
                                                                </div>
                                                            </ListGroup.Item>
                                                        )
                                                    })
                                                }
                                            </ListGroup>
                                        </div>
                                )
                            })
                        :
                            <div className="col-12">
                                <div className="jumbotron text-center p-4 secondary light mb-3">
                                    <i className="fas fa-info-circle fa-3x "></i>
                                    <h4 className="mt-2 light">.</h4>
                                    <h6 >No se inserto ningun servicio.</h6>
                                </div>
                            </div>
                    }
                </div>
            </Container>
            <Container className="text-end mb-3 p-0">
                <Button onClick={() => setShowModal(true) }>Editar</Button>
            </Container>

            <h5>Puntos de Interes cerca del Hotel</h5>
            <Table striped bordered hover className="shadow bg-white mb-2">
                <thead>
                    <tr className="text-center">
                        <th>Lugar</th>
                        <th>Distancia</th>
                        <th>Acci&oacute;n</th>
                    </tr>
                </thead>
                <tbody>
                    {   
                        (data.alojamiento.puntos_interes.length === 0)
                        ?
                            <tr>
                                <td colSpan="3">No existen datos insertados.</td>
                            </tr>
                        :
                            _.map(data.alojamiento.puntos_interes,(val, i) => {
                                return(
                                    <tr key={i}>
                                        <td>{_.startCase(_.camelCase(val.lugar))}</td>
                                        <td>{val.distancia}</td>
                                        <td className="text-center">
                                            <Button  type="button" size="sm"  variant="primary" onClick={ () => updatePunto(val) } >
                                                <i className="fas fa-pen"/>
                                            </Button>
                                            {' '}
                                            <Button  type="button" size="sm"  variant="danger" onClick={ () => deletePunto(val) } >
                                                <i className="fas fa-trash"/>
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            })
                    }
                </tbody>
            </Table>
            <Container className="text-end mb-3 p-0">
                <Button onClick={() => newPunto() }>Agregar</Button>
            </Container>

            <Modal show={showModal} onHide={handleClose}  backdrop="static"  size="lg" >
                <Modal.Header>
                    <Modal.Title className="h4">
                        Editar Servicios
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-2">
                    
                    <InfoHotelService 
                        serviceAll = {data.servicios_alojamiento_all}
                        serviceSave = {serviceSave}
                        setServiceSave = {setServiceSave}
                    />

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} >Salir</Button>
                    <Button variant="primary" disabled={btnServiceDisabled} onClick={saveService} >Confirmar</Button>
                </Modal.Footer>

            </Modal>

            <Modal show={showModalPunto} onHide={handleClose}  backdrop="static"  size="md" >
                
                <Modal.Header>
                    <Modal.Title className="h4">
                        {puntoInteres.id === 0 ? 'Agregar' : disableTxt ? 'Eliminar' : 'Editar'} Punto Interes
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="p-4">
                    
                    <Form.Group as={Row} >
                        <Form.Label column sm={12}>
                          Lugar
                        </Form.Label>
                        <Col sm={12}>
                            <Form.Control 
                                input="text" 
                                id="txtLugar"
                                value={puntoInteres.lugar} 
                                onChange={(e) => onChangeLugar(e) } 
                                disabled={disableTxt}
                                className={txLugar}
                                rows="2"  />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} >
                        <Form.Label column sm={12}>
                          Distancia
                        </Form.Label>
                        <Col sm={12}>
                            <Form.Control 
                                input="text" 
                                id="txtDistancia"
                                value={puntoInteres.distancia}
                                onChange={(e) => onChangeDistancia(e) } 
                                disabled={disableTxt}
                                className={txDistancia}
                                rows="2"  />
                        </Col>
                    </Form.Group>
                    <Alert show={alertErr} variant="warning">
                        El dato introducido ya existe.
                    </Alert>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalPunto(false)}  >Salir</Button>
                    {
                        disableTxt
                        ?
                            <Button variant="danger" disabled={btnDisable} onClick={() => confirmDeletPunto() } >Eliminar</Button>
                        :
                            <Button 
                                variant="primary" 
                                disabled={btnDisable} 
                                onClick={() => saveUpdatePunto() } >
                                {puntoInteres.id === 0 ? 'Registrar' : 'Confirmar'}
                            </Button>
                    }
                    
                </Modal.Footer>

            </Modal>
        
            <Modal show={showModalInfo} onHide={handleClose}  backdrop="static"  size="md" >
                <Modal.Body className="p-4">
                    <Row className="mb-5">
                        <Col sm="12" className="mb-3">
                            <Form.Label >
                            A&ntilde;o Construcci&otilde;n
                            </Form.Label>
                            
                            <Form.Control 
                                input="text"
                                name = "fecha_construccion"
                                onChange={(e) => handleChange(e) }
                                value={dataHotelInfo.fecha_construccion}/>
                        </Col>

                        <Col sm="12" className="mb-3">
                            <Form.Label >
                            Categoria
                            </Form.Label>
                            
                            <Form.Control 
                                input="text"
                                name = "categoria"
                                onChange={(e) => handleChange(e) }
                                value={dataHotelInfo.categoria}/>
                        </Col>

                        <Col sm="12" className="mb-3">
                            <Form.Label>
                                Tipo Alojamiento
                            </Form.Label>
                            <Form.Control as="select" 
                                name="tipo_alojamiento" 
                                defaultValue={dataHotelInfo.tipo_alojamiento.id} 
                                onChange={ (e)=> handleChange(e) }>
                                {
                                    _.map(data.tipo_alojamientos, (val ,i) => {
                                        return(
                                            <option 
                                                value={val.id} 
                                                key={i} >
                                                    {val.nombre}
                                            </option>
                                        )
                                    })
                                }
                            </Form.Control>
                        </Col>

                        <Col sm="12" className="mb-3">
                            <Form.Label>
                            Numero de Pisos
                            </Form.Label>
       
                            <Form.Control 
                                input="text"
                                name="numero_pisos"
                                onChange={ (e)=> handleChange(e) }
                                value={dataHotelInfo.numero_pisos} />
                        </Col>
   
                        <Col sm="4" className="mb-3">
                            <Form.Label>
                            Single
                            </Form.Label>
                            
                            <Form.Control 
                                input="text"
                                name="nro_single"
                                onChange={ (e)=> handleChange(e) }
                                value={dataHotelInfo.nro_single}/>
                        </Col>
                    
                        <Col sm="4" className="mb-3">
                            <Form.Label>
                            Doble
                            </Form.Label>
                            <Form.Control 
                                input="text"
                                name="nro_doble"
                                onChange={ (e)=> handleChange(e) }
                                value={dataHotelInfo.nro_doble}/>
                        </Col>

                        <Col sm="4" className="mb-3">
                            <Form.Label>
                            Triple
                            </Form.Label>

                            <Form.Control 
                                input="text"
                                name="nro_triple"
                                onChange={ (e)=> handleChange(e) }
                                value={dataHotelInfo.nro_triple}/>
                        </Col>
                    
                        <Col sm="4" className="mb-3">
                            <Form.Label>
                            Cuadruple
                            </Form.Label>

                            <Form.Control 
                                input="text"
                                name="nro_cuadruple"
                                onChange={ (e)=> handleChange(e) }
                                value={dataHotelInfo.nro_cuadruple}/>
                        </Col>

                        <Col sm="4" className="mb-3">
                            <Form.Label>
                            Matrimonial
                            </Form.Label>

                            <Form.Control 
                                input="text"
                                name="nro_matrimonial"
                                onChange={ (e)=> handleChange(e) }                             
                                value={dataHotelInfo.nro_matrimonial}/>
                        </Col>

                        <Col sm="4" className="mb-3">
                            <Form.Label>
                            Suite
                            </Form.Label>

                            <Form.Control 
                                input="text"
                                name="nro_suite"
                                onChange={ (e)=> handleChange(e) }
                                value={dataHotelInfo.nro_suite}/>
                        </Col>
                        
                        <Col sm="12" className="mb-3">
                            <Form.Label>
                            Metodos de Pago
                            </Form.Label>

                            <Select
                                defaultValue={dataHotelInfo.tipos_pago}
                                isMulti
                                onChange={handleChangeSelect}
                                options={tipo_pagos_options()} />
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalInfo(false)}  >Salir</Button>
                    <Button variant="primary" disabled={btnDisable} onClick={() => saveAlojamiento() } >Guardar</Button>
                </Modal.Footer>

            </Modal>
        </>
    )
}

export default InfoHotel