import React, { useEffect, useState } from "react";
import { Container, InputGroup, FormControl, Table } from "react-bootstrap";
import _ from "lodash";
import Highlighter from "react-highlight-words";

const colorHighlighter = "#ffff6f";

export const BuscarContactos = ({ sucursales, contactoGeneral }) => {
  const [empleadosBase, setEmpleadosBase] = useState([]);
  const [empleados, setEmpleados] = useState([]);
  const [input, setInput] = useState("");

  useEffect(() => {
    cargarEmpleados(sucursales, contactoGeneral);
  }, [sucursales, contactoGeneral]);

  const cargarEmpleados = (dataSucursal, dataContactoGeneral) => {
    let resultBase = [];
    _.map(dataSucursal, (sucursal) => {
      _.map(sucursal.empleados, (empleado) => {
        const aux = {
          nombre_apellido: empleado.nombre_completo,
          oficina: sucursal.nombre,
          area: empleado.area.nombre,
          cargo: empleado.cargo.nombre,
          email: empleado.email,
          telefono: empleado.interno,
        };
        resultBase = _.concat(resultBase, aux);
      });
    });
    // CONTACTO GENERAL
    _.map(dataContactoGeneral, (contacto) => {
      let telf = "";
      let email = "";
      _.map(contacto.detalle_contacto, (detalle) => {
        if (detalle.medio_de_contacto.nombre === "Telefono") {
          telf = `${telf} ${telf === "" ? "" : ", "} ${detalle.informacion}`;
        }

        if (detalle.medio_de_contacto.nombre === "Email") {
          email = `${email} ${email === "" ? "" : ", "} ${detalle.informacion}`;
        }
      });
      const aux = {
        nombre_apellido: contacto.nombre,
        oficina: "",
        area: "",
        cargo: "",
        email: email,
        telefono: telf,
      };
      resultBase = _.concat(resultBase, aux);
    });

    setEmpleadosBase(resultBase);
    setEmpleados(resultBase);
  };

  const handleBuscar = (e) => {
    const valInput = _.trim(e.target.value).toLowerCase();
    let result = [];
    if (_.size(empleadosBase) > 0) {
      _.map(empleadosBase, (empleado) => {
        const nombre_apellido = _.trim(empleado.nombre_apellido).toLowerCase();
        const oficina = _.trim(empleado.oficina).toLowerCase();
        const area = _.trim(empleado.area).toLowerCase();
        const cargo = _.trim(empleado.cargo).toLowerCase();

        if (
          _.includes(nombre_apellido, valInput) ||
          _.includes(oficina, valInput) ||
          _.includes(area, valInput) ||
          _.includes(cargo, valInput)
        ) {
          result = _.concat(result, empleado);
        }
      });
      setEmpleados(result);
      setInput(valInput);
    }
  };

  return (
    <>
      <h5 className="">Busqueda de Contactos</h5>
      <InputGroup className="mb-2" size="">
        <FormControl
          name="nombre"
          placeholder="Nombre, Oficina, Area, Cargo"
          autoComplete="off"
          onChange={handleBuscar}
        />
      </InputGroup>
      <Container className="shadow p-0 mt-0 mb-5 bg-white rounded">
        <Table striped bordered hover className="mb-0">
          <thead>
            <tr>
              <th className="text-center">Nombre</th>
              <th className="text-center">Oficina</th>
              <th className="text-center">Area</th>
              <th className="text-center">Cargo</th>
              <th className="text-center">Email</th>
              <th className="text-center">Telefono</th>
            </tr>
          </thead>
          <tbody>
            {_.size(empleadosBase) > 0 ? (
              _.size(empleados) > 0 ? (
                _.map(empleados, (empleado, ei) => {
                  return (
                    <tr key={ei}>
                      <td>
                        <Highlighter
                          highlightStyle={{ backgroundColor: colorHighlighter }}
                          autoEscape={true}
                          searchWords={[_.trim(input)]}
                          textToHighlight={empleado.nombre_apellido}
                        />
                      </td>
                      <td>
                        <Highlighter
                          highlightStyle={{ backgroundColor: colorHighlighter }}
                          autoEscape={true}
                          searchWords={[_.trim(input)]}
                          textToHighlight={empleado.oficina}
                        />
                      </td>
                      <td>
                        <Highlighter
                          highlightStyle={{ backgroundColor: colorHighlighter }}
                          autoEscape={true}
                          searchWords={[_.trim(input)]}
                          textToHighlight={empleado.area}
                        />
                      </td>
                      <td>
                        <Highlighter
                          highlightStyle={{ backgroundColor: colorHighlighter }}
                          autoEscape={true}
                          searchWords={[_.trim(input)]}
                          textToHighlight={empleado.cargo}
                        />
                      </td>
                      <td>{empleado.email}</td>
                      <td>{empleado.telefono}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    No existen contactos
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No existen contactos registrados
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Container>
    </>
  );
};
