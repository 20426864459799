import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Container } from "react-bootstrap";
import { isLogin } from "./utils/authentication";

import Header from "./layout/Header";
import Footer from './layout/Footer'

const PrivateRoute = ({ component: Component, user, fluid: isFluid, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? (
          <>
            <Header user={user} />
            <Container fluid={isFluid} className={isFluid ? 'p-0' : ''} style={{ marginTop: 80, minHeight: 500 }}>
              <Component {...props} />
            </Container>
            <Footer />
          </>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
