import React, { useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { logout } from "../utils/authentication";

const Header = ({ user }) => {
  const [salir, setSalir] = useState(false);
  const clickLogout = () => {
    logout();
    setSalir(true);
  };

  if (salir) {
    return <Redirect to="/login" />;
  }
  const bgColor = "#62D3C0";

  return (
    <Navbar
      style={{ backgroundColor: bgColor, height: '80px' }}
      expand="lg"
      variant="dark"
      fixed="top"
    >
      <Navbar.Brand className="ms-5 me-5">
        <img src={process.env.PUBLIC_URL + "/yoturismo.svg"}  className="" style={{width: "180px"}}/>
        {user && user.empresa && (
          <h6 style={{ fontSize: 12, marginBottom: 0 }} className="ms-1">
            {user.empresa.nombre}
          </h6>
        )}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" style={{ backgroundColor: bgColor }} >
        <Nav className="me-auto">
          <Nav.Link as={Link} to="/" className="ms-5 ms-lg-0">
            Inicio
          </Nav.Link>
          {user && user.empresa ? (
            <Nav.Link as={Link} to={`/proveedor/${user.empresa.id}`}  className="ms-5 ms-lg-0">
              Empresa
            </Nav.Link>
          ) : (
            <>
              <NavDropdown title="Empresas" id="empresas-nav-dropdown" className="ms-5 ms-lg-0 mx-auto">
                <NavDropdown.Item as={Link} to="/empresas">
                  Buscar
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/NuevaEmpresa">
                  Registrar
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/invitaciones">
                  Invitacion
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} to="/configuracion"  className="ms-5 ms-lg-0">
                Configuracion
              </Nav.Link>
            </>
          )}
        </Nav>

        <Nav>
          <NavDropdown
            title={
                    <span>
                        <i className='fa fa-user'></i> {user && user.nombre}
                    </span>
                  } 
            id="basic-nav-dropdown"
            style={{ marginRight:'50px' }}
            className="ms-5 ms-lg-0 mx-auto me-5"
          >
            <NavDropdown.Item as={Link} to="/usuario">
              Perfil
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={clickLogout}>Salir</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
