import React, { useState, useEffect } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import _ from "lodash";
import { Typeahead } from "react-bootstrap-typeahead";
import { useMutation } from "@apollo/client";
import { ADD_SUCURSAL, UPDATE_SUCURSAL, DELETE_SUCURSAL } from "../../utils/graphql_mutation";
import {
  ValidarFormCrudSucursal,
  getCityById,
  getCountryById,
} from "../../utils/helpers";

const initialState = {
  sucursal: {
    nombre: "",
    telefono: "",
    direccion: "",
  },
  paisCiudad: {
    pais: [],
    ciudad: [],
  },
};

const CrudSucursal = (prop) => {
  const { crud, paises, empresaId, loadCities, dataCities } = prop;
  const { setCrud, refetchEmpresa, getCities } = prop;
  // STATE
  const [validate, setValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sucursal, setSucursal] = useState(initialState.sucursal);
  const [ciudades, setCiudades] = useState([]);
  const [paisCiudad, setPaisCiudad] = useState(initialState.paisCiudad);
  // MUTATIONS
  const [addSucursal] = useMutation(ADD_SUCURSAL);
  const [deleteSucursal] = useMutation(DELETE_SUCURSAL)
  const [updateSucursal] = useMutation(UPDATE_SUCURSAL);
  // FUNCTION
  const handleChange = (e) => {
    setSucursal({
      ...sucursal,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangePais = async (e) => {
    setPaisCiudad({ ...paisCiudad, pais: e, ciudad: [] });
    if (_.size(e) > 0) {
      getCities({ countryId: e[0].id });
    }
  };
  const handleRegistrar = async () => {
    if (ValidarFormCrudSucursal(sucursal)) {
      setLoading(true);
      try {
        await addSucursal({
          variables: {
            empresa_id: parseInt(empresaId),
            nombre: _.trim(sucursal.nombre),
            telefono: _.trim(sucursal.telefono),
            direccion: _.trim(sucursal.direccion),
            ciudad:
              _.size(paisCiudad.ciudad) > 0
                ? parseInt(paisCiudad.ciudad[0].id)
                : 0,
          },
        });
        await refetchEmpresa();
        setCrud({ ...crud, show: false });
      } catch (error) {
        console.log(error);
      }
    } else {
      setValidate(true);
    }
  };
  const handleEliminar = async () => {
      try {
        await deleteSucursal({
          variables: {
            id: parseInt(crud.sucursal.id),
          },
        });
        await refetchEmpresa();
        setCrud({ ...crud, show: false });
      } catch (error) {
        console.log(error);
      }
    
  };
  const handleModificar = async () => {
    if (ValidarFormCrudSucursal(sucursal)) {
      setLoading(true);
      try {
        await updateSucursal({
          variables: {
            id: parseInt(crud.sucursal.id),
            nombre: _.trim(sucursal.nombre),
            telefono: _.trim(sucursal.telefono),
            direccion: _.trim(sucursal.direccion),
            ciudad_id:
              _.size(paisCiudad.ciudad) > 0 ? paisCiudad.ciudad[0].id : 0,
          },
        });
        await refetchEmpresa();
        setCrud({ ...crud, show: false });
      } catch (error) {
        console.log(error);
      }
    } else {
      setValidate(true);
    }
  };
  // EFFECT
  useEffect(() => {
    const cargarValores = async () => {
      if (crud && crud.type === "update") {
        const { nombre, telefono, direccion, ciudad_id, city } = crud.sucursal;
        setSucursal({
          nombre: nombre ? nombre : "",
          telefono: telefono ? telefono : "",
          direccion: direccion ? direccion : "",
        });
        if (ciudad_id) {
          const auxCity = city ? city : getCityById(ciudad_id);
          const country = getCountryById(auxCity.country_id);
          setPaisCiudad({ pais: [country], ciudad: [auxCity] });
        }
      }
      if (crud && crud.type === "delete") {
        const { nombre, telefono, direccion, ciudad_id, city } = crud.sucursal;
        setSucursal({
          nombre: nombre ? nombre : "",
          telefono: telefono ? telefono : "",
          direccion: direccion ? direccion : "",
        });
        if (ciudad_id) {
          const auxCity = city ? city : getCityById(ciudad_id);
          const country = getCountryById(auxCity.country_id);
          setPaisCiudad({ pais: [country], ciudad: [auxCity] });
        }
      }
    };
    cargarValores();
  }, [crud]);
  useEffect(() => {
    setCiudades(dataCities);
  }, [dataCities]);
  // RENDER
  let classNombre = "";
  let classTelefono = "";
  let classDireccion = "";
  if (validate) {
    classNombre = _.trim(sucursal.nombre) === "" ? "is-invalid" : "is-valid";
    classTelefono =
      _.trim(sucursal.telefono) === "" ? "is-invalid" : "is-valid";
    classDireccion =
      _.trim(sucursal.direccion) === "" ? "is-invalid" : "is-valid";
  }
  return (
    <Modal show={true} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>{`${
          crud.type === "create" ? "Agregar" : crud.type === "update" ? "Editar" : "Eliminar"

        } Oficina`}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pl-4">
        <Form>
          <Form.Group>
            <Form.Label>Nombre de la Oficina</Form.Label>
            <Form.Control
              name="nombre"
              type="text"
              autoComplete="off"
              disabled={ crud.type === "delete"}
              onChange={handleChange}
              value={sucursal.nombre}
              className={classNombre}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Telefono</Form.Label>
            <Form.Control
              name="telefono"
              type="text"
              autoComplete="off"
              disabled={ crud.type === "delete"}
              onChange={handleChange}
              value={sucursal.telefono}
              className={classTelefono}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Direccion</Form.Label>
            <Form.Control
              name="direccion"
              as="textarea"
              rows={2}
              disabled={ crud.type === "delete"}
              onChange={handleChange}
              value={sucursal.direccion}
              className={classDireccion}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Pais</Form.Label>
            <Typeahead
              id="pais"
              name="pais"
              labelKey="name"
              disabled={ crud.type === "delete"}
              onChange={handleChangePais}
              options={paises}
              selected={paisCiudad.pais}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Ciudad</Form.Label>
            <Typeahead
              id="ciudad"
              name="ciudad"
              labelKey="name"
              onChange={(e) => setPaisCiudad({ ...paisCiudad, ciudad: e })}
              options={ciudades}
              selected={paisCiudad.ciudad}
              placeholder={loadCities ? "Cargando..." : ""}
              disabled={ crud.type === "delete"}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => setCrud({ ...crud, show: false })}
          disabled={loading}
        >
          Cancelar
        </Button>
        {crud.type === "create" && (
          <Button
            variant="primary"
            onClick={handleRegistrar}
            disabled={loading}
          >
            Registrar
          </Button>
        )}
         {crud.type === "delete" && (
          <Button
            variant="primary"
            onClick={handleEliminar}
            disabled={loading}
          >
            Eliminar
          </Button>
        )}
        {crud.type === "update" && (
          <Button
            variant="primary"
            onClick={handleModificar}
            disabled={loading}
          >
            Modificar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CrudSucursal;
