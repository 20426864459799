import React, { useState, useRef} from 'react'
import { useFormik } from 'formik';
import { Form, InputGroup, FormControl, Button, Container, Table, Spinner } from 'react-bootstrap';
import ResultadoInvitaciones from '../components/invitacion/ResultadoInvitaciones';
import { useMutation } from '@apollo/client';
import { ADD_INVITACION } from '../utils/graphql_mutation'




const Invitaciones = () => {
  const childRef = useRef();
  const [btnNuevo, setBtnNuevo] = useState(false);
  const [nombre, setNombre] = useState('');
  const [addInvitacion] = useMutation(ADD_INVITACION);

  const formik = useFormik({
    initialValues: {
      nombre: '',
    },
    onSubmit: val => {
      setNombre(val.nombre)
    },
  });

  const generarInvitacion = async () => {
    try {
      setBtnNuevo(true)
      await addInvitacion({ variables: { nombre: "registrar empresa"}})

      childRef.current.recargarLista();
    } catch (error) {
      console.log(error)
    }
    setBtnNuevo(false)
  }



  return (
    <>
      <h3 className="mb-0">Invitaciones</h3>
      <hr />

      <Form onSubmit={formik.handleSubmit} className="mt-4">
        <InputGroup className="mb-0" size="">
          <FormControl
            name="nombre"
            placeholder="Nombre"
            onChange={formik.handleChange}
            value={formik.values.nombre}
            autoComplete="off"
          />
            <Button variant="primary" type="submit" className="me-1">Buscar</Button>
            <Button variant="primary" onClick={generarInvitacion} disabled={btnNuevo}>
              { btnNuevo && <Spinner as="span" animation="border" size="sm" className="me-1"/> }
              Nuevo
            </Button>
        </InputGroup>
      </Form>
    

      <Container className="shadow p-0 mt-2 bg-white rounded" >
        <Table striped bordered hover size="lg" >
          <thead>
            <tr className="text-center">
              <th>Codigo</th>
              <th>Nombre</th>
              <th>Fecha</th>
              <th>Estado</th>
              <th>Descripcion</th>
              <th></th>
            </tr>
          </thead>
          
          <ResultadoInvitaciones nombre={nombre} ref={childRef}/>

            
          
        </Table>
      </Container>
      
    </>
  )
}

export default Invitaciones
