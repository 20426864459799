import React from 'react'
import { ProgressBar } from 'react-bootstrap';

const HeaderStep = ({show, resultado}) => {
  const gbShow = ''//resultado.success ? 'success' : 'danger'
  return (
    <ProgressBar style={{ height: 20 }} className="mb-4">
      <ProgressBar now={show > 0 ? "20" : "0"} label={show === 1 ? "20%" : ""} variant={show === 5 ? gbShow:""}/>
      <ProgressBar now={show > 1 ? "20" : "0"} label={show === 2 ? "40%" : ""} variant={show === 5 ? gbShow:""}/>
      <ProgressBar now={show > 2 ? "20" : "0"} label={show === 3 ? "60%" : ""} variant={show === 5 ? gbShow:""}/>
      <ProgressBar now={show > 3 ? "20" : "0"} label={show === 4 ? "80%" : ""} variant={show === 5 ? gbShow:""}/>
      <ProgressBar now={show > 4 ? "20" : "0"} label={show === 5 ? "100%" : ""} variant={show === 5 ? gbShow:""}/>
    </ProgressBar>
  )
}

export default HeaderStep
