import React, { useState } from "react";
import _ from "lodash";
import FormBusqueda from "./FormBusqueda";
import ResultadoBusqueda from "./ResultadoBusqueda";
// import { Button } from 'react-bootstrap';

const IuCrudNombre = ({ title, query, mutation, mut_delet, mut_edit }) => {
  const [nombre, setNombre] = useState("");
  const [disabledRegister, setDisabledRegister] = useState(true);

  const validateRegister = (val) => {
    // Verificar si tiene 3 digitos por lo menos
    if (_.size(nombre) < 3) {
      setDisabledRegister(true);
      return null;
    }
    // Verificar si existe coincidencia
    if (_.size(val) > 0) {
      const buscar = _.find(val, (v) =>
        _.isEqual(_.toUpper(v.nombre), _.toUpper(nombre))
      );
      if (buscar && _.size(buscar) > 0) {
        setDisabledRegister(true);
        return null;
      }
    }
    // Si paso la validacion habilitamos BTN registrar
    setDisabledRegister(false);
  };

  return (
    <div className="mb-5">
      <center>
        <h3 className="pl-0">{_.upperFirst(title)}</h3>
      </center>
      <FormBusqueda
        nombre={nombre}
        setNombre={setNombre}
        disabledRegister={disabledRegister}
        mutation={mutation}
      />

      <ResultadoBusqueda
        title={title}
        nombre={nombre}
        setNombre={setNombre}
        query={query}
        mut_delet={mut_delet}
        mut_edit={mut_edit}
        validateRegister={validateRegister}
      />
    </div>
  );
};

export default IuCrudNombre;
