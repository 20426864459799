import React from "react";
import { Spinner, Button } from "react-bootstrap";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import { BUSCAR_EMPRESA } from "../../utils/graphql_query";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import Errors from "../base/Errors";
import {
  getCountryById,
  getCityById,
  getStatesById,
} from "../../utils/helpers";

const colorHighlighter = "#ffff6f";
const ResultadoBusqueda = ({ nombre, pais, ciudad, departamento, pagina, limite }) => {
  const { loading, error, data } = useQuery(BUSCAR_EMPRESA, {
    variables: {
      nombre: nombre,
      pais_id: _.size(pais) > 0 ? parseInt(pais[0].id) : 0,
      departamento_id:
        _.size(departamento) > 0 ? parseInt(departamento[0].id) : 0,
      ciudad_id: _.size(ciudad) > 0 ? parseInt(ciudad[0].id) : 0,
      pagina: pagina,
      limite: limite
    },
  });
  const renderCiudad = (val) => {
    return val.city ? val.city.name : getCityById(val.ciudad_id).name;
  };

  if (loading) {
    return (
      <tbody>
        <tr>
          <td colSpan="6" className="text-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Cargando...</span>
            </Spinner>
          </td>
        </tr>
      </tbody>
    );
  }

  if (error) return <Errors error={error} />;
  return (
    <tbody>
      {_.size(data.buscar_empresa) > 0 ? (
        _.map(data.buscar_empresa, (val, i) => {
          const countryName = val.pais_id
            ? getCountryById(val.pais_id).name
            : "";
          const cityName = val.ciudad_id ? renderCiudad(val) : "";
          const departName = val.departamento_id
            ? getStatesById(val.departamento_id).name
            : "";
          return (
            <tr key={i}>
              <td>
                <Highlighter
                  highlightStyle={{ backgroundColor: colorHighlighter }}
                  autoEscape={true}
                  searchWords={[_.trim(nombre)]}
                  textToHighlight={val.nombre}
                />
              </td>
              <td>
                <Highlighter
                  highlightStyle={{ backgroundColor: colorHighlighter }}
                  autoEscape={true}
                  searchWords={[_.trim(nombre)]}
                  textToHighlight={val.rubro.nombre}
                />
              </td>
              <td>
                <Highlighter
                  highlightStyle={{ backgroundColor: colorHighlighter }}
                  autoEscape={true}
                  searchWords={[_.size(pais) > 0 ? pais[0].name : ""]}
                  textToHighlight={countryName}
                />
              </td>
              <td>
                <Highlighter
                  highlightStyle={{ backgroundColor: colorHighlighter }}
                  autoEscape={true}
                  searchWords={[
                    _.size(departamento) > 0 ? departamento[0].name : "",
                  ]}
                  textToHighlight={departName}
                />
              </td>
              <td>
                <Highlighter
                  highlightStyle={{ backgroundColor: colorHighlighter }}
                  autoEscape={true}
                  searchWords={[_.size(ciudad) > 0 ? ciudad[0].name : ""]}
                  textToHighlight={cityName}
                />
              </td>
              <td className="text-center">
                <Link to={`/proveedor/${val.id}`}>
                  <Button variant="light">
                    Ver
                  </Button>
                </Link>
              </td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td colSpan="6" className="text-center">
            No existen resultados
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default ResultadoBusqueda;
