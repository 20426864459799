import React, { useState} from 'react'
import { Modal, Form, Button, Alert } from 'react-bootstrap';
import _ from 'lodash'
import { useMutation } from '@apollo/client';
import {CHANGE_PASSWORD} from '../../utils/graphql_mutation'

const EditarContrasenia = ({crud, setCrud}) => {
  const [result, setResult] = useState({
    show: false,
    variant: '',
    message: ''
  });
  const [validate, setValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [valor, setValor] = useState({
    actual: '',
    nuevo: '',
    confirmar: ''
  });

  const [changePassword] = useMutation(CHANGE_PASSWORD);

  const handleChange = (e) => {
    setValor({ ...valor, [e.target.name]: e.target.value})
  }

  const handleBlur = (e) => {
    if (_.trim(valor.nuevo) !== _.trim(valor.confirmar)) {
      setValidate(true)
    }
  }
  
  const handleModificar = async () => {
    if (validarForm()) {
      try {
        setLoading(true)
        setResult({...result, show: false});
        const { data: { changePassword: resultChange }} = await changePassword({
          variables: {
            id: parseInt(crud.usuario.id),
            actual: _.trim(valor.actual),
            nuevo: _.trim(valor.nuevo)
          }
        })

        if (resultChange.success) {
          setResult({
            show: true,
            variant: 'success',
            message: "Se ha modificado correctamente."
          })
          setTimeout(() => { 
            setCrud({ ...crud, show: false })
          }, 2000);

          
        } else {
          setResult({
            show: true,
            variant: 'danger',
            message: resultChange.message
          })

          setLoading(false)
        }

      } catch (error) {
        
      }
    } else {
      setValidate(true)
    }
  }

  const validarForm = () => {
    let result = []
    if (_.trim(valor.actual) === "") { result = _.concat(result, 'Password Actual')}
    if (_.trim(valor.nuevo) === "") { result = _.concat(result, 'Password Nuevo')}
    if (_.trim(valor.confirmar) === "") { result = _.concat(result, 'Password Confirmar')}
    if (_.size(valor.nuevo) < 6) { result = _.concat(result, 'Password Nuevo tiene que ser minimo 6 digitos') }
    if (_.trim(valor.nuevo) !== _.trim(valor.confirmar)) { result = _.concat(result, 'Password Nuevo y Confirmar son diferentes')}

    if (_.size(result) > 0) {
      console.log(result)
      return false;
    }
    return true;
  }

  let classActual = ''
  let classNuevo = ''
  let classConfirmar = ''
  if (validate) {
    classActual = _.trim(valor.actual) === '' ? 'is-invalid' : ''
    classNuevo = _.trim(valor.nuevo) === '' || _.size(valor.nuevo) < 6 ? 'is-invalid' : ''
    classConfirmar = _.trim(valor.nuevo) !== _.trim(valor.confirmar) ? 'is-invalid' : ''
  }

  return (
    <Modal show={true} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Modificar Contraseña</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group >
            <Form.Label>Contraseña Actual</Form.Label>
            <Form.Control
              name="actual"
              type="password"
              autoComplete="off"
              value={valor.actual}
              onChange={handleChange}
              className={classActual}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label >Nueva Contraseña</Form.Label>
            <Form.Control
              name="nuevo"
              type="password"
              autoComplete="off"
              value={valor.nuevo}
              onChange={handleChange}
              className={classNuevo}
            />
            {
              classNuevo !== '' &&
              <Form.Text className="text-danger">
                Las contraseña tiene que tener al menos 6 digitos.
              </Form.Text>
            }
          </Form.Group>

          <Form.Group>
            <Form.Label>Confirmar Contraseña</Form.Label>
            <Form.Control
              name="confirmar"
              type="password"
              autoComplete="off"
              value={valor.confirmar}
              onChange={handleChange}
              onBlur={handleBlur}
              className={classConfirmar}
            />
            {
              classConfirmar !== '' &&
              <Form.Text className="text-danger">
                Las contraseña nuevas no son iguales.
              </Form.Text>
            }
            
          </Form.Group>
         
        </Form>
          
          {
            result.show && 
            <Alert variant={result.variant}>{result.message}</Alert>
          }
        

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setCrud({ ...crud, show: false })} disabled={loading}>Cancelar</Button>
        <Button variant="primary" onClick={handleModificar} disabled={loading}>Modificar</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditarContrasenia
