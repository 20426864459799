import React, { useState, useEffect} from 'react'
import { Row, Col, Form } from 'react-bootstrap';
import _ from 'lodash'

const ServiciosAlojamiento = ({ baseServicios, alojamiento, setAlojamiento}) => {
  const [tipoServicios, setTipoServicios] = useState([])

  const agruparTipo = (data) => {
    const agrupado = _.chain(data)
      .groupBy("tipo_servicio")
      .map((value, key) => ({ tipo_servicio: key, servicio: value }))
      .value();
    setTipoServicios(agrupado)
  }

  useEffect(() => {
    if (baseServicios) {
      agruparTipo(baseServicios)
    }
  }, [baseServicios]);

  const handleChange = (e, ser) => {
    if (e.target.checked) {
      setAlojamiento({
        ...alojamiento,
        servicios: _.concat(alojamiento.servicios, ser)
      })
    } else {
      let aux = []
      _.map(alojamiento.servicios, (servicio) => {
        if (parseInt(servicio.id) !== parseInt(ser.id)) {
          aux = _.concat(aux, servicio)
        }
      })
      setAlojamiento({ ...alojamiento, servicios: aux});
    }
  }
  
  return (
    <>
      <h5>Servicios</h5>
      <hr />
      <Row className="mb-5">
        {
          _.map(tipoServicios, (tipo, kt) => {
            return(
              <Col key={kt} sm="4" className="mb-3">
                <Form.Label>{_.upperFirst(_.lowerCase(tipo.tipo_servicio))}</Form.Label>
                {
                  _.map(tipo.servicio, (ser, ks) => {
                    const check = _.findIndex(alojamiento.servicios, (servicio) => parseInt(servicio.id) === parseInt(ser.id));
                    return (
                      <Form.Check 
                        key={ks}
                        type="checkbox" 
                        name={ser.id} 
                        id={ser.id} 
                        label={_.upperFirst(_.lowerCase(ser.nombre))}
                        onChange={(e) => handleChange(e, ser)}
                        value={ser.id}
                        checked={check < 0? false: true}
                        />
                    )
                  })
                }
              </Col>
            )
          })
        }
      </Row>
    </>
  )
}

export default ServiciosAlojamiento
