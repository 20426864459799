import React, {
  useState,
  useEffect,
  // useCallback
} from "react";
import { Container, ListGroup, Button, Form } from "react-bootstrap";
import { useQuery, useMutation } from "@apollo/client";
import { RUBROS } from "./../../utils/graphql_query";
import {
  UPDATE_FIELD_EMPRESA,
  UPDATE_PAIS_CIUDAD,
} from "./../../utils/graphql_mutation";
import _ from "lodash";
import { Typeahead } from "react-bootstrap-typeahead";
import ButtonIcon from "../base/ButtonIcon";
import {
  getCountryById,
  getCityById,
  getStatesById,
  getProvinceById,
} from "../../utils/helpers";
import useCities from "../../hooks/useCities";
import parse from "html-react-parser";

const initialState = {
  paisCiudad: { validate: false, editar: false, pais: [], ciudad: [] },
};

const InfoGeneral = ({ empresaDatos, refectEmpresa, paisesAll }) => {
  const {
    loading,
    data: dataRubros,
    refetch,
  } = useQuery(RUBROS, { variables: { nombre: "" } });

  const [updateFieldEmpresa] = useMutation(UPDATE_FIELD_EMPRESA);
  const [updatePaisCiudad, { loading: loadUpdatePaisCiudad }] =
    useMutation(UPDATE_PAIS_CIUDAD);
  // STATE
  const [infoEmpresa, setInfoEmpresa] = useState({
    Nombre: {
      field: "nombre",
      valor: empresaDatos.nombre,
      edit: false,
      element: "input",
      type: "string",
      inputType: "text",
      can_edit: true,
    },
    "Razon Social": {
      field: "razon_social",
      valor: empresaDatos.razon_social,
      edit: false,
      element: "input",
      type: "string",
      inputType: "text",
      can_edit: true,
    },
    Nit: {
      field: "nit",
      valor: empresaDatos.nit,
      edit: false,
      element: "input",
      type: "string",
      inputType: "text",
      can_edit: true,
    },
    Telefono: {
      field: "telefono",
      valor: empresaDatos.telefono,
      edit: false,
      element: "input",
      type: "int",
      inputType: "number",
      can_edit: true,
    },
    Whatsapp: {
      field: "whatsapp",
      valor: empresaDatos.whatsapp,
      edit: false,
      element: "input",
      type: "int",
      inputType: "number",
      can_edit: true,
    },
    Email: {
      field: "email",
      valor: empresaDatos.email,
      edit: false,
      element: "input",
      type: "string",
      inputType: "text",
      can_edit: true,
    },
    // "Pagina Web": {
    //   field: "web_oficial",
    //   valor: empresaDatos.web_oficial,
    //   edit: false,
    //   element: "input",
    //   type: "string",
    // inputType: "text",   
    // can_edit: true,
    // },
    Rubro: {
      field: "rubro_id",
      valor: empresaDatos.rubro.nombre,
      edit: false,
      element: "select",
      type: "int",
      inputType: "text",
      can_edit: false,
    },
    Dirección: {
      field: "direccion",
      valor: empresaDatos.direccion,
      edit: false,
      element: "textarea",
      type: "string",
      inputType: "text",
      can_edit: true,
    },
    Descripción: {
      field: "descripcion",
      valor: empresaDatos.descripcion,
      edit: false,
      element: "textarea",
      type: "string",
      inputType: "text",
      can_edit: true,
    },
  });
  const [paisCiudad, setPaisCiudad] = useState(initialState.paisCiudad);
  const [editing, setEditing] = useState(false);
  const [ciudades, setCiudades] = useState([]);
  // CUSTOM HOOKS
  const [getCities, { loading: loadCities, data: dataCities }] = useCities();
  // FUNCTION
  const updateFieldEmpresaConexion = async (key, value, newval) => {
    try {
      let datos = {
        id: parseInt(empresaDatos.id),
        field: value.field,
        value: newval.value,
        type: value.type,
      };
      let result = await updateFieldEmpresa({
        variables: datos,
      });
      if (!result) {
        var error = new Error("Error de actualización");
        throw error;
      } else {
        refectEmpresa();
        let temp = infoEmpresa;
        if (temp[key].element === "select") {
          temp[key].valor = newval.options[newval.selectedIndex].text;
        } else {
          temp[key].valor = newval.value;
        }
        setInfoEmpresa(temp);
        return true;
      }
    } catch (error) {
      alert(
        "Lo sentimos, ocurrio un error en el servidor, por favor intentalo nuevamente!"
      );
      console.log(error);
      return false;
    }
  };
  const editField = (key) => {
    if (!editing) {
      let temp = infoEmpresa;
      temp[key].edit = true;
      setInfoEmpresa(temp);
      setEditing(true);
    } else {
      alert("No puede editar dos campos a la vez.");
    }
  };
  const cancelEditField = (key) => {
    let temp = infoEmpresa;
    temp[key].edit = false;
    setInfoEmpresa(temp);
    setEditing(false);
  };
  const saveField = (value, key) => {
    let newval = document.getElementById(key);
    if (newval.value !== value.valor) {
      if (updateFieldEmpresaConexion(key, value, newval)) {
        cancelEditField(key);
      } else {
        cancelEditField(key);
      }
    }
  };
  const handleChangePais = async (e) => {
    setPaisCiudad({ ...paisCiudad, pais: e });
    if (_.size(e) > 0) {
      getCities({ countryId: e[0].id });
    }
    setCiudades([]);
    setPaisCiudad({ ...paisCiudad, pais: e, ciudad: [] });
  };
  const handleModificarPais = async () => {
    const { pais, ciudad } = paisCiudad;
    if (_.size(pais) > 0) {
      try {
        const datos = {
          id: parseInt(empresaDatos.id),
          pais_id: pais[0].id,
          ciudad_id: _.size(ciudad) > 0 ? ciudad[0].id : 0,
          departamento_id: _.size(ciudad) > 0 ? ciudad[0].state_id : 0,
          provincia_id: _.size(ciudad) > 0 ? ciudad[0].province_id : 0,
        };
        await updatePaisCiudad({ variables: datos });
        setPaisCiudad({ ...paisCiudad, editar: false, validate: false });
      } catch (error) {
        console.log(error);
      }
      refectEmpresa();
    } else {
      setPaisCiudad({ ...paisCiudad, validate: true });
    }
  };
  const changeEstado = async (e) => {
    try {
      let valor = e.target.checked ? "1" : "0";
      let datos = {
        id: parseInt(empresaDatos.id),
        field: "estado",
        value: valor,
        type: "int",
      };
      await updateFieldEmpresa({
        variables: datos,
      });
      await refectEmpresa();
    } catch (error) {
      alert(
        "Lo sentimos, ocurrio un error en el servidor, por favor intentalo nuevamente!"
      );
      console.log(error);
    }
  };
  const renderShowCiudad = () => {
    if (empresaDatos.ciudad_id) {
      let result = "<br/>";
      if (empresaDatos.city) {
        const objDpto = getStatesById(empresaDatos.city.state_id);
        const objProv = getProvinceById(empresaDatos.city.province_id);
        result += objDpto.name + "<br/>";
        result += objProv.nombre + "<br/>";
        result += empresaDatos.city.name;
      } else {
        const objCiudad = getCityById(empresaDatos.ciudad_id);

        const objDpto = getStatesById(objCiudad.state_id);
        result += objDpto.name + "<br/>";
        if (objCiudad.province_id) {
          const objProv = getProvinceById(objCiudad.province_id);
          result += objProv.nombre + "<br/>";
        }
        result += objCiudad.name + "<br/>";
      }
      return result;
    }
  };
  const handleEditPaisCiudad = () => {
    setPaisCiudad({ ...paisCiudad, editar: true });
    // Obtener Ciudad del pais
    if (_.size(paisCiudad.pais) > 0) {
      getCities({ countryId: paisCiudad.pais[0].id });
    }
  };
  // EFFECT
  useEffect(() => {
    if (empresaDatos.pais_id) {
      let newPaisCiudad = {
        ...initialState.paisCiudad,
        pais: [getCountryById(empresaDatos.pais_id)],
      };
      if (empresaDatos.ciudad_id) {
        newPaisCiudad = {
          ...newPaisCiudad,
          ciudad: empresaDatos.city
            ? [empresaDatos.city]
            : [getCityById(empresaDatos.ciudad_id)],
        };
      }
      setPaisCiudad(newPaisCiudad);
    }
  }, [empresaDatos]);
  useEffect(() => {
    setCiudades(dataCities);
  }, [dataCities]);
  // RENDER
  let isValidate = "";
  if (paisCiudad.validate) {
    isValidate = _.size(paisCiudad.pais) > 0 ? "isValid" : "isInvalid";
  }
  return (
    <>
      <Container className="shadow p-4 bg-white rounded mb-5 mt-3">
        <ListGroup variant="flush">
          {_.map(infoEmpresa, (value, key) => {
            return (
              <ListGroup.Item
                key={key}
                className="d-flex justify-content-between py-1"
              >
                <div className="col-3">
                  <strong>{key} :</strong>
                </div>
                <div className="col-6">
                  {value.edit ? (
                    <>
                      {value.element === "input" ? (
                        <input
                          type={value.inputType}
                          name={key}
                          id={key}
                          defaultValue={value.valor}
                          autoComplete="off"
                          className="form-control form-control-sm"
                        />
                      ) : (
                        <>
                          {value.element === "textarea" ? (
                            <textarea
                              type="text"
                              name={key}
                              id={key}
                              defaultValue={value.valor}
                              autoComplete="off"
                              className="form-control form-control-sm"
                            />
                          ) : (
                            <>
                              {!loading ? (
                                <Form.Control
                                  as="select"
                                  custom
                                  size="sm"
                                  id={key}
                                  defaultValue={empresaDatos.rubro.id}
                                >
                                  <option value="0">Seleccione</option>
                                  {_.map(dataRubros.rubros, (rubro, k) => {
                                    return (
                                      <option value={rubro.id} key={rubro.id}>
                                        {rubro.nombre}
                                      </option>
                                    );
                                  })}
                                </Form.Control>
                              ) : (
                                <Button
                                  className="btn-secondary me-2"
                                  size="sm"
                                  onClick={() => refetch}
                                >
                                  <i className="fa fa-redo"></i>Recargar Rubros
                                </Button>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    value.valor
                  )}
                </div>
                <div className="col-3 text-end">
                  {value.edit ? (
                    <>
                      <Button
                        className="btn-primary me-2"
                        size="sm"
                        onClick={() => saveField(value, key)}
                      >
                        <i className="fa fa-save"></i>
                      </Button>

                      <Button
                        className="btn-secondary"
                        size="sm"
                        onClick={() => cancelEditField(key)}
                      >
                        <i className="fa fa-times"></i>
                      </Button>
                    </>
                  ) : (
                    value.can_edit && (
                      <ButtonIcon
                        titulo={`Editar ${key}`}
                        variant="primary"
                        size="sm"
                        icono="fa fa-pen"
                        onClick={() => editField(key)}
                      />
                    )
                  )}
                </div>
              </ListGroup.Item>
            );
          })}

          <ListGroup.Item className="d-flex justify-content-between py-1 ">
            <div className="col-3 my-auto">
              <strong>Pais / Ciudad :</strong>
            </div>
            <div className="col-6 my-auto">
              {paisCiudad.editar ? (
                <>
                  <Typeahead
                    id="pais"
                    name="pais"
                    labelKey="name"
                    onChange={(e) => handleChangePais(e)}
                    options={paisesAll}
                    selected={paisCiudad.pais}
                    placeholder="Pais"
                    className="mt-2"
                    isInvalid={
                      paisCiudad.validate && isValidate === "isInvalid"
                        ? true
                        : false
                    }
                    isValid={
                      paisCiudad.validate && isValidate === "isValid"
                        ? true
                        : false
                    }
                  />

                  <Typeahead
                    id="ciudad"
                    name="ciudad"
                    labelKey="name"
                    onChange={(e) =>
                      setPaisCiudad({ ...paisCiudad, ciudad: e })
                    }
                    options={ciudades}
                    selected={paisCiudad.ciudad}
                    className="mt-2"
                    disabled={loadCities}
                    placeholder={loadCities ? "Cargando..." : ""}
                  />
                </>
              ) : (
                <>
                  {empresaDatos.pais_id && (
                    <>
                      {getCountryById(empresaDatos.pais_id).name}
                      {empresaDatos.ciudad_id && parse(renderShowCiudad())}
                    </>
                  )}
                </>
              )}
            </div>

            <div className="col-3 text-end my-auto">
              {paisCiudad.editar ? (
                <>
                  <Button
                    className="btn-primary me-2"
                    size="sm"
                    onClick={handleModificarPais}
                    disabled={loadUpdatePaisCiudad}
                  >
                    <i className="fa fa-save"></i>
                  </Button>
                  <Button
                    className="btn-secondary"
                    size="sm"
                    onClick={() =>
                      setPaisCiudad({
                        ...paisCiudad,
                        editar: false,
                        validate: false,
                      })
                    }
                    disabled={loadUpdatePaisCiudad}
                  >
                    <i className="fa fa-times"></i>
                  </Button>
                </>
              ) : (
                <ButtonIcon
                  titulo="Editar Pais/Ciudad"
                  variant="primary"
                  size="sm"
                  icono="fa fa-pen"
                  onClick={handleEditPaisCiudad}
                />
              )}
            </div>
          </ListGroup.Item>

          <ListGroup.Item className="d-flex justify-content-between py-1 ">
            <div className="col-3 my-auto">
              <strong>Estado :</strong>
            </div>
            <div className="col-6 my-auto">
              <Form.Check
                type="switch"
                id="custom-switch"
                label={empresaDatos.estado === 1 ? "Visible" : "Oculto"}
                defaultChecked={empresaDatos.estado}
                onChange={(e) => changeEstado(e)}
              />
            </div>
            <div className="col-3 text-end my-auto"></div>
          </ListGroup.Item>
        </ListGroup>
      </Container>
    </>
  );
};

export default InfoGeneral;
