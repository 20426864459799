import React, { useState } from "react";
import ListarServicios from "./ListarServicios";
import Servicios from "./Servicios";
import ShowServicio from "./ShowServicio";
import useCountries from "../../hooks/useCountries";

const InfoServicio = ({ empresa_id, showAccion, setShowAccion }) => {
  const [selectService, setSelectService] = useState(0);

  const { loading: loadingCountry, response: countries } = useCountries();
  if (loadingCountry) {
    return "Cargando...";
  }

  return (
    <>
      {showAccion === "listar_servicio" && (
        <ListarServicios
          empresa_id={empresa_id}
          setShowAccion={setShowAccion}
          setSelectService={setSelectService}
          countries={countries}
        />
      )}
      {showAccion === "addServicio" && (
        <Servicios
          empresa_id={empresa_id}
          setShowAccion={setShowAccion}
          countries={countries}
        />
      )}
      {showAccion === "showServicio" && (
        <ShowServicio
          servicioId={selectService}
          setShowAccion={setShowAccion}
        />
      )}
    </>
  );
};

export default InfoServicio;
