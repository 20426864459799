import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Table,
  Image,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  SAVE_SERVICE_EMPRESA,
  ADD_ADJUNTO_SERVICIO,
  DELETE_ADJUNTO_SERVICIO,
} from "./../../utils/graphql_mutation";
import { useMutation } from "@apollo/client";
import { Typeahead } from "react-bootstrap-typeahead";
import BtnComponent from "../../components/base/ButtonIcon";
import _ from "lodash";
import { uploadingAwsS3, getStatesByCountry } from "../../utils/helpers";
import { GET_ATRACCIONES } from "../../utils/graphql_query";
import { useQuery } from "@apollo/client";
import ContainerNotResult from "../base/ContainerNotResult";
import useCities from "../../hooks/useCities";

export const Servicios = ({ empresa_id, setShowAccion, countries }) => {
  const verifyInput = [
    { name: "txtTitulo", accion: "validar" },
    { name: "txtPrecio", accion: "validar" },
    { name: "lbDocAlert", accion: "ocultar" },
  ];
  const initialState = {
    countryStateCity: { country: [], state: [], city: [] },
  };
  // State
  const [files, setFiles] = useState([]);
  const [showImg, setShowImg] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [verificador, setVerificador] = useState(false);
  const [allStates, setAllStates] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [countryStateCity, setCountryStateCity] = useState(
    initialState.countryStateCity
  );
  const [allAtraccion, setAllAtraccion] = useState([]);
  const [selectAtraccion, setSelectAtraccion] = useState([]);
  // Query
  const { loading: loadingAtracion, data: dataAtraccion } =
    useQuery(GET_ATRACCIONES);
  // CUSTOM HOOKS
  const [getCities, { loading: loadCities, data: dataCities }] = useCities();
  // Mutation
  const [addService, { loading }] = useMutation(SAVE_SERVICE_EMPRESA);
  const [addAdjuntoServicio] = useMutation(ADD_ADJUNTO_SERVICIO);
  const [deleteAdjuntoServicio] = useMutation(DELETE_ADJUNTO_SERVICIO);
  // Function
  const handleClose = () => {
    setShowModal(false);
  };
  const uploadTempFiles = (event) => {
    var extensionesValidas = ".png, .jpeg, .jpg";
    let arrImg = [event.target.files[0]];
    let newArr = [event.target.files[0]];
    let arrShow = [];
    _.map(newArr, (fil) => {
      arrShow.push(URL.createObjectURL(fil));
    });

    var ruta = arrImg[0].name;
    var extension = ruta.substring(ruta.lastIndexOf(".") + 1).toLowerCase();
    var extensionValida = extensionesValidas.indexOf(extension);
    if (extensionValida !== -1) {
      setShowImg(arrShow);
      setFiles(arrImg);
    } else {
      alert("solo se admite imagenes de tipo " + extensionesValidas);
    }
    document.getElementById("imagenesFiles").value = "";
  };
  const uploadTempFilesDocument = () => {
    var file = document.getElementById("documentFiles");
    var description = document.getElementById("documentDescription").value;
    if (file.files.length !== 0) {
      if (file.files[0].type !== "application/pdf") {
        alert("Solo se aceptan archivo de tipo PDF");
      } else {
        let getFiles = documents;
        let newArr = _.concat(getFiles, {
          description: description,
          file: file.files[0],
        });
        setDocuments(newArr);
        document.getElementById("documentFiles").value = "";
        document.getElementById("documentDescription").value = "";
        setShowModal(false);
        document.getElementById("lbDocAlert").style = "display:none";
      }
    } else {
      alert("Se debe adjuntar un archivo.");
    }
  };
  const deleteTempFilesDocument = () => {
    let dataFile = documents;
    let newDataFile = _.dropRight(dataFile);
    setDocuments(newDataFile);
  };
  const sendService = async () => {
    if (!verificador) setVerificador(true);
    let titulo = document.getElementById("txtTitulo");
    let descripcion = document.getElementById("description_service").value;
    let precio = document.getElementById("txtPrecio");
    let moneda = document.getElementById("txtMoneda");
    let nroDias = document.getElementById("txtNroDias");
    var canSave = true;

    _.map(verifyInput, (val) => {
      var input = document.getElementById(val.name);
      if (val.accion === "validar" && input.value === "") {
        input.className = "form-control is-invalid";
        canSave = false;
      } else if (val.accion === "validar") {
        input.className = "form-control is-valid";
      }
      if (val.name === "lbDocAlert" && !documents.length) {
        input.style = "display:block";
        input.style = "color:#dc3545";
        canSave = false;
      }
    });

    // VALIDAR PAIS
    if (_.size(countryStateCity.country) === 0) {
      canSave = false;
    }
    if (canSave) {
      try {
        // Adjunto Servicio
        let parmsSer = {
          titulo: titulo.value,
          descripcion: descripcion,
          moneda: _.toUpper(moneda.value),
          precio: parseFloat(precio.value),
          estado: 1,
          empresa_id: empresa_id,
          pais_id: parseInt(countryStateCity.country[0].id),
        };
        // Departamento
        if (_.size(countryStateCity.state) > 0) {
          const auxDep = parseInt(countryStateCity.state[0].id);
          parmsSer = { ...parmsSer, departamento_id: auxDep };
        }
        // Ciudad
        if (_.size(countryStateCity.city) > 0) {
          const auxCiu = parseInt(countryStateCity.city[0].id);
          parmsSer = { ...parmsSer, ciudad_id: auxCiu };
        }
        // Atracciones
        let auxAtraccion = [];
        _.map(selectAtraccion, (valA) => {
          auxAtraccion = _.concat(auxAtraccion, valA.id);
        });
        if (_.size(auxAtraccion) > 0) {
          parmsSer = {
            ...parmsSer,
            json_atracciones_id: JSON.stringify(auxAtraccion),
          };
        }
        // Nro Dias
        if (_.trim(nroDias.value) !== "" && parseInt(nroDias.value) > 0) {
          parmsSer = {
            ...parmsSer,
            nro_dias: parseInt(nroDias.value),
          };
        }
        const service = await addService({ variables: parmsSer });
        if (service) {
          const {
            data: {
              addServicio: { id: idServicios },
            },
          } = service;
          setBtnLoading(true);
          // SAVE IMG PORTADA
          await Promise.all(
            _.map(files, async (val) => {
              const {
                data: { addAdjuntoServicio: resultaddAdjuntoServicio },
              } = await addAdjuntoServicio({
                variables: {
                  tipo: "img",
                  nombre: _.trim(val.name),
                  descripcion: _.trim(""),
                  servicio_id: idServicios,
                },
              });
              // S3
              const nameFile = resultaddAdjuntoServicio.nombre_descargar;
              const resultImg = await uploadingAwsS3(val, "servicio", nameFile);
              if (!resultImg.success) {
                await deleteAdjuntoServicio({
                  variables: { id: resultaddAdjuntoServicio.id },
                });
              }
            })
          );
          // SAVE ADJUNTO DOCUMENTO
          await Promise.all(
            _.map(documents, async (val) => {
              const {
                data: { addAdjuntoServicio: resultaddAdjuntoServicio },
              } = await addAdjuntoServicio({
                variables: {
                  tipo: "doc",
                  nombre: _.trim(val.file.name),
                  descripcion: _.trim(val.description),
                  servicio_id: idServicios,
                },
              });
              // S3
              const nameFile = resultaddAdjuntoServicio.nombre_descargar;
              const resultDoc = await uploadingAwsS3(
                val.file,
                "servicio",
                nameFile
              );
              if (!resultDoc.success) {
                await deleteAdjuntoServicio({
                  variables: { id: resultaddAdjuntoServicio.id },
                });
              }
            })
          );

          setBtnLoading(false);
          setShowAccion("listar_servicio");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const fileSize = (element) => {
    return (element.size / 1024 / 1024).toFixed(2) + " MB";
  };
  const handleValidar = (e) => {
    var target = e.target;
    if (verificador && target.value !== "") {
      target.className = "form-control is-valid";
    } else if (verificador) {
      target.className = "form-control is-invalid";
    }
  };
  const handleChangePais = (e) => {
    setCountryStateCity({
      ...countryStateCity,
      country: e,
      state: [],
      city: [],
    });
    if (_.size(e) > 0) {
      setAllStates(getStatesByCountry(e[0].id));
      getCities({ countryId: e[0].id });
    } else {
      setAllStates([]);
      setAllCities([]);
    }
  };
  const handleChangeState = (e) => {
    setCountryStateCity({
      ...countryStateCity,
      state: e,
      city: [],
    });
    if (_.size(e) > 0) {
      getCities({ stateId: e[0].id });
    } else {
      getCities({ countryId: countryStateCity.country[0].id });
    }
  };
  const handleChangeCity = (e) => {
    setCountryStateCity({ ...countryStateCity, city: e });
  };
  const bLoading = loading || btnLoading ? true : false;
  const styleVolver = { width: "100px" };
  // EFFECT
  useEffect(() => {
    if (dataAtraccion) {
      setAllAtraccion(dataAtraccion.atracciones);
    }
  }, [dataAtraccion]);

  useEffect(() => {
    setAllCities(dataCities);
  }, [dataCities]);
  // RENDER
  if (loadingAtracion) {
    return "Cargando...";
  }
  return (
    <>
      <Button
        className="mb-3"
        style={styleVolver}
        onClick={() => setShowAccion("listar_servicio")}
      >
        <i className="fas fa-chevron-left"></i> Atras
      </Button>
      <div
        className="text-center"
        style={{ fontSize: "1.25rem", fontWeight: "500" }}
      >
        Nuevo Servicio
      </div>
      <Container className="p-4 mb-4 mt-2 shadow bg-white">
        <Form.Group>
          <div>
            {_.size(showImg) > 0 ? (
              <>
                {_.map(showImg, (im, key) => {
                  return (
                    <div key={key} style={{ textAlign: "center" }}>
                      <div
                        className="p-3"
                        style={{ position: "relative", background: "#f2f2f2" }}
                      >
                        <Image src={im} width="300" height="150" />
                      </div>
                    </div>
                  );
                })}
                <div className="float-right">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>{"Editar Imagen"}</Tooltip>}
                  >
                    <Form.Label
                      className="btn btn-outline-primary"
                      style={{ position: "absolute", right: 43, top: 136 }}
                    >
                      <Form.Control
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={uploadTempFiles}
                        id="imagenesFiles"
                      />
                      <i className="fa fa-pen"></i>
                    </Form.Label>
                  </OverlayTrigger>
                </div>
              </>
            ) : (
              <>
                <ContainerNotResult
                  icon="fas fa-image"
                  mensaje="Imagen de Portada"
                >
                  <Form.Label
                    className="btn btn-outline-primary"
                    style={{ marginBottom: "0px" }}
                  >
                    <Form.Control
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={uploadTempFiles}
                      id="imagenesFiles"
                    />
                    Adjuntar
                  </Form.Label>
                </ContainerNotResult>
              </>
            )}
          </div>
        </Form.Group>
      </Container>

      <Container className="p-4 mb-4 shadow bg-white">
        <Form>
          <Form.Group>
            <Form.Label>Titulo *</Form.Label>
            <Form.Control
              type="text"
              id="txtTitulo"
              autoComplete="off"
              onChange={(e) => handleValidar(e)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Descripci&oacute;n</Form.Label>
            <Form.Control as="textarea" rows="2" id="description_service" />
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={6}>
              <Form.Label>Precio *</Form.Label>
              <Form.Control
                type="number"
                id="txtPrecio"
                autoComplete="off"
                onChange={(e) => handleValidar(e)}
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Moneda</Form.Label>
              <Form.Control id="txtMoneda" as="select" defaultValue="choose..">
                <option value="bob">BOB</option>
                <option value="usd">USD</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group>
            <Form.Label>Nro de Dias</Form.Label>
            <Form.Control type="number" id="txtNroDias" autoComplete="off" />
          </Form.Group>
        </Form>
      </Container>

      <Container className="p-4 mb-4 shadow bg-white">
        <Form>
          <Form.Group as={Row}>
            <Col sm={4}>
              <Form.Label>Pais *</Form.Label>
              <Typeahead
                clearButton
                id="pais"
                name="pais"
                labelKey="name"
                options={countries}
                selected={countryStateCity.country}
                onChange={handleChangePais}
                isInvalid={
                  verificador && countryStateCity.country.length === 0
                    ? true
                    : false
                }
                isValid={
                  verificador && countryStateCity.country.length !== 0
                    ? true
                    : false
                }
              />
            </Col>
            <Col sm={4}>
              <Form.Label>Departamento</Form.Label>
              <Typeahead
                clearButton
                id="departamento"
                name="departamento"
                labelKey="name"
                options={allStates}
                selected={countryStateCity.state}
                onChange={handleChangeState}
              />
            </Col>
            <Col sm={4}>
              <Form.Label>Ciudad</Form.Label>
              <Typeahead
                clearButton
                id="ciudad"
                name="ciudad"
                labelKey="name"
                options={allCities}
                selected={countryStateCity.city}
                onChange={handleChangeCity}
                placeholder={loadCities ? "Cargando..." : ""}
                disabled={loadCities}
              />
            </Col>
          </Form.Group>

          <Form.Group>
            <Form.Label>Atracciones Turistica</Form.Label>
            <Typeahead
              id="atracciones"
              labelKey="nombre"
              multiple
              options={allAtraccion}
              onChange={(e) => setSelectAtraccion(e)}
            />
          </Form.Group>
        </Form>
      </Container>

      <Container className="p-4 mb-4 shadow bg-white">
        <div className="row">
          <div className="col-sm-12 col-xs-12"></div>

          <div className="col-sm-12 col-xs-12">
            <Form.Group>
              <Form.Label>
                <strong>Adjuntar Archivos *</strong>
              </Form.Label>
              <div className="float-right">
                <BtnComponent
                  titulo={"adjuntar"}
                  icono={"fa fa-paperclip"}
                  variant={"primary"}
                  onClick={() => setShowModal(true)}
                />
                <BtnComponent
                  titulo={"Eliminar"}
                  icono={"fa fa-trash"}
                  variant={"danger"}
                  onClick={deleteTempFilesDocument}
                />
              </div>

              <div style={{ clear: "both", height: "20px" }}></div>

              <div>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr className="text-center">
                      <th>Descripcion</th>
                      <th>Nombre</th>
                      <th>Tipo</th>
                      <th>Tamaño</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.size(documents) > 0 ? (
                      _.map(documents, (dc, key) => {
                        return (
                          <tr key={key}>
                            <td>{dc.description}</td>
                            <td>{dc.file.name}</td>
                            <td>{"PDF"}</td>
                            <td className="text-center">{fileSize(dc.file)}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">
                          No existen archivos adjuntos.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <Form.Label
                  id="lbDocAlert"
                  style={{ display: "none", color: "#dc3545" }}
                >
                  <strong>Es necesario adjuntar un documento.</strong>
                </Form.Label>
              </div>
            </Form.Group>
          </div>
        </div>
      </Container>
      <div className="text-end mt-4 mb-4">
        <Button
          style={{ width: "20%" }}
          variant="primary"
          type="button"
          disabled={bLoading}
          onClick={sendService}
        >
          {bLoading ? "Cargando.." : "Registrar"}
        </Button>
      </div>

      <Modal show={showModal} onHide={handleClose} backdrop="static" size="md">
        <Modal.Header className="h4">Agregar - Adjunto</Modal.Header>
        <Modal.Body className="p-4">
          <Row className="mb-5">
            <Col sm="12" className="mb-3">
              <Form.Label>Descripci&oacute;n</Form.Label>

              <textarea
                type="text"
                id="documentDescription"
                autoComplete="off"
                className="form-control form-control-sm"
              />
            </Col>
            <Col sm="12" className="mb-3">
              <Form.Label>Adjunto</Form.Label>

              <Form.Control type="file" id="documentFiles" accept="application/pdf" />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Salir
          </Button>
          <Button variant="primary" onClick={uploadTempFilesDocument}>
            Cargar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Servicios;
