import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { END_POINT } from './config'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css'
import './index.css';
import "react-datepicker/dist/react-datepicker.css";

import './assets/fonts/Intelo-ExtraBold.ttf';
import './assets/fonts/Intelo-SemiBold.ttf';
import './assets/fonts/Intelo-Medium.ttf';
import './assets/fonts/Intelo-Light.ttf';


/**
 * Configuración de Apollo
 * @type {any}
 */
const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    // errorPolicy: 'all',
  },
}

const httpLink = createHttpLink({ uri: END_POINT});

const authLink = setContext((_, { headers }) => {
  const token = JSON.parse(localStorage.getItem(process.env.REACT_APP_NAME_TOKEN ?? "") ?? "{}");
  return {
    headers: { ...headers, authorization: token ? `Bearer ${token.token}` : ''}
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions ,
});

ReactDOM.render(
<ApolloProvider client={client}>
  <App />
</ApolloProvider>, 
document.getElementById('root'));
