import React, { useState, useEffect } from "react";
import ButtonIcon from "../base/ButtonIcon";
import {
  Row,
  Form,
  Col,
  Button,
  Modal,
  ListGroup,
  Alert,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useMutation } from "@apollo/client";
import {
  UPDATE_FIELD_EMPRESA,
  VERIFICAR_SINCRONIZACION,
} from "./../../utils/graphql_mutation";
import _ from "lodash";
import axios from "axios";
const Sincronizar = ({ empresa, refectEmpresa }) => {
  const [showModal, setShowModal] = useState(false);
  const [alertState, setAlertState] = useState(false);
  const [alertVariant, setAlertVariant] = useState("danger");
  const [alertMsg, setAlertMsg] = useState("");
  const [sincronizados, setSincronizados] = useState([]);
  const [clientesAgencia, setClientesAgencia] = useState([]);
  const [updateFieldEmpresa] = useMutation(UPDATE_FIELD_EMPRESA);
  const [verificarEmpresaSyncronizada] = useMutation(VERIFICAR_SINCRONIZACION);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  useEffect(() => {
    const buscarClientes = async () => {
      const parametros = { txt_nombre: "" };
      try {
        const url_bb = process.env.REACT_APP_BOLIVIABOOKING;
        await axios
          .get(url_bb, { params: parametros }, { crossdomain: true })
          .then((result) => {
            let agencias = _.map(result.data.clientes, (value) => {
              return { label: value.nombre, value: value.id };
            });
            setClientesAgencia(agencias);
          })
          .catch((error) => {
            console.log("Error", error);
          });
      } catch (error) {
        console.log("Errors", error);
      }
    };
    buscarClientes();
  }, [setClientesAgencia]);
  const handleCliente = async (e) => {
    setSincronizados(e[0]);
  };
  const guardarClienteSincronizado = async () => {
    try {
      let result = await verificarEmpresaSyncronizada({
        variables: { booking_id: parseInt(sincronizados.value) },
      });
      const sincronizado = result.data.verificar_sincronizacion;
      if (sincronizado !== null && sincronizado.length === 0) {
        let datos = {
          id: parseInt(empresa.id),
          field: "booking_id",
          value: sincronizados.value.toString(),
          type: "int",
        };
        let result = await updateFieldEmpresa({
          variables: datos,
        });
        if (!result) {
          setAlertMsg("Error al Editar.");
          setAlertState(true);
          setAlertVariant("danger");
          setTimeout(() => {
            setAlertState(false);
          }, 2000);
        }
        if (result) {
          setAlertMsg("Editado Correctamente.");
          setAlertState(true);
          setAlertVariant("success");
          setTimeout(() => {
            refectEmpresa();
            setAlertState(false);
            handleClose();
          }, 2000);
        }
      } else {
        setAlertMsg(
          "El cliente seleccionado ya esta sincronizado con la empresa " +
            sincronizado[0].nombre
        );
        setAlertState(true);
        setAlertVariant("danger");
        setTimeout(() => {
          setAlertState(false);
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      alert(
        "Lo sentimos, ocurrio un error en el servidor, por favor intentalo nuevamente!"
      );
    }
  };
  const desvincularCliente = async () => {
    if (window.confirm("Confirma para desvincular el cliente.?")) {
      const booking_id = 0;
      let datos = {
        id: parseInt(empresa.id),
        field: "booking_id",
        value: booking_id.toString(),
        type: "int",
      };
      await updateFieldEmpresa({
        variables: datos,
      });
      refectEmpresa();
    }
  };
  return (
    <>
      {
        <div className="shadow p-4 bg-white rounded mb-3 mt-3 container">
          <ListGroup>
            <ListGroup.Item>
              <div className="row">
                <div className="col-5">
                  <strong>BoliviaBooking</strong>
                </div>
                <div className="col-3">
                  {empresa.booking_id === 0 ? (
                    <div>No Sincronizado</div>
                  ) : (
                    <div>
                      <div>Sincronizado</div>
                      <small>Codigo: {empresa.booking_id}</small>
                    </div>
                  )}
                </div>
                <div className="col-4 text-end">
                  <ButtonIcon
                    titulo="Sincronizar"
                    icono="fa fa-sync"
                    variant="primary"
                    className="me-2"
                    onClick={handleShow}
                  />
                  {empresa.booking_id !== 0 && (
                    <ButtonIcon
                      titulo="Quitar"
                      icono="fa fa-trash"
                      variant="danger"
                      className="me-2"
                      onClick={desvincularCliente}
                    />
                  )}
                </div>
              </div>
            </ListGroup.Item>
          </ListGroup>
        </div>
      }
      <Modal show={showModal} onHide={handleClose} backdrop="static" size="md">
        <Modal.Body className="p-4">
          <Form.Group as={Row}>
            <Form.Label column sm={12}></Form.Label>
            <Col sm={12}>
              <Typeahead
                id="codigo_cliente"
                name="codigo_cliente"
                placeholder="Buscar Cliente"
                options={clientesAgencia}
                onChange={(e) => handleCliente(e)}
              />
            </Col>
          </Form.Group>
          <Alert show={alertState} variant={alertVariant}>
            {alertMsg}
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Salir
          </Button>
          <Button
            variant="primary"
            onClick={() => guardarClienteSincronizado()}
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Sincronizar;
