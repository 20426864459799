import React from "react";
import { useFormik } from "formik";
import { Form, Button, InputGroup, FormControl } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import _ from "lodash";

const FormBusqueda = ({ nombre, setNombre, disabledRegister, mutation }) => {
  const [addCrud, { loading: mutationLoading }] = useMutation(mutation);

  const formik = useFormik({
    initialValues: { nombre: nombre },
    onSubmit: (val) => {
      setNombre(val.nombre);
    },
  });

  const handleChange = (e) => {
    formik.handleChange(e);
    formik.handleSubmit();
  };

  const handleRegister = async () => {
    const name = _.upperFirst(formik.values.nombre);
    try {
      await addCrud({
        variables: {
          nombre: name,
        },
      });

      setNombre("");
      formik.resetForm({ values: { nombre: "" } });
    } catch (error) {
      console.log(error);
    }
  };

  const btnDisabled = disabledRegister || mutationLoading ? true : false;
  return (
    <Form onSubmit={formik.handleSubmit}>
      <InputGroup className="mb-3">
        <FormControl
          id="nombre"
          name="nombre"
          placeholder="Buscar o registrar"
          autoComplete="off"
          className="pl-3"
          onChange={handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.nombre}
        />
          <Button disabled={btnDisabled} onClick={handleRegister}>
            {mutationLoading ? "Registrando..." : "Registrar"}
          </Button>
      </InputGroup>
    </Form>
  );
};

export default FormBusqueda;
