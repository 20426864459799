import React from "react";
import { Container } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import RegisterEmpresa from "../../components/invitacion/RegisterEmpresa";
import LoadingPage from "../../components/base/LoadingPage";
import { GET_INVITACION } from "../../utils/graphql_query";

const Invitacion = (props) => {
  const { loading, error, data } = useQuery(GET_INVITACION, {
    variables: {
      codigo: props.match.params.codigo,
    },
  });

  if (loading) return <LoadingPage />;
  if (error) return error;

  console.log(data);
  return (
    <div className="m-5">
      <Container style={{ width: 900, height: "auto" }}>
        {!data.invitacion ? (
          <div className="p-5 shadow bg-white rounded text-center">
            <i className="fas fa-times fa-4x text-danger"></i>
            <h6 className="mt-4">
              Invitacion <strong>no existe.</strong>
            </h6>
          </div>
        ) : !data.invitacion.vigente ? (
          <div className="p-5 shadow bg-white rounded text-center">
            <i className="fas fa-exclamation fa-4x text-danger"></i>
            <h6 className="mt-4">
              Invitacion <strong>no disponible.</strong>
            </h6>
          </div>
        ) : data.invitacion.nombre === "registrar empresa" ? (
          <RegisterEmpresa data={data} />
        ) : (
          <h6 className="mt-4">No existe formulario para esta invitacion.</h6>
        )}
      </Container>
    </div>
  );
};
export default Invitacion;
