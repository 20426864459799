import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation ($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      nombre
      email
      token
      empresa {
        id
        nombre
      }
    }
  }
`;

export const ADD_AREA = gql`
  mutation AddArea($nombre: String!) {
    addArea(nombre: $nombre) {
      id
      nombre
    }
  }
`;

export const ADD_CARGO = gql`
  mutation AddCargo($nombre: String!) {
    addCargo(nombre: $nombre) {
      id
      nombre
    }
  }
`;

export const ADD_RUBRO = gql`
  mutation AddRubro($nombre: String!) {
    addRubro(nombre: $nombre) {
      id
      nombre
    }
  }
`;

export const ADD_SUCURSAL = gql`
  mutation (
    $empresa_id: Int!
    $nombre: String!
    $telefono: String!
    $direccion: String!
    $ciudad: Int
  ) {
    addSucursal(
      empresa_id: $empresa_id
      nombre: $nombre
      telefono: $telefono
      direccion: $direccion
      ciudad: $ciudad
    ) {
      id
    }
  }
`;

export const DELETE_SUCURSAL = gql`
  mutation (
    $id: Int!
  ) {
    deleteSucursal(
      id: $id
    ) {
      id
    }
  }
`;

export const ADD_SUCURSAL_INVITACION = gql`
  mutation (
    $codigo_invitacion: String!
    $empresa_id: Int!
    $nombre: String!
    $telefono: String
    $direccion: String
    $ciudad: Int
  ) {
    addSucursalInvitacion(
      codigo_invitacion: $codigo_invitacion
      empresa_id: $empresa_id
      nombre: $nombre
      telefono: $telefono
      direccion: $direccion
      ciudad: $ciudad
    ) {
      id
    }
  }
`;

export const ADD_EMPLEADO = gql`
  mutation (
    $sucursal_id: Int!
    $nombres: String!
    $apellidos: String!
    $email: String
    $interno: String
    $area_id: Int!
    $cargo_id: Int!
    $ciudad_id: Int
    $visible: Int!
  ) {
    addEmpleado(
      sucursal_id: $sucursal_id
      nombres: $nombres
      apellidos: $apellidos
      email: $email
      interno: $interno
      area_id: $area_id
      cargo_id: $cargo_id
      ciudad_id: $ciudad_id
      visible: $visible
    ) {
      id
    }
  }
`;

export const ADD_EMPLEADO_INVITACION = gql`
  mutation (
    $codigo_invitacion: String!
    $sucursal_id: Int!
    $nombres: String!
    $apellidos: String!
    $email: String
    $interno: String
    $area_id: Int!
    $cargo_id: Int!
    $ciudad_id: Int
  ) {
    addEmpleadoInvitacion(
      codigo_invitacion: $codigo_invitacion
      sucursal_id: $sucursal_id
      nombres: $nombres
      apellidos: $apellidos
      email: $email
      interno: $interno
      area_id: $area_id
      cargo_id: $cargo_id
      ciudad_id: $ciudad_id
    ) {
      id
    }
  }
`;

export const ADD_EMPRESA = gql`
  mutation (
    $nombre: String!
    $razon_social: String
    $nit: String
    $web_oficial: String
    $rubro: Int!
    $sucursales: String
    $pais_id: Int!
    $ciudad_id: Int
    $departamento_id: Int
    $provincia_id: Int
  ) {
    addEmpresa(
      nombre: $nombre
      razon_social: $razon_social
      nit: $nit
      web_oficial: $web_oficial
      rubro: $rubro
      sucursales: $sucursales
      pais_id: $pais_id
      ciudad_id: $ciudad_id
      departamento_id: $departamento_id
      provincia_id: $provincia_id
    ) {
      id
    }
  }
`;

export const ADD_EMPRESA_INVITACION = gql`
  mutation (
    $codigo_invitacion: String!
    $nombre: String!
    $razon_social: String
    $nit: String
    $web_oficial: String
    $rubro: Int!
    $pais_id: Int!
    $ciudad_id: Int
    $departamento_id: Int
    $provincia_id: Int
  ) {
    addEmpresaInvitacion(
      codigo_invitacion: $codigo_invitacion
      nombre: $nombre
      razon_social: $razon_social
      nit: $nit
      web_oficial: $web_oficial
      rubro: $rubro
      pais_id: $pais_id
      ciudad_id: $ciudad_id
      departamento_id: $departamento_id
      provincia_id: $provincia_id
    ) {
      id
    }
  }
`;

export const UPDATE_SUCURSAL = gql`
  mutation UpdateSucursal(
    $id: Int!
    $nombre: String!
    $telefono: String
    $direccion: String
    $ciudad_id: Int
  ) {
    updateSucursal(
      id: $id
      nombre: $nombre
      telefono: $telefono
      direccion: $direccion
      ciudad_id: $ciudad_id
    ) {
      id
    }
  }
`;

export const EDIT_EMPLEADO = gql`
  mutation (
    $persona_id: Int!
    $sucursal_id: Int!
    $id: Int!
    $nombres: String!
    $apellidos: String!
    $email: String
    $interno: String
    $area_id: Int!
    $cargo_id: Int!
    $ciudad_id: Int
    $visible: Int!
  ) {
    editEmpleado(
      persona_id: $persona_id
      sucursal_id: $sucursal_id
      id: $id
      nombres: $nombres
      apellidos: $apellidos
      email: $email
      interno: $interno
      area_id: $area_id
      cargo_id: $cargo_id
      ciudad_id: $ciudad_id
      visible: $visible
    ) {
      id
    }
  }
`;

export const DELETE_EMPLEADO = gql`
  mutation ($id: Int!) {
    deleteEmpleado(id: $id) {
      id
    }
  }
`;

export const UPDATE_FIELD_EMPRESA = gql`
  mutation ($id: Int!, $field: String!, $value: String!, $type: String!) {
    updateFieldEmpresa(id: $id, field: $field, value: $value, type: $type) {
      id
    }
  }
`;

export const DELET_AREA = gql`
  mutation deletArea($id: Int!, $verify: String) {
    deletArea(id: $id, verify: $verify) {
      id
      nombre
    }
  }
`;

export const PROCESAR_INVITACION = gql`
  mutation ($codigo_invitacion: String!, $id: Int!, $descripcion: String!) {
    procesarInvitacion(
      codigo_invitacion: $codigo_invitacion
      id: $id
      descripcion: $descripcion
    ) {
      id
    }
  }
`;

export const DELET_CARGO = gql`
  mutation deletCargo($id: Int!, $verify: String) {
    deletCargo(id: $id, verify: $verify) {
      id
      nombre
    }
  }
`;

export const DELET_RUBRO = gql`
  mutation deletRubro($id: Int!, $verify: String) {
    deletRubro(id: $id, verify: $verify) {
      id
      nombre
    }
  }
`;

export const EDIT_AREA = gql`
  mutation editArea($id: Int!, $nombre: String) {
    editArea(id: $id, nombre: $nombre) {
      id
      nombre
    }
  }
`;

export const EDIT_CARGO = gql`
  mutation editCargo($id: Int!, $nombre: String) {
    editCargo(id: $id, nombre: $nombre) {
      id
      nombre
    }
  }
`;

export const EDIT_RUBRO = gql`
  mutation editRubro($id: Int!, $nombre: String) {
    editRubro(id: $id, nombre: $nombre) {
      id
      nombre
    }
  }
`;

export const OBTENER_CIUDADES = gql`
  mutation ($pais_id: Int!) {
    ciudadesPorPais(pais_id: $pais_id) {
      id
      nombre_ciudad
      provincia {
        id
        nombre
        departamento {
          id
          nombre
        }
      }
    }
  }
`;

export const ADD_ALOJAMIENTO = gql`
  mutation (
    $tipo_alojamiento_id: Int!
    $empresa_id: Int!
    $categoria: Int
    $fecha_construccion: String
    $numero_pisos: Int
    $nro_single: Int
    $nro_doble: Int
    $nro_triple: Int
    $nro_cuadruple: Int
    $nro_matrimonial: Int
    $nro_suite: Int
  ) {
    addAlojamiento(
      tipo_alojamiento_id: $tipo_alojamiento_id
      empresa_id: $empresa_id
      categoria: $categoria
      fecha_construccion: $fecha_construccion
      numero_pisos: $numero_pisos
      nro_single: $nro_single
      nro_doble: $nro_doble
      nro_triple: $nro_triple
      nro_cuadruple: $nro_cuadruple
      nro_matrimonial: $nro_matrimonial
      nro_suite: $nro_suite
    ) {
      id
    }
  }
`;

export const ADD_ALOJAMIENTO_INVITACION = gql`
  mutation (
    $codigo_invitacion: String!
    $tipo_alojamiento_id: Int!
    $empresa_id: Int!
    $categoria: Int
    $fecha_construccion: String
    $numero_pisos: Int
    $nro_single: Int
    $nro_doble: Int
    $nro_triple: Int
    $nro_cuadruple: Int
    $nro_matrimonial: Int
    $nro_suite: Int
  ) {
    addAlojamientoInvitacion(
      codigo_invitacion: $codigo_invitacion
      tipo_alojamiento_id: $tipo_alojamiento_id
      empresa_id: $empresa_id
      categoria: $categoria
      fecha_construccion: $fecha_construccion
      numero_pisos: $numero_pisos
      nro_single: $nro_single
      nro_doble: $nro_doble
      nro_triple: $nro_triple
      nro_cuadruple: $nro_cuadruple
      nro_matrimonial: $nro_matrimonial
      nro_suite: $nro_suite
    ) {
      id
    }
  }
`;

export const ADD_TIPO_PAGO_ALOJAMIENTO = gql`
  mutation ($alojamiento_id: Int!, $tipo_pago_id: Int!) {
    addTipoPagoAlojamiento(
      alojamiento_id: $alojamiento_id
      tipo_pago_id: $tipo_pago_id
    ) {
      id
    }
  }
`;

export const ADD_TIPO_PAGO_ALOJAMIENTO_INVITACION = gql`
  mutation (
    $codigo_invitacion: String!
    $alojamiento_id: Int!
    $tipo_pago_id: Int!
  ) {
    addTipoPagoAlojamientoInvitacion(
      codigo_invitacion: $codigo_invitacion
      alojamiento_id: $alojamiento_id
      tipo_pago_id: $tipo_pago_id
    ) {
      id
    }
  }
`;

export const ADD_SERVICIOS_ALOJAMIENTO = gql`
  mutation ($alojamiento_id: Int!, $servicio_alojamiento_id: Int!) {
    addServiciosAlojamiento(
      alojamiento_id: $alojamiento_id
      servicio_alojamiento_id: $servicio_alojamiento_id
    ) {
      id
    }
  }
`;

export const ADD_SERVICIOS_ALOJAMIENTO_INVITACION = gql`
  mutation (
    $codigo_invitacion: String!
    $alojamiento_id: Int!
    $servicio_alojamiento_id: Int!
  ) {
    addServiciosAlojamientoInvitacion(
      codigo_invitacion: $codigo_invitacion
      alojamiento_id: $alojamiento_id
      servicio_alojamiento_id: $servicio_alojamiento_id
    ) {
      id
    }
  }
`;

export const ADD_PUNTO_INTERES = gql`
  mutation ($alojamiento_id: Int!, $lugar: String!, $distancia: String!) {
    addPuntoInteres(
      alojamiento_id: $alojamiento_id
      lugar: $lugar
      distancia: $distancia
    ) {
      id
    }
  }
`;

export const ADD_PUNTO_INTERES_INVITACION = gql`
  mutation (
    $codigo_invitacion: String!
    $alojamiento_id: Int!
    $lugar: String!
    $distancia: String!
  ) {
    addPuntoInteresInvitacion(
      codigo_invitacion: $codigo_invitacion
      alojamiento_id: $alojamiento_id
      lugar: $lugar
      distancia: $distancia
    ) {
      id
    }
  }
`;

export const SAVE_SERVICE = gql`
  mutation ($alojamiento_id: Int!, $services: String!) {
    saveServices(alojamiento_id: $alojamiento_id, json_services: $services) {
      id
      nombre
    }
  }
`;

export const DELETE_PUNTO = gql`
  mutation ($id: Int!) {
    deletPuntoInteres(id: $id) {
      id
      lugar
      distancia
    }
  }
`;

export const UPDATE_PUNTO = gql`
  mutation (
    $id: Int!
    $alojamiento_id: Int!
    $lugar: String!
    $distancia: String!
  ) {
    updatePuntoInteres(
      id: $id
      alojamiento_id: $alojamiento_id
      lugar: $lugar
      distancia: $distancia
    ) {
      id
      alojamiento_id
      lugar
      distancia
    }
  }
`;

export const UPDATE_ALOJAMIENTO = gql`
  mutation (
    $id: Int!
    $tipo_alojamiento_id: Int!
    $categoria: Int
    $fecha_construccion: String
    $numero_pisos: Int
    $nro_single: Int
    $nro_doble: Int
    $nro_triple: Int
    $nro_cuadruple: Int
    $nro_matrimonial: Int
    $nro_suite: Int
  ) {
    updateAlojamiento(
      id: $id
      tipo_alojamiento_id: $tipo_alojamiento_id
      categoria: $categoria
      fecha_construccion: $fecha_construccion
      numero_pisos: $numero_pisos
      nro_single: $nro_single
      nro_doble: $nro_doble
      nro_triple: $nro_triple
      nro_cuadruple: $nro_cuadruple
      nro_matrimonial: $nro_matrimonial
      nro_suite: $nro_suite
    ) {
      id
    }
  }
`;

export const SAVE_TIPO_PAGO = gql`
  mutation ($alojamiento_id: Int!, $json_tipos_pago: String!) {
    updateTipoPagoAlojamiento(
      alojamiento_id: $alojamiento_id
      json_tipos_pago: $json_tipos_pago
    ) {
      id
    }
  }
`;

export const ADD_CONTACTO_GENERAL = gql`
  mutation (
    $empresa_id: Int!
    $nombre: String!
    $telefono: String!
    $email: String
    $chat: String
    $visible: Int!
    $dpto_area: String
    $comentario: String
  ) {
    addContactoGeneral(
      empresa_id: $empresa_id
      nombre: $nombre
      telefono: $telefono
      email: $email
      chat: $chat
      visible: $visible
      dpto_area: $dpto_area
      comentario: $comentario
    ) {
      id
    }
  }
`;

export const ADD_CONTACTO_GENERAL_INVITACION = gql`
  mutation (
    $codigo_invitacion: String!
    $empresa_id: Int!
    $nombre: String!
    $telefono: String!
    $email: String!
    $dpto_area: String
    $comentario: String
  ) {
    addContactoGeneralInvitacion(
      codigo_invitacion: $codigo_invitacion
      empresa_id: $empresa_id
      nombre: $nombre
      telefono: $telefono
      email: $email
      dpto_area: $dpto_area
      comentario: $comentario
    ) {
      id
    }
  }
`;

export const ADD_DETALLE_CONTACTO = gql`
  mutation (
    $contacto_general_id: Int!
    $medio_de_contacto_id: Int!
    $informacion: String!
    $mas_informacion: String
  ) {
    addDetalleContacto(
      contacto_general_id: $contacto_general_id
      medio_de_contacto_id: $medio_de_contacto_id
      informacion: $informacion
      mas_informacion: $mas_informacion
    ) {
      id
    }
  }
`;

export const ADD_DETALLE_CONTACTO_INVITACION = gql`
  mutation (
    $codigo_invitacion: String!
    $contacto_general_id: Int!
    $medio_de_contacto_id: Int!
    $informacion: String!
    $mas_informacion: String
  ) {
    addDetalleContactoInvitacion(
      codigo_invitacion: $codigo_invitacion
      contacto_general_id: $contacto_general_id
      medio_de_contacto_id: $medio_de_contacto_id
      informacion: $informacion
      mas_informacion: $mas_informacion
    ) {
      id
    }
  }
`;

export const DELETE_CONTACTO_GENERAL = gql`
  mutation ($id: Int!) {
    deleteContactoGeneral(id: $id) {
      id
    }
  }
`;

export const UPDATE_CONTACTO_GENERAL = gql`
  mutation (
    $id: Int!
    $nombre: String!
    $telefono: String!
    $email: String!
    $chat: String!
    $visible: Int!
    $dpto_area: String
    $comentario: String
  ) {
    updateContactoGeneral(
      id: $id
      nombre: $nombre
      telefono: $telefono
      email: $email
      chat: $chat
      visible: $visible
      dpto_area: $dpto_area
      comentario: $comentario
    ) {
      id
    }
  }
`;

export const UPDATE_DETALLE_CONTACTO = gql`
  mutation (
    $id: Int!
    $medio_de_contacto_id: Int!
    $informacion: String!
    $mas_informacion: String
  ) {
    updateDetalleContacto(
      id: $id
      medio_de_contacto_id: $medio_de_contacto_id
      informacion: $informacion
      mas_informacion: $mas_informacion
    ) {
      id
    }
  }
`;

export const DELETE_DETALLE_CONTACTO = gql`
  mutation ($id: Int!) {
    deleteDetalleContacto(id: $id) {
      id
    }
  }
`;

export const UPDATE_PAIS_CIUDAD = gql`
  mutation (
    $id: Int!
    $pais_id: Int!
    $ciudad_id: Int
    $departamento_id: Int
    $provincia_id: Int
  ) {
    updatePaisCiudad(
      id: $id
      pais_id: $pais_id
      ciudad_id: $ciudad_id
      departamento_id: $departamento_id
      provincia_id: $provincia_id
    ) {
      id
    }
  }
`;

export const SAVE_SERVICE_EMPRESA = gql`
  mutation (
    $titulo: String!
    $descripcion: String!
    $moneda: String!
    $precio: Float!
    $detalle_precio: String
    $cupos_disponibles: Int
    $estado: Int!
    $empresa_id: Int!
    $pais_id: Int!
    $departamento_id: Int
    $ciudad_id: Int
    $json_atracciones_id: String
    $nro_dias: Int
    $localidad_id: Int
  ) {
    addServicio(
      titulo: $titulo
      descripcion: $descripcion
      moneda: $moneda
      precio: $precio
      detalle_precio: $detalle_precio
      cupos_disponibles: $cupos_disponibles
      estado: $estado
      empresa_id: $empresa_id
      pais_id: $pais_id
      departamento_id: $departamento_id
      ciudad_id: $ciudad_id
      json_atracciones_id: $json_atracciones_id
      nro_dias: $nro_dias
      localidad_id: $localidad_id
    ) {
      id
    }
  }
`;

export const GET_TAGS = gql`
  mutation ($descripcion: String!) {
    getTags(descripcion: $descripcion) {
      id
      tipo
      descripcion
    }
  }
`;

export const ADD_TAGS = gql`
  mutation ($servicio_id: Int!, $json_tags: String!) {
    addTags(servicio_id: $servicio_id, json_tags: $json_tags) {
      id
    }
  }
`;

export const UPDATE_SERVICE_EMPRESA = gql`
  mutation (
    $id: Int!
    $titulo: String!
    $descripcion: String!
    $moneda: String!
    $precio: Float!
    $pais_id: Int!
    $departamento_id: Int!
    $ciudad_id: Int!
    $json_atracciones_id: String!
    $nro_dias: Int!
    $localidad_id: Int!
  ) {
    updateServicio(
      id: $id
      titulo: $titulo
      descripcion: $descripcion
      moneda: $moneda
      precio: $precio
      pais_id: $pais_id
      departamento_id: $departamento_id
      ciudad_id: $ciudad_id
      json_atracciones_id: $json_atracciones_id
      nro_dias: $nro_dias
      localidad_id: $localidad_id
    ) {
      id
    }
  }
`;

export const UPDATE_ESTADO_SERVICIO = gql`
  mutation (
    $id: Int!
    $estado: Int!
  ) {
    updateEstadoServicio(
      id: $id
      estado: $estado
    ) {
      id
    }
  }
`;

export const UPDATE_TAGS = gql`
  mutation ($servicio_id: Int!, $json_tags: String!) {
    updateTags(servicio_id: $servicio_id, json_tags: $json_tags) {
      id
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ($id: Int!, $actual: String!, $nuevo: String!) {
    changePassword(id: $id, actual: $actual, nuevo: $nuevo) {
      success
      message
    }
  }
`;

export const ADD_INVITACION = gql`
  mutation ($nombre: String!) {
    addInvitacion(nombre: $nombre) {
      id
    }
  }
`;

export const DELETE_INVITACION = gql`
  mutation ($id: Int!) {
    deleteInvitacion(id: $id) {
      id
    }
  }
`;

export const ADD_ONLY_TAGS = gql`
  mutation ($tipo: String!, $descripcion: String!) {
    addOnlyTags(tipo: $tipo, descripcion: $descripcion) {
      id
    }
  }
`;

export const DELETE_ONLY_TAGS = gql`
  mutation ($id: Int!) {
    deleteOnlyTags(id: $id) {
      id
    }
  }
`;

export const EDIT_ONLY_TAGS = gql`
  mutation ($id: Int!, $tipo: String!, $descripcion: String!) {
    editOnlyTags(id: $id, tipo: $tipo, descripcion: $descripcion) {
      id
      tipo
      descripcion
    }
  }
`;

export const ADD_ADJUNTO_EMPRESA = gql`
  mutation ($nombre: String!, $descripcion: String!, $empresa_id: Int!) {
    addAdjuntoEmpresa(
      nombre_archivo: $nombre
      descripcion: $descripcion
      empresa_id: $empresa_id
    ) {
      id
      nombre_descargar
    }
  }
`;

export const DELETE_ADJUNTO_EMPRESA = gql`
  mutation ($id: Int!) {
    deleteAdjuntoEmpresa(id: $id) {
      id
    }
  }
`;

export const ADD_ADJUNTO_SERVICIO = gql`
  mutation (
    $tipo: String!
    $nombre: String!
    $descripcion: String!
    $servicio_id: Int!
  ) {
    addAdjuntoServicio(
      tipo: $tipo
      nombre_archivo: $nombre
      descripcion: $descripcion
      servicio_id: $servicio_id
    ) {
      id
      nombre_descargar
    }
  }
`;

export const DELETE_ADJUNTO_SERVICIO = gql`
  mutation ($id: Int!) {
    deleteAdjuntoServicio(id: $id) {
      id
    }
  }
`;

export const EDIT_ADJUNTO_SERVICIO = gql`
  mutation ($id: Int!, $nombre: String!) {
    editAdjuntoServicio(id: $id, nombre_archivo: $nombre) {
      id
      nombre_descargar
    }
  }
`;

export const ADD_IMAGEN_GALERIA_EMPRESA = gql`
  mutation ($nombre_imagen: String!,$empresa_id: Int!) {
    addImagenGaleriaEmpresa(
      nombre_imagen: $nombre_imagen
      empresa_id: $empresa_id
    ) {
      id,
      nombre_descargar
    }
  }
`;

export const DELETE_IMAGEN_GALERIA_EMPRESA = gql`
  mutation ($id: Int!) {
    deleteImagenGaleriaEmpresa(id: $id) {
      id
    }
  }
`;

export const ADD_USUARIO_EMPRESA = gql`
  mutation (
    $nombre: String!
    $apellido: String!
    $username: String!
    $email: String!
    $password: String!
    $empresa_id: Int!
    $sendMail: Int
    $is_active: Int
  ) {
    addUsuarioEmpresa(
      nombre: $nombre
      apellido: $apellido
      username: $username
      email: $email
      password: $password
      empresa_id: $empresa_id
      sendMail: $sendMail
      is_active: $is_active
    ) {
      success
      message
      data
    }
  }
`;
export const RESET_PASSWORD = gql`
  mutation ($id: Int!, $password: String, $sendMail: Int, $is_active: Int) {
    resetPassword(id: $id, password: $password, sendMail: $sendMail, is_active: $is_active) {
      success
      message
      data
    }
  }
`;
export const DELETE_USUARIO = gql`
  mutation ($id: Int!) {
    deleteUsuario(id: $id) {
      success
      message
      data
    }
  }
`;
export const ADD_LOGO_EMPRESA = gql`
  mutation ($id: Int!, $nombre_logo: String!) {
    addLogoEmpresa(id: $id, nombre_logo: $nombre_logo) {
      id
      nombre_logo
    }
  }
`;
export const ADD_ATRACCIONES = gql`
  mutation ($nombre: String!) {
    addAtracciones(nombre: $nombre) {
      id
      nombre
    }
  }
`;
export const DELETE_ATRACCIONES = gql`
  mutation ($id: Int!, $verify: String) {
    deleteAtracciones(id: $id, verify: $verify) {
      id
      nombre
    }
  }
`;
export const EDIT_ATRACCIONES = gql`
  mutation ($id: Int!, $nombre: String) {
    editAtracciones(id: $id, nombre: $nombre) {
      id
      nombre
    }
  }
`;
export const EDIT_VISIBILIDAD_CONTACTO_GENERAL = gql`
  mutation ($id: Int!, $visible: Int!, $jsonEmpresa: String!) {
    updateVisibilidadContactoGeneral(
      id: $id
      visible: $visible
      jsonEmpresa: $jsonEmpresa
    ) {
      id
    }
  }
`;
export const EDIT_VISIBILIDAD_EMPLEADO = gql`
  mutation ($id: Int!, $visible: Int!, $jsonEmpresa: String!) {
    editVisibilidadEmpleado(
      id: $id
      visible: $visible
      jsonEmpresa: $jsonEmpresa
    ) {
      id
    }
  }
`;
export const ADD_GRUPO_EMPRESA_VER_CONTACTO = gql`
  mutation ($empresa_id: Int!, $nombre: String!) {
    addGrupoEmpresaVerContacto(empresa_id: $empresa_id, nombre: $nombre) {
      id
    }
  }
`;
export const DELETE_GRUPO_EMPRESA_VER_CONTACTO = gql`
  mutation ($id: Int!) {
    deleteGrupoEmpresaVerContacto(id: $id) {
      id
    }
  }
`;
export const EDIT_GRUPO_EMPRESA_DETALLE = gql`
  mutation ($id: Int!, $jsonEmpresa: String!) {
    updateGrupoEmpresaDetalle(id: $id, jsonEmpresa: $jsonEmpresa) {
      id
    }
  }
`;
export const VERIFICAR_SINCRONIZACION = gql`
  mutation ($booking_id: Int!) {
    verificar_sincronizacion(booking_id: $booking_id) {
      id
      nombre
      booking_id
    }
  }
`;

export const ADD_LOCALIDAD = gql`
  mutation (
    $nombre: String!
    $pais_id: Int!
    $departamento_id: Int
    $ciudad_id: Int
  ) {
    addLocalidad(
      nombre: $nombre
      pais_id: $pais_id
      departamento_id: $departamento_id
      ciudad_id: $ciudad_id
    ) {
      id
    }
  }
`;

export const DELETE_LOCALIDAD = gql`
  mutation ($id: Int!) {
    deleteLocalidad(id: $id) {
      id
    }
  }
`;

export const GET_CITIES = gql`
  mutation ($country_id: Int, $state_id: Int, $province_id: Int) {
    getCity(
      country_id: $country_id
      state_id: $state_id
      province_id: $province_id
    ) {
      id
      name
      state_id
      country_id
      province_id
    }
  }
`;

export const ADD_CITIES = gql`
  mutation (
    $name: String!
    $country_id: Int!
    $state_id: Int
    $province_id: Int
  ) {
    addCity(
      name: $name
      country_id: $country_id
      state_id: $state_id
      province_id: $province_id
    ) {
      id
    }
  }
`;

export const DELETE_CITIES = gql`
  mutation ($id: Int!) {
    deleteCity(id: $id) {
      id
    }
  }
`;

export const ADD_HABITACION = gql`
  mutation ($json_habitacion: String!) {
    addHabitacion(json_habitacion: $json_habitacion) {
      id
    }
  }
`;
export const DELETE_HABITACION = gql`
  mutation ($habitacion_id: Int!) {
    deleteHabitacion(habitacion_id: $habitacion_id) {
      id
    }
  }
`;
export const UPDATE_HABITACION = gql`
  mutation ($json_habitacion: String!) {
    updateHabitacion(json_habitacion: $json_habitacion) {
      id
    }
  }
`;

export const ADD_EMPRESA_ENTIDAD_FINANCIERA = gql`
  mutation (
    $empresa_id: Int!
    $entidad_financiera_id: Int!
    $nro_cuenta: String!
  ) {
    addEmpresasEntidadFinanciera(
      empresa_id: $empresa_id
      entidad_financiera_id: $entidad_financiera_id
      nro_cuenta: $nro_cuenta
    ) {
      id
    }
  }
`;

export const UPDATE_EMPRESA_ENTIDAD_FINANCIERA = gql`
  mutation (
    $id: Int!
    $nro_cuenta: String!
  ) {
    updateEmpresasEntidadFinanciera(
      id: $id
      nro_cuenta: $nro_cuenta
    ) {
      id
    }
  }
`;

export const DELETE_EMPRESA_ENTIDAD_FINANCIERA = gql`
  mutation (
    $id: Int!
  ) {
    deleteEmpresasEntidadFinanciera(
      id: $id
    ) {
      id
    }
  }
`;