import React, { useState, useEffect, useCallback } from "react";
import { Col, Table, Row } from "react-bootstrap";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import {
  getCountryById,
  getStatesById,
  getProvinceById,
  removeAccents,
} from "../../utils/helpers";
import { DELETE_CITIES, ADD_CITIES } from "../../utils/graphql_mutation";
import useCountries from "../../hooks/useCountries";
import Highlighter from "react-highlight-words";
import ButtonIcon from "../base/ButtonIcon";
import swal from "sweetalert";
import CiudadForm from "./CiudadForm";
import useCities from "../../hooks/useCities";
import CiudadFormAdd from "./CiudadFormAdd";

const colorHighlighter = "#ffff6f";

const Ciudad = () => {
  const initialState = {
    selected: { country: [], state: [], province: [] },
    nameCity: "",
  };
  // STATE
  const [selected, setSelected] = useState(initialState.selected);
  const [nameCity, setNameCity] = useState(initialState.nameCity);
  const [showCity, setShowCity] = useState([]);
  // MUTATIONS
  const [addCity, { loading: loadAddCity }] = useMutation(ADD_CITIES);
  const [deleteCity] = useMutation(DELETE_CITIES);
  // CUSTOM HOOKS
  const { loading, response: allCountry } = useCountries();
  const [getCities, { loading: loadCities, data: dataCities }] = useCities();
  // FUNCTION
  const onRegister = async () => {
    let auxParams = { name: nameCity, country_id: selected.country[0].id };
    if (_.size(selected.state) > 0) {
      auxParams = { ...auxParams, state_id: selected.state[0].id };
    }
    if (_.size(selected.province) > 0) {
      auxParams = { ...auxParams, province_id: selected.province[0].id };
    }
    await addCity({ variables: auxParams });
    onSearchCities();
  };
  const onDelete = async (params) => {
    swal({
      icon: "warning",
      title: "Estas seguro en eliminar?",
      text: `${_.upperCase(params.name)}`,
      dangerMode: true,
      buttons: {
        cancel: { text: "Cancelar", visible: true },
        confirm: {
          text: "Eliminar",
          value: true,
          visible: true,
          closeModal: false,
        },
      },
    }).then(async (val) => {
      if (val) {
        try {
          await deleteCity({ variables: { id: params.id } });
          onSearchCities();
          setNameCity("");
          swal.stopLoading();
          swal.close();
        } catch (error) {
          console.log(error);
          swal("Error al eliminar", "La CIUDAD ya se encuentra asignado.", {
            icon: "error",
            timer: 3000,
          });
        }
      }
    });
  };
  const renderNamePais = (pais_id) => {
    let result = "";
    if (pais_id) {
      result = getCountryById(pais_id).name;
    }
    return result;
  };
  const renderNameDepartamento = (departamento_id) => {
    let result = "";
    if (departamento_id) {
      result = getStatesById(departamento_id).name;
    }
    return result;
  };
  const renderNameProvince = (province_id) => {
    let result = "";
    if (province_id) {
      result = getProvinceById(province_id).nombre;
    }
    return result;
  };
  const changeResult = useCallback((name, data) => {
    const newCities = _.filter(data, (item) =>
      _.includes(_.toLower(removeAccents(item.name)), _.toLower(name))
    );
    setShowCity(newCities);
  }, []);
  const onSearchCities = () => {
    let params = {};
    if (_.size(selected.country) > 0) {
      params = { countryId: selected.country[0].id };
    }
    if (_.size(selected.state) > 0) {
      params = { stateId: selected.state[0].id };
    }
    if (_.size(selected.province) > 0) {
      params = { provinceId: selected.province[0].id };
    }
    getCities(params);
  };
  // EFFECT
  useEffect(() => {
    changeResult(nameCity, dataCities);
  }, [nameCity, dataCities, changeResult]);
  // RENDER
  if (loading) {
    return `Cargando...`;
  }
  return (
    <>
      <center>
        <h3 className="pl-0">Agregar Ciudad</h3>
      </center>
      <hr />
      <CiudadForm
        initialState={initialState}
        selected={selected}
        setSelected={setSelected}
        nameCity={nameCity}
        setNameCity={setNameCity}
        getCities={getCities}
        allCountry={allCountry}
      />

      {_.size(showCity) > 0 ? (
        <Table
          striped
          bordered
          hover
          className="shadow p-0 mt-4 bg-white rounded"
        >
          <thead>
            <tr className="text-center">
              <th>Pais</th>
              <th>Departamento</th>
              <th>Provincia</th>
              <th>Ciudad</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {loadCities || loadAddCity ? (
              <tr>
                <td colSpan={5} className="text-center">
                  Cargando...
                </td>
              </tr>
            ) : (
              <>
                {showCity.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{renderNamePais(item.country_id)}</td>
                      <td>{renderNameDepartamento(item.state_id)}</td>
                      <td>{renderNameProvince(item.province_id)}</td>
                      <td>
                        <Highlighter
                          highlightStyle={{
                            backgroundColor: colorHighlighter,
                            padding: 0,
                          }}
                          autoEscape={true}
                          searchWords={[_.trim(nameCity)]}
                          textToHighlight={item.name}
                        />
                      </td>
                      <td className="text-center">
                        {item.db && item.db > 0 && (
                          <ButtonIcon
                            titulo="Eliminar"
                            size="sm"
                            variant="danger"
                            icono="fas fa-trash"
                            onClick={() => onDelete(item)}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </>
            )}
          </tbody>
        </Table>
      ) : (
        _.size(selected.country) > 0 &&
        _.trim(nameCity) !== "" && (
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <CiudadFormAdd
                selected={selected}
                nameCity={nameCity}
                loadAddCity={loadAddCity}
                onRegister={onRegister}
              />
            </Col>
          </Row>
        )
      )}
    </>
  );
};

export default Ciudad;
