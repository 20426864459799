import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import InputRequired from "../base/InputRequired";
import _ from "lodash";

const FrmGrupo = ({
  typeCrud,
  validate,
  grupo,
  disabledDelete,
  handleChange,
}) => {
  let classNombre = "";
  let classTelefono = "";
  if (validate) {
    classNombre = _.trim(grupo.nombre) === "" ? "is-invalid" : "is-valid";
    classTelefono = _.trim(grupo.telefono) === "" ? "is-invalid" : "is-valid";
  }
  const tipo = typeCrud ? typeCrud : "create";
  return (
    <Form>
      <Form.Group as={Row}>
        <Form.Label column sm="4">
          Dpto. / Area
        </Form.Label>
        <Col sm="8">
          <Form.Control
            name="dpto_area"
            type="text"
            autoComplete="off"
            value={grupo.dpto_area}
            onChange={handleChange}
            disabled={disabledDelete}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="4">
          Nombre <InputRequired />
        </Form.Label>
        <Col sm="8">
          <Form.Control
            name="nombre"
            type="text"
            autoComplete="off"
            value={grupo.nombre}
            onChange={handleChange}
            className={classNombre}
            disabled={disabledDelete}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="4">
          Telefono <InputRequired />
        </Form.Label>
        <Col sm="8">
          <Form.Control
            name="telefono"
            type="text"
            autoComplete="off"
            value={grupo.telefono}
            onChange={handleChange}
            className={classTelefono}
            disabled={disabledDelete}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="4">
          Email
        </Form.Label>
        <Col sm="8">
          <Form.Control
            name="email"
            type="text"
            autoComplete="off"
            value={grupo.email}
            onChange={handleChange}
            disabled={disabledDelete}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="4">
          Chat
        </Form.Label>
        <Col sm="8">
          <Form.Control
            name="chat"
            type="text"
            autoComplete="off"
            value={grupo.chat}
            onChange={handleChange}
            disabled={disabledDelete}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="4">
          Comentario
        </Form.Label>
        <Col sm="8">
          <textarea
            className="form-control form-control-sm"
            type="text"
            name="comentario"
            autoComplete="off"
            defaultValue={grupo.comentario}
            onChange={handleChange}
            disabled={disabledDelete}
            />
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formCheckGrupo">
        <Col sm={{ span: 9, offset: 3 }}>
          <Form.Check
            name="visible"
            type="checkbox"
            label="Visible para todos"
            checked={grupo.visible}
            onChange={handleChange}
            disabled={disabledDelete}
          />
          {!grupo.visible && tipo !== "delete" && (
            <div
              className="text-secondary"
              style={{ fontSize: 12, marginLeft: "20px" }}
            >
              {`Luego de ${
                tipo === "create" ? "registrar" : "modificar"
              } podras gestionar los que pueden visualizar.`}
            </div>
          )}
        </Col>
      </Form.Group>
    </Form>
  );
};

export default FrmGrupo;
