import React, { useState, useEffect } from "react";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import IuCrudNombre from "../components/configuracion/IuCrudNombre";
// import TagServicio from "../components/configuracion/TagServicio";
import _ from "lodash";
import { AREAS, CARGOS, RUBROS, GET_ATRACCIONES } from "../utils/graphql_query";
import {
  ADD_AREA,
  ADD_CARGO,
  ADD_RUBRO,
  ADD_ATRACCIONES,
  DELET_AREA,
  DELET_RUBRO,
  DELET_CARGO,
  DELETE_ATRACCIONES,
  EDIT_AREA,
  EDIT_CARGO,
  EDIT_RUBRO,
  EDIT_ATRACCIONES,
} from "../utils/graphql_mutation";
import { getLogin } from "../utils/authentication";
import Ciudad from "../components/configuracion/Ciudad";

const Configuracion = () => {
  // STATE
  const [infoUser, setInfoUser] = useState({});
  // EFFECT
  useEffect(() => {
    if (_.size(infoUser) === 0) {
      setInfoUser(getLogin());
    }
  }, [infoUser]);

  return (
    <Container>
      <Tabs defaultActiveKey="Area">
        <Tab eventKey="Area" title="Area">
          <div className="tab-item-wrapper">
            <Container className="bg-white p-4 shadow">
              <Row>
                <Col md={12}>
                  <IuCrudNombre
                    title="areas"
                    query={AREAS}
                    mutation={ADD_AREA}
                    mut_delet={DELET_AREA}
                    mut_edit={EDIT_AREA}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </Tab>

        <Tab eventKey="Cargos" title="Cargos">
          <div className="tab-item-wrapper">
            <Container className="bg-white p-4 shadow">
              <Row>
                <Col md={12}>
                  <IuCrudNombre
                    title="cargos"
                    query={CARGOS}
                    mutation={ADD_CARGO}
                    mut_delet={DELET_CARGO}
                    mut_edit={EDIT_CARGO}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </Tab>

        <Tab eventKey="Rubros" title="Rubros">
          <div className="tab-item-wrapper">
            <Container className="bg-white p-4 shadow">
              <Row>
                <Col md={12}>
                  <IuCrudNombre
                    title="rubros"
                    query={RUBROS}
                    mutation={ADD_RUBRO}
                    mut_delet={DELET_RUBRO}
                    mut_edit={EDIT_RUBRO}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </Tab>

        {/* <Tab eventKey="Tags" title="Tags Servicios">
          <div className="tab-item-wrapper">
            <Container className="bg-white p-4 shadow">
              <Row>
                <Col md={12}>
                  <TagServicio />
                </Col>
              </Row>
            </Container>
          </div>
        </Tab> */}

        <Tab eventKey="Tags" title="Atracciones Turistica">
          <div className="tab-item-wrapper">
            <Container className="bg-white p-4 shadow">
              <Row>
                <Col md={12}>
                  <IuCrudNombre
                    title="atracciones"
                    query={GET_ATRACCIONES}
                    mutation={ADD_ATRACCIONES}
                    mut_delet={DELETE_ATRACCIONES}
                    mut_edit={EDIT_ATRACCIONES}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </Tab>
        <Tab eventKey="Ciudad" title="Ciudad">
          <div className="tab-item-wrapper">
            <Container className="bg-white p-4 shadow">
              <Row>
                <Col md={12}>
                  <Ciudad />
                </Col>
              </Row>
            </Container>
          </div>
        </Tab>
      </Tabs>
    </Container>
  );
};

export default Configuracion;
