import { useState } from "react";
import { getStatesByCountry } from "../utils/helpers";
// import { useMutation } from "@apollo/client";
// import { GET_CITIES } from "../utils/graphql_mutation";
// import _ from "lodash";

const useStates = () => {
  // STATES
  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState([]);
  // MUTATIONS
  // const [getCity] = useMutation(GET_CITIES);
  // FUNCTION
  const getStates = (params = {}) => {
    setLoading(true);

    const { countryId } = params;

    let result = [];
    // let paramsVal = {};
    if (countryId) {
      result = getStatesByCountry(countryId);
      console.log(result);
      // paramsVal = { country_id: countryId };
    }
    // Request BD cities
    // if (_.size(paramsVal) > 0) {
    //   const dataNew = await getCity({ variables: paramsVal });
    //   let aux = [];
    //   // Asignar viariable para edit
    //   _.map(dataNew.data.getCity, (item) => {
    //     aux = _.concat(aux, { ...item, db: 1 });
    //   });
    //   // Ordenar desc
    //   if (_.size(aux) > 0) {
    //     aux = _.reverse(_.sortBy(aux, (item) => item.id));
    //   }
    //   // Mergue result local y DB
    //   result = _.unionBy(aux, result, "id");
    // }
    setdata(result);
    setLoading(false);
  };
  return [getStates, { loading, data }];
};

export default useStates;
