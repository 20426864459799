import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import {
  ADD_EMPLEADO,
  EDIT_EMPLEADO,
  DELETE_EMPLEADO,
} from "../../utils/graphql_mutation";
import {
  AgregarProvinciaDepartamento,
  ValidarFormCrudEmpleado,
  getCitiesByCountry,
  getCityById,
  getCountryById,
} from "../../utils/helpers";
import FrmEmpleado from "./FrmEmpleado";

const CrudEmpleado = ({
  crud,
  paises,
  areas,
  cargos,
  sucursales,
  refetchEmpresa,
  onClose,
}) => {
  const [validate, setValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ciudades, setCiudades] = useState([]);
  const [paisCiudad, setPaisCiudad] = useState({ pais: [], ciudad: [] });
  const [empleado, setEmpleado] = useState({
    nombres: "",
    apellidos: "",
    email: "",
    telefono: "",
    area: 0,
    cargo: 0,
    oficina: 0,
    visible: true,
  });

  const [addEmpleado] = useMutation(ADD_EMPLEADO);
  const [editEmpleado] = useMutation(EDIT_EMPLEADO);
  const [deleteEmpleado] = useMutation(DELETE_EMPLEADO);
  useEffect(() => {
    const cargarValores = async () => {
      if (crud && crud.type !== "create") {
        setEmpleado({
          nombres: crud.empleado.empleado.persona.nombres,
          apellidos: crud.empleado.empleado.persona.apellidos,
          email: crud.empleado.empleado.email
            ? crud.empleado.empleado.email
            : "",
          telefono: crud.empleado.empleado.interno
            ? crud.empleado.empleado.interno
            : "",
          area: crud.empleado.empleado.area.id,
          cargo: crud.empleado.empleado.cargo.id,
          oficina: crud.empleado.sucursal.id,
          visible: parseInt(crud.empleado.empleado.visible) > 0 ? true : false,
        });
        if (crud.empleado.empleado.ciudad_id) {
          // Cargar valores seleccionado
          const city = getCityById(crud.empleado.empleado.ciudad_id);
          const country = getCountryById(city.country_id);
          setPaisCiudad({ pais: [country], ciudad: [city] });
          // Cargar CIUDADES para seleccionar
          if (crud.type === "update") {
            const ciudadesPorPais = getCitiesByCountry(country.id);
            setCiudades(AgregarProvinciaDepartamento(ciudadesPorPais));
          }
        }
      }
    };
    cargarValores();
  }, [crud]);

  const handleChange = (e) => {
    let { type, name, value, checked } = e.target;
    if (type === "checkbox") {
      value = checked;
    }
    setEmpleado({ ...empleado, [name]: value });
  };

  const handleChangePais = async (e) => {
    setPaisCiudad({ ...paisCiudad, pais: e });
    if (_.size(e) > 0) {
      const ciudadesPorPais = getCitiesByCountry(e[0].id);
      setCiudades(AgregarProvinciaDepartamento(ciudadesPorPais));
    }
    setPaisCiudad({ ...paisCiudad, pais: e, ciudad: [] });
  };

  const handleRegistrar = async () => {
    if (ValidarFormCrudEmpleado(empleado)) {
      try {
        setLoading(true);
        await addEmpleado({
          variables: {
            sucursal_id: parseInt(empleado.oficina),
            nombres: _.trim(empleado.nombres),
            apellidos: _.trim(empleado.apellidos),
            email: _.trim(empleado.email),
            interno: _.trim(empleado.telefono),
            area_id: parseInt(empleado.area),
            cargo_id: parseInt(empleado.cargo),
            ciudad_id:
              _.size(paisCiudad.ciudad) > 0
                ? parseInt(paisCiudad.ciudad[0].id)
                : 0,
          },
        });
        await refetchEmpresa();
        // setCrud({ ...crud, show: false });
        onClose();
      } catch (error) {
        console.log(error);
      }
    } else {
      setValidate(true);
    }
  };

  const handleModificar = async () => {
    if (ValidarFormCrudEmpleado(empleado)) {
      try {
        setLoading(true);
        await editEmpleado({
          variables: {
            persona_id: parseInt(crud.empleado.empleado.persona.id),
            sucursal_id: parseInt(empleado.oficina),
            id: parseInt(crud.empleado.empleado.id),
            nombres: _.trim(empleado.nombres),
            apellidos: _.trim(empleado.apellidos),
            email: _.trim(empleado.email),
            interno: _.trim(empleado.telefono),
            area_id: parseInt(empleado.area),
            cargo_id: parseInt(empleado.cargo),
            ciudad_id:
              _.size(paisCiudad.ciudad) > 0
                ? parseInt(paisCiudad.ciudad[0].id)
                : 0,
            visible: empleado.visible ? 1 : 0,
          },
        });
        await refetchEmpresa();
        // setCrud({ ...crud, show: false });
        onClose();
      } catch (error) {
        console.log(error);
      }
    } else {
      setValidate(true);
    }
  };

  const handleEliminar = async () => {
    try {
      setLoading(true);
      await deleteEmpleado({
        variables: { id: parseInt(crud.empleado.empleado.id) },
      });
      await refetchEmpresa();
      // setCrud({ ...crud, show: false });
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeCiudad = (e) => {
    setPaisCiudad({ ...paisCiudad, ciudad: e });
  };

  const disabledDelete = crud.type === "delete" ? true : false;

  return (
    <Modal show={true} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>
          {`${crud.type === "create" ? "Agregar - Funcionario" : ""}`}
          {`${crud.type === "update" ? "Editar - Funcionario" : ""}`}
          {`${crud.type === "delete" ? "Eliminar - Funcionario" : ""}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <FrmEmpleado
          typeCrud={crud.type}
          validate={validate}
          empleado={empleado}
          disabledDelete={disabledDelete}
          areas={areas}
          cargos={cargos}
          paises={paises}
          paisCiudad={paisCiudad}
          ciudades={ciudades}
          sucursales={sucursales}
          handleChange={handleChange}
          handleChangePais={handleChangePais}
          handleChangeCiudad={handleChangeCiudad}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} disabled={loading}>
          Cancelar
        </Button>
        {crud.type === "create" && (
          <Button
            variant="primary"
            onClick={handleRegistrar}
            disabled={loading}
          >
            Registrar
          </Button>
        )}
        {crud.type === "update" && (
          <Button
            variant="primary"
            onClick={handleModificar}
            disabled={loading}
          >
            Modificar
          </Button>
        )}
        {crud.type === "delete" && (
          <Button variant="danger" onClick={handleEliminar} disabled={loading}>
            Eliminar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CrudEmpleado;
