import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";

const SelectPaises = ({ options, id, handlePaisChange, selected }) => {
  return (
    <Typeahead
      id={id}
      name={id}
      labelKey="name"
      options={options}
      onChange={(e) => handlePaisChange(e, id)}
      defaultSelected={selected.length === 0 ? [] : [selected]}
    />
  );
};

export default SelectPaises;
