import React, { useState, useEffect } from "react";
import { Container, Button, ListGroup, Row, Col } from "react-bootstrap";
import _ from "lodash";
import ContainerNotResult from "../base/ContainerNotResult";
import ButtonIcon from "../base/ButtonIcon";
import CrudContacto from "./CrudContacto";
import CrudEmpleado from "../empleado/CrudEmpleado";
import CrudContactoGeneral from "./CrudContactoGeneral";
import CrudAccessToContact from "./CrudAccessToContact";
import CrudGrupoEmpresaVisualizar from "./CrudGrupoEmpresaVisualizar";

const Contactos = ({
  empresaId,
  contactoGeneral,
  sucursales,
  paises,
  areas,
  cargos,
  refetchEmpresa,
}) => {
  const initialState = {
    crud: { show: false, type: "", empleado: {} },
    crudGrupo: { show: false, type: "", grupo: {} },
    crudAccess: { show: false, type: "", contacto: {} },
  };
  // STATE
  const [empleados, setEmpleados] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [crud, setCrud] = useState(initialState.crud);
  const [crudGrupo, setCrudGrupo] = useState(initialState.crudGrupo);
  const [crudAccess, setCrudAccess] = useState(initialState.crudAccess);
  const [showListEmpresa, setShowListEmpresa] = useState(false);
  // EFFECT
  useEffect(() => {
    if (_.size(sucursales) > 0) {
      let result = [];
      _.map(sucursales, (sucursal) => {
        if (_.size(sucursal.empleados) > 0) {
          let aux = { sucursal: { id: sucursal.id, nombre: sucursal.nombre } };
          _.map(sucursal.empleados, (empl) => {
            aux = { ...aux, empleado: empl };
            result = _.concat(result, aux);
          });
        }
      });
      setEmpleados(result);
    }
  }, [sucursales]);
  // FUNCTION
  const viewRow = (icon, title, other = "") => {
    return (
      <Row>
        <Col sm="2">
          <i className={icon}></i>
        </Col>
        <Col sm="10">
          {title}
          {other && (
            <span
              className="ms-2"
              style={{ fontSize: 11 }}
            >{`(${other})`}</span>
          )}
        </Col>
      </Row>
    );
  };
  const mostrar_ocultar = (id, sw) => {
    if(sw){
      document.getElementById('ver_mas_'+id).style='display:block'
      document.getElementById('ver_menos_'+id).style='display:none'
    } else {
      document.getElementById('ver_mas_'+id).style='display:none'
      document.getElementById('ver_menos_'+id).style='display:block'
    }
  }
  const viewRowComment = (icon, comment = "", key) => {
    return (
      <Row>
        <Col sm="2">
          <i className={icon}></i>
        </Col>
        <Col sm="10">
          {comment && (
            <>
              <div id={`ver_menos_${key}`}>
                {`${comment.substring(0, 68)}`}
                {
                  comment.length >= 80 &&
                  <>
                    {'... '}
                    <label 
                      style={{color: 'blue',cursor: 'pointer'}} 
                      onClick={()=>mostrar_ocultar(key, true)}>
                        ver mas
                    </label>
                  </>
                }
              </div>
              <div id={`ver_mas_${key}`} style={{display:'none'}}>
                {`${comment}`}{'. '}
                <label 
                  style={{color: 'blue',cursor: 'pointer'}} 
                  onClick={()=>mostrar_ocultar(key, false)}>
                    ver menos
                </label>
              </div>
            </>
          )}
        </Col>
      </Row>
    );
  };
  const renderAddContacto = () => {
    let result = true;
    if (_.size(contactoGeneral) === 0 && _.size(empleados) === 0) {
      result = false;
    }
    return result;
  };
  const renderBtnVisible = (type, info) => {
    let title = "Personalizar la visibilidad";
    let icon = "eye-slash";
    if (info.visible) {
      title = "Visible para todos";
      icon = "eye";
    }
    return (
      <ButtonIcon
        titulo={title}
        icono={`fa fa-${icon}`}
        variant="dark"
        size="sm"
        onClick={() =>
          setCrudAccess({ show: true, type: type, contacto: info })
        }
      />
    );
  };
  // RENDER
  return (
    <>
      <Container className="shadow p-4 bg-white rounded mt-3">
        {_.size(contactoGeneral) === 0 && _.size(empleados) === 0 ? (
          <ContainerNotResult
            icon="fas fa-users"
            mensaje="No existe Contactos registrados"
          >
            <Button
              variant="outline-primary"
              className="mt-3"
              onClick={() => setShowAdd(true)}
            >
              Agregar
            </Button>
          </ContainerNotResult>
        ) : (
          <ListGroup variant="flush">
            {_.map(contactoGeneral, (contacto, kc) => {
              return (
                <ListGroup.Item key={`cg${kc}`}>
                  <Row>
                    <Col sm="3" className="my-auto">
                      <Row>
                        <Col sm="3" className="text-center my-auto">
                          <i className="fas fa-users fa-lg"></i>
                        </Col>
                        <Col sm="9" className="my-auto">
                          <strong>{contacto.nombre}</strong>
                          <h6
                            className="text-secondary"
                            style={{ fontSize: 14 }}
                          >
                            {contacto.dpto_area}
                          </h6>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="6" className="my-auto">
                      {contacto.telefono &&
                        viewRow("fas fa-phone-alt", contacto.telefono)}
                      {contacto.email &&
                        viewRow("fas fa-envelope", contacto.email)}
                      {contacto.chat &&
                        viewRow("fas fa-comment", contacto.chat)}
                      {contacto.comentario &&
                        viewRowComment("fas fa-comment-alt", contacto.comentario, kc)}
                    </Col>
                    <Col sm="3" className="text-end my-auto">
                      <ButtonIcon
                        titulo="Editar"
                        icono="fa fa-pen"
                        variant="primary"
                        className="me-2"
                        size="sm"
                        onClick={() =>
                          setCrudGrupo({
                            show: true,
                            type: "update",
                            grupo: contacto,
                          })
                        }
                      />

                      <ButtonIcon
                        titulo="Eliminar"
                        icono="fa fa-trash"
                        variant="danger"
                        className="me-2"
                        size="sm"
                        onClick={() =>
                          setCrudGrupo({
                            show: true,
                            type: "delete",
                            grupo: contacto,
                          })
                        }
                      />

                      {renderBtnVisible("general", contacto)}
                    </Col>
                  </Row>
                </ListGroup.Item>
              );
            })}

            {_.map(empleados, (emp, ke) => {
              return (
                <ListGroup.Item key={ke}>
                  <Row>
                    <Col sm="3" className="my-auto">
                      <Row>
                        <Col sm="3" className="text-center my-auto">
                          <i className="fas fa-user fa-lg"></i>
                        </Col>
                        <Col sm="9" className="my-auto">
                          <b>{emp.empleado.nombre_completo}</b>
                          <h6
                            className="text-secondary"
                            style={{ fontSize: 14 }}
                          >
                            {emp.empleado.cargo.nombre} -{" "}
                            {emp.empleado.area.nombre}
                          </h6>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="6" className="my-auto">
                      {viewRow(
                        "fa fa-building",
                        `Oficina ${emp.sucursal.nombre}`
                      )}
                      {emp.empleado.interno &&
                        viewRow("fas fa-phone-alt", emp.empleado.interno)}
                      {emp.empleado.email &&
                        viewRow("fas fa-envelope", emp.empleado.email)}
                    </Col>
                    <Col sm="3" className="text-end my-auto">
                      <ButtonIcon
                        titulo="Editar"
                        variant="primary"
                        size="sm"
                        icono="fa fa-pen"
                        className="me-2"
                        onClick={() =>
                          setCrud({ show: true, type: "update", empleado: emp })
                        }
                      />

                      <ButtonIcon
                        titulo="Eliminar"
                        variant="danger"
                        className="me-2"
                        size="sm"
                        icono="fa fa-trash"
                        onClick={() =>
                          setCrud({ show: true, type: "delete", empleado: emp })
                        }
                      />

                      {renderBtnVisible("empleado", emp.empleado)}
                    </Col>
                  </Row>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        )}
      </Container>
      {renderAddContacto() && (
        <Container className="p-0 mb-3 mt-2 text-end">
          <Button
            variant="primary"
            onClick={() => setShowListEmpresa(true)}
            className="me-2"
            style={{display:"none"}}
          >
            <i className="fa fa-eye-slash me-2"></i>
            Empresas para Visualizar
          </Button>
          <Button variant="primary" onClick={() => setShowAdd(true)}>
            Agregar Contacto
          </Button>
        </Container>
      )}
      {showAdd && (
        <CrudContacto
          empresaId={empresaId}
          sucursales={sucursales}
          paises={paises}
          areas={areas}
          cargos={cargos}
          onClose={() => setShowAdd(false)}
          refetchEmpresa={refetchEmpresa}
        />
      )}
      {crud.show && (
        <CrudEmpleado
          crud={crud}
          paises={paises}
          areas={areas}
          cargos={cargos}
          sucursales={sucursales}
          refetchEmpresa={refetchEmpresa}
          onClose={() => setCrud({ ...crud, show: false })}
        />
      )}
      {crudGrupo.show && (
        <CrudContactoGeneral
          crud={crudGrupo}
          refectEmpresa={refetchEmpresa}
          onClose={() => setCrudGrupo({ ...crudGrupo, show: false })}
        />
      )}
      {crudAccess.show && (
        <CrudAccessToContact
          empresaId={empresaId}
          crud={crudAccess}
          onClose={() => setCrudAccess({ ...crudAccess, show: false })}
          refectEmpresa={refetchEmpresa}
        />
      )}
      {showListEmpresa && (
        <CrudGrupoEmpresaVisualizar
          empresaId={empresaId}
          onClose={() => setShowListEmpresa(false)}
        />
      )}
    </>
  );
};

export default Contactos;
