import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingPage = ({ size }) => {
  let width = 100;
  if (size) {
    width = size;
  }
  return (
    <div
      style={{
        height: `${width}vh`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: 800, height: "auto" }}>
        <div className="text-center">
          <Spinner animation="border" role="status" />
          <h6 className="mt-4">Cargando...</h6>
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
