import React from 'react'
import { Row, Col, Form } from 'react-bootstrap';
import * as yup from 'yup';

const NroHabitaciones = ({ alojamiento, setAlojamiento}) => {
  
  const handleChange = (e) => {
    setAlojamiento({ ...alojamiento, [e.target.name]: e.target.value})
  }

  const handleBlur = async (e) => {
    const nombre = e.target.name;
    let valor = e.target.value;
    let schema = yup.object().shape({
      cantidad: yup.number().required().positive().integer(),
    });
    
    const resp = await schema.isValid({
      cantidad: valor
    })
    .then(function (valid) {
      return valid;
    });

    valor = resp ? parseInt(valor) : 0;
    setAlojamiento({ ...alojamiento, [nombre]: valor})
  }
  return (
    <>
      <h5>Nro de Habitaciones</h5>
      <hr />
      <Row className="mb-5">
        <Col sm="4" className="mb-3">
          <Form.Label>Singles</Form.Label>
          <Form.Control 
            name="nroSingle" 
            type="number"
            className="text-center" 
            value={alojamiento.nroSingle} 
            onChange={handleChange}
            onBlur={handleBlur} 
            />
        </Col>
        <Col sm="4" className="mb-3">
          <Form.Label>Dobles</Form.Label>
          <Form.Control 
            name="nroDoble" 
            type="number" 
            className="text-center" 
            value={alojamiento.nroDoble} 
            onChange={handleChange} 
            onBlur={handleBlur} 
            />
        </Col>
        <Col sm="4" className="mb-3">
          <Form.Label>Triples</Form.Label>
          <Form.Control 
            name="nroTriple" 
            type="number" 
            className="text-center" 
            value={alojamiento.nroTriple} 
            onChange={handleChange} 
            onBlur={handleBlur} 
            />
        </Col>
        <Col sm="4" className="mb-3">
          <Form.Label>Cuadruples</Form.Label>
          <Form.Control 
            name="nroCuadruple" 
            type="number" 
            className="text-center" 
            value={alojamiento.nroCuadruple} 
            onChange={handleChange} 
            onBlur={handleBlur} 
            />
        </Col>
        <Col sm="4" className="mb-3">
          <Form.Label>Matrimonial</Form.Label>
          <Form.Control 
            name="nroMatrimonial" 
            type="number" 
            className="text-center" 
            value={alojamiento.nroMatrimonial} 
            onChange={handleChange} 
            onBlur={handleBlur} 
            />
        </Col>
        <Col sm="4" className="mb-3">
          <Form.Label>Suite</Form.Label>
          <Form.Control 
            name="nroSuite" 
            type="number" 
            className="text-center" 
            value={alojamiento.nroSuite} 
            onChange={handleChange} 
            onBlur={handleBlur} 
            />
        </Col>
      </Row>
    </>
  )
}

export default NroHabitaciones
