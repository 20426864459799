import React, { useState} from 'react'
import { Button, Form, Row, Col, Container, Table } from 'react-bootstrap';
import _ from 'lodash'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import ButtonIcon from '../base/ButtonIcon';
import ContainerNotResult from '../base/ContainerNotResult';

const baseEmpleado = { nombres: '', apellidos: '', email: '', interno: '', area: 0, cargo: 0, oficina: 0 }
const baseSucursal = { nombre: '', telefono: '', direccion: '', pais: [], ciudad: [], listCiudad: [], empleados:[] }

const Step2 = ({ sucursales, setSucursales, setStep, areas, cargos}) => {
  const [validate, setValidate] = useState(false);

  const handleNext = () => {
    if (validar()) {
      setStep(4)
    }
  }

  const handleAdd = () => {
    setSucursales(_.concat(sucursales, baseSucursal))
  }

  const validar = () => {
    let result = []
    _.map(sucursales, (val, i) => {
      if (_.trim(val.nombre) === '') result = _.concat(result, `Nombre Oficina#${i}`)
      if (_.trim(val.telefono) === '') result = _.concat(result, `Telefono Oficina#${i}`)
      if (_.trim(val.direccion) === '') result = _.concat(result, `Direccion Oficina#${i}`)

      _.map(val.empleados, (empleado, ke) => {
        if (_.trim(empleado.nombres) === '') result = _.concat(result, `Nombres #O${i} #E${ke}`)
        if (_.trim(empleado.apellidos) === '') result = _.concat(result, `Apellidos #O${i} #E${ke}`)
        if (parseInt(empleado.area) === 0) result = _.concat(result, `Area #O${i} #E${ke}`)
        if (parseInt(empleado.cargo) === 0) result = _.concat(result, `Cargo #O${i} #E${ke}`)
      })

    })
    if (_.size(result)) {
      console.log(result)
      setValidate(true)
      return false;
    }
    return true;
  }

  const handleDelete = async (id) => {
    let antiguo = sucursales;
    await setSucursales([]);
    let result = []
    const index = _.findIndex(antiguo, (v, i) => i === id);
    _.map(antiguo, (val, key) => {
      if (key !== index) {
        result = _.concat(result, val);
      }
    })
    setSucursales(result);
  }

  const handleChange = (e, i) => {
    asingarValor(e.target.name, e.target.value, i);
  }

  const asingarValor = (name, value, i) => {
    let result = []
    _.map(sucursales, (val, key) => {
      let aux = val
      if (key === i) {
        aux = { ...val, [name]: value }
      }
      result = _.concat(result, aux)
    })
    setSucursales(result);
  }

  const handleAddEmpleados = (is) => {
    let result = []
    _.map(sucursales, (sucursal, ks) => {
      let auxEmpleado = sucursal
      if (ks === is) {
        auxEmpleado = { ...auxEmpleado, empleados: _.concat(sucursal.empleados, baseEmpleado) }
      }
      result = _.concat(result, auxEmpleado)
    })
    setSucursales(result)
  }

  const handleDeleteEmpleados = (is, ie) => {
    let result = []
    _.map(sucursales, (sucursal, ks) => {
      let auxSucursal = sucursal
      if (ks === is) {
        let auxEmpleado = []
        _.map(sucursal.empleados, (empleado, ke) => {
          if (ke !== ie) {
            auxEmpleado = _.concat(auxEmpleado, empleado)
          }
        })
        auxSucursal = { ...auxSucursal, empleados: auxEmpleado }
      }
      result = _.concat(result, auxSucursal)
    })
    setSucursales(result)
  }

  const handleChangeEmpleados = (e, is, ie) => {
    let result = []
    _.map(sucursales, (sucursal, ks) => {
      let auxSucursal = sucursal
      if (ks === is) {
        let auxEmpleado = []
        _.map(sucursal.empleados, (empleado, ke) => {
          let aux = empleado
          if (ke === ie) {
            aux = { ...empleado, [e.target.name]: e.target.value }
          }
          auxEmpleado = _.concat(auxEmpleado, aux)
        })
        auxSucursal = { ...auxSucursal, empleados: auxEmpleado }
      }
      result = _.concat(result, auxSucursal)
    })
    setSucursales(result)
  }

  return (
    <>
      <h3 className="mb-0">Oficinas</h3>
      <p>Informacion de Oficina o Sucursal de la empresa.</p>
      {
        _.size(sucursales) === 0
        &&
        <Container className="shadow bg-white pb-3 pt-5 text-center">
            <h4>Desea agregar Oficinas?</h4>
            <Button variant="outline-primary" className="mt-1 mb-3" onClick={handleAdd}>Agregar</Button>
            <h6 className="text-secondary">Informacion no requerido para el registro.</h6>
          </Container>
      }

      {
        _.map(sucursales, (sucursal, i) => {

          let classNombre = '';
          let classTelefono = '';
          let classDireccion = '';
          if (validate) {
            classNombre = sucursal.nombre === '' ? 'is-invalid' : 'is-valid'
            classTelefono = sucursal.telefono === '' ? 'is-invalid' : 'is-valid'
            classDireccion = sucursal.direccion === '' ? 'is-invalid' : 'is-valid'
          }
          return (
            <div key={i} className="p-4 shadow bg-white rounded mt-3">
              <Row noValidate>
                  <Col sm="6" className="mb-3">
                    <Form.Label>Nombre Oficina</Form.Label>
                    <Form.Control
                      name="nombre"
                      type="text"
                      autoComplete="off"
                      onChange={(e) => handleChange(e, i)}
                      value={sucursal.nombre}
                      className={classNombre}
                    />
                  </Col>
                  <Col sm="6" className="mb-3">
                  <Form.Label >Telefono</Form.Label>
                    <Form.Control
                      name="telefono"
                      type="text"
                      autoComplete="off"
                      onChange={(e) => handleChange(e, i)}
                      value={sucursal.telefono}
                      className={classTelefono}
                    />
                  </Col>
                
                
                  <Col sm="12" className="mb-3">
                    <Form.Label >Direccion</Form.Label>
                    <Form.Control
                      name="direccion"
                      as="textarea"
                      rows={2}
                      onChange={(e) => handleChange(e, i)}
                      value={sucursal.direccion}
                      className={classDireccion}
                      />
                  </Col>

                </Row>
              
              <Form.Label>Funcionario o Empleados</Form.Label>
              <div className="mb-3">
                {
                  _.size(sucursal.empleados) === 0 &&
                  <ContainerNotResult icon="fas fa-user" mensaje="No existen datos cargados" >
                    <Button variant="outline-primary" onClick={() => handleAddEmpleados(i)}>Agregar Funcionario</Button>    
                  </ContainerNotResult>
                }

                {
                  _.size(sucursal.empleados) > 0 &&
                  <Table bordered hover responsive="sm" className="text-center mb-0">
                    <thead>
                      <tr>
                        <th>Nombres</th>
                        <th>Apellidos</th>
                        <th>Area</th>
                        <th>Cargo</th>
                        <th>Email</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        _.map(sucursal.empleados, (empleado, ke) => {
                          let classNombres = '';
                          let classApellidos = '';
                          let classArea = '';
                          let classCargo = '';
                          if (validate) {
                            classNombres = _.trim(empleado.nombres) === "" ? 'is-invalid' : 'is-valid'
                            classApellidos = _.trim(empleado.apellidos) === "" ? 'is-invalid' : 'is-valid'
                            classArea = parseInt(empleado.area) > 0 ? 'is-valid' : 'is-invalid'
                            classCargo = parseInt(empleado.cargo) > 0 ? 'is-valid' : 'is-invalid'
                            
                          }
                          return (
                            <tr className="bg-light" key={ke}>
                              <td>
                                <Form.Control
                                  name="nombres"
                                  type="text"
                                  autoComplete="off"
                                  onChange={(e) => handleChangeEmpleados(e, i, ke)}
                                  value={empleado.nombres}
                                  className={classNombres}
                                  size="sm"
                                />
                              </td>
                              <td>
                                <Form.Control name="apellidos" type="text" autoComplete="off" size="sm"
                                  onChange={(e) => handleChangeEmpleados(e, i, ke)}
                                  value={empleado.apellidos}
                                  className={classApellidos}
                                />
                              </td>
                              <td>
                                <Form.Control name="area" as="select" size="sm"
                                  onChange={(e) => handleChangeEmpleados(e, i, ke)}
                                  value={empleado.area}
                                  className={classArea}
                                >
                                  <option value='0'></option>
                                  {
                                    _.map(areas, (area, key) => {
                                      return (
                                        <option key={area.id} value={area.id}>{area.nombre}</option>
                                      )
                                    })
                                  }
                                </Form.Control>


                              </td>
                              <td>
                                <Form.Control name="cargo" as="select" size="sm"
                                  onChange={(e) => handleChangeEmpleados(e, i, ke)}
                                  value={empleado.cargo}
                                  className={classCargo}
                                >
                                  <option value='0'></option>
                                  {
                                    _.map(cargos, (cargo) => <option key={cargo.id} value={cargo.id}>{cargo.nombre}</option>)
                                  }
                                </Form.Control>
                              </td>
                              <td>
                                <Form.Control name="email" type="text" autoComplete="off" size="sm"
                                  onChange={(e) => handleChangeEmpleados(e, i, ke)}
                                  value={empleado.email}
                                  />
                              </td>
                              <td style={{width:100}} className="text-start">
                                
                                <ButtonIcon
                                  titulo="Quitar Funcionario"
                                  icono="fa fa-trash"
                                  variant="danger"
                                  size="sm"
                                  className="me-2"
                                  onClick={() => handleDeleteEmpleados(i, ke)}
                                />

                                {
                                  ke === _.size(sucursal.empleados) - 1 &&
                                  <ButtonIcon
                                    titulo="Nuevo Funcionario"
                                    icono="fa fa-plus"
                                    variant="primary"
                                    size="sm"
                                    onClick={() => handleAddEmpleados(i)}
                                  />
                                }

                                
                              </td>
                            </tr>
                          )
                        })
                      }
                        
                    </tbody>
                  </Table>
                }
              </div>
              
                

              <div className="text-end">
                {
                  i + 1 === _.size(sucursales)
                  &&
                  <Button variant="outline-primary" className="mt-1" onClick={handleAdd}>Nueva Oficina</Button>
                }

                {
                  <Button variant="outline-danger" className="mt-1 ms-2" onClick={() => handleDelete(i)}>Eliminar Oficina</Button>
                }
              </div>
            </div>
          )
        })
      }
      
      <div className="mt-3">
        <Button variant="secondary" onClick={() => setStep(2)}>Atras</Button>
        <Button variant="primary" className="float-right" onClick={handleNext}>Siguiente</Button>
      </div>
    </>
  )
}

export default Step2
