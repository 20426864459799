import React from "react";
import { Redirect } from "react-router-dom";
import _ from "lodash";
import { logout } from "../../utils/authentication";

const Errors = ({ error }) => {
  // VERIFICAR SI EL ERROR ES POR EL TOKEN INVALIDO
  if (
    _.findIndex(error.graphQLErrors, (err) => err.message === "JWT-INVALID") >=
    0
  ) {
    logout();
    return <Redirect to="/login" />;
  }
  return <div>{`${error}`}</div>;
};

export default Errors;
