import React, { useState } from "react";
import { Container, Button, Row, Col, ListGroup } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { GET_ENTIDAD_FINANCIERA_BY_EMPRESA } from "../../utils/graphql_query";
import CrudEntidadFinanciera from "./CrudEntidadFinanciera";
import _ from "lodash";
import ButtonIcon from "../base/ButtonIcon";
import LoadingPage from "../base/LoadingPage";
import ContainerNotResult from "../base/ContainerNotResult";
import useEntidadesFinancieras from "../../hooks/useEntidadesFinancieras";

const EntidadFinanciera = ({ idEmpresa }) => {
  // STATE
  const [crud, setCrud] = useState({ show: false, type: "", cuentas: {} });
  // QUERY
  const { loading, data, refetch } = useQuery(GET_ENTIDAD_FINANCIERA_BY_EMPRESA, {
    variables: { empresa_id: idEmpresa },
  });
  // CUSTOM
  const [getAllEntidadFinanciera, { loading: loadEntidades, data: dataEntidades }] = useEntidadesFinancieras();
  // FUNCTION
  const viewRow = (icon, title) => {
    return (
      <span>
        <i className={icon}></i>&nbsp;&nbsp;{title}
      </span>
    );
  };

  const handleDeleteEntidadFinanciera = (cuenta) => {
    getAllEntidadFinanciera();
    setCrud({ ...crud, show: true, type: "delete", cuenta: cuenta });
  }

  const handleCreateEntidadFinanciera = () => {
    getAllEntidadFinanciera();
    setCrud({ ...crud, show: true, type: "create" });
  }

  if (loading) {
    return <LoadingPage size={30} />;
  }
  const { getEntidadFinancieraByEmpresa: cuentas } = data;
  // RENDER
  return (
    <>
      <Container className="shadow p-4 bg-white rounded mb-3 mt-3">
        {_.size(cuentas) === 0 && (
          <ContainerNotResult
            icon="fa fa-piggy-bank"
            mensaje="No existen cuentas registradas"
          >
            <Button
              variant="outline-primary"
              className="mt-3"
              onClick={() => handleCreateEntidadFinanciera()}
            >
              Agregar
            </Button>
          </ContainerNotResult>
        )}

        <ListGroup variant="flush">
          {_.map(cuentas, (cuenta, i) => {
            return (
              <ListGroup.Item key={i}>
                <Row>
                  <Col sm="6" className="my-auto">
                    <strong>{cuenta.entidad_financiera &&
                      viewRow("fas fa-university", (cuenta.entidad_financiera.nombre) )}</strong>
                  </Col>
                  <Col sm="4" className="my-auto">
                    {cuenta.nro_cuenta &&
                      viewRow("fas fa-money-check-alt", cuenta.nro_cuenta )}
                    
                  </Col>
                  <Col sm="2" className="my-auto text-center">
                    <ButtonIcon
                        titulo="Eliminar"
                        icono="fa fa-trash"
                        variant="danger"
                        className="me-2"
                        size="sm"
                        onClick={() => handleDeleteEntidadFinanciera(cuenta)}
                      />
                  </Col>
                </Row>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </Container>

      {_.size(cuentas) > 0 && (
        <Container className="text-end p-0">
          <Button
            variant="primary"
            onClick={() => handleCreateEntidadFinanciera()}
          >
            Agregar Cuenta
          </Button>
        </Container>
      )}

      {crud.show && (
        <CrudEntidadFinanciera
          crud={crud}
          setCrud={setCrud}
          idEmpresa={idEmpresa}
          refetch={refetch}
          loadEntidades={loadEntidades}
          dataEntidades={dataEntidades}
        />
      )}
    </>
  );
};

export default EntidadFinanciera;
