import React, { useState } from "react";
import { Container, Button, Row, Col, ListGroup } from "react-bootstrap";
import CrudUser from "../usuario/CrudUser";
import { useQuery } from "@apollo/client";
import { GET_USUARIO_BY_EMPRESA } from "../../utils/graphql_query";
import _ from "lodash";
import ButtonIcon from "../base/ButtonIcon";
import { getLogin } from "../../utils/authentication";
import LoadingPage from "../base/LoadingPage";
import ContainerNotResult from "../base/ContainerNotResult";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const userLogin = getLogin();

const Usuario = ({ idEmpresa }) => {
  // STATE
  const [crud, setCrud] = useState({ show: false, type: "", usuario: {} });
  // QUERY
  const { loading, data, refetch } = useQuery(GET_USUARIO_BY_EMPRESA, {
    variables: { empresa_id: idEmpresa },
  });
  if (loading) {
    return <LoadingPage size={30} />;
  }
  const { getUsuarioByEmpresa: allUsers } = data;
  // RENDER
  return (
    <>
      <Container className="shadow p-4 bg-white rounded mb-3 mt-3">
        {_.size(allUsers) === 0 ? (
          <ContainerNotResult
            icon="fas fa-user-lock"
            mensaje="No existe Usuarios registrados"
          >
            {userLogin && !userLogin.empresa && (
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => setCrud({ ...crud, show: true, type: "create" })}
              >
                Agregar
              </Button>
            )}
          </ContainerNotResult>
        ) : (
          <ListGroup variant="flush">
            <ListGroup.Item >
            <Row>
                  <Col sm="2" className="my-auto">
                      Nombre
                    </Col>
                    <Col sm="4" className="my-auto text-center">
                      Usuario
                    </Col>
                    <Col sm="4" className="my-auto">
                      Correo
                    </Col>
                    <Col sm="1" className="my-auto">
                      Estado
                    </Col>
                    <Col sm="1" className="text-end my-auto">
                      
                    </Col>
                    </Row>
            </ListGroup.Item>
            
            {_.map(allUsers, (user, u) => {
              // console.log(user);
              return (
                <ListGroup.Item key={u}>
                  <Row>
                    <Col sm="2" className="my-auto">
                      {user.nombre}
                    </Col>
                    <Col sm="4" className="my-auto text-center" >
                    <OverlayTrigger overlay={<Tooltip>{user.username}</Tooltip>}>
                      <div className="text-truncate">{user.username}</div>
                    </OverlayTrigger>
                    </Col>
                    <Col sm="4" className="my-auto text-truncate" >
                    <OverlayTrigger overlay={<Tooltip>{user.email}</Tooltip>}>
                      <div className="text-truncate">{user.email}</div>
                    </OverlayTrigger>
                    </Col>
                    <Col sm="1" className="my-auto">
                      {user.is_active===1 ? "Activo" : "Inactivo" }
                    </Col>
                    <Col sm="1" className="text-end my-auto">
                      {userLogin && !userLogin.empresa && (
                        <div className="d-flex"> 
                          <ButtonIcon
                            titulo="Actualizar usuario"
                            variant="primary"
                            size="sm"
                            icono="fas fa-user-lock"
                            className=""
                            onClick={() =>
                              setCrud({
                                ...crud,
                                show: true,
                                type: "update",
                                usuario: user,
                              })
                            }
                          />

                          <ButtonIcon
                            titulo="Eliminar usuario"
                            variant="danger"
                            size="sm"
                            icono="fa fa-trash"
                            onClick={() =>
                              setCrud({
                                ...crud,
                                show: true,
                                type: "delete",
                                usuario: user,
                              })
                            }
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        )}
      </Container>

      {userLogin && !userLogin.empresa && _.size(allUsers) > 0 && (
        <Container className="text-end p-0 mb-5">
          <Button
            variant="primary"
            onClick={() => setCrud({ ...crud, show: true, type: "create" })}
          >
            Agregar Usuario
          </Button>
        </Container>
      )}

      {crud.show && (
        <CrudUser
          idEmpresa={idEmpresa}
          crud={crud}
          onClose={() => setCrud({ ...crud, show: false })}
          onRefetch={refetch}
        />
      )}
    </>
  );
};

export default Usuario;
