import React, { useState } from "react";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import { Row, Col, Table, Button, Modal, Form, Alert } from "react-bootstrap";
import { ADJUNTO_EMPRESA } from "./../../utils/graphql_query";
import {
  ADD_ADJUNTO_EMPRESA,
  DELETE_ADJUNTO_EMPRESA,
} from "./../../utils/graphql_mutation";
import { useQuery } from "@apollo/client";
import Errors from "../base/Errors";
import LoadingPage from "../base/LoadingPage";
import { uploadFileS3, uploadingAwsS3 } from "../../utils/helpers";
import moment from "moment";
import { trimLastKey } from "./galeriaImagenes";

const DocumentacionEmpresa = ({ empresa_id }) => {
  const { data, loading, refetch, error } = useQuery(ADJUNTO_EMPRESA, {
    variables: { empresa_id: empresa_id },
  });
  const [showModal, setShowModal] = useState(false);
  const [modDelete, setModDelete] = useState(false);
  const [ldAccion, setLdAccion] = useState(false);
  const [complet, setComplet] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [selectedElement, setselectedElement] = useState(undefined);
  const [descriptionFile, setDescriptionFile] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);

  const [addAdjuntoEmpresa] = useMutation(ADD_ADJUNTO_EMPRESA);
  const [deleteAdjuntoEmpresa] = useMutation(DELETE_ADJUNTO_EMPRESA);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const handleCloseModDelete = () => setModDelete(false);
  const handleShowModDelete = () => setModDelete(true);
  const selectFile = (event) => {
    console.log(event.target.files[0]);
    setSelectedFiles(event.target.files[0]);
  };

  const onChangeDescription = (e) => {
    if (e.target.value !== "") {
      e.target.className = "form-control is-valid";
    }
    setDescriptionFile(e.target.value);
  };

  const saveDocuments = async () => {
    if (selectedFiles !== undefined && descriptionFile !== "") {
      setBtnDisabled(true);
      setLdAccion(true);
      const upload = await uploadFileS3({
        data: selectedFiles,
        filename: "file",
        prefix: `empresa`,
      });
      await addAdjuntoEmpresa({
        variables: {
          nombre: upload?.key,
          descripcion: _.trim(descriptionFile),
          empresa_id: empresa_id,
        },
      });

      refetch();
      setComplet(true);
      setBtnDisabled(false);
      setLdAccion(false);
      setTimeout(() => {
        setComplet(false);
        setDescriptionFile("");
        setSelectedFiles("");
        setShowModal(false);
      }, 1000);
    } else {
      let txtArea = document.getElementById("txtDescription");
      txtArea.className = "form-control is-invalid";
    }
  };

  const handleClickOpen = ({ nameFile, isOld }) => {
    const folderTest = process.env.REACT_APP_AWS_S3_FOLDER_TEST
      ? process.env.REACT_APP_AWS_S3_FOLDER_TEST
      : "";
    if (isOld) {
      window.open(
        `${process.env.REACT_APP_AWS_S3_URL}/empresa${folderTest}/${nameFile}`
      );
      return;
    }

    window.open(
      `${process.env.REACT_APP_AWS_S3_URL}/empresa${folderTest}/${trimLastKey(
        nameFile
      )}`
    );
  };

  const handleClickSetElement = (value) => {
    handleShowModDelete();
    setselectedElement(value);
  };

  const handleDeletElement = async () => {
    setLdAccion(true);
    const {
      data: { deleteAdjuntoEmpresa: result },
    } = await deleteAdjuntoEmpresa({
      variables: { id: selectedElement.id },
    });
    if (parseInt(result.id) > 0) {
      refetch();
    }
    setLdAccion(false);
    setComplet(true);
    setTimeout(() => {
      setComplet(false);
      setModDelete(false);
    }, 1000);
  };

  const createTitle = (obj) => {
    let extension = obj.nombre_archivo.split(".");
    return obj.nombre_descargar + "." + extension[1];
  };

  if (loading) return <LoadingPage />;
  if (error) return <Errors error={error} />;
  if (!data.adjuntos_empresa) return "No existe informacion";

  return (
    <>
      <h5 className="">Documentos Adjuntos</h5>

      <div className="mb-2">
        <Button className="btn-primary me-2" size="sm" onClick={handleShow}>
          <i className="fa fa-plus"></i> Agregar
        </Button>
      </div>

      <Table striped bordered hover className="shadow bg-white">
        <thead>
          <tr className="text-center">
            <th>Archivo</th>
            <th>Fecha</th>
            <th>Descripci&oacute;n</th>
            <th>Acci&oacute;n</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td>Cargando...</td>
            </tr>
          ) : (
            <>
              {data.adjuntos_empresa.length === 0 ? (
                <tr>
                  <td colSpan="4">No existe informaci&oacute;n.</td>
                </tr>
              ) : (
                _.map(data.adjuntos_empresa, (value, key) => {
                  return (
                    <tr key={key}>
                      <td className="text-center">
                        <Button
                          id="linkDocument"
                          variant="link"
                          style={{ lineHeight: "1" }}
                          onClick={() =>
                            handleClickOpen({
                              nameFile: value.nombre_descargar,
                              //hardcoded old way to load files
                              isOld: moment(value.fecha, "YYYY-MM-DD").isBefore(
                                moment('2024-08-15', "YYYY-MM-DD")
                              )
                            })
                          }
                          title={createTitle(value)}
                        >
                          <i
                            className="fas fa-file-download"
                            style={{ fontSize: "20px" }}
                            data-link={value.nombre_archivo}
                            data-newname={value.nombre_descargar}
                          />
                          {/* <br /> */}
                          <span className="ms-2">Descargar</span>
                        </Button>
                      </td>
                      <td className="text-center">
                        {moment(value.fecha, "YYYY-MM-DD").format("DD/MM/YYYY")}
                      </td>
                      <td>{value.descripcion}</td>
                      <td className="text-center">
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleClickSetElement(value)}
                        >
                          <i className="fas fa-trash"></i>
                        </Button>
                      </td>
                    </tr>
                  );
                })
              )}
            </>
          )}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={handleClose} backdrop="static" size="md">
        <Modal.Body className="p-4">
          <Form.Group as={Row}>
            <Form.Label column sm={12}>
              Descripcion
            </Form.Label>
            <Col sm={12}>
              <Form.Control
                as="textarea"
                id="txtDescription"
                rows="2"
                value={descriptionFile}
                onChange={onChangeDescription}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formPlaintextEmail">
            <Form.Label column sm={12}>
              Archivos
            </Form.Label>
            <Col sm={12}>
              <Form.Control type="file" onChange={selectFile} />
            </Col>
          </Form.Group>
          {ldAccion ? <Alert variant="success">Cargando...</Alert> : ""}
          {complet ? (
            <Alert variant="success">Cargado Correctamente.</Alert>
          ) : (
            ""
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Salir
          </Button>
          <Button
            variant="primary"
            disabled={btnDisabled}
            onClick={saveDocuments}
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modDelete}
        onHide={handleCloseModDelete}
        backdrop="static"
        size="md"
      >
        <Modal.Body className="p-4">
          <div>Se Necesita Confirmaci&oacute;n Para Eliminar.</div>
          {ldAccion ? <Alert variant="success">Cargando...</Alert> : ""}
          {complet ? (
            <Alert variant="success">Eliminado Correctamente.</Alert>
          ) : (
            ""
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModDelete}>
            Salir
          </Button>
          <Button variant="primary" onClick={handleDeletElement}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DocumentacionEmpresa;
