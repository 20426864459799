import React, { useState, useEffect } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import _ from "lodash";
import Select from 'react-select';
import { useMutation } from "@apollo/client";
import { ADD_EMPRESA_ENTIDAD_FINANCIERA, UPDATE_EMPRESA_ENTIDAD_FINANCIERA, DELETE_EMPRESA_ENTIDAD_FINANCIERA } from "../../utils/graphql_mutation";

const CrudEntidadFinanciera = (prop) => {
  const { crud, setCrud, refetch, idEmpresa, dataEntidades, loadEntidades } = prop;
  // STATE
  const [validate, setValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [entidadesFinancieras, setEntidadesFinancieras] = useState([]);
  const [cuenta, setCuenta] = useState({nro_cuenta: '', idEmpresa: '', entidad_financiera_id: '', selected:null});
  // MUTATIONS
  const [addEmpresasEntidadFinanciera] = useMutation(ADD_EMPRESA_ENTIDAD_FINANCIERA);
  const [updateEmpresasEntidadFinanciera] = useMutation(UPDATE_EMPRESA_ENTIDAD_FINANCIERA);
  const [deleteEmpresasEntidadFinanciera] = useMutation(DELETE_EMPRESA_ENTIDAD_FINANCIERA);
  // FUNCTION
  const handleChange = (e) => {
    const value = e.target.value.trim();
    setCuenta({
      ...cuenta,
      nro_cuenta: value === '' ? '' : isNaN(value) ? cuenta.nro_cuenta : _.toNumber(value),
    });
  };

  const handleChangeSelect = (newValue) => {
    setCuenta( {...cuenta, entidad_financiera_id:newValue.value, selected:newValue});
  };

  const handleRegistrar = async () => {
    if (_.isNumber(cuenta.nro_cuenta) && cuenta.selected) {
      setLoading(true);
      try {
        await addEmpresasEntidadFinanciera({
          variables: {
            empresa_id: parseInt(idEmpresa),
            entidad_financiera_id: cuenta.entidad_financiera_id,
            nro_cuenta: cuenta.nro_cuenta.toString(),
          },
        });
        await refetch();
        setCrud({ ...crud, show: false });
      } catch (error) {
        console.log(error);
      }
    } else {
      setValidate(true);
    }
  };
  const handleModificar = async () => {
    if (_.isNumber(cuenta.nro_cuenta)) {
      setLoading(true);
      try {
        await updateEmpresasEntidadFinanciera({
          variables: {
            id: parseInt(crud.cuenta.id),
            nro_cuenta: cuenta.nro_cuenta.toString(),
          },
        });
        await refetch();
        setCrud({ ...crud, show: false });
      } catch (error) {
        console.log(error);
      }
    } else {
      setValidate(true);
    }
  };
  const handleEliminar = async () => {
      setLoading(true);
      try {
        await deleteEmpresasEntidadFinanciera({
          variables: {
            id: parseInt(crud.cuenta.id),
          },
        });
        await refetch();
        setCrud({ ...crud, show: false });
      } catch (error) {
        console.log(error);
      }
  };

  const cargarValores = (entidades) => {
      const { nro_cuenta, empresa_id, entidad_financiera_id } = crud.cuenta;
      let entidadSelected = null
      if(entidadesFinancieras.length){
        entidadSelected= _.find(entidadesFinancieras, {'value':entidad_financiera_id})
      }
      if (entidades) {
        entidadSelected= _.find(entidades, {'value':entidad_financiera_id})
      }
      setCuenta({
        nro_cuenta: nro_cuenta ? nro_cuenta : "",
        empresa_id: empresa_id ? empresa_id : "",
        entidad_financiera_id: entidad_financiera_id ? entidad_financiera_id : "",
        selected: entidadSelected,
      });
  };

  // EFFECT
  useEffect(() => {
    if (crud && crud.type !== "create") {
      cargarValores();
    }
  }, [crud]);

  useEffect(() => {
    if(dataEntidades){
      let _entidades = _.map(dataEntidades.getAllEntidadFinanciera, (ef) => {
        return {
          value: ef.id,
          label: ef.nombre
        }
      })
      setEntidadesFinancieras(_entidades);
      if (crud && crud.type !== "create") {
        cargarValores(_entidades);
      }
    }
  }, [dataEntidades]);

  // RENDER
  let classNroCuenta = "";
  let classSelectEntidad = "";
  if (validate) {
    classNroCuenta = _.isNumber(cuenta.nro_cuenta) ? "is-valid" : "is-invalid";
    classSelectEntidad = cuenta.selected ? "is-valid" : "is-invalid";
  }

  return (
    <Modal show={true} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>{`${
          crud.type === "create" ? "Agregar" : "Editar"
        } Cuenta Bancaria`}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pl-4">
        <Form>
          <Form.Group>
            <Form.Label>Entidad Financiera</Form.Label>
            <Select
              onChange={handleChangeSelect}
              value={cuenta.selected}
              options={entidadesFinancieras}
              isDisabled={ crud.type !== "create" || loadEntidades}
              isLoading={loadEntidades}
              classNames={{
                valueContainer: () => `form-control ${classSelectEntidad}`,
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Número de cuenta</Form.Label>
            <Form.Control
              name="nro_cuenta"
              type="text"
              autoComplete="off"
              onChange={handleChange}
              disabled={ crud.type === "delete"}
              value={cuenta.nro_cuenta}
              className={classNroCuenta}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => setCrud({ ...crud, show: false })}
          disabled={loading}
        >
          Cancelar
        </Button>
        {crud.type === "create" && (
          <Button
            variant="primary"
            onClick={handleRegistrar}
            disabled={loading}
          >
            Registrar
          </Button>
        )}
        {crud.type === "update" && (
          <Button
            variant="primary"
            onClick={handleModificar}
            disabled={loading}
          >
            Modificar
          </Button>
        )}
        {crud.type === "delete" && (
          <Button
            variant="danger"
            onClick={handleEliminar}
            disabled={loading}
          >
            Eliminar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CrudEntidadFinanciera;