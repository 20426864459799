
import * as Yup from 'yup';

export const LOGIN_SCHEMA = Yup.object().shape({
  email: Yup
    .string()
    .email()
    .required(),
  password: Yup
    .string()
    .min(3)
    .max(15)
    .required(),
});

export const EMPLEADO_SCHEMA = Yup.object().shape({
  nombres: Yup
    .string()
    .required(),
  apellidos: Yup
    .string()
    .required(),
  email: Yup
    .string()
    .email(),
  interno: Yup
    .number(),
  area: Yup
    .number()
    .required(),
  cargo: Yup
    .number()
    .required(),
});

export const SUCURSAL_SCHEMA = Yup.object().shape({
  nombre: Yup
    .string()
    .required(),
  telefono: Yup
    .string()
    .required(),
  direccion: Yup
    .string()
    .required(),
});

