import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Form, Col, Table, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import ResultadoBusqueda from "../components/empresa/ResultadoBusqueda";
import Paginacion from "../components/empresa/Paginacion";
//import TablaEmpresas from "../components/empresa/TablaEmpresas";
import useCountries from "../hooks/useCountries";
import useCities from "../hooks/useCities";
import useStates from "../hooks/useStates";

const Empresa = () => {
  // STATE
  const [ciudades, setCiudades] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [input, setInput] = useState({
    nombre: "",
    pais: [],
    ciudad: [],
    departamento: [],
    pagina: 1,
    limite: 20,
  });
  // CUSTOM
  const { loading, response: countries } = useCountries();
  const [getCities, { loading: loadCities, data: dataCities }] = useCities();
  const [getStates, { loading: loadStates, data: dataStates }] = useStates();
  // FUNCTION
  const handleChangePais = async (e) => {
    setInput({ ...input, pais: e, pagina: 1 });

    if (_.size(e) > 0) {
      getCities({ countryId: e[0].id });
      getStates({ countryId: e[0].id });
    } else {
      setCiudades([]);
      setDepartamentos([]);
    }
    setInput({ ...input, pais: e, departamento: [], ciudad: [], pagina: 1 });
  };

  const handleChangePagina = (newPage) => {
    setInput({ ...input, pagina: newPage });
  };

  const handleChangeLimite = (newLimit) => {
    setInput({ ...input, limite: newLimit });
  };

  const handleChangeDepartamento = async (e) => {
    setInput({ ...input, departamento: e, pagina: 1 });

    if (_.size(e) > 0) {
      getCities({ stateId: e[0].id });
    } else {
      setCiudades([]);
      getCities({ countryId: input.pais[0].id });
    }
    setInput({ ...input, departamento: e, ciudad: [], pagina: 1 });
  };
  // EFFECT
  useEffect(() => {
    setCiudades(dataCities);
  }, [dataCities]);

  useEffect(() => {
    setDepartamentos(dataStates);
  }, [dataStates]);
  // RENDER
  if (loading) {
    return `Cargando...`;
  }
  return (
    <>
      <h3 className="mb-0">Buscar Empresas</h3>
      <hr />
      <Form className="mb-1">
        <Row>
          <Form.Group as={Col} sm={4} className="mb-0">
            <Form.Label>Empresa o Rubro</Form.Label>
            <Form.Control
              name="nombre"
              type="text"
              autoComplete="off"
              value={input.nombre}
              onChange={(e) =>
                setInput({
                  ...input,
                  [e.target.name]: e.target.value,
                  pagina: 1,
                })
              }
            />
          </Form.Group>
          <Form.Group as={Col} sm={2} className="mb-0">
            <Form.Label>Pais</Form.Label>
            <Typeahead
              id="pais"
              name="pais"
              labelKey="name"
              options={countries}
              selected={input.pais}
              onChange={handleChangePais}
            />
          </Form.Group>
          <Form.Group as={Col} sm={3} className="mb-0">
            <Form.Label>Departamento</Form.Label>
            <Typeahead
              id="departamento"
              name="departamento"
              labelKey="name"
              options={departamentos}
              selected={input.departamento}
              placeholder={loadStates ? "Cargando..." : ""}
              disabled={loadStates}
              onChange={handleChangeDepartamento}
            />
          </Form.Group>
          <Form.Group as={Col} sm={3} className="mb-0">
            <Form.Label>Ciudad</Form.Label>
            <Typeahead
              id="ciudad"
              name="ciudad"
              labelKey="name"
              onChange={(e) => setInput({ ...input, ciudad: e, pagina: 1 })}
              options={ciudades}
              inputProps={{ autoComplete: "off" }}
              selected={input.ciudad}
              placeholder={loadCities ? "Cargando..." : ""}
              disabled={loadCities}
            />
          </Form.Group>
        </Row>
      </Form>

      <Row>
        <Paginacion
          currentPage={input.pagina}
          changePagina={handleChangePagina}
          pageSize={input.limite}
          changeSize={handleChangeLimite}
        />
      </Row>

      <Table
        striped
        bordered
        hover
        className="shadow p-0 mt-1 bg-white rounded"
      >
        <thead>
          <tr className="text-center">
            <th>Empresa</th>
            <th>Rubro</th>
            <th>Pais</th>
            <th>Departamento</th>
            <th>Ciudad</th>
            <th></th>
          </tr>
        </thead>
        <ResultadoBusqueda
          nombre={input.nombre}
          pais={input.pais}
          ciudad={input.ciudad}
          departamento={input.departamento}
          limite={input.limite}
          pagina={input.pagina}
        />
      </Table>
    </>
  );
};

export default Empresa;
