import React, { useState, useEffect } from "react";
import {
  Container,
  ListGroup,
  Button,
  Modal,
  Form,
  Row,
  Col,
  Table,
  Image
} from "react-bootstrap";
import {
  EDIT_ADJUNTO_SERVICIO,
  DELETE_ADJUNTO_SERVICIO,
  ADD_ADJUNTO_SERVICIO,
  UPDATE_ESTADO_SERVICIO
} from "./../../utils/graphql_mutation";
import ButtonIcon from "../../components/base/ButtonIcon";
import { useMutation } from "@apollo/client";
import _ from "lodash";
import {
  uploadingAwsS3,
  getCountryById,
  getStatesById,
  getCityById,
} from "../../utils/helpers";
import { SERVICE_EMPRESA_FIND_ID } from "../../utils/graphql_query";
import { useQuery } from "@apollo/client";
import CrudServicio from "../servicio/CrudServicio";
import useCities from "../../hooks/useCities";

export const ShowServicio = ({ servicioId, setShowAccion }) => {
  // VARIABLE
  const initialState = {
    servicio: {},
  };
  // STATE
  const [modDelete, setModDelete] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [modEditImg, setModEditImg] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState("");
  const [modEditAdjunto, setModEditAdjunto] = useState(false);
  const [selectedService, setSelectedService] = useState({});
  const [verificadorAdjunto, setVerificadorAdjunto] = useState(false);
  const [state, setState] = useState(initialState.servicio);
  const [countryStateCity, setCountryStateCity] = useState({
    country: [],
    state: [],
    city: [],
  });
  const [adjuntoServicio, setAdjuntoServicio] = useState({ img: [], doc: [] });
  // QUERY
  const {
    loading: loadingQuery,
    data: dataQuery,
    refetch: refetchQuery,
  } = useQuery(SERVICE_EMPRESA_FIND_ID, {
    variables: { id: servicioId },
  });
  // MUTATION
  const [editAdjuntoServicio] = useMutation(EDIT_ADJUNTO_SERVICIO);
  const [deleteAdjuntoServicio] = useMutation(DELETE_ADJUNTO_SERVICIO);
  const [addAdjuntoServicio] = useMutation(ADD_ADJUNTO_SERVICIO);
  const [editEstadoServicio, { loading: loadingEditEstadoServicio }] = useMutation(UPDATE_ESTADO_SERVICIO);
  // CUSTOM HOOKS
  const [getCities, { loading: loadCities, data: dataCities }] = useCities();
  // FUNCTION
  const selectFileService = (event) => {
    var extensionesValidas = ".png, .jpeg, .jpg";
    var target = event.target;
    var ruta = target.files[0].name;
    var extension = ruta.substring(ruta.lastIndexOf(".") + 1).toLowerCase();
    var extensionValida = extensionesValidas.indexOf(extension);
    if (target.files.length !== 0) {
      if (extensionValida !== -1) {
        document.getElementById("lbDocImgAlert").style = "display:none";
      } else {
        target.value = "";
        alert("Solo se admiten imagenes de tipo " + extensionesValidas);
      }
    }
    setSelectedFiles(event.target.files[0]);
  };

  const updateEstadoServicio = async (newEstado) =>{
    await editEstadoServicio({ variables: {
      id: state.id,
      estado: newEstado
    } });
    refetchQuery();
  }

  const updateServiceFile = async () => {
    var can_save = true;
    var element = document.getElementById("inputFileAdImg");
    if (element.files.length === 0) {
      document.getElementById("lbDocImgAlert").style = "display:block";
      document.getElementById("lbDocImgAlert").style = "color:#dc3545";
      can_save = false;
    }
    if (can_save) {
      try {
        setBtnLoading(true);
        let nameFile = "";
        if (_.size(adjuntoServicio.img) > 0) {
          // EDITAR
          const {
            data: { editAdjuntoServicio: resulteditAdjuntoServicio },
          } = await editAdjuntoServicio({
            variables: {
              id: adjuntoServicio.img[0].id,
              nombre: selectedFiles.name,
            },
          });
          nameFile = resulteditAdjuntoServicio.nombre_descargar;
        } else {
          // AGREGAR
          const {
            data: { addAdjuntoServicio: resultaddAdjuntoServicio },
          } = await addAdjuntoServicio({
            variables: {
              tipo: "img",
              nombre: selectedFiles.name,
              descripcion: "",
              servicio_id: state.id,
            },
          });
          nameFile = resultaddAdjuntoServicio.nombre_descargar;
        }

        const resultPortada = await uploadingAwsS3(
          selectedFiles,
          "servicio",
          nameFile
        );
        if (resultPortada.success) {
          refetchQuery();
          setModEditImg(false);
        }
        setBtnLoading(false);
      } catch (error) {
        setBtnLoading(false);
        console.log("FAILURE!!");
        console.log(error);
      }
    }
  };

  const saveAdjuntoService = async () => {
    if (verificadorAdjunto) {
      setVerificadorAdjunto(true);
    }
    setBtnLoading(true);
    var adjuntoDoc = document.getElementById("adjuntoFileDoc");
    var descripcion = document.getElementById("adjuntoDescriptionDoc");
    var can_save = true;
    if (adjuntoDoc.files.length === 0) {
      var lbAlert = document.getElementById("lbDocAlert");
      lbAlert.style = "display:block";
      lbAlert.style = "color:#dc3545";
      can_save = false;
    }

    if (can_save) {
      try {
        const fileInput = adjuntoDoc.files[0];
        const {
          data: { addAdjuntoServicio: resultaddAdjuntoServicio },
        } = await addAdjuntoServicio({
          variables: {
            tipo: "doc",
            nombre: fileInput.name,
            descripcion: _.trim(descripcion.value),
            servicio_id: state.id,
          },
        });
        // S3
        const nameFile = resultaddAdjuntoServicio.nombre_descargar;
        const resultDoc = await uploadingAwsS3(fileInput, "servicio", nameFile);
        if (!resultDoc.success) {
          await deleteAdjuntoServicio({
            variables: { id: resultaddAdjuntoServicio.id },
          });
        } else {
          refetchQuery();
          setModEditAdjunto(false);
        }
        setBtnLoading(false);
      } catch (error) {
        setBtnLoading(false);
        console.log(error);
      }
    }
  };
  const deleteAdjuntoService = async () => {
    setBtnLoading(true);
    const resultDelete = await deleteAdjuntoServicio({
      variables: { id: selectedService.id },
    });
    if (resultDelete) {
      refetchQuery();
      setModDelete(false);
    }
    setBtnLoading(false);
  };
  const handleSelectedService = (adjunto) => {
    setSelectedService(adjunto);
    setModDelete(true);
  };
  const handleVerificarAdjunto = (e) => {
    var target = e.target;
    var type = target.type;
    if (type === "file" && target.files.length !== 0) {
      if (target.files[0].type === "application/pdf") {
        document.getElementById("lbDocAlert").style = "display:none";
      } else {
        target.value = "";
        alert("Solo se aceptan archivo de tipo PDF");
      }
    }
  };
  const onError = (e) => {
    if (e.target.onerror === null) e.target.src = "../not-available.png";
  };
  const renderAdjunto = (nameFile) => {
    if (!nameFile) {
      return null;
    }
    const folderTest = process.env.REACT_APP_AWS_S3_FOLDER_TEST
      ? process.env.REACT_APP_AWS_S3_FOLDER_TEST
      : "";
    return `${process.env.REACT_APP_AWS_S3_URL}/servicio${folderTest}/${nameFile}`;
  };
  const handleEditDetail = () => {
    setShowModal(true);
    if (_.size(countryStateCity.country) > 0) {
      let newVal = { countryId: countryStateCity.country[0].id };
      if (_.size(countryStateCity.state) > 0) {
        newVal = { stateId: countryStateCity.state[0].id };
      }
      getCities(newVal);
    }
  };

  // EFFECT
  useEffect(() => {
    if (dataQuery) {
      const dataServicioQuery = dataQuery.servicio_empresa;
      setState(dataServicioQuery);
      // COUNTRY, STATE, CITY
      if (dataServicioQuery.pais_id) {
        const country = getCountryById(dataServicioQuery.pais_id);
        let auxCSC = { country: [country], state: [], city: [] };
        // Departamento
        if (dataServicioQuery.departamento_id) {
          const staties = getStatesById(dataServicioQuery.departamento_id);
          auxCSC = { ...auxCSC, state: [staties] };
        }
        // Ciudad
        if (dataServicioQuery.ciudad_id) {
          const cities = dataServicioQuery.city
            ? dataServicioQuery.city
            : getCityById(dataServicioQuery.ciudad_id);
          auxCSC = { ...auxCSC, city: [cities] };
        }
        setCountryStateCity(auxCSC);
      }
      // ADJUNTO SERVICIO
      if (dataServicioQuery.adjuntos) {
        let arrImg = [];
        let arrDoc = [];
        _.map(dataServicioQuery.adjuntos, (adj) => {
          if (adj.tipo === "img") {
            arrImg = _.concat(arrImg, adj);
          }
          if (adj.tipo === "doc") {
            arrDoc = _.concat(arrDoc, adj);
          }
        });
        setAdjuntoServicio({ img: arrImg, doc: arrDoc });
      }
    }
  }, [dataQuery]);
  // RENDER
  if (loadingQuery) {
    return "Cargando...";
  }
  const btnDisabled = btnLoading ? true : false;
  const styleVolver = { width: "100px", height:'37px' };
  return (
    <>
      <div className="d-flex justify-content-between">
        <Button
          className="mb-3"
          style={styleVolver}
          onClick={() => setShowAccion("listar_servicio")}
        >
          <i className="fas fa-chevron-left"></i> Atras
        </Button>
        <div className="d-flex flex-column text-center">
          <Form>
            <Form.Check
              type="switch"
              id="active-switch"
              disabled={loadingEditEstadoServicio}
              checked={state.estado}
              onClick={() => updateEstadoServicio(state.estado ? 0 : 1)}
              style={{transform: 'scale(1.6)'}}
            />
          </Form>
          <span className="pr-2">
            {state.estado 
              ? "Activo"
              : "Inactivo"}
          </span>
        </div>
      </div>
      <h5>Imagen de Portada</h5>
      <Container className="shadow p-4 bg-white rounded mb-2">
        <div className="card">
          <div className="row no-gutters">
            <div
              className="col-md-12 text-center"
              style={{ background: "#f2f2f2" }}
            >
              {_.size(adjuntoServicio.img) > 0 ? (
                <Image
                  src={renderAdjunto(adjuntoServicio.img[0].nombre_descargar)}
                  onError={(e) => onError(e)}
                  style={{ height: "400px" }}
                  fluid
                />
              ) : (
                <Image
                  src={`${process.env.PUBLIC_URL}/not-available.png`}
                  onError={(e) => onError(e)}
                  style={{ height: "400px" }}
                  fluid
                />
              )}
            </div>
          </div>
        </div>
      </Container>

      <Container className="text-end mb-3 p-0">
        <Button onClick={() => setModEditImg(true)} className="mb-3">
          Editar
        </Button>
      </Container>

      <label style={{ fontSize: "1.25rem", fontWeight: "500" }}>Detalles</label>

      <Container className="shadow p-4 bg-white rounded mb-2">
        <ListGroup variant="flush">
          <ListGroup.Item>
            <Row>
              <Col sm="6" className="my-auto">
                <b>Titulo</b>
                <h6 className="text-secondary mt-2">{state.titulo}</h6>
              </Col>
              <Col sm="6" className="my-auto">
                <b>Precio Aprox.</b>
                <h6 className="text-secondary mt-2">
                  {`${_.round(Number(state.precio), 2)} ${_.toUpper(
                    state.moneda
                  )}`}
                </h6>
              </Col>
            </Row>
          </ListGroup.Item>

          <ListGroup.Item>
            <b>Descripcion</b>
            <h6 className="text-secondary mt-2">{state.descripcion}</h6>
          </ListGroup.Item>

          <ListGroup.Item>
            <Row>
              <Col sm="4" className="my-auto">
                <b>Pais</b>
                <h6 className="text-secondary mt-2">
                  {_.size(countryStateCity.country) > 0 &&
                    countryStateCity.country[0].name}
                </h6>
              </Col>

              {_.size(countryStateCity.state) > 0 && (
                <Col sm="4" className="my-auto">
                  <b>Departamento</b>
                  <h6 className="text-secondary mt-2">
                    {countryStateCity.state[0].name}
                  </h6>
                </Col>
              )}

              {_.size(countryStateCity.city) > 0 && (
                <Col sm="4" className="my-auto">
                  <b>Ciudad</b>
                  <h6 className="text-secondary mt-2">
                    {countryStateCity.city[0].name}
                  </h6>
                </Col>
              )}
            </Row>
          </ListGroup.Item>

          <ListGroup.Item>
            <b>Atracciones Turisticas</b>
            <h6 className="text-secondary mt-2">
              {_.map(state.atracciones, (val, ka) => {
                return ka === 0 ? (
                  val.nombre
                ) : (
                  <span key={ka}>
                    <b className="ms-2 me-2"> / </b>
                    {val.nombre}
                  </span>
                );
              })}
            </h6>
          </ListGroup.Item>

          <ListGroup.Item>
            <b>Nro de Dias</b>
            <h6 className="text-secondary mt-2">{state.nro_dias}</h6>
          </ListGroup.Item>
        </ListGroup>
      </Container>

      <Container className="text-end mb-3 p-0">
        <Button onClick={handleEditDetail} className="mb-3">
          Editar
        </Button>
      </Container>

      <h5>Adjuntos</h5>
      <Table striped bordered hover className="shadow bg-white">
        <thead>
          <tr className="text-center">
            <th>Nombre</th>
            <th>Tipo</th>
            <th>Descripcion</th>
            <th>Acci&oacute;n</th>
          </tr>
        </thead>
        <tbody>
          {state.adjuntos ? (
            _.map(state.adjuntos, (val, i) => {
              return (
                val.tipo !== "img" && (
                  <tr key={i}>
                    <td>{val.nombre_archivo}</td>
                    <td>{val.tipo}</td>
                    <td>{val.descripcion}</td>
                    <td className="text-center">
                      <ButtonIcon
                        size="sm"
                        titulo={"Descargar"}
                        icono={"fa fa-download"}
                        variant={"primary"}
                        onClick={() =>
                          window.open(
                            renderAdjunto(val.nombre_descargar),
                            "_blank"
                          )
                        }
                      />

                      {_.size(adjuntoServicio.doc) > 1 && (
                        <ButtonIcon
                          size="sm"
                          className="ms-1"
                          titulo={"Eliminar"}
                          icono={"fa fa-trash"}
                          variant={"danger"}
                          onClick={() => handleSelectedService(val)}
                        />
                      )}
                    </td>
                  </tr>
                )
              );
            })
          ) : (
            <tr>
              <td colSpan="4" className="text-center">
                No existen adjuntos registrados.
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <Container className="text-end mb-3 p-0">
        <Button onClick={() => setModEditAdjunto(true)} className="mb-3">
          Agregar
        </Button>
      </Container>

      {showModal && (
        <CrudServicio
          servicio={state}
          selectCountryStateCity={countryStateCity}
          onClose={() => setShowModal(false)}
          onRefresh={refetchQuery}
          dataCities={dataCities}
          loadCities={loadCities}
          getCities={getCities}
        />
      )}

      <Modal
        show={modDelete}
        onHide={() => setModDelete(false)}
        backdrop="static"
        size="md"
      >
        <Modal.Body className="p-4">
          <div>Se Necesita Confirmaci&oacute;n Para Eliminar.</div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModDelete(false)}>
            Salir
          </Button>
          <Button
            variant="primary"
            disabled={btnDisabled}
            onClick={() => deleteAdjuntoService()}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modEditImg}
        onHide={() => setModEditImg(false)}
        backdrop="static"
        size="md"
      >
        <Modal.Header className="h4">Editar - Imagen</Modal.Header>
        <Modal.Body className="p-4">
          <Form.Group as={Row} controlId="formPlaintextEmail">
            <Col sm={12}>
              <Form.Control
                type="file"
                accept="image/*"
                id="inputFileAdImg"
                onChange={selectFileService}
              />
            </Col>
            <Form.Label
              column
              sm={12}
              id="lbDocImgAlert"
              style={{ display: "none", color: "#dc3545" }}
            >
              <strong>Campo requerido.</strong>
            </Form.Label>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            disabled={btnDisabled}
            onClick={() => setModEditImg(false)}
          >
            Salir
          </Button>
          <Button
            variant="primary"
            disabled={btnDisabled}
            onClick={updateServiceFile}
          >
            Modificar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modEditAdjunto}
        onHide={() => setModEditAdjunto(false)}
        backdrop="static"
        size="md"
      >
        <Modal.Header className="h4">Agregar Adjunto</Modal.Header>
        <Modal.Body className="p-4">
          <Row className="mb-5">
            <Col sm="12" className="mb-3">
              <Form.Label>Descripci&oacute;n</Form.Label>

              <textarea
                type="text"
                name="adjuntoDescriptionDoc"
                id="adjuntoDescriptionDoc"
                autoComplete="off"
                onChange={(e) => {
                  handleVerificarAdjunto(e);
                }}
                className="form-control form-control-sm"
              />
            </Col>
            <Col sm="12" className="mb-3">
              <Form.Label>Adjunto Archivo</Form.Label>

              <Form.Control
                type="file"
                id="adjuntoFileDoc"
                name="adjuntoFileDoc"
                onChange={(e) => {
                  handleVerificarAdjunto(e);
                }}
                accept="application/pdf"
              />

              <Form.Label
                id="lbDocAlert"
                style={{ display: "none", color: "#dc3545" }}
              >
                <strong>Campo requerido.</strong>
              </Form.Label>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            disabled={btnDisabled}
            onClick={() => setModEditAdjunto(false)}
          >
            Salir
          </Button>
          <Button
            variant="primary"
            disabled={btnDisabled}
            onClick={() => saveAdjuntoService()}
          >
            Registrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ShowServicio;
