import React, { useState } from 'react'
import { Button, Col, Container, ListGroup, Row } from 'react-bootstrap';
import { getLogin } from '../utils/authentication';
import { GET_USUARIO} from '../utils/graphql_query'
import { useQuery } from '@apollo/client';
import EditarContrasenia from '../components/usuario/EditarContrasenia';
import Errors from '../components/base/Errors';
import LoadingPage from '../components/base/LoadingPage';

const PerfilUsuario = () => {
  const user = getLogin();
  
  const [crud, setCrud] = useState({ show: false, type: '', usuario: {} })

  const { loading, error, data } = useQuery(GET_USUARIO, { variables: { id: user.id }} );

  if (loading) return <LoadingPage />;
  if (error) return <Errors error={error}/>;
  if (!data.getUsuario) return 'No existe informacion';

  const usuario = data.getUsuario
  return (
    <>
      <h3 className="mb-0">Perfil de Usuario</h3>
      <hr />

      <h5 className="">Informacion Basica</h5>
      <Container className="shadow p-4 bg-white rounded mb-5">
        <ListGroup variant="flush">
          <ListGroup.Item>
            <Row>
              <Col sm="4" className="font-weight-bold">Nombres : </Col>
              <Col sm="4">{usuario.persona.nombres}</Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row>
              <Col sm="4" className="font-weight-bold">Apellidos : </Col>
              <Col sm="4">{usuario.persona.apellidos}</Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row>
              <Col sm="4" className="font-weight-bold">Genero : </Col>
              <Col sm="4">{usuario.persona.genero === "M" ? "Hombre" : "Mujer"}</Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row>
              <Col sm="4" className="font-weight-bold">Fecha Nacimiento : </Col>
              <Col sm="4">{usuario.persona.fecha_nacimiento}</Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
      </Container>

      <h5 className="">Datos de Accesos</h5>
      <Container className="shadow p-4 bg-white rounded mb-5">
        <ListGroup variant="flush">
          {
            usuario.empresa &&
            <ListGroup.Item>
              <Row>
                <Col sm="4" className="font-weight-bold">Empresa : </Col>
                <Col sm="4">{usuario.empresa.nombre}</Col>
              </Row>
            </ListGroup.Item>
          }
          <ListGroup.Item>
            <Row>
              <Col sm="4" className="font-weight-bold">Usuario : </Col>
              <Col sm="4">{usuario.email}</Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row>
              <Col sm="4" className="font-weight-bold">Contraseña : </Col>
              <Col sm="4">
                <Button size="sm" variant="outline-primary" onClick={() => setCrud({ ...crud, show: true, usuario: usuario})}>
                  <i className="fa fa-pen me-2"></i>Modificar
                </Button>
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
      </Container>

      {
        crud.show &&
        <EditarContrasenia
          crud={crud}
          setCrud={setCrud}
        />
      }
      

    </>
  )
}

export default PerfilUsuario
