import React from "react";
import _ from "lodash";
//import Pagination from 'react-bootstrap/Pagination';
import Select from 'react-select';

function Paginacion({currentPage, changePagina, pageSize, changeSize}) {

  const options = [
    { value: 20, label: '20' },
    { value: 40, label: '40' },
    { value: 0, label: 'Todos' }
  ]
  

  return (
    <div className="d-flex  justify-content-end align-items-baseline">
      <span>Filas por Página</span>
      <Select
      
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            width:'100px',
          }),
        }}
        className="m-2"
        onChange={(newValue) => changeSize(newValue.value)}
        value={_.find(options, {'value':pageSize})}
        options={options}
      />
      {/* <Pagination >
        <Pagination.First disabled={currentPage <= 1} onClick={() => changePagina(1)}  />
        <Pagination.Prev disabled={currentPage <= 1} onClick={() => changePagina(currentPage-1)} />

        {currentPage-2 > 0 && <Pagination.Item onClick={() => changePagina(currentPage-2)}>{currentPage -2}</Pagination.Item>}
        {currentPage-1 > 0 && <Pagination.Item onClick={() => changePagina(currentPage-1)}>{currentPage -1}</Pagination.Item>}
        <Pagination.Item active>{currentPage}</Pagination.Item>
        <Pagination.Item onClick={() => changePagina(currentPage+1)}>{currentPage+1}</Pagination.Item>
        <Pagination.Item onClick={() => changePagina(currentPage+2)}>{currentPage+2}</Pagination.Item>

        <Pagination.Next onClick={() => changePagina(currentPage+1) />
        <Pagination.Last onClick={() => changePagina(currentPage+lasPage) /> *****Falta LastPage*****
      </Pagination> */}
    </div>
  );
}

export default Paginacion;