import { gql } from "@apollo/client";

export const LOGIN_USUARIO = gql`
  query LoginUsuario($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      nombre
      email
    }
  }
`;

export const BUSCAR_EMPRESA = gql`
  query BuscarEmpresa(
    $nombre: String
    $pais_id: Int
    $departamento_id: Int
    $ciudad_id: Int,
    $limite: Int,
    $pagina: Int
  ) {
    buscar_empresa(
      nombre: $nombre
      pais_id: $pais_id
      departamento_id: $departamento_id
      ciudad_id: $ciudad_id,
      limite: $limite,
      pagina: $pagina
    ) {
      id
      nombre
      razon_social
      nit
      telefono
      direccion
      pais_id
      ciudad_id
      provincia_id
      departamento_id
      city {
        id
        name
        state_id
        country_id
        province_id
      }
      rubro {
        id
        nombre
      }
    }
  }
`;
// export const BUSCAR_EMPRESA = gql`
//   query BuscarEmpresa($nombre: String, $pais_id: Int, $ciudad_id: Int) {
//     buscar_empresa(nombre: $nombre, pais_id: $pais_id, ciudad_id: $ciudad_id) {
//       id
//       nombre
//       razon_social
//       nit
//       telefono
//       direccion
//       rubro {
//         id
//         nombre
//       }
//       pais {
//         id
//         nombre_pais
//       }
//       ciudad {
//         id
//         nombre_ciudad
//       }
//     }
//   }
// `;

export const EMPRESA = gql`
  query Empresa($id: Int!) {
    empresa(id: $id) {
      id
      nombre
      razon_social
      nit
      estado
      pais_id
      ciudad_id
      city {
        id
        name
        state_id
        country_id
        province_id
      }
      nombre_logo
      telefono
      whatsapp
      direccion
      email
      booking_id
      rubro {
        id
        nombre
      }
      sucursales {
        id
        nombre
        telefono
        direccion
        ciudad_id
        city {
          id
          name
          state_id
          country_id
          province_id
        }
        empleados {
          id
          nombre_completo
          email
          interno
          ciudad_id
          visible
          cargo {
            id
            nombre
          }
          area {
            id
            nombre
          }
          persona {
            id
            nombres
            apellidos
          }
        }
      }
      web_oficial
      descripcion
      contacto_general {
        id
        nombre
        telefono
        email
        chat
        visible
        dpto_area
        comentario
      }
    }
    areas(nombre: "") {
      id
      nombre
    }
    cargos(nombre: "") {
      id
      nombre
    }
  }
`;

export const AREAS = gql`
  query Areas($nombre: String!) {
    areas(nombre: $nombre) {
      id
      nombre
    }
  }
`;

export const CARGOS = gql`
  query Cargos($nombre: String!) {
    cargos(nombre: $nombre) {
      id
      nombre
    }
  }
`;

export const RUBROS = gql`
  query Rubros($nombre: String!) {
    rubros(nombre: $nombre) {
      id
      nombre
    }
  }
`;

export const OBTENER_DEPARTAMENTOS = gql`
  query Departamento {
    departamentos {
      id
      nombre
    }
  }
`;

export const OBTENER_CIUDAD = gql`
  query Ciudad($departamento_id: Int!, $nombre: String) {
    ciudades(departamento_id: $departamento_id, nombre: $nombre) {
      id
      nombre
      departamento_id
    }
  }
`;

export const RUBRO_AEREA_CARGO_PAIS_MEDIODECONTACTO = gql`
  query {
    rubros(nombre: "") {
      id
      nombre
    }
    areas(nombre: "") {
      id
      nombre
    }
    cargos(nombre: "") {
      id
      nombre
    }
    medio_de_contacto_all {
      id
      nombre
      icon
    }
  }
`;

export const OBTENER_PAISES = gql`
  query {
    paises {
      id
      nombre_pais
    }
  }
`;

export const OBTENER_CIUDADES = gql`
  query ($pais_id: Int!) {
    ciudadesPorPais(pais_id: $pais_id) {
      id
      nombre_ciudad
      provincia {
        id
        nombre
        departamento {
          id
          nombre
        }
      }
    }
  }
`;

// export const RUBROS_Y_PAISES = gql`
//   query {
//     rubros(nombre: ""){
//       id
//       nombre
//     },
//       paisesAll{
//       id
//       nombre_pais
//     }
//   }`;
export const RUBROS_Y_PAISES = gql`
  query {
    rubros(nombre: "") {
      id
      nombre
    }
  }
`;

export const PAISES = gql`
  query {
    paisesAll {
      id
      nombre_pais
    }
  }
`;

export const ADJUNTO_EMPRESA = gql`
  query AdjuntoEmpresa($empresa_id: Int!) {
    adjuntos_empresa(empresa_id: $empresa_id) {
      id
      nombre_descargar
      descripcion
      fecha
      disponible
      empresa_id
      nombre_archivo
    }
  }
`;

export const GALERIA_EMPRESA = gql`
  query GaleriaEmpresa($empresa_id: Int!) {
    galeria_empresa(empresa_id: $empresa_id) {
      id,
      nombre_imagen,
      fecha,
      empresa_id,
      nombre_descargar,
      estado
    }
  }
`;

export const DATA_SERVICIO_METODO_PAGO_TIPO_ALOJA = gql`
  query {
    servicios_alojamiento {
      id
      nombre
      tipo_servicio
    }
    tipo_pagos {
      id
      nombre
    }
    tipo_alojamientos {
      id
      nombre
    }
  }
`;

export const INFO_HOTEL = gql`
  query Alojamiento($empresa_id: Int!) {
    alojamiento(empresa_id: $empresa_id) {
      id
      categoria
      fecha_construccion
      numero_pisos
      nro_single
      nro_doble
      nro_triple
      nro_cuadruple
      nro_matrimonial
      nro_suite
      tipos_pago {
        id
        nombre
      }
      tipo_alojamiento {
        id
        nombre
      }
      puntos_interes {
        id
        lugar
        distancia
      }
      servicios_alojamiento {
        id
        nombre
        tipo_servicio
      }
      habitaciones {
        id
        tipo_habitacion
        camas
        precio
        moneda
        descripcion
        fecha_inicio_temp
        fecha_fin_temp
        alojamiento_id
      }
    }
    servicios_alojamiento_all {
      id
      nombre
      tipo_servicio
    }
    tipo_alojamientos {
      id
      nombre
    }
    tipo_pagos {
      id
      nombre
    }
  }
`;

export const SERVICE_EMPRESA = gql`
  query Search_Service($empresa_id: Int!, $filter: String!, $pais_id: Int!, $estado: Int) {
    search_servicio_empresa(
      empresa_id: $empresa_id
      filter: $filter
      pais_id: $pais_id
      estado: $estado
    ) {
      id
      titulo
      descripcion
      moneda
      precio
      estado
      pais_id
      departamento_id
      ciudad_id
      atracciones {
        id
        nombre
      }
    }
  }
`;

export const SERVICE_EMPRESA_FIND_ID = gql`
  query servicio_empresa($id: Int!) {
    servicio_empresa(id: $id) {
      id
      titulo
      descripcion
      moneda
      precio
      estado
      pais_id
      departamento_id
      ciudad_id
      city {
        id
        name
        state_id
        country_id
        province_id
      }
      nro_dias
      adjuntos {
        id
        tipo
        nombre_archivo
        descripcion
        nombre_descargar
      }
      atracciones {
        id
        nombre
        pais_id
        departamento_id
        ciudad_id
      }
      localidad {
        id
        nombre
      }
    }
  }
`;

export const GET_USUARIO = gql`
  query getUsuario($id: Int!) {
    getUsuario(id: $id) {
      id
      nombre
      email
      empresa {
        id
        nombre
      }
      persona {
        id
        nombres
        apellidos
        fecha_nacimiento
        genero
      }
    }
  }
`;

export const GET_INVITACIONES = gql`
  query Invitaciones($nombre: String!) {
    invitaciones(nombre: $nombre) {
      id
      nombre
      fecha
      codigo
      vigente
      descripcion
    }
  }
`;

export const GET_INVITACION = gql`
  query ($codigo: String!) {
    invitacion(codigo: $codigo) {
      id
      nombre
      fecha
      codigo
      vigente
      descripcion
    }
  }
`;

export const SEARCH_TAG = gql`
  query ($tipo: String!, $descripcion: String!) {
    search_tag(tipo: $tipo, descripcion: $descripcion) {
      id
      tipo
      descripcion
    }
  }
`;

export const GET_USUARIO_BY_EMPRESA = gql`
  query ($empresa_id: Int!) {
    getUsuarioByEmpresa(empresa_id: $empresa_id) {
      id
      nombre
      email
      username
      is_active
      persona {
        id
        nombres
        apellidos
      }
    }
  }
`;

export const GET_ATRACCIONES = gql`
  query ($nombre: String) {
    atracciones(nombre: $nombre) {
      id
      nombre
      pais_id
      departamento_id
      ciudad_id
    }
  }
`;
export const BUSCAR_EMPRESA_VER_CONTACTO = gql`
  query ($nombre: String, $empresaId: Int) {
    buscar_empresa_ver_contacto(nombre: $nombre, empresaId: $empresaId) {
      id
      nombre
    }
  }
`;

export const GET_CONTACTO_GENERAL_VER_EMPRESA = gql`
  query ($id: Int!) {
    contacto_general(id: $id) {
      id
      nombre
      ver_empresas {
        id
        nombre
      }
    }
  }
`;

export const GET_EMPLEADO_VER_EMPRESA = gql`
  query ($id: Int!) {
    empleado(id: $id) {
      id
      nombre_completo
      ver_empresas {
        id
        nombre
      }
    }
  }
`;

export const GET_GRUPO_EMPRESA_VER_CONTACTO = gql`
  query ($empresa_id: Int!) {
    getGrupoEmpresaVerContactoByEmpresaId(empresa_id: $empresa_id) {
      id
      nombre
      grupo_empresa_detalle {
        id
        nombre
      }
    }
  }
`;

export const GET_LOCALIDAD = gql`
  query (
    $nombre: String!
    $pais_id: Int
    $departamento_id: Int
    $ciudad_id: Int
  ) {
    getLocalidad(
      nombre: $nombre
      pais_id: $pais_id
      departamento_id: $departamento_id
      ciudad_id: $ciudad_id
    ) {
      id
      nombre
      pais_id
      departamento_id
      ciudad_id
    }
  }
`;

export const GET_ALL_ENTIDAD_FINANCIERA = gql`
  query{
   getAllEntidadFinanciera {
    id,
    nombre,
    codigo
  }
}
`;

export const GET_ENTIDAD_FINANCIERA_BY_EMPRESA = gql`
  query ($empresa_id: Int!) {
    getEntidadFinancieraByEmpresa(empresa_id: $empresa_id) {
      id,
      nro_cuenta,
      empresa_id,
      entidad_financiera_id
      empresa{
        id,
        nombre
      },
      entidad_financiera{
        id,
        nombre,
        codigo
      }
    }
  }
`;
