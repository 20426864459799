import React, { useState, useEffect } from "react";
import { Modal, ListGroup, Row, Col } from "react-bootstrap";
import ContainerNotResult from "../base/ContainerNotResult";
import LoadingPage from "../base/LoadingPage";
import { GET_GRUPO_EMPRESA_VER_CONTACTO } from "../../utils/graphql_query";
import { DELETE_GRUPO_EMPRESA_VER_CONTACTO } from "../../utils/graphql_mutation";
import { useQuery, useMutation } from "@apollo/client";
import _ from "lodash";
import NewGroupEmpresas from "./NewGroupEmpresas";
import ListGroupEmpresas from "./ListGroupEmpresas";
import ButtonIcon from "../base/ButtonIcon";
import swal from "sweetalert";

const CrudGrupoEmpresaVisualizar = ({ empresaId, onClose }) => {
  // STATE
  const [selected, setSelected] = useState([]);
  const [modeAdd, setModeAdd] = useState(false);
  // QUERY
  const { loading, data, refetch } = useQuery(GET_GRUPO_EMPRESA_VER_CONTACTO, {
    variables: { empresa_id: empresaId },
  });

  // MUTATION
  const [deleteGrupoEmpresaVerContacto] = useMutation(
    DELETE_GRUPO_EMPRESA_VER_CONTACTO
  );

  // FUNTIOM
  const handleSelected = (params) => {
    setSelected(params);
    setModeAdd(false);
  };
  const handleDelete = async (params) => {
    swal({
      icon: "warning",
      title: "Estas seguro en eliminar?",
      text: `${_.upperCase(selected.nombre)}`,
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Cancelar",
          visible: true,
        },
        confirm: {
          text: "Eliminar",
          value: true,
          visible: true,
          closeModal: false,
        },
      },
    }).then(async (val) => {
      if (val) {
        await deleteGrupoEmpresaVerContacto({
          variables: { id: selected.id },
        });
        refetch();
        setSelected([]);
        swal.stopLoading();
        swal.close();
      }
    });
  };
  // EFFECT
  useEffect(() => {
    if (data && _.size(data.getGrupoEmpresaVerContactoByEmpresaId) > 0) {
      // setSelected(data.getGrupoEmpresaVerContactoByEmpresaId[0]);
    }
  }, [data]);
  // RENDER

  return (
    <>
      <Modal
        show={true}
        backdrop="static"
        keyboard={false}
        onHide={onClose}
        size="lg"
        style={{ zIndex: 1040 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Grupo de empresas para ver contacto</Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-4" style={{ backgroundColor: "whitesmoke" }}>
          {loading ? (
            <LoadingPage size={20} />
          ) : (
            <>
              {data &&
              _.size(data.getGrupoEmpresaVerContactoByEmpresaId) > 0 ? (
                <Row>
                  <Col sm="5">
                    <ListGroup>
                      {_.map(
                        data.getGrupoEmpresaVerContactoByEmpresaId,
                        (grupo, kg) => {
                          return (
                            <ListGroup.Item
                              key={kg}
                              action
                              eventKey={kg}
                              onClick={() => handleSelected(grupo)}
                            >
                              <Row>
                                <Col sm="10">{grupo.nombre}</Col>
                                <Col sm="2">
                                  <i className="fas fa-chevron-right"></i>
                                </Col>
                              </Row>
                            </ListGroup.Item>
                          );
                        }
                      )}
                    </ListGroup>
                    <div className="mt-2">
                      <ButtonIcon
                        titulo="Agregar nuevo grupo"
                        icono="fa fa-plus"
                        variant="primary"
                        className="me-2"
                        size="sm"
                        onClick={() => setModeAdd(true)}
                      />

                      <ButtonIcon
                        titulo="Eliminar grupo seleccionado"
                        icono="fa fa-trash"
                        variant="danger"
                        size="sm"
                        onClick={() => handleDelete()}
                        disabled={_.size(selected) === 0}
                      />
                    </div>
                  </Col>
                  <Col sm="7">
                    <ListGroupEmpresas
                      selected={selected}
                      empresaId={empresaId}
                      refetch={refetch}
                    />
                  </Col>
                </Row>
              ) : (
                <ContainerNotResult
                  icon="fas fa-city"
                  mensaje={`No existe Grupo de Empresas`}
                  classContainer="pt-5 bg-transparent text-secondary"
                >
                  <Row>
                    <Col md={{ span: 6, offset: 3 }}>
                      <NewGroupEmpresas
                        empresaId={empresaId}
                        placeholder="Escribir nombre del grupo"
                        refetchGroup={refetch}
                        onCloseAdd={() => setModeAdd(false)}
                      />
                    </Col>
                  </Row>
                </ContainerNotResult>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
      {modeAdd && (
        <Modal
          centered
          show={true}
          backdrop="static"
          keyboard={false}
          onHide={() => setModeAdd(false)}
          style={{ zIndex: 1040 }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Nuevo Grupo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <NewGroupEmpresas
              empresaId={empresaId}
              placeholder="Escribir nombre del grupo"
              refetchGroup={refetch}
              onCloseAdd={() => setModeAdd(false)}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default CrudGrupoEmpresaVisualizar;
